import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import AuctionBlock from "../../components/auction/AuctionBlock";
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import {
  ITEM_URL,
  NEWLY_ADDED_AUCTIONS_URL,
  POPULAR_AUCTIONS_URL,
} from "../../Constants";
import Layout from "../../layout/Layout";
import { LibraryService } from "../../services/LibraryService";
import AuctionsPanel from "../home/AuctionsPanel";
import ZImageCarousal from "./ZImageCarousal";
import classes from "./Auction.module.css";
import clock from "../../assets/images/search-clock.svg";
import LinkButton from "../../components/button/LinkButton";
import TitleUnderline from "../../components/common/TitleUnderline";
import { ItemService } from "../../services/SearchService";
import Map from "./Map";
import { useAuthContext } from "../../redux/auth-context";
import { da } from "date-fns/locale";
import Question from "./Question";
import { userAddQuestion } from "../../services/AuctionService";
import { shallowEqual, useSelector } from "react-redux";
import AuctionBid from "./AuctionBid";
import MyTimer from "./MyTimer";
import BuyNow from "./BuyNow";
import { isSeller } from "../../services/Utilities";
import { ITEM_TERMS_AND_CONDITIONS_URL } from "../../Constants";

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const stringsSomeWithHtml = (html) => {
  return <Fragment>{html}</Fragment>;
};

const SellerTerms = () => {
  const location = useLocation();
  const ctx = useAuthContext();

  const [sellerTerms, setSellerTerms] = useState();
  let params = useParams();

  const { isAuthorized, viewExpired, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      viewExpired: auth.viewExpired,
      user: auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    fetchItemDetails(params.item);
  }, [params.item]);
  
  const fetchItemDetails = (itemId) => {
    ctx.showProgress(true);
    ItemService(ITEM_TERMS_AND_CONDITIONS_URL + itemId, isAuthorized)
      .then((data) => {
        setSellerTerms(data.termsAndConditions);
        ctx.showProgress(false);
      })
      .catch((e) => {
        console.log(e);
        setSellerTerms(null);
        ctx.showProgress(false);
      });
  };

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <h4>Seller Terms & Conditions</h4>
            <TitleUnderline />
            <div className="mt-2" dangerouslySetInnerHTML={{ __html: sellerTerms }} />
            {/* <div className="mt-2" dangerouslySetInnerHTML={{ __html: location.state.term }} /> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SellerTerms;
