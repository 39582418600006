import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import PlaceholderHeading from "../credit-card/PlaceholderHeading";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import { formatUSD, isStopFlowConditionMet } from "../../services/Utilities";
import * as Yup from "yup";
import { actionTypes } from "../_redux/wizardRedux";
import { useDispatch } from "react-redux";
import { AUCTION_ITEM_SALES_REPORT_URL } from "../../Constants";
import { fetchRecords } from "../../services/ReportsService";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Formik } from "formik";
import { Select } from "../../components/Select";
import ZDatePicker from "../../components/form/ZDatePicker";
import Label from "../../components/UI/Label";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import WizardButtons from "../seller-listing/wizard/WizardButtons";
import moment from "moment";

const SalesReport = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();
  const [values, setValues] = useState([]);

  const fetchReportData = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam =
        "?" +
        Object.keys(values)
          .map((key) => key + "=" + values[key])
          .join("&");

      const rs = await fetchRecords(
        AUCTION_ITEM_SALES_REPORT_URL + statusParam + pagingParams
      );
      setItemList(rs?.data);

      setTotal(rs.total);

      const pages = Math.ceil((rs.total ?? 0) / (pageSize ?? 1));
      setTotalPages(pages);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchReportData();
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchReportData();
  }, [values]);

  const filterInitialValues = {
    endedFrom: "",
    endedTo: "",
    lot: "",
    invoiceNumber: "",
    title: "",
    winningAmountFrom: "",
    winningAmountTo: "",
    totalAmountFrom: "",
    totalAmountTo: "",
    bidderFirstName: "",
    bidderLastName: "",
    paymentStatus: "",
  };

  const filterValidationSchema = Yup.object().shape({
    endedFrom: Yup.date().typeError("Invalid date"),
    endedTo: Yup.date().typeError("Invalid date"),
    lot: Yup.string(),
    invoiceNumber: Yup.string(),
    title: Yup.string(),
    winningAmountFrom: Yup.number().typeError("Invalid amount"),
    winningAmountTo: Yup.number().typeError("Invalid amount"),
    totalAmountFrom: Yup.number().typeError("Invalid amount"),
    totalAmountTo: Yup.number().typeError("Invalid amount"),
    bidderFirstName: Yup.string(),
    bidderLastName: Yup.string(),
    paymentStatus: Yup.string(),
  });

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu reportsActive subMenu="sales-report" />
            </div>

            <div className="col-lg-9 col-md-8">
              <PlaceholderHeading title="Sales Report"></PlaceholderHeading>
              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12 mt-4">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={filterInitialValues}
                    validationSchema={filterValidationSchema}
                    onSubmit={(values) => {
                      console.log("submitted values", values);

                      const submitted = {
                        ...values,
                        endedFrom: values?.endedFrom
                          ? moment(new Date(values?.endedFrom)).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                        endedTo: values?.endedTo
                          ? moment(new Date(values?.endedTo)).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                      };

                      const valArr = [];
                      for (const [key, value] of Object.entries(submitted)) {
                        console.log(`${key}: ${value}`);
                        valArr[key] = value;
                      }

                      setValues(valArr);
                    }}
                  >
                    {({
                      handleSubmit,
                      resetForm,
                      errors,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form>
                        <div className="row">
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Label>Ended On</Label>
                              <ZDatePicker
                                dateFormat="MM/dd/yyyy"
                                errors={errors}
                                placeholderText="From"
                                maxDate={new Date()}
                                name="endedFrom"
                              />
                              <div className="mb-2" />

                              <ZDatePicker
                                dateFormat="MM/dd/yyyy"
                                errors={errors}
                                placeholderText="To"
                                name="endedTo"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="lot"
                                component={ZInputComponent}
                                placeholder="Enter Listing ID"
                                label="Listing ID"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="invoiceNumber"
                                component={ZInputComponent}
                                placeholder="Enter Invoice #"
                                label="Invoice #"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="title"
                                component={ZInputComponent}
                                placeholder="Enter Listing Title"
                                label="Listing Title"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="winningAmountFrom"
                                component={ZInputComponent}
                                placeholder="From"
                                label="Winning Amount"
                              />

                              <div className="mb-2" />

                              <Field
                                className="form-control"
                                name="winningAmountTo"
                                component={ZInputComponent}
                                placeholder="To"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="totalAmountFrom"
                                component={ZInputComponent}
                                placeholder="From"
                                label="Total Amount"
                              />

                              <div className="mb-2" />

                              <Field
                                className="form-control"
                                name="totalAmountTo"
                                component={ZInputComponent}
                                placeholder="To"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3"></div>
                          <div className="col-12 col-lg-3"></div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="bidderFirstName"
                                component={ZInputComponent}
                                placeholder="First Name"
                                label="Bidder First Name"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                name="bidderLastName"
                                component={ZInputComponent}
                                placeholder="Last Name"
                                label="Bidder Last Name"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="js-form-message mb-4">
                              <Select
                                name="paymentStatus"
                                className="form-control"
                                label="Invoice Status"
                                defaultTouch
                                onChange={(e) => {
                                  setFieldValue(
                                    "paymentStatus",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="" disabled>
                                  Select Invoice Status
                                </option>

                                <option value={"ALL"}>All</option>
                                <option value={"PAYMENT_COMPLETED"}>
                                  Payment Completed
                                </option>
                                <option value={"PENDING"}>
                                  Payment Pending
                                </option>
                              </Select>
                            </div>
                          </div>
                          <div className="col-12 col-lg-3"></div>
                        </div>

                        <div className="js-form-message mb-4 row">
                          <div className="col-12">
                            <WizardButtons direction="right">
                              <button
                                className="btn btn-secondary px-5"
                                type="button"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  resetForm();
                                  setValues([]);
                                }}
                              >
                                Reset Filters
                              </button>

                              <button
                                className="btn btn-primary-dark-w px-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let res = await validateForm();
                                  console.log("errors", res);
                                  if (res && Object.values(res)?.length > 0) {
                                  }
                                  handleSubmit(e);
                                }}
                              >
                                Apply Filters
                              </button>
                            </WizardButtons>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-3">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <SalesReport.TableBodyRow
                          key={i}
                          invoiceId={item.invoiceId}
                          invoiceNumber={item.invoiceNumber}
                          auctionType={item.saleType}
                          title={item.title}
                          lot={item.lot}
                          endDate={item.endedIn}
                          startingPrice={item.price}
                          totalPrice={item.total}
                          currency={item.currency}
                          buyerName={item.bidderName}
                          buyerUsername={item.bidderUsername}
                          invoiceStatus={item.paymentStatus}
                          itemStatus={item.itemStatus}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <SalesReport.MobileBodyRow
                          key={i}
                          invoiceId={item.invoiceId}
                          invoiceNumber={item.invoiceNumber}
                          auctionType={item.saleType}
                          title={item.title}
                          lot={item.lot}
                          endDate={item.endedIn}
                          startingPrice={item.price}
                          totalPrice={item.total}
                          currency={item.currency}
                          buyerName={item.bidderName}
                          buyerUsername={item.bidderUsername}
                          invoiceStatus={item.paymentStatus}
                          itemStatus={item.itemStatus}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

SalesReport.TableBodyRow = ({
  invoiceId,
  invoiceNumber,
  auctionType,
  title,
  lot,
  endDate,
  startingPrice,
  totalPrice,
  currency,
  buyerName,
  buyerUsername,
  invoiceStatus,
  itemStatus,
}) => {
  return (
    <>
      <tr>
        <td>
          <div className={`${tableClasses.label}`}>
            <span className="text-uppercase font-weight-bold">
              {(itemStatus === "ACTIVE" ||
                itemStatus === "DRAFT" ||
                itemStatus === "SCHEDULED") &&
                itemStatus}
              {itemStatus === "TIMED_OUT" && "TIME ENDED"}
              {itemStatus === "FINALIZING_WINNER" && "WAITING FOR PAYMENT"}
              {itemStatus === "WAITING_TO_RECEIVE_PAYMENT" && "CONFIRM PAYMENT"}
              {itemStatus === "SUCCESSFUL_ENDED" && "SOLD"}
              {itemStatus === "UNSUCCESSFUL_ENDED" && "UNSOLD"}
            </span>{" "}
            |{" "}
            <span className="text-capitalize">
              {auctionType.replaceAll("_", " ").toLowerCase()}
            </span>
          </div>
          <div className={tableClasses.heading}>{title}</div>
          <div className="mt-2">
            <span className={tableClasses.label}>Invoice# </span>
            <span className={tableClasses.value}> {invoiceNumber}</span>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Listing ID</div>
          <div className={tableClasses.value}>{lot}</div>
          <div className={tableClasses.label}>Ended On</div>
          <div className={tableClasses.value}>{endDate}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Buyer Name</div>
          <div className={tableClasses.value}>{buyerName}</div>
          <div className={tableClasses.label}>Email</div>
          <div className={tableClasses.value}>{buyerUsername}</div>
        </td>
        <td>
          <div className={tableClasses.label}>Price</div>
          <div className={tableClasses.value}>{formatUSD(startingPrice)}</div>
          <div className={tableClasses.label}>Total</div>
          <div className={tableClasses.value}>{formatUSD(totalPrice)}</div>
        </td>

        <td>
          {invoiceStatus === "PAYMENT_COMPLETED" && (
            <span
              className={`${tableClasses.badge} badge bg-success d-block py-2`}
            >
              PAID
            </span>
          )}

          {invoiceStatus !== "PAYMENT_COMPLETED" && (
            <span
              className={`${tableClasses.badge} badge bg-danger d-block py-2`}
            >
              UNPAID
            </span>
          )}
        </td>
      </tr>
    </>
  );
};

SalesReport.MobileBodyRow = ({
  invoiceId,
  invoiceNumber,
  auctionType,
  title,
  lot,
  endDate,
  startingPrice,
  totalPrice,
  currency,
  buyerName,
  buyerUsername,
  invoiceStatus,
  itemStatus,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={`${tableClasses.label} `}>
              <span className="text-uppercase font-weight-bold">
                {(itemStatus === "ACTIVE" ||
                  itemStatus === "DRAFT" ||
                  itemStatus === "SCHEDULED") &&
                  itemStatus}
                {itemStatus === "TIMED_OUT" && "TIME ENDED"}
                {itemStatus === "FINALIZING_WINNER" && "WAITING FOR PAYMENT"}
                {itemStatus === "WAITING_TO_RECEIVE_PAYMENT" &&
                  "CONFIRM PAYMENT"}
                {itemStatus === "SUCCESSFUL_ENDED" && "SOLD"}
                {itemStatus === "UNSUCCESSFUL_ENDED" && "UNSOLD"}
              </span>{" "}
              |{" "}
              <span className="text-capitalize">
                {auctionType.replaceAll("_", " ").toLowerCase()}
              </span>
            </div>
            <div className={tableClasses.heading}>{title}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Invoice #</div>
            <div className={tableClasses.value}>{invoiceNumber}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing ID</div>
            <div className={tableClasses.value}>{lot}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Ended On</div>
            <div className={tableClasses.value}>{endDate}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Buyer Name</div>
            <div className={tableClasses.value}>{buyerName}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Email</div>
            <div className={tableClasses.value}>{buyerUsername}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Price</div>
            <div className={tableClasses.value}>{formatUSD(startingPrice)}</div>
          </div>
        </div>
      </div>

      <div className={`${tableClasses.block} ${tableClasses.bordered}`}>
        <div className={tableClasses.totalRow}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Total</div>
            <div className={tableClasses.value}>{formatUSD(totalPrice)}</div>
          </div>
          <div className={tableClasses.buttons}>
            {invoiceStatus === "PAYMENT_COMPLETED" && (
              <span
                className={`${tableClasses.badge} badge bg-success px-5 py-2`}
              >
                PAID
              </span>
            )}

            {invoiceStatus !== "PAYMENT_COMPLETED" && (
              <span
                className={`${tableClasses.badge} badge bg-danger px-5 py-2`}
              >
                UNPAID
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
