import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import PlaceholderHeading from "./PlaceholderHeading";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import { formatUSD, isStopFlowConditionMet } from "../../services/Utilities";
import {
  BIDDER_DASHBOARD_RECENT_LISTINGS_URL,
  BIDDER_DASHBOARD_STATS_ACCOUNT_UPDATES_URL,
  BIDDER_DASHBOARD_STATS_STATUS_URL,
} from "../../Constants";
import { fetchRecords } from "../../services/DashboardService";
import DashboardCard from "./DashboardCard";
import CardHeading from "./CardHeading";
import ListingInfoBlocks from "./ListingInfoBlocks";
import ListingInfoBlock from "./ListingInfoBlock";
import LabelValue from "./LabelValue";
import DetailLinkDashboardCard from "./DetailLinkDashboardCard";
import DetailedLink from "./DetailedLink";
import ListItem from "./ListItem";
import ListCard from "./ListCard";
import LastDaysSaleBarChart from "./LastDaysSaleBarChart";
import classes from "./dashboard.module.css";

const Dashboard = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();

  const [statusStats, setStatusStats] = useState([]);
  const [accountUpdate, setAccountUpdate] = useState([]);

  const [itemList, setItemList] = useState();

  const getValueOrZero = (key, arr) => {
    return arr[key] ?? "Pending";
  };

  const getValuesOrZero = (value) => {
    return value ?? 0;
  };

  const fetchDashboardData = async () => {
    ctx.showProgress(true);
    try {
      const response = await Promise.all([
        fetchRecords(BIDDER_DASHBOARD_STATS_STATUS_URL),
        fetchRecords(BIDDER_DASHBOARD_STATS_ACCOUNT_UPDATES_URL),
        fetchRecords(BIDDER_DASHBOARD_RECENT_LISTINGS_URL),
      ]);

      const itemStatusesCount = [];
      for (const [key, value] of Object.entries(response[0])) {
        itemStatusesCount[value.key] = value.value;
      }
      setStatusStats(itemStatusesCount);
      setAccountUpdate(response[1]);
      setItemList(response[2]);
    } catch (e) {
      console.log("error", e);
      if (isStopFlowConditionMet(e.message)) return;
      // navigate("/error");
    }
    ctx.showProgress(false);
  };

  useEffect(() => {
    async function fetchData() {
      ctx.showProgress(true);
      await fetchDashboardData();
      ctx.showProgress(false);
    }

    fetchData();

    const updateInterval = setInterval(fetchDashboardData, 60000);

    return () => {
      if (updateInterval) clearInterval(updateInterval);
    };
  }, []);

  return (
    <>
      <Layout grey>
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col">
              <PlaceholderHeading title="Dashboard" headingSize="sm">
                <Link
                  onClick={() => fetchDashboardData()}
                  className="font-size-16 ml-1"
                >
                  <i class="fas fa-sync fa-rotate-180"></i> Refresh
                </Link>

                <Link
                  to="/open-bids"
                  className="font-size-16 float-right"
                >
                  Back to My Bids
                </Link>
              </PlaceholderHeading>

              <div className="row">
                <div className="col-lg-4 col-12 mb-2">
                  <DetailLinkDashboardCard
                    height="820"
                    card={
                      <DashboardCard>
                        <CardHeading heading="Bidder Account Status" />

                        <ListingInfoBlocks>
                          <div className={classes.listingInfoBlock}>
                            <div className={`col-8 ${classes.label}`}>
                              Identity Verification
                            </div>
                            <div className={`col-4 text-center ${classes.value}`}  onClick={() => {
                                  if(getValueOrZero("IV", statusStats) === "Approved" || getValueOrZero("IV", statusStats) === "Submitted")
                                    navigate("/verify-identity");
                                  else
                                    navigate("/verify-identity-welcome");
                              }}
                            >
                              {getValueOrZero("IV", statusStats) ===
                              "Approved" ? (
                                <>
                                  <div className="badge bg-success px-2 py-1 font-size-14 d-block">
                                    {getValueOrZero("IV", statusStats)}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                                    {getValueOrZero("IV", statusStats)}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className={classes.listingInfoBlock}>
                            <div className={`col-8 ${classes.label}`}>
                              Credit Card
                            </div>
                            <div
                              className={`col-4 text-center ${classes.value}`}  onClick={() => {
                                navigate("/add-card-info");
                              }}
                            >
                              {getValueOrZero("CC", statusStats) === "Added" ? (
                                <>
                                  <div className="badge bg-success px-2 py-1 font-size-14 d-block">
                                    {getValueOrZero("CC", statusStats)}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                                    {getValueOrZero("CC", statusStats)}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </ListingInfoBlocks>

                        <CardHeading marginTop="40" heading="Account Updates" />
                        <div className="row">
                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/wish-list"
                                label="Watch List"
                                value={getValuesOrZero(accountUpdate.watchList)}
                              />
                            </DashboardCard>
                          </div>
                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/open-bids"
                                label="Open Lots"
                                value={getValuesOrZero(accountUpdate.openLots)}
                              />
                            </DashboardCard>
                          </div>
                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/bids-won"
                                label="Lots Won"
                                value={getValuesOrZero(accountUpdate.wonLots)}
                              />
                            </DashboardCard>
                          </div>

                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/bids-won"
                                label="Lots Completed"
                                value={getValuesOrZero(
                                  accountUpdate.completedLots
                                )}
                              />
                            </DashboardCard>
                          </div>
                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/bids-not-won"
                                label="Lots Not Won"
                                value={getValuesOrZero(
                                  accountUpdate.notWonLots
                                )}
                              />
                            </DashboardCard>
                          </div>
                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/open-offers"
                                label="Open Offers"
                                value={getValuesOrZero(
                                  accountUpdate.openOffers
                                )}
                              />
                            </DashboardCard>
                          </div>

                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/offers-won"
                                label="Offers Won"
                                value={getValuesOrZero(accountUpdate.wonOffers)}
                              />
                            </DashboardCard>
                          </div>
                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/offers-not-won"
                                label="Offers Not Won"
                                value={getValuesOrZero(
                                  accountUpdate.notWonOffers
                                )}
                              />
                            </DashboardCard>
                          </div>
                          <div className="col-lg-4 col-12 pb-2">
                            <DashboardCard
                              height="90"
                              border="1px solid #dbebfa"
                            >
                              <LabelValue
                                url="/item-bought"
                                label="Items Bought"
                                value={getValuesOrZero(
                                  accountUpdate.itemsBought
                                )}
                              />
                            </DashboardCard>
                          </div>
                        </div>

                        <CardHeading
                          marginTop="40"
                          heading="Actions Required"
                        />

                        <ListingInfoBlocks>
                          <ListingInfoBlock
                            url="/bids-won"
                            label="Pending Payments - Lots Won"
                            value={getValuesOrZero(
                              accountUpdate.pendingPayments
                            )}
                          />
                          <ListingInfoBlock
                            url="/item-bought-checkout"
                            label="Pending Payments - Buy Now"
                            value={getValuesOrZero(
                              accountUpdate.pendingPaymentsBuyNow
                            )}
                          />
                          <ListingInfoBlock
                            url="/bids-won"
                            label="Pending Pickup - Lots Won"
                            value={getValuesOrZero(
                              accountUpdate.pendingPickups
                            )}
                          />
                          <ListingInfoBlock
                            url="/item-bought-checkout"
                            label="Pending Pickup - Buy Now"
                            value={getValuesOrZero(
                              accountUpdate.pendingPickupsBuyNow
                            )}
                          />
                          <ListingInfoBlock
                            url="/inbox"
                            label="Seller Messages"
                            value={getValuesOrZero(
                              accountUpdate.sellerMessages
                            )}
                          />
                        </ListingInfoBlocks>
                        <br></br>
                      </DashboardCard>
                    }
                  ></DetailLinkDashboardCard>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="row">
                    <div className="col-12">
                      <DashboardCard>
                        {itemList && Object.keys(itemList)?.length > 0 ? (
                          <>
                            <CardHeading heading="Bidding Tracker : Live Bidding" />
                            <ListingTable
                              tableBody={itemList?.map((item, i) => {
                                return (
                                  <Dashboard.TableBodyRow
                                    key={i}
                                    itemId={item.auction.itemId}
                                    auctionType={item.auction.type}
                                    image={item.auction.defaultImage}
                                    title={item.auction.title}
                                    lot={item.auction.lot}
                                    listingDate={item.auction.createdOn}
                                    startDate={item.auction.startedOn}
                                    endDate={item.auction.endedOn}
                                    startingPrice={item.auction.price}
                                    bidAmount={item.bidAmount}
                                    currentPrice={item.maxBidAmount}
                                    reserveMet={item.auction.reserveMet}
                                    reservePrice={item.auction.reservePrice}
                                    currency={item.auction.currency}
                                    bids={item.auction.bids}
                                    watching={item.auction.watching}
                                    status={item.auction.status}
                                    highestBidder={item.auction.highestBidder}
                                  />
                                );
                              })}
                              mobileBody={itemList?.map((item, i) => {
                                return (
                                  <Dashboard.MobileBodyRow
                                    key={i}
                                    itemId={item.auction.itemId}
                                    auctionType={item.auction.type}
                                    image={item.auction.defaultImage}
                                    title={item.auction.title}
                                    lot={item.auction.lot}
                                    listingDate={item.auction.createdOn}
                                    startDate={item.auction.startedOn}
                                    endDate={item.auction.endedOn}
                                    startingPrice={item.auction.price}
                                    bidAmount={item.bidAmount}
                                    currentPrice={item.maxBidAmount}
                                    reserveMet={item.auction.reserveMet}
                                    reservePrice={item.auction.reservePrice}
                                    currency={item.auction.currency}
                                    bids={item.auction.bids}
                                    watching={item.auction.watching}
                                    status={item.auction.status}
                                    highestBidder={item.auction.highestBidder}
                                  />
                                );
                              })}
                            />
                            </>
                        ) : (
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center mt-4">You are currently not participating in online auction for any item. </h5>
                            </div>
                            <div className="col-12 mb-4" style={{textAlign: "-webkit-center"}}>
                              <Link to="/" className="btn-block btn btn-primary-dark-w px-5 w-30 mt-2">
                              Start Bidding Now
                              </Link>
                            </div>
                          </div>
                        )}

                      </DashboardCard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

Dashboard.TableBodyRow = ({
  itemId,
  auctionType,
  image,
  title,
  lot,
  listingDate,
  startDate,
  endDate,
  startingPrice,
  currentPrice,
  reservePrice,
  reserveMet,
  currency,
  bids,
  watching,
  status,
  highestBidder,
  bidAmount,
}) => {
  return (
    <>
      <tr>
        <td>
          {image && (
            <div
              className={tableClasses.imgContainer}
              style={{ backgroundImage: `url(${image})` }}
            />
          )}
        </td>

        <td>
          <div className={`${tableClasses.label}`}>
            <span className="text-capitalize">{auctionType}</span>
          </div>
          <div className={tableClasses.heading}>
            {status === "ACTIVE" && (
              <Link to={`/auction/${itemId}`}>{title}</Link>
            )}
            {status !== "ACTIVE" && title}
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>Listing ID: </span>
            <span className={tableClasses.value}> {lot}</span>
          </div>
          <div>
            <span className={tableClasses.label}>Posting Dated: </span>
            <span className={tableClasses.value}> {listingDate}</span>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Start Date</div>
          <div className={tableClasses.value}>{startDate}</div>
          <div className={tableClasses.label}>Ending Date</div>
          <div className={tableClasses.value}>{endDate}</div>
          <div className="mt-4">
            <>
              {highestBidder ? (
                <div className="badge bg-success px-2 py-1 font-size-14 d-block">
                  {highestBidder}
                </div>
              ) : (
                <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                  Out Bid
                </div>
              )}
            </>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Starting Price</div>
          <div className={tableClasses.value}>{formatUSD(startingPrice)}</div>
          <div className={tableClasses.label}>Highest Bid | My Bid</div>
          <div className={tableClasses.value}>{formatUSD(currentPrice)} | {formatUSD(bidAmount)}</div>
          <div className="mt-4">
            {auctionType === "ONLINE AUCTION" &&
              (status === "ACTIVE" ||
                status === "TIMED_OUT" ||
                status === "FINALIZING_WINNER" ||
                status === "INVOICE_GENERATED" ||
                status === "WAITING_TO_RECEIVE_PAYMENT") && (
                <>
                  {reserveMet ? (
                    <div className="badge bg-success px-2 py-1 font-size-14 d-block">
                      Reserve is Met
                    </div>
                  ) : (
                    <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                      Reserve Not Met
                    </div>
                  )}
                </>
              )}
          </div>
        </td>
        {status !== "DRAFT" && status !== "SCHEDULED" && (
          <>
            <td>
              {(auctionType === "ONLINE AUCTION" ||
                auctionType === "SEALED AUCTION") && (
                <div className={tableClasses.label}>Bids</div>
              )}
              {auctionType === "MAKE AN OFFER" && (
                <div className={tableClasses.label}>Offers</div>
              )}
              <div className={tableClasses.value}>{bids}</div>

              {(status === "ACTIVE" || status === "TIMED_OUT") && (
                <>
                  <div className={tableClasses.label}>Watching</div>
                  <div className={tableClasses.value}>{watching}</div>
                </>
              )}

              <div className="mt-4">
                <Link
                  to={`${
                    status === "ACTIVE"
                      ? "/auction/" + itemId
                      : "/auction-item/" + itemId
                  }`}
                  className="btn-block btn btn-primary-dark-w px-0 py-0"
                >
                  View Item
                </Link>
              </div>
            </td>
          </>
        )}
      </tr>
    </>
  );
};

Dashboard.MobileBodyRow = ({
  itemId,
  auctionType,
  image,
  title,
  lot,
  listingDate,
  startDate,
  endDate,
  startingPrice,
  currentPrice,
  reservePrice,
  reserveMet,
  currency,
  bids,
  watching,
  status,
  highestBidder,
  bidAmount,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={`${tableClasses.label} `}>
              <span className="text-capitalize">{auctionType}</span>
            </div>
            <div className={tableClasses.heading}>
              <Link
                to={`${
                  status === "ACTIVE"
                    ? "/auction/" + itemId
                    : "/auction-item/" + itemId
                }`}
              >
                {title}
              </Link>
            </div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing ID</div>
            <div className={tableClasses.value}>{lot}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing Date</div>
            <div className={tableClasses.value}>{listingDate}</div>
          </div>
          {startDate && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Start Date</div>
              <div className={tableClasses.value}>{startDate}</div>
            </div>
          )}

          {endDate && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Ending Date</div>
              <div className={tableClasses.value}>{endDate}</div>
            </div>
          )}

          {startingPrice && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Starting Price</div>
              <div className={tableClasses.value}>
                {formatUSD(startingPrice)}
              </div>
            </div>
          )}

          {currentPrice ? (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Highest Bid | My Bid</div>
              <div className={tableClasses.value}>
                {formatUSD(currentPrice)} | {formatUSD(bidAmount)}
              </div>
            </div>
          ) : (
            ""
          )}

          {auctionType === "ONLINE AUCTION" &&
            (status === "ACTIVE" ||
              status === "TIMED_OUT" ||
              status === "FINALIZING_WINNER" ||
              status === "INVOICE_GENERATED" ||
              status === "WAITING_TO_RECEIVE_PAYMENT") && (
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Reserve Price</div>
                <div className={tableClasses.value}>
                  <div className="row">
                    <div className="col">{formatUSD(reservePrice)}</div>
                    <div className="col-auto">
                      {reserveMet && (
                        <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                          Reserve is Met
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {status !== "DRAFT" && status !== "SCHEDULED" && (
            <>
              <div className={tableClasses.item}>
                {(auctionType === "ONLINE AUCTION" ||
                  auctionType === "SEALED AUCTION") && (
                  <div className={tableClasses.label}>Bids</div>
                )}
                {auctionType === "MAKE AN OFFER" && (
                  <div className={tableClasses.label}>Offers</div>
                )}
                <div className={tableClasses.value}>{bids}</div>
              </div>

              {(status === "ACTIVE" || status === "TIMED_OUT") && (
                <div className={tableClasses.item}>
                  <div className={tableClasses.label}>Watching</div>
                  <div className={tableClasses.value}>{watching}</div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
