import React from "react";
import DatePicker from "react-datepicker";

const SearchMinMaxInput = ({
  field,
  label,
  required,
  placeholder,
  type = "text",
  className = "form-control",
  handleChange,
  ...props
}) => {
  return (
    <input
      type={"number"}
      min={1}
      {...field}
      {...props}
      className={className}
      placeholder={placeholder}
      onChange={(evt) => handleChange(evt)}
    />
  );
};

export default SearchMinMaxInput;
