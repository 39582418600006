import React from "react";
import AlertCommon from "../errors/AlertCommon";
import successIcon from "../../assets/images/success-icon.svg";
import Layout from "../../layout/Layout";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const CashierChequePaymentStatusMessage = () => {
  const params = useLocation()?.state ?? {};

  useEffect(() => {
    console.log(params);
  }, [params]);

  const description =
    "This confirms that we’ve just received your copy of cashier cheque. You can download Payment Receipt for future reference. Thank you for bidding on BidlyAuction.";
  const heading = "Thank your for your payment!";

  return (
    <>
      <Layout>
        <div className="container mt-10 mb-10">
          <div className="row">
            <div className="offset-xl-3 col-xl-6">
              <AlertCommon
                description={description}
                heading={heading}
                image={successIcon}
                hideButton={true}
              />

              <div className="d-flex gap-10 justify-content-center flex-column-reverse flex-sm-row">
                <>
                  <Link
                    to={params?.from?.url}
                    className="btn btn-secondary px-5"
                  >
                    Back to {params?.from?.type === "bids" ? "My Bids" : ""}
                    {params?.from?.type === "offers" ? "My Offers" : ""}
                    {params?.from?.type === "buy_now" ? "My Bought Items" : ""}
                  </Link>
                  <a
                    href={params?.status?.receiptURL}
                    className="btn btn-primary-dark-w px-5"
                    target="_blank"
                    download={true}
                  >
                    Download Payment Receipt
                  </a>
                </>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CashierChequePaymentStatusMessage;
