import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.scss";
import classes from "./Search.module.css";

export const PaginationComponent = ({
  onPageChange = (page) => {},
  totalCount = 0,
  siblingCount = 1,
  currentPage = 1,
  pageSize = 10,
  className,
  showNumbers = true,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {showNumbers &&
        paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} className="pagination-item dots">
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={index}
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export const Pagination = ({ currentPage = 1, total = 1, pageSize = 10 }) => {
  let myPage = currentPage - 1;

  let start = myPage == 0 ? 1 : myPage * pageSize;
  let end = currentPage * pageSize;

  return (
    <p className={`${classes.paginationLabel} mb-0`}>
      Showing {start}–{total < end ? total : end} of {total} results
    </p>
  );
};
