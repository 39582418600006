import React from "react";
import classes from "./dashboard.module.css";

const CardHeading = ({ heading, children, marginTop }) => {
  const cardHeadingStyle = marginTop ? { marginTop: marginTop + "px" } : {};
  return (
    <>
      <div className={classes.doubleHeading} style={cardHeadingStyle}>
        <h3 className={classes.dashboardCardHeading}>{heading}</h3>
        {children}
      </div>
    </>
  );
};

export default CardHeading;
