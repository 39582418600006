import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./Listing.module.css";
import {
  formatUSD,
  formattedUTCDatetoUSCentral,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import {
  chooseAuctionWinner,
  fetchMyItem,
  itemPickupConfirmation,
  sendAuctionInvoice,
} from "../../services/AuctionItemService";
import {
  SELLER_AUCTION_ITEM_DETAIL_URL,
  SELLER_AUCTION_SEND_INVOICE_URL,
} from "../../Constants";
import ConfirmModal from "../../components/UI/ConfirmModal";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";
import Map from "../auction/Map";

const ListingPickupConfirm = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [itemDetail, setItemDetail] = useState({});
  const [mapItem, setMapItem] = useState({});

  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const fetchData = async (itemId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchMyItem(
        SELLER_AUCTION_ITEM_DETAIL_URL.replace("{itemId}", itemId)
      );
      console.log("item", rs);
      setItemDetail(rs);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const confirmItemPickup = async () => {
    ctx.showProgress(true);
    itemPickupConfirmation(itemDetail?.itemId)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/my-listing");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  useEffect(() => {
    if (params.item) fetchData(params.item);
  }, [params.item]);

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Do you confirm the bidder has received the item?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            confirmItemPickup();
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myItemsActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Item Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col-auto">
                  <div className="d-lg-none d-block mb-3">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.saleType
                            ?.toLowerCase()
                            ?.replaceAll("_", " ")}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div
                      className={classes.imgContainer}
                      style={{ background: `url(${itemDetail.defaultImage})` }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-lg-block d-none">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.saleType
                            ?.toLowerCase()
                            ?.replaceAll("_", " ")}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <h4>{itemDetail.title}</h4>

                  <div
                    className="instructions-desc"
                    dangerouslySetInnerHTML={{ __html: itemDetail.description }}
                  />

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Start Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.startDate)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>End Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.endDate)}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Buyer Name</div>
                      <div className={classes.value}>
                        {itemDetail.winningBid?.name}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Buyer Email</div>
                      <div className={classes.value}>
                        {itemDetail.winningBid?.email}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>
                        {itemDetail?.saleType === "BUY_NOW" && "Price"}
                        {itemDetail?.saleType !== "BUY_NOW" && "Starting Price"}
                      </div>
                      <div className={classes.value}>
                        {formatUSD(itemDetail.startingPrice)}
                      </div>
                    </div>
                    {itemDetail?.saleType !== "BUY_NOW" && (
                      <div className="col-auto my-2">
                        <div className={classes.label}>Reserve Price</div>
                        <div className={classes.value}>
                          {formatUSD(itemDetail.reservePrice)}
                        </div>
                      </div>
                    )}
                    <div className="col-auto my-2">
                      <div className={classes.label}>Winning Price</div>
                      <div className={classes.value}>
                        {formatUSD(itemDetail.winningBid?.amount)}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Payment Date</div>
                      <div className={classes.value}>
                        {formattedUTCDatetoUSCentral(itemDetail?.paymentDate)}
                      </div>
                    </div>

                    <div className="col-auto my-2">
                      <div className={classes.label}>
                        Payment Acknowledgment Date
                      </div>
                      <div className={classes.value}>
                        {formattedUTCDatetoUSCentral(
                          itemDetail?.paymentAcknowledgeDate
                        )}
                      </div>
                    </div>

                    <div className="col-auto my-2">
                      <div className={classes.label}>Payment Method</div>
                      <div className={classes.value}>
                        {itemDetail?.paymentMethod?.replaceAll("_", " ")}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Pickup Location</div>
                      <div className={classes.value}>
                        {itemDetail?.pickupLocation}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col my-2">
                      <Map
                        item={{
                          pickUpLat: itemDetail.pickupLocationLat,
                          pickUpLong: itemDetail.pickupLocationLong,
                          citiName:
                            itemDetail?.city?.value +
                            ", " +
                            itemDetail?.state?.value +
                            ", " +
                            itemDetail?.state?.valueTwo,
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-5">
                    <ButtonsPanel>
                      <Link
                        to="/my-listing"
                        className={`btn btn-secondary-dark-w ${buttonPanelClasses.item}`}
                      >
                        CANCEL
                      </Link>
                      <a
                        href="#"
                        className={`btn btn-primary-dark-w ${buttonPanelClasses.item}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowConfirmBox(true);
                        }}
                      >
                        CONFIRM PICKUP
                      </a>
                    </ButtonsPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ListingPickupConfirm;
