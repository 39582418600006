import React from "react";
import classes from "./PhoneCardsPanel.module.css";

const PhoneCardsPanel = (props) => {
  return (
    <>
      <div className={classes.phoneCardsContainer}>{props.children}</div>
    </>
  );
};

export default PhoneCardsPanel;
