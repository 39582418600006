import React from "react";
import classes from "./CreditCard.module.css";
import creditCardImage from "../../assets/images/credit-card.svg";
import editIcon from "../../assets/images/edit-icon.svg";

const CreditCard = ({
  isActive = true,
  identifier,
  cardNumber,
  validity,
  onRemove = () => {},
}) => {
  return (
    <div
      className={classes.cardDiv}
      style={{ backgroundImage: `url(${creditCardImage})` }}
    >
      <div className={classes.actionBar}>
        <div className={classes.info}>
          <span className={classes.status}>
            {isActive && "ACTIVE"} {!isActive && "INACTIVE"}
          </span>
        </div>
        <div className={classes.actionButton}>
          <span className={classes.remove} onClick={() => onRemove(identifier)}>
            <img src={editIcon} />
          </span>
        </div>
      </div>
      <div className={classes.heading}>Credit Card</div>
      <div className={classes.numberBar}>**** **** **** {cardNumber}</div>
      <div className={classes.validity}>
        Valid Upto:
        <span className={classes.date}>{validity}</span>
      </div>
    </div>
  );
};

export default CreditCard;
