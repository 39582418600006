import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../redux/auth-context";
import classes from "./VerticalMenu.module.css";

const VerticalMenu = ({
  verticalMenuVisible = false,
  nonClickable = false,
}) => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const menuRef = useRef(null);
  const [menuVisibility, setMenuVisibility] = useState(true);

  const documentClickEventHandler = (e) => {
    if (
      !menuRef?.current?.contains(e.target) &&
      menuVisibility &&
      !nonClickable
    ) {
      setMenuVisibility(false);
    }
  };

  useEffect(() => {
    setMenuVisibility(verticalMenuVisible);
  }, [verticalMenuVisible]);

  useEffect(() => {
    window.addEventListener("click", documentClickEventHandler);

    return () => {
      window.removeEventListener("click", documentClickEventHandler);
    };
  }, []);

  return (
    <>
      <div className="card border-0" ref={menuRef}>
        <div className="card-header card-collapse border-0">
          <button
            type="button"
            disabled={nonClickable}
            className={`btn-block d-flex py-3 text-lh-1 px-4 shadow-none btn-primary rounded-top-lg border-0 text-grey-90 ${
              nonClickable ? "no-click" : ""
            }`}
            onClick={() => {
              if (!nonClickable) setMenuVisibility((prev) => !prev);
            }}
          >
            <div
              className={`pl-1 text-white ${
                nonClickable && menuVisibility ? "" : "v-m-text"
              } ${menuVisibility ? "" : "closed"}`}
            >
              <span>Shop By Category</span>
            </div>
          </button>
        </div>
        <div
          className={`collapse vertical-menu v2 ${
            menuVisibility ? "show" : "hide"
          }`}
        >
          <div className="card-body p-0">
            <nav className="js-mega-menu navbar navbar-expand-xl u-header__navbar u-header__navbar--no-space hs-menu-initialized">
              <div className="collapse navbar-collapse u-header__navbar-collapse">
                <ul className="navbar-nav u-header__navbar-nav">
                  {ctx.menuData?.categories &&
                    ctx.menuData?.categories?.map((item, index) => {
                      return (
                        <li
                          key={item.id}
                          className={`nav-item hs-has-mega-menu u-header__nav-item ${classes.verticalMenuItem}`}
                          data-event="hover"
                          data-position="left"
                        >
                          <a
                            className={`nav-link u-header__nav-link u-header__nav-link-toggle ${classes.menuItem}`}
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {item.key} ({item.valueNumber})
                          </a>

                          {item.subList && (
                            <div
                              className={`hs-mega-menu vmm-tfw u-header__sub-menu custom-scroll-self ${classes.verticalMenuBlock}`}
                            >
                              <div
                                className={`row u-header__mega-menu-wrapper ${classes.verticalMenuBlockWrapper}`}
                              >
                                <div className="col mb-sm-0">
                                  <ul className="u-header__sub-menu-nav-group">
                                    {item.subList.map((subItem, index) => {
                                      return (
                                        index <= 4 && (
                                          <li key={subItem.id}>
                                            <a
                                              className="nav-link u-header__sub-menu-nav-link"
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setMenuVisibility(false);
                                                navigate("/search-result", {
                                                  state: {
                                                    filter:
                                                      "?sub_category=" +
                                                      subItem.id,
                                                  },
                                                });
                                              }}
                                            >
                                              {subItem.key} (
                                              {subItem.valueNumber})
                                            </a>
                                          </li>
                                        )
                                      );
                                    })}

                                    <li key={item?.subList?.length}>
                                      <a
                                        className={`nav-link u-header__sub-menu-nav-link ${classes.lastItem}`}
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigate("/search-result", {
                                            state: {
                                              filter: "?category=" + item.id,
                                            },
                                          });
                                        }}
                                      >
                                        View All {item.key} (
                                        {item.valueNumber})
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                      );
                    })}

                  <li
                    className={`nav-item hs-has-mega-menu u-header__nav-item ${classes.verticalMenuItem}`}
                    data-event="hover"
                    data-position="left"
                  >
                    <a
                      className={`nav-link u-header__nav-link ${classes.menuItem} ${classes.lastItem}`}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/categories");
                      }}
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      View All Categories
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerticalMenu;
