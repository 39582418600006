import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import storageSession from "redux-persist/lib/storage/session";

export const actionTypes = {
  ResetListingWizard: "Reset Listing Wizard States",
  UpdateListingWizard: "Update Listing Wizard States",
};

const initialAuthState = {
  listingWizardState: {},
};

export const reducer = persistReducer(
  {
    storage,
    key: "v10-listing-wizard",
    whitelist: ["listingWizardState"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.ResetListingWizard: {
        return {
          ...state,
          listingWizardState: {
            ...action.payload.wizardState,
          },
        };
      }
      case actionTypes.UpdateListingWizard: {
        return {
          ...state,
          listingWizardState: {
            ...state.listingWizardState,
            ...action.payload.wizardState,
          },
        };
      }
      default:
        return state;
    }
  }
);
