import { READ_USER_NOTIFICATION_URL } from "../Constants";
import authenticatedFetch, { simpleFetch } from "./fetch";

export const fetchNotifications = async (url) => {
  const response = await authenticatedFetch(
    `${url}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
    false,
    false
  );

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const readNotification = async (notificationId) => {
  const response = await authenticatedFetch(
    `${READ_USER_NOTIFICATION_URL.replace("{notificationId}", notificationId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
    false
  );

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
};
