import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import classes from "./Static.module.css";
import LinkButton from "../../components/button/LinkButton";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { isSeller } from "../../services/Utilities";
import { LibraryService } from "../../services/LibraryService";
import { ALL_REVIEWS } from "../../Constants";
import { useAuthContext } from "../../redux/auth-context";
import mobile from "../../assets/images/mobile_mockup.png";

const Buy = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <Layout>
      <>
        <div className="mb-5">
          <div className={`bg-img-hero ${classes.bannerImgBuy}`}>
            <div className="container min-height-300 overflow-hidden d-flex justify-content-md-start justify-content-start align-items-center">
              <div className={` ${classes.bannerTextContainer} col-md-7 mt-5`}>
                <h2 className={classes.heading}>Sign Up, Explore & Gain</h2>
                <p className={classes.para}>
                  entry to premier surplus equipment, conveniently consolidated
                  in a single location from our trustworthy and esteemed sellers
                </p>

                <div className="mb-4">
                  <LinkButton
                    type="secondary2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/account-type");
                    }}
                  >
                    Create a Bidder Account
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-7 mt-10">
          <div className="container min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start">
            <div className={classes.textContainer}>
              <div className="row">
                <div className="offset-xl-2 col-xl-8 col-lg-12 text-left">
                  <h2 className={classes.heading}>
                    1. Create Your Complimentary BidlyAuction Account.
                  </h2>
                  <p className={`${classes.para} text-left`}>
                    Secure your sole login by becoming a BidlyAuction bidder. The
                    uncomplicated and free registration process opens up avenues
                    of savings and convenience, enabling you to source assets
                    effortlessly through BidlyAuction!
                  </p>
                  <h2 className={classes.heading}>
                    2. Explore and Locate Equipment at Unbeatable Prices.
                  </h2>
                  <p className={`${classes.para} text-left`}>
                    Optimize efficiency and cost-effectiveness by identifying
                    the specific equipment you need from a wide range of asset
                    categories offered by our extensive network of sellers.
                    BidlyAuction features outstanding deals on a diverse array
                    of assets, presented by sellers from various sectors, all
                    conveniently consolidated in one location.
                  </p>
                  <h2 className={classes.heading}>
                    3. Place Your Bid, Make It Yours.
                  </h2>
                  <p className={`${classes.para} text-left`}>
                    Experience a smarter way to acquire business assets and
                    equipment at BidlyAuction. Our transparent online platform
                    offers a diverse range of options through auctions and 'Buy
                    Now' listings. Simplify your procurement process, save on
                    costs, and explore a world of opportunities. Start your
                    journey with us today!
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="offset-xl-2 col-xl-8 col-lg-12 text-center">
                  <LinkButton
                    type="secondary2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/account-type");
                    }}
                  >
                    Sign Up Today
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-7 mt-10">
          <div className="container min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start">
            <div className={classes.textContainer}>
              <div className="row">
                <div className="col-12 text-center">
                  <h2 className={classes.heading}>
                    The Most Intelligent Approach to Acquiring Excess Business
                    Equipment
                  </h2>
                </div>
                <div className="row mt-6">
                  <div className="offset-xl-1 col-xl-6 col-lg-6 text-left p-4">
                    <h4 className={classes.heading2}>
                      Effortless Equipment Discovery, Global Access
                    </h4>
                    <p className={`${classes.para} text-left mt-0`}>
                      BidlyAuction streamlines your search for the exact
                      equipment you need, no matter where it's located
                      worldwide. Our personalized Saved Search and
                      recommendation features ensure you always find what
                      matters most to your business.
                    </p>
                    <h4 className={classes.heading2}>
                      Cost Savings, Value Gained
                    </h4>
                    <p className={`${classes.para} text-left mt-0`}>
                      Select used surplus equipment, and you'll spend less.
                      You'll only pay what it's worth in the market. This helps
                      you make more money from your investment and allows your
                      business to grow.
                    </p>
                    <h4 className={classes.heading2}>Convenience Redefined</h4>
                    <p className={`${classes.para} text-left mt-0`}>
                      Make purchases according to your own timetable,
                      unrestricted by others. Participate in surplus equipment
                      bidding from any location and at any time, all from the
                      convenience of your personal mobile device. Urgently need
                      the equipment? Our instant "Buy Now" option is at your
                      service.
                    </p>
                    <h4 className={classes.heading2}>
                      Expert Support, Every Step
                    </h4>
                    <p className={`${classes.para} text-left mt-0`}>
                      Our newly dedicated customer service team is here to
                      assist you from bidding to delivery. We've encountered a
                      wide range of online surplus transactions, and we're
                      well-prepared to guide you through any scenario.
                    </p>
                  </div>
                  <div className="col-xl-4 col-lg-4 text-center d-flex align-self-center justify-content-center">
                    <img
                      style={{ display: "flex", maxHeight: "700px" }}
                      src={mobile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Buy;
