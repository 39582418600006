import React, { useEffect, useState } from "react";
import SearchActionItemList from "./SearchActionItemList";
import { SearchService } from "../../services/SearchService";
import SearchFilter from "./SearchFiltter";
import Layout from "../../layout/Layout";
import ViewOption from "./ViewOption";
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SearchActionItemGrid from "./SearchActionItemGrid";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./Search.module.css";
import AuctionsPanel from "../home/AuctionsPanel";
import AuctionBlock from "../../components/auction/AuctionBlock";
import SearchFilterSidebar from "./SearchFilterSidebar";
import { LibraryService } from "../../services/LibraryService";
import { PaginationComponent, Pagination } from "./PaginationComponent";
import TagsInput from "./TagsInput";
import {
  ALL_CATEGORY_SUBCATEGORY_COUNT_LIBRARY_URL,
  FILTER_BASE_URL,
} from "../../Constants";
import {
  accountTypeParser,
  categoryParser,
  commonParser,
  countryParser,
  currencyParser,
  salesTypeParser,
  startingPriceParser,
} from "./LibraryParser";
import { shallowEqual, useSelector } from "react-redux";
import {
  deleteAddWatchedItem,
  userAddWatchedItem,
} from "../../services/AuctionService";

const SearchResult = () => {
  let navigate = useNavigate();
  const { isAuthorized, viewExpired } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      viewExpired: auth.viewExpired,
    }),
    shallowEqual
  );

  const ctx = useAuthContext();
  const location = useLocation();
  const params = location.state ?? {};
  const [searchParams, setSearchParams] = useSearchParams();

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchResult, setSearchResult] = useState([]);
  const [popularAuctions, setPopularAuctions] = useState([]);
  const [sortOrder, setSortOrder] = useState();
  const [view, setView] = useState(
    window.screen.width >= 1280 ? "list" : "grid"
  );

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);

  const [startingPrice, setStartingPrice] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [salesType, setSalesType] = useState([]);
  const [checkedSalesType, setCheckedSalesType] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [checkedAccountType, setCheckedAccountType] = useState([]);
  const [seller, setSeller] = useState([]);
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [maxPrice, setMaxPrice] = useState();
  const [minPrice, setMinPrice] = useState();

  const [allBadges, setAllBadges] = useState([]);
  const [bidlyFilter, setBidlyFilter] = useState({});

  const loadFiltersLibraries = async (params = "") => {
    try {
      ctx.showProgress(true);

      const responses = await Promise.all([
        LibraryService(ALL_CATEGORY_SUBCATEGORY_COUNT_LIBRARY_URL),
        LibraryService(`${FILTER_BASE_URL}/countries${params}`),
        LibraryService(`${FILTER_BASE_URL}/states${params}`),
        LibraryService(`${FILTER_BASE_URL}/city${params}`),
        LibraryService(`${FILTER_BASE_URL}/currency${params}`),
        LibraryService(`${FILTER_BASE_URL}/price${params}`),
        LibraryService(`${FILTER_BASE_URL}/sales${params}`),
        LibraryService(`${FILTER_BASE_URL}/condition${params}`),
        LibraryService(`${FILTER_BASE_URL}/account${params}`),
        LibraryService(`${FILTER_BASE_URL}/seller${params}`),
        LibraryService(`${FILTER_BASE_URL}/make${params}`),
        LibraryService(`${FILTER_BASE_URL}/model${params}`),
      ]);

      let serviceCategories = responses[0];
      let parsedCategory = categoryParser(serviceCategories);
      setCategories(parsedCategory);
      findSelectedCatFromSubCat(
        parsedCategory,
        selectedSubCategory,
        selectedCategory
      );

      let serviceCountries = responses[1];
      setCountry(countryParser(serviceCountries));

      let states = responses[2];
      setStates(countryParser(states));

      let serviceCities = responses[3];
      setCities(countryParser(serviceCities));

      let currencies = responses[4];
      setCurrency(currencyParser(currencies));

      let startingPrice = responses[5];
      setStartingPrice(startingPriceParser(startingPrice));

      let salesType = responses[6];
      setSalesType(salesTypeParser(salesType));

      let conditions = responses[7];
      setConditions(countryParser(conditions));

      let accountType = responses[8];
      setAccountType(accountTypeParser(accountType));

      let sellers = responses[9];
      setSeller(countryParser(sellers));

      let make = responses[10];
      setMake(countryParser(make));

      let model = responses[11];
      setModel(countryParser(model));

      ctx.showProgress(false);
    } catch (error) {
      ctx.showProgress(false);
    }
  };

  useEffect(() => {
    const search = async () => {
      let filters = params.filter;
      if (filters) {
        window.scrollTo(0, 0);
      }
      //await loadFiltersLibraries();
      setCategorySubCategory(filters);
    };
    search();
  }, [params.filter]);

  const searchUp = async (currentPage = 1) => {
    let params = buildUrl(currentPage);
    await loadFiltersLibraries(params);
    searchAuction(params);
  };

  useEffect(() => {
    if (Object.keys(bidlyFilter).length > 0) {
      setCurrentPage(1);
      searchUp();
    }
  }, [bidlyFilter]);

  const handlePageChange = (newCurrentPage) => {
    setCurrentPage(newCurrentPage);
    searchUp(newCurrentPage);
  };

  const findSelectedCatFromSubCat = (categoryList, subCatId, catId = 0) => {
    if (subCatId > 0) {
      categoryList?.forEach((cat) => {
        cat.subCategories.forEach((subCat) => {
          if (subCat.id == subCatId) {
            setSelectedCategory(cat);
          }
        });
      });
    }
    if (catId) {
      setSelectedCategory(
        categoryList?.find((cat) => cat.value == catId.value)
      );
    }
  };

  const setCategorySubCategory = (filters) => {
    console.log("filters", filters);

    if(filters === undefined){
      const badgeParam = searchParams.get('badge');
      console.log("badgeParam", badgeParam);
      filters = "?badge="+badgeParam;
    }

    const urlParams = new URLSearchParams(filters);

    if (filters?.includes("advance_search")) {
      let newFilter = {};
      newFilter.title = urlParams.get("title");
      newFilter.listing = urlParams.get("listing");
      if (urlParams.get("category") && urlParams.get("category").length) {
        newFilter.category = urlParams.get("category");
        setSelectedCategory({ value: urlParams.get("category") });
      }
      if (
        urlParams.get("sub_category") &&
        urlParams.get("sub_category").length
      ) {
        newFilter.subcategory = urlParams.get("sub_category");
        setSelectedSubCategory(urlParams.get("sub_category"));
      }
      newFilter.fromPrice = urlParams.get("priceFrom");
      newFilter.toPrice = urlParams.get("priceTo");
      if (urlParams.get("state") && urlParams.get("state").length) {
        newFilter.state = [urlParams.get("state")];
      }
      newFilter.zip = urlParams.get("zip");
      newFilter.radius = urlParams.get("radius");
      newFilter.seller = [urlParams.get("seller")];
      newFilter.endDate = urlParams.get("endDate");
      newFilter.startDate = urlParams.get("startDate");
      if (urlParams.get("listing") && urlParams.get("listing").length) {
        newFilter.listing = urlParams.get("listing");
      }
      setBidlyFilter(newFilter);
    } else if (filters?.includes("seller")) {
      let newFilter = {};
      newFilter.seller = [urlParams.get("seller")];
      setBidlyFilter(newFilter);
    } else if (filters?.includes("sub_category")) {
      onFilterChange(urlParams.get("sub_category"), "sub_category");
      setSelectedSubCategory(urlParams.get("sub_category"));
      findSelectedCatFromSubCat(categories, urlParams.get("sub_category"));
    } else if (filters?.includes("category")) {
      let singleCategory = categories?.find(
        (category) => category.value == urlParams.get("category")
      );
      if (!singleCategory)
        singleCategory = { value: urlParams.get("category") };
      onFilterChange(singleCategory, "category");
      setSelectedCategory(singleCategory);
    } else if (filters?.includes("badge")) {
      resetCatgory();
      onFilterChange(urlParams.get("badge"), "badge");
    } else if (filters?.includes("state")) {
      resetCatgory();
      let newFilter = {};
      newFilter.state = [urlParams.get("state")];
      if(urlParams.get("city"))
        newFilter.city = [urlParams.get("city")];
      console.log(newFilter);
      setBidlyFilter(newFilter);
    } else if (filters?.includes("title")) {
      resetCatgory();
      let newFilter = {};
      newFilter.title = urlParams.get("title");
      setBidlyFilter(newFilter);
    }
  };

  const resetCatgory = () => {
    setSelectedCategory({});
    setSelectedSubCategory(0);
  };

  const onFilterChange = (position, feildName, target) => {

    console.log(feildName);

    ctx.searchFilterSidebarOpen(false);
    let newFilter = { ...bidlyFilter };

    if (feildName == "state") {
      let filterStates = newFilter.state ? newFilter.state : [];
      target.checked
        ? filterStates.push(target.value)
        : filterStates.pop(target.value);
      newFilter.state = filterStates;
      newFilter.city = "";
    }

    if (feildName == "city") {
      let filteredCities = newFilter.city ? newFilter.city : [];
      target.checked
        ? filteredCities.push(target.value)
        : filteredCities.pop(target.value);
      newFilter.city = filteredCities;
    }

    if (feildName == "conditions") {
      let filteredCondition = newFilter.condition ? newFilter.condition : [];
      target.checked
        ? filteredCondition.push(target.value)
        : filteredCondition.pop(target.value);
      newFilter.condition = filteredCondition;
    }

    if (feildName == "accountType") {
      const updatedCheckedState = checkedAccountType.map((item, index) =>
        index === position ? !item : item
      );
      setCheckedAccountType(updatedCheckedState);
    }

    if (feildName == "salesType") {
      const updatedCheckedState = checkedSalesType.map((item, index) =>
        index === position ? !item : item
      );
      setCheckedSalesType(updatedCheckedState);
    }

    if (feildName == "seller") {
      let filteredSeller = newFilter.seller ? newFilter.seller : [];
      target.checked
        ? !filteredSeller.includes(target.value) && filteredSeller.push(target.value)
        : filteredSeller.pop(target.value);
      newFilter.seller = filteredSeller;
      newFilter.listing = "";
    }

    if (feildName == "currency") {
      let filteredCurrency = newFilter.currency ? newFilter.currency : [];
      target.checked
        ? filteredCurrency.push(target.value)
        : filteredCurrency.pop(target.value);
      newFilter.currency = filteredCurrency;
    }

    if (feildName == "sub_category") {
      newFilter.subcategory = position;
      newFilter.category = "";
      setSelectedSubCategory(selectedSubCategory);
    }

    if (feildName == "category" && position) {
      newFilter.category = position.value;
      newFilter.subcategory = "";
      setSelectedCategory(position);
      setSelectedSubCategory(0);
    }

    if (feildName == "badge") {
      newFilter.badge = position ? position : "";
    }

    if (feildName == "title") {
      newFilter.title = position ? position : "";
    }

    if (feildName == "salesType") {
      newFilter.salesType = position == "any" ? "" : [position];
    }

    if (feildName == "accountType") {
      newFilter.accountType = position == "any" ? "" : [position];
    }

    if (feildName == "price") {
      newFilter.fprice = [position];
    }

    if (feildName == "sprice") {
      newFilter.fromPrice =
        target.minPrice > 2147483647 ? 2147483647 : target.minPrice;
      newFilter.toPrice =
        target.maxPrice > 2147483647 ? 2147483647 : target.maxPrice;
    }

    if (feildName == "make") {
      let filteredMake = newFilter.make ? newFilter.make : [];
      target.checked
        ? !filteredMake.includes(target.value) && filteredMake.push(target.value)
        : filteredMake.pop(target.value);
      newFilter.make = filteredMake;
    }

    if (feildName == "model") {
      let filteredModel = newFilter.model ? newFilter.model : [];
      target.checked
        ? !filteredModel.includes(target.value) && filteredModel.push(target.value)
        : filteredModel.pop(target.value);
      newFilter.model = filteredModel;
    }

    setBidlyFilter(newFilter);
  };

  const getCategories = () => {
    let selected = selectedCategory?.value;
    let selecteCat = [];
    if (selectedSubCategory > 0) {
      categories?.forEach((cat) => {
        cat.subCategories.forEach((subCat) => {
          if (subCat.id == selectedSubCategory) {
            selected = subCat.idTwo;
            let selectCat = categories?.filter(
              (category) => category.value == selected
            );
            setSelectedCategory(selectCat[0]);
          }
        });
      });
    }
    selecteCat = categories?.filter((category) => category.value != selected);
    return selecteCat;
  };

  const queryParamFromFilter = (values) => {
    let queryParam = "";

    queryParam +=
      bidlyFilter.title && bidlyFilter.title.length > 0
        ? "title=" + bidlyFilter.title + "&"
        : "";

    queryParam +=
      bidlyFilter.listing && bidlyFilter.listing.length > 0
        ? "listing=" + bidlyFilter.listing + "&"
        : "";

    queryParam +=
      bidlyFilter.category && bidlyFilter.category > 0
        ? "category=" + bidlyFilter.category + "&"
        : "";
    queryParam +=
      bidlyFilter.subcategory > 0
        ? "sub_category=" + bidlyFilter.subcategory + "&"
        : "";
    queryParam += bidlyFilter.state
      ? "state=" + bidlyFilter.state.join([","]) + "&"
      : "";
    queryParam += bidlyFilter.badge ? "badge=" + bidlyFilter.badge + "&" : "";
    queryParam += bidlyFilter.condition
      ? "condition=" + bidlyFilter.condition.join([","]) + "&"
      : "";
    queryParam += bidlyFilter.salesType
      ? "sales-type=" + bidlyFilter.salesType.join([","]) + "&"
      : "";
    queryParam += bidlyFilter.currency
      ? "currency=" + bidlyFilter.currency.join([","]) + "&"
      : "";
    queryParam += bidlyFilter.accountType
      ? "account-type=" + bidlyFilter.accountType.join([","]) + "&"
      : "";
    queryParam += bidlyFilter.seller
      ? "seller=" + bidlyFilter.seller.join([","]) + "&"
      : "";
    queryParam += bidlyFilter.city
      ? "city=" + bidlyFilter.city.join([","]) + "&"
      : "";
    queryParam += bidlyFilter.fprice
      ? "fprice=" + bidlyFilter.fprice + "&"
      : "";
    queryParam += bidlyFilter.toPrice
      ? "priceTo=" + bidlyFilter.toPrice + "&"
      : "";
    queryParam += bidlyFilter.fromPrice
      ? "priceFrom=" + bidlyFilter.fromPrice
      : "";
    queryParam += bidlyFilter.startDate
      ? "startDate=" + bidlyFilter.startDate
      : "";
    queryParam += bidlyFilter.endDate ? "endDate=" + bidlyFilter.endDate : "";
    queryParam += bidlyFilter.make
      ? "make=" + bidlyFilter.make.join([","]) + "&"
      : "";
    queryParam += bidlyFilter.model
      ? "model=" + bidlyFilter.model.join([","]) + "&"
      : "";  

    console.log(queryParam);  

    return queryParam;
  };

  const buildUrl = (currentPage) => {
    let queryParam = queryParamFromFilter();

    let appendUrl = "?";

    let start = currentPage - 1;

    let paginatedString = "&start=" + start * pageSize + "&end=" + pageSize;

    appendUrl += queryParam + paginatedString;

    appendUrl = appendUrl.replaceAll("??", "?");

    return appendUrl;
  };

  const searchAuction = (filterParams) => {
    ctx.showProgress(true);
    buildBadges();
    SearchService(filterParams, isAuthorized)
      .then((res) => {
        setTotal(res.totalItem);
        setSearchResult(res.searchList);
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
        console.log(error);
      });
  };

  const handleSort = (sortParam) => {
    setSortOrder(sortParam);
  };

  const buildBadges = () => {
    let myBadges = [];

    console.log("IN Badges", bidlyFilter);

    if (bidlyFilter.badge) {
      myBadges.push({
        type: "badge",
        label: bidlyFilter.badge,
        key: bidlyFilter.badge,
      });
    }

    if (bidlyFilter.title) {
      myBadges.push({
        type: "title",
        label: bidlyFilter.title,
        key: bidlyFilter.title,
      });
    }

    if (bidlyFilter.subcategory) {
      categories?.forEach((cat) => {
        cat.subCategories.forEach((subCat) => {
          if (subCat.id == bidlyFilter.subcategory) {
            myBadges.push({
              type: "sub_category",
              label: subCat.key,
              key: subCat.id,
            });
          }
        });
      });
    }

    if (selectedCategory && selectedCategory.value) {
      myBadges.push({
        type: "category",
        label: selectedCategory.label,
        key: selectedCategory.value,
      });
    }

    if (bidlyFilter.state && bidlyFilter.state.length > 0) {
      states
        .filter((item) => {
          return bidlyFilter.state.includes(item.value);
        })
        .forEach((state) => {
          myBadges.push({
            type: "state",
            label: state.label,
            key: state.value,
          });
        });
    }

    if (bidlyFilter.city && bidlyFilter.city.length > 0) {
      cities
        .filter((item) => {
          return bidlyFilter.city.includes(item.value);
        })
        .forEach((state) => {
          myBadges.push({
            type: "city",
            label: state.label,
            key: state.value,
          });
        });
    }

    if (bidlyFilter.currency && bidlyFilter.currency.length > 0) {
      currency
        .filter((item) => {
          return bidlyFilter.currency.includes(item.value);
        })
        .forEach((state) => {
          myBadges.push({
            type: "currency",
            label: state.label,
            key: state.value,
          });
        });
    }

    if (bidlyFilter.condition && bidlyFilter.condition.length > 0) {
      conditions
        .filter((item) => {
          return bidlyFilter.condition.includes(item.value);
        })
        .forEach((state) => {
          myBadges.push({
            type: "conditions",
            label: state.label,
            key: state.value,
          });
        });
    }

    if (bidlyFilter.seller && bidlyFilter.seller.length > 0) {
      seller
        .filter((item) => {
          return bidlyFilter.seller.includes(item.value);
        })
        .forEach((state) => {
          myBadges.push({
            type: "seller",
            label: state.label,
            key: state.value,
          });
        });
    }
    setAllBadges(myBadges);
  };

  const removeBadge = (tag) => {
    if (
      tag.type == "state" ||
      tag.type == "city" ||
      tag.type == "conditions" ||
      tag.type == "seller" ||
      tag.type == "currency"
    ) {
      onFilterChange(tag.key, tag.type, { value: tag.key, checked: false });
    }
    if (tag.type == "category") {
      setSelectedCategory({});
      onFilterChange({}, tag.type);
    }
    if (tag.type == "sub_category") {
      onFilterChange({}, tag.type);
      setSelectedSubCategory(0);
    }
    if (tag.type == "badge" || tag.type == "title") {
      onFilterChange(false, tag.type);
    }
  };

  const watchListHadler = (auction) => {
    if (isAuthorized) {
      ctx.showProgress(true);
      if (!auction.inWishList) {
        userAddWatchedItem(auction.itemId)
          .then((data) => {
            let items = [...searchResult];
            items = items.map((item) => {
              if (item.itemId == auction.itemId) item.inWishList = true;
              return item;
            });
            setSearchResult(items);
            ctx.showProgress(false);
          })
          .catch((error) => {
            console.log(error);
            ctx.showProgress(false);
          });
      } else {
        deleteAddWatchedItem(auction.itemId)
          .then((data) => {
            let items = [...searchResult];
            items = items.map((item) => {
              if (item.itemId == auction.itemId) item.inWishList = false;
              return item;
            });
            setSearchResult(items);
            ctx.showProgress(false);
          })
          .catch((error) => {
            console.log(error);
            ctx.showProgress(false);
          });
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {window.screen.width < 1280 && (
        <SearchFilterSidebar
          categories={categories}
          country={country}
          states={states}
          cities={cities}
          conditions={conditions}
          salesType={salesType}
          checkedSalesType={checkedSalesType}
          accountType={accountType}
          checkedAccountType={checkedAccountType}
          currency={currency}
          startingPrice={startingPrice}
          seller={seller}
          getFilteredCategories={getCategories}
          selectedCategory={selectedCategory}
          selectSubCategory={selectedSubCategory}
          onFilterChange={onFilterChange}
          onCategoryChange={setCategorySubCategory}
          bidlyFilter={bidlyFilter}
          make={make}
          model={model}
        />
      )}
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="mb-3">
            <Breadcrumb
              items={[
                <BreadcrumbItem key={1} title="Home" to="/" />,
                <BreadcrumbItem key={1} title="Search Result" />,
              ]}
            />
          </div>

          <div className="row">
            <div className="d-none d-xl-block col-3">
              <SearchFilter
                categories={categories}
                country={country}
                states={states}
                cities={cities}
                conditions={conditions}
                salesType={salesType}
                checkedSalesType={checkedSalesType}
                accountType={accountType}
                checkedAccountType={checkedAccountType}
                currency={currency}
                startingPrice={startingPrice}
                seller={seller}
                getFilteredCategories={getCategories}
                selectedCategory={selectedCategory}
                selectSubCategory={selectedSubCategory}
                onFilterChange={onFilterChange}
                onCategoryChange={setCategorySubCategory}
                bidlyFilter={bidlyFilter}
                make={make}
                model={model}
              />
            </div>
            <div className="col-xl-9 col-lg-9 col-md-9">
              <div className="d-block d-md-flex flex-center-between mb-3 mx-2">
                <h3 className={`${classes.searhResultTitle} mb-2 mb-md-0`}>
                  {selectedCategory?.label}
                </h3>
                {searchResult.length > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                )}
              </div>
              <div className="bg-gray-1 flex-center-between borders-radius-9 top-bar-search">
                <div className="handheld-sidebar-toggle">
                  <button
                    onClick={(e) => {
                      console.log("Open Filter");
                      e.preventDefault();
                      ctx.searchFilterSidebarOpen(true);
                    }}
                    className="btn sidebar-toggler"
                    type="button"
                  >
                    <i className="fas fa-sliders-h mx-1"></i>
                    <span>Filters</span>
                  </button>
                </div>
                <div className="px-3 d-none d-xl-block my-3">
                  <ul
                    className="nav nav-tab-shop"
                    id="pills-tab"
                    role="tablist"
                  >
                    <ViewOption
                      onClick={(val) => setView(val)}
                      viewOption={"list"}
                      active={view == "list" ? "active" : ""}
                    />
                    <ViewOption
                      onClick={(val) => setView(val)}
                      viewOption={"grid"}
                      active={view == "grid" ? "active" : ""}
                    />
                  </ul>
                </div>
                {/* <div className="d-flex">
                <div className="min-width-200">
                  <Select
                    defaultValue={{ label: "New", value: "newest" }}
                    onChange={(option) => {
                      handleSort(option.value);
                    }}
                    options={sortOption}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        borderRadius: "6px",
                      }),
                    }}
                  />
                </div>
              </div> */}
              </div>
              {allBadges && allBadges.length > 0 && (
                <div className="d-flex-center-between borders-radius-9 py-1">
                  <TagsInput
                    badges={allBadges}
                    onRemove={(badge) => {
                      removeBadge(badge);
                    }}
                  />
                </div>
              )}
              <div
                className="tab-pane fade pt-2 show active"
                id="pills-three-example1"
                role="tabpanel"
                aria-labelledby="pills-three-example1-tab"
                data-target-group="groups"
              >
                {searchResult && searchResult.length > 0 ? (
                  view == "list" ? (
                    <ul className="d-block list-unstyled products-group prodcut-list-view">
                      {searchResult.map((eachItem) => {
                        return (
                          <SearchActionItemList
                            watchListHadler={watchListHadler}
                            onFilterChange={onFilterChange}
                            sellers={seller}
                            key={eachItem.itemId}
                            data={eachItem}
                          />
                        );
                      })}
                    </ul>
                  ) : (
                    <ul className="row list-unstyled products-group no-gutters">
                      {searchResult.map((eachItem) => {
                        return (
                          <SearchActionItemGrid
                            watchListHadler={watchListHadler}
                            onFilterChange={onFilterChange}
                            sellers={seller}
                            key={eachItem.itemId}
                            data={eachItem}
                          />
                        );
                      })}
                    </ul>
                  )
                ) : (
                  <h3 className="font-size-20 mt-10 text-center">
                    No Result found
                  </h3>
                )}
              </div>
              {searchResult.length > 0 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => handlePageChange(page)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {popularAuctions && popularAuctions.length > 0 && (
              <div className="col-12">
                <AuctionsPanel title="Popular Auctions">
                  {popularAuctions.map((item, i) => {
                    return (
                      <AuctionBlock
                        key={i}
                        auctionType="Online Auction"
                        heading={item.title}
                        description={`${item.city}, ${item.state}`}
                        price={item.price}
                        image={item.defaultImage}
                        itemId={item.itemId}
                        time="22 Days, 6 Hours"
                        lot="LOT # 12345-5F"
                      />
                    );
                  })}
                </AuctionsPanel>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SearchResult;
