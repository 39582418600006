import React from "react";

const Alert = ({ type = "success", children }) => {
  let alertClass = "";

  alertClass = type === "success" ? "alert-success" : alertClass;
  alertClass = type === "warning" ? "alert-warning" : alertClass;
  alertClass = type === "info" ? "alert-info" : alertClass;
  alertClass = type === "error" ? "alert-danger" : alertClass;

  return (
    <>
      <div className="card border-0 mb-5">
        <div className={`alert ${alertClass} mb-0`} role="alert">
          {children}
        </div>
      </div>
    </>
  );
};

export default Alert;
