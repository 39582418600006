import React from "react";
import { Link } from "react-router-dom";
import classes from "./Alert.module.css";

const AlertCommon = ({
  image,
  heading,
  description,
  buttonText = "BACK TO HOME",
  clickTo = "/",
  hideButton = false,
  imageStyle,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <img src={image}  style={imageStyle} />
      </div>

      <div className="position-relative bg-white text-center w-100">
        <ul className="nav nav-classic nav-tab justify-content-center">
          <li className="nav-item">
            <div
              className={`nav-link active bidly d-md-flex justify-content-md-center align-items-md-center ${classes.heading}`}
            >
              {heading}
            </div>
          </li>
        </ul>
      </div>

      <p className={classes.desc}>{description}</p>

      {!hideButton && (
        <Link to={clickTo} className="btn btn-outline-primary px-5">
          {buttonText}
        </Link>
      )}
    </div>
  );
};

export default AlertCommon;
