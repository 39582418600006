export const commonParser = (res) => {
    let myArray = [];
    res.map((item) => {
        myArray.push({ value: item.key, label: item.value, checked: false });
    });
    return myArray;
}

export const countryParser = (res) => {
    let myArray = [];
    res.map((item) => {
        myArray.push({ value: item.key, label: `${item.value} (${item.valueTwo})`, checked: false });
    });
    return myArray;
}

export const categoryParser = (res) => {
    let myArray = [];
    res.map((item) => {
        myArray.push({ value: item.id, label: item.key, count: item.valueNumber, subCategories: item.subList, checked: false });
    });
    return myArray;
}

export const commonParserWithCount = (res) => {
    let myArray = [];
    res.map((item) => {
        myArray.push({ value: item.id, label: item.key + ` (${item.value})`, checked: false });
    });
    return myArray;
}

export const startingPriceParser = (res) => {
    let myArray = [];
    res.map((item) => {
        myArray.push({ value: item.value, label: item.valueTwo + ` (${item.valueThree})`, checked: false});
    });
    return myArray;
}

export const currencyParser = (res) => {
    let myArray = [];
    res.map((item) => {
        myArray.push({ value: item.key, label: item.value + ` (${item.valueTwo})`, checked: false});
    });

    return myArray;
}

export const salesTypeParser = (res) => {
    let myArray = [];
    myArray.push({ value: "any", label: "Any", checked: false });
    res.map((item) => {
        myArray.push({value: item.key, label: `${item.value} (${item.valueTwo})`, checked: false });
    });
    return myArray;
}

export const accountTypeParser = (res) => {
    let myArray = [];
    myArray.push({ value: "any", label: "Any" });
    res.map((item) => {
        myArray.push({  value: item.key, label: item.key + ` (${item.value})` });
    });

    return myArray;
}