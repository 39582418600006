import { useEffect } from "react";
import { useRef } from "react";

const MapComponent = ({ item }) => {
  const ref = useRef();

  useEffect(() => {
    let map = new window.google.maps.Map(ref.current, {
      center: { lat: Number(item.pickUpLat), lng: Number(item.pickUpLong) },
      zoom: 10,
      mapTypeId: "terrain",
    });
    new window.google.maps.Marker({
      position: { lat: Number(item.pickUpLat), lng: Number(item.pickUpLong) },
      map,
      title: item.citiName,
    });
  });

  return <div ref={ref} id="map" style={{ minHeight: "300px" }} />;
};
export default MapComponent;
