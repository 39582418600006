import React from "react";
import classes from "./StepHeading.module.css";

const StepHeading = ({ title, step, secondInfo }) => {
  return (
    <>
      <div className="row d-flex">
        <div className="col-auto flex-grow-1">
          <h2 className="h4 font-weight-bold">{title}</h2>
        </div>
        <div className={`col-auto ${classes.step}`}>
          {step && (
            <>
              Step <span>{step}</span>
            </>
          )}

          {secondInfo && (
            <>
              {secondInfo.title} <span>{secondInfo.info}</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StepHeading;
