import {
  BOUGHT_BUYER_FEE_PROCESS_URL,
  BOUGHT_REMOVE_ITEM_URL,
  CASHIER_CHEQUE_INVOICE_ACCOUNT_INFO_URL,
  CASHIER_CHEQUE_UPLOAD_URL,
  INVOICE_ACCOUNT_INFO_URL,
  INVOICE_CARD_PAYMENT_INIT_URL,
  INVOICE_CARD_PAYMENT_SUCESS_URL,
  WIRE_INVOICE_RECEIPT_UPLOAD_URL,
} from "../Constants";
import authenticatedFetch from "./fetch";

export async function fetchMyBids(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function fetchAccountInfo(itemId) {
  const response = await authenticatedFetch(
    `${INVOICE_ACCOUNT_INFO_URL.replace("{item}", itemId)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function fetchCashierChequeAccountInfo(itemId) {
  const response = await authenticatedFetch(
    `${CASHIER_CHEQUE_INVOICE_ACCOUNT_INFO_URL.replace("{item}", itemId)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function initCardPayment(requestBody, itemId) {
  const response = await authenticatedFetch(
    `${INVOICE_CARD_PAYMENT_INIT_URL.replace("{item}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function savePaymentCompleted(requestBody, itemId) {
  const response = await authenticatedFetch(
    `${INVOICE_CARD_PAYMENT_SUCESS_URL.replace("{item}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function uploadWireTransferInvoice(requestBody, itemId) {
  const response = await authenticatedFetch(
    `${WIRE_INVOICE_RECEIPT_UPLOAD_URL.replace("{item}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function uploadCashierChequeImage(requestBody, itemId) {
  const response = await authenticatedFetch(
    `${CASHIER_CHEQUE_UPLOAD_URL.replace("{item}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function initBuyerFeeProcess(itemId) {
  const response = await authenticatedFetch(
    `${BOUGHT_BUYER_FEE_PROCESS_URL.replace("{itemId}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function removeBuyNow(itemId) {
  const response = await authenticatedFetch(
    `${BOUGHT_REMOVE_ITEM_URL.replace("{itemId}", itemId)}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
    false
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}
