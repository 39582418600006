import React from "react";

const ErrorMessage = ({ children, position = "bottom" }) => {
  return (
    <div
      className={`ui-message-error invalid-feedback ${
        position === "bottom" ? "" : "review"
      }`}
    >
      {children}
    </div>
  );
};

export default ErrorMessage;
