import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import PictureUploader from "../../components/uploader/PictureUploader";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import {
  uploadCashierChequeImage,
  uploadWireTransferInvoice,
} from "../../services/MyBidsService";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import CheckedoutItems from "./CheckedoutItems";

const UploadCashierCheque = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [navigationFrom, setNavigationFrom] = useState();
  const [selected, setSelected] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const params = useLocation()?.state;

  const [receipt, setReceipt] = useState();
  const [receiptError, setReceiptError] = useState();

  useEffect(() => {
    console.log(params?.selected);
    setSelected((prev) => {
      setTotalAmount(
        params?.selected.reduce((total, cur) => total + cur.total, 0)
      );

      return params?.selected;
    });

    setNavigationFrom(params?.from);
  }, [params]);

  const uploadReceipt = (document) => {
    ctx.showProgress(true);
    uploadCashierChequeImage(
      { invoiceBase64: document },
      [...selected].shift().auction.itemId
    )
      .then((data) => {
        console.log("data after upload", data);
        navigate("/bid-cashier-cheque-payment-status", {
          state: { status: data, from: navigationFrom },
        });
        ctx.showProgress(false);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setReceiptError(deflateErrors(json.data.errors).invoiceBase64);
          }
        }
      });
  };

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
              <CheckedoutItems selected={selected} totalAmount={totalAmount} />

              <h2 className="h4 font-weight-bold">
                Upload Cashier Cheque Image
              </h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12 col-lg-5">
                  <p className="instructions-desc mb-5">
                    Please upload your cashier cheque image here
                  </p>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setReceiptError("");
                      if (!receipt) {
                        setReceiptError("Please upload cashier cheque image");
                        return;
                      }

                      uploadReceipt(receipt);
                    }}
                  >
                    <div className="js-form-message mb-4 text-center">
                      <PictureUploader
                        label={"Upload Cashier Cheque Image"}
                        errorEvent={(errors) => {
                          setReceiptError(errors);
                        }}
                        fileSelectedEvent={(event, file) => {
                          setReceipt(file.data_url.split(",")[1]);
                          event.proceed(file);
                        }}
                      />
                      {receiptError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {receiptError}
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <Link
                          className="btn btn-outline-primary px-5 d-block mb-2 mb-md-0"
                          to={navigationFrom?.url}
                        >
                          CANCEL
                        </Link>
                      </div>
                      <div className="col-12 col-md-6">
                        <button
                          type="submit"
                          className="btn btn-primary-dark-w px-5 btn-block"
                        >
                          UPLOAD
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UploadCashierCheque;
