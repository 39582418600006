import React, { useState, useEffect } from "react";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import { isBadRequest } from "../../services/Utilities";
import { VerifyRegistrationLinkRequest } from "../../services/SignUpService";

const VerifyLink = () => {
  const navigate = useNavigate();
  const [linkVerified, setLinkVerified] = useState(false);
  const [userType, setUserType] = useState(null);
  const [searchParams] = useSearchParams();

  const link = searchParams.get("emailParam");

  useEffect(() => {
    setLinkVerified(false);
    VerifyRegistrationLinkRequest({ link: link })
      .then((data) => {
        if (data.status === "EXPIRED") {
          navigate("/register/expired");
        } else if (data.status === "VALID") {
          setLinkVerified(true);
          setUserType(data.userType);
          // navigate("/user-notified");
        }
      })
      .catch((e) => {
        if (isBadRequest(e.message)) navigate("/error");
      });

    console.log("on link verification");
  }, [link]);

  useEffect(() => {
    console.log("linkVerified", linkVerified, "userType", userType);
  }, [linkVerified, userType]);

  return (
    <>
      {(!linkVerified || userType == null) && <div>Verifing ...</div>}

      {linkVerified && userType === "BIDDER" && <Navigate to="/reg-success" />}

      {linkVerified && userType === "SELLER" && (
        <Navigate to="/agency-notified" />
      )}
    </>
  );
};

export default VerifyLink;
