import React from "react";
import classes from "./ConfirmModal.module.css";
import Card from "./Card";

const ConfirmModal = ({ title, message, cancelClicked, confirmClicked }) => {
  return (
    <div>
      <div className="backdrop" />
      <Card className={classes.modal}>
        <header className={`${classes.header}`}>
          <h6>{title}</h6>
        </header>
        <div className={`${classes.content}`}>
          <p>{message}</p>
        </div>
        <footer className={classes.actions}>
          <button
            type="button"
            className="btn btn-outline-primary px-5"
            onClick={cancelClicked}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary-dark-w px-5"
            onClick={confirmClicked}
          >
            Yes
          </button>
        </footer>
      </Card>
    </div>
  );
};

ConfirmModal.defaultProps = {
  title: "Confirm",
  message: "Are you sure you want to perform this action?",
  cancelClicked: (e) => {
    e.preventDefault();
  },

  confirmClicked: (e) => {
    e.preventDefault();
  },
};

export default ConfirmModal;
