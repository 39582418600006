import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import envelop from "../../assets/images/envelop.svg";
import "../registration/RegisterModule.css";
import Layout from "../../layout/Layout";
import classes from "../search/Search.module.css";

const SellerUserNotified = () => {
  return (
    <Layout>
      <div className="container">
        <div className="row mt-10 mb-10">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <section className="section-onsale-product p-2">
              <div className="text-center mb-4">
                <img
                  src={envelop}
                  className="notification-logo img-responsive"
                  alt="envelop img"
                />
              </div>
              <h2 className="pg-4 font-weight-bold text-center purple-500">
                Thank you for your interest in selling with BidlyAuction.
              </h2>
              <h3
                className={`nav-classic ${classes.searchSection} ${classes.asSearchTitle} mb-0 pb-2 font-size-25 section-title text-center`}
              ></h3>
              <p className="pg-2 text-center mt-5">
                Your information is passed to a designated representative for
                your industry and they will reach out to you soon.
              </p>
            </section>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </Layout>
  );
};

export default SellerUserNotified;
