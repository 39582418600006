import Menu from "./Menu";
import SearchArea from "./SearchArea";
import TopBar from "./TopBar";

const Header = (props) => {
  return (
    <header id="header" className="u-header u-header-left-aligned-nav">
      <div className="u-header__section">
        <TopBar {...props} />
        <SearchArea {...props} />
        {window.screen.width >= 1200 && <Menu {...props} />}
      </div>
    </header>
  );
};
export default Header;
