import {
  BIDDER_ITEM_PICKUP_DETAILS,
  USER_ADD_AUCTIONS_URL,
  USER_AUCTIONS_QUESTION_URL,
  USER_WATCHED_AUCTIONS_URL,
} from "../Constants";
import authenticatedFetch from "./fetch";

export async function userWatchedAuctions() {
  const response = await authenticatedFetch(`${USER_WATCHED_AUCTIONS_URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function userAddWatchedItem(itemId) {
  const response = await authenticatedFetch(
    `${USER_ADD_AUCTIONS_URL}${itemId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function deleteAddWatchedItem(itemId) {
  const response = await authenticatedFetch(
    `${USER_ADD_AUCTIONS_URL}${itemId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function userAddQuestion(requestBody) {
  const response = await authenticatedFetch(`${USER_AUCTIONS_QUESTION_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function fetchBidderItemPickupDetails(itemId) {
  const response = await authenticatedFetch(
    `${BIDDER_ITEM_PICKUP_DETAILS.replace("{itemId}", itemId)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}
