import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import classes from "./Static.module.css";
import LinkButton from "../../components/button/LinkButton";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { isSeller } from "../../services/Utilities";
import { LibraryService } from "../../services/LibraryService";
import { ALL_REVIEWS } from "../../Constants";
import { useAuthContext } from "../../redux/auth-context";

const AboutUs = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    ctx.showProgress(true);
    LibraryService(ALL_REVIEWS)
      .then((res) => {
        setReviews(res);
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
      });
  }, []);

  return (
    <Layout>
      <>
        <div className="mb-5">
          <div className={`bg-img-hero ${classes.bannerImgAbout}`}>
            <div className="container min-height-300 overflow-hidden d-flex justify-content-md-start justify-content-start align-items-center">
              <div className={` ${classes.bannerTextContainer} col-md-7 mt-5`}>
                <h2 className={classes.heading}>Ready to Sell Your Items?</h2>
                <p className={classes.para}>
                  Whether you're an organization, a community group, or an
                  individual looking to engage in auctions and bidding, explore
                  our information page to uncover the myriad reasons why
                  BidlyAuction stands as the ultimate open platform for all your
                  auction needs.
                </p>

                <div className="mb-4">
                  <LinkButton
                    type="secondary2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/become-seller");
                    }}
                  >
                    TAKE A LOOK
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-7">
          <div className="container min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
            <div className={classes.textContainer}>
              <h2 className={classes.heading}>About BidlyAuction</h2>
              <p className={classes.para}>
                Welcome to our online auction platform! At BidlyAuction, we are
                dedicated to bringing bidders and sellers together in an exciting
                virtual marketplace. With a passion for connecting people with
                unique treasures and remarkable deals, we've created a
                user-friendly and secure environment where individuals can
                discover a wide array of items up for bidding. Our team is
                committed to delivering an exceptional auction experience,
                characterized by transparency, convenience, and exceptional
                customer support. Whether you're an avid collector searching for
                that elusive item or a seller looking to reach a global
                audience, BidlyAuction is your destination for seamless online
                auctions. Join us and become part of our thriving community that
                celebrates the thrill of bidding and the joy of winning.
              </p>
            </div>
          </div>
        </div>
        <div className="mb-7">
          <div className="container min-height-450 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
            <div className={classes.learnMoreContainer}>
              <div className={`${classes.textContainer} mt-4 p-4`}>
                <h2 className={classes.heading}>
                  Streamline All Your Business Excess Requirements on a Unified
                  Platform
                </h2>
                <p className={classes.para}>
                  Managing Excess Assets and Inventory Made Effortless:
                  Introducing BidlyAuction, the ultimate solution that empowers
                  businesses and individuals to seamlessly centralize their
                  excess management through an intuitive platform. BidlyAuction
                  simplifies the process of handling assets, offering a
                  user-friendly platform for businesses. Whether you're dealing
                  with industrial machinery, electronics, furniture, or
                  materials, our platform accommodates a wide range of items.
                  <br></br>
                  With BidlyAuction's robust tools and features, you can
                  confidently list, track, and sell your excess assets,
                  enhancing your overall management process. Say goodbye to
                  complexity, reduce operational costs, and unlock the hidden
                  value in your assets – all through BidlyAuction. Embark on
                  your journey here, where efficiency meets tangible results.
                </p>
                <div className="d-flex flex-row justify-content-center mb-4">
                  <LinkButton
                    type="secondary2"
                    className="mr-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/become-seller");
                    }}
                  >
                    SELL NOW
                  </LinkButton>

                  <LinkButton
                    type="secondary2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/buy");
                    }}
                  >
                    BID NOW
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {reviews && reviews.length > 0 && (
          <>
            <div className="mb-7">
              <div className="container min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
                <div className={classes.textContainer}>
                  <h2 className={classes.heading}>
                    See Our Seller Success Stories
                  </h2>
                </div>
              </div>
            </div>
            <div className="mb-7">
              <div className="container min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
                <div className="row">
                  {reviews.map((data) => {
                    return (
                      <div className="col-md-4 pt-5">
                        <div className={classes.stepsCard}>
                          <div className="p-3">
                            <h4 className={classes.heading2}>
                              {data.valueTwo}
                            </h4>
                            <p className={classes.para}>{data.value}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )} */}
      </>
    </Layout>
  );
};

export default AboutUs;
