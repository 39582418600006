import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../../components/UI/HorizontalProgress";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../../components/UI/ZInputComponent";
import * as Yup from "yup";
import Label from "../../../components/UI/Label";
import PlaceholderHeading from "../../credit-card/PlaceholderHeading";
import {
  areAllPropertiesNullOrEmptyArrays,
  scrollTo,
} from "../../../services/Utilities";
import { wizardGenericErrorMsg } from "../../../Constants";
import ErrorMessage from "../../../components/UI/ErrorMessage";
import ZCheckBoxComponent from "../../../components/form/ZCheckBoxComponent";
import DualErrorMessage from "../../../components/UI/DualErrorMessage";
import { useRef } from "react";
import WizardButtons from "./WizardButtons";
import { Link } from "react-router-dom";
import Alert from "../../../components/UI/Alert";
import ZRadioComponent from "../../../components/form/ZRadioComponent";
import ReactQuill from "react-quill";

const ListingPaymentMethod = (props) => {
  const { step, data, serverErrors, tabDisable } = props;
  const { sellerBankAccount, bidlyBankAccount } = props;
  const { saveTab, closeWizard } = props;
  const { disableCCPaymentParam } = props;

  const draftSubmitRef = useRef();
  const [isDraft, setIsDraft] = useState(false);

  const [itemPaymentInstructions, setItemPaymentInstructions] = useState();

  const paymentDefault =
    "If you are the winning bidder, you will facilitate payment by referring to the My Bids section of your account.\nNote: Payment is due within 5 (five) business days of auction closure.\nSales Tax: When applicable, sales tax is calculated based upon the auction’s advertised location.";

  const tabInitValues = {
    paymentInstructions: paymentDefault,
    paymentInstructionsStyled: paymentDefault,
    paymentMethods: ["WIRE_TRANSFER", "CASHIER_CHEQUE"],
    isWireTransferSelected: "yes",
    bankSource: "BIDLY",
  };

  const schema = Yup.object().shape({
    paymentInstructions: Yup.string().required(
      "Payment instruction is required"
    ),
    paymentInstructionsStyled: Yup.string().optional(),
    paymentMethods: Yup.array()
      .required("Payment method is required")
      .min(1, "Payment method is required"),
    bankSource: Yup.string().when("isWireTransferSelected", {
      is: "yes",
      then: Yup.string()
        .required("Bank information is required")
        .max(32, "Maximum 32 characters allowed"),
    }),
  });

  const [tabData, setTabData] = useState(tabInitValues);
  const [tabErrors, setTabErrors] = useState(serverErrors);

  const populateTabData = (inputData) => {
    setTabData((state) => {
      let tempState = {
        ...state,
      };

      setItemPaymentInstructions(tempState?.paymentInstructionsStyled);

      if (
        inputData &&
        Object.values(inputData)?.length > 0 &&
        !areAllPropertiesNullOrEmptyArrays(inputData)
      ) {
        console.log("temp state", state);
        console.log("input data", inputData);
        console.log("merged data", { ...state, ...inputData });
        if (!inputData?.paymentMethods?.includes("WIRE_TRANSFER")) {
          inputData?.paymentMethods.push("WIRE_TRANSFER");
        }

        if (!inputData?.paymentMethods?.includes("CASHIER_CHEQUE")) {
          inputData?.paymentMethods.push("CASHIER_CHEQUE");
        }

        tempState = {
          ...tempState,
          ...inputData,
          isWireTransferSelected: inputData?.paymentMethods?.includes(
            "WIRE_TRANSFER"
          )
            ? "yes"
            : "no",
        };

        setItemPaymentInstructions(tempState?.paymentInstructionsStyled);

        if (disableCCPaymentParam) {
          tempState = {
            ...tempState,
            paymentMethods: tempState.paymentMethods.filter(
              (v) => v !== "CREDIT_CARD"
            ),
          };
        }
      }

      return tempState;
    });
  };

  useEffect(() => {
    populateTabData(data);
  }, []);

  useEffect(() => {
    populateTabData(data);
  }, [data]);

  useEffect(() => {
    setTabErrors(serverErrors);
  }, [serverErrors]);

  // useEffect(() => {
  //   console.log("ccpaymentparam", disableCCPaymentParam);

  //   if (disableCCPaymentParam) {
  //     setTabData((state) => {
  //       console.log(
  //         "filtered payment methods",
  //         state.paymentMethods.filter((v) => v !== "CREDIT_CARD")
  //       );

  //       const tempState = {
  //         ...state,
  //         paymentMethods: state.paymentMethods.filter(
  //           (v) => v !== "CREDIT_CARD"
  //         ),
  //       };
  //       return tempState;
  //     });
  //   }
  // }, [disableCCPaymentParam]);

  useEffect(() => {
    console.log("payment method tab data changed", tabData);
  }, [tabData]);

  return (
    <>
      <PlaceholderHeading title="Payment Details">
        <button
          disabled={tabDisable}
          className="btn-block btn btn-outline-primary px-4"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            closeWizard();
          }}
        >
          Close
        </button>
      </PlaceholderHeading>
      <div className="mt-3 mb-2">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mt-5 instructions-desc">
            Instruction on how the winning bidder can pay for your auction.
          </p>

          <div className="row" id="pageError">
            <div className="col-md-12">
              {tabErrors && tabErrors.message && (
                <div className="mb-4">
                  <ErrorMessage position="top">
                    {tabErrors.message}
                  </ErrorMessage>
                </div>
              )}
            </div>
          </div>
          <fieldset disabled={tabDisable}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={tabData}
              validationSchema={schema}
              onSubmit={(values) => {
                console.log("submitted values", values);

                saveTab(
                  step,
                  {
                    ...tabData,
                    ...values,
                  },
                  isDraft
                );
              }}
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                validateForm,
              }) => (
                <form id="passportInfoForm">
                  <div className="row">
                    <div className="col-12">
                      <div className="js-form-message mb-4">
                        <ReactQuill
                          theme="snow"
                          value={itemPaymentInstructions}
                          onChange={(m) => {
                            setItemPaymentInstructions(m);

                            const ele = document.createElement("div");
                            ele.innerHTML = m;

                            setFieldValue(
                              "paymentInstructions",
                              ele.textContent
                            );
                            setFieldValue("paymentInstructionsStyled", m);
                          }}
                          placeholder="Enter Payment Instructions"
                          rows
                        />

                        <DualErrorMessage
                          fieldName="paymentInstructions"
                          serverError={
                            serverErrors && serverErrors.paymentInstructions
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Choose Payment Method
                        </Label>

                        {disableCCPaymentParam && (
                          <Alert type="info">
                            Credit Card Not Allowed. Payment through credit card
                            is limited till $5000.
                          </Alert>
                        )}

                        <Field
                          className="form-control"
                          fieldName="paymentMethods"
                          name="paymentMethods"
                          items={[
                            {
                              value: "CREDIT_CARD",
                              label: "PAY with Credit Card",
                              disabled: disableCCPaymentParam,
                            },
                            {
                              value: "CASHIER_CHEQUE",
                              label: "Cashier's Cheque",
                              disabled: true,
                            },
                            {
                              value: "WIRE_TRANSFER",
                              label: "PAY through Wire Transfer",
                              disabled: true,
                            },
                          ]}
                          component={ZCheckBoxComponent}
                          handleOnChange={(index, name, checkedValues) => {
                            if (!checkedValues?.includes("WIRE_TRANSFER")) {
                              checkedValues.push("WIRE_TRANSFER");
                            }

                            if (!checkedValues?.includes("CASHIER_CHEQUE")) {
                              checkedValues.push("CASHIER_CHEQUE");
                            }

                            setFieldValue("paymentMethods", checkedValues);

                            // setFieldValue(
                            //   "isWireTransferSelected",
                            //   checkedValues.includes("WIRE_TRANSFER")
                            //     ? "yes"
                            //     : "no"
                            // );
                          }}
                        />

                        <DualErrorMessage
                          fieldName="paymentMethods"
                          serverError={
                            serverErrors && serverErrors.paymentMethods
                          }
                        />
                      </div>

                      {values.isWireTransferSelected === "yes" && (
                        <>
                          <h5 className="card-billing-sub-heading mb-4">
                            Wire Transfer Information
                          </h5>

                          <div className="js-form-message mb-4">
                            <Label hightlighted required>
                              Who will manage payments?
                            </Label>

                            <Alert type="info">
                              BidlyAuction would manage wire transfer payments
                            </Alert>

                            {false && (
                              <Field
                                className="form-control"
                                name="bankSource"
                                radioName="bankSource"
                                items={[
                                  {
                                    value: "BIDLY",
                                    label:
                                      "BidlyAuction would manage wire transfer payments",
                                  },
                                  {
                                    value: "SELLER",
                                    label:
                                      "Seller would manage wire transfer payments",
                                  },
                                ]}
                                component={ZRadioComponent}
                                handleOnChange={(value) => {
                                  setFieldValue("bankSource", value);
                                }}
                              />
                            )}

                            <DualErrorMessage
                              fieldName="bankSource"
                              serverError={
                                serverErrors && serverErrors.bankSource
                              }
                            />
                          </div>

                          {values.bankSource === "SELLER" && (
                            <>
                              {sellerBankAccount &&
                                Object.values(sellerBankAccount)?.length >
                                  0 && (
                                  <>
                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Bank Name
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sellerBankAccount.bankName}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Bank Address
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sellerBankAccount.bankAddress}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Bank Phone
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={
                                          sellerBankAccount.bankPhoneNumber
                                        }
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Beneficiary
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sellerBankAccount.beneficiary}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Physical Address
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={
                                          sellerBankAccount.physicalAddress
                                        }
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Routing Number
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sellerBankAccount.routingNumber}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Account Number
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={sellerBankAccount.accountNumber}
                                        disabled
                                      />
                                    </div>
                                  </>
                                )}

                              {(!sellerBankAccount ||
                                Object.values(sellerBankAccount)?.length ==
                                  0) && (
                                <>
                                  <Alert type="error">
                                    Please add seller bank account from{" "}
                                    <Link to="/seller-bank-info">here</Link>
                                  </Alert>
                                </>
                              )}
                            </>
                          )}

                          {values.bankSource === "BIDLY" && (
                            <>
                              {bidlyBankAccount &&
                                Object.values(bidlyBankAccount)?.length > 0 && (
                                  <>
                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Bank Name
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={bidlyBankAccount.bankName}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Bank Address
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={bidlyBankAccount.bankAddress}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Bank Phone
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={bidlyBankAccount.bankPhoneNumber}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Beneficiary
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={bidlyBankAccount.beneficiary}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Physical Address
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={bidlyBankAccount.physicalAddress}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Routing Number
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={bidlyBankAccount.routingNumber}
                                        disabled
                                      />
                                    </div>

                                    <div className="js-form-message mb-4">
                                      <Label hightlighted required>
                                        Account Number
                                      </Label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={bidlyBankAccount.accountNumber}
                                        disabled
                                      />
                                    </div>
                                  </>
                                )}

                              {(!bidlyBankAccount ||
                                Object.values(bidlyBankAccount)?.length ==
                                  0) && (
                                <>
                                  <Alert type="error">
                                    Please contact BidlyAuction team
                                  </Alert>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="js-form-message mb-4 row">
                    <div className="col-12">
                      <WizardButtons>
                        <button
                          className="btn btn-primary-dark-w px-5"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(false);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save and Continue
                        </button>

                        <button
                          ref={draftSubmitRef}
                          className="btn btn-outline-primary px-5"
                          type="button"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(true);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save Draft
                        </button>
                      </WizardButtons>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default ListingPaymentMethod;
