import { useEffect, useState } from "react";

function TagsInput({ badges, onRemove }) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(badges);
  }, [badges]);

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";
  }

  return (
    <div className="tags-input-container">
      {tags &&
        tags.map(
          (tag, index) =>
            tag &&
            tag.label && (
              <div className="tag-item" key={index}>
                <span className="text">
                  {tag?.label?.replace(/_/g, " ").toProperCase() ==
                  "Ending Soon"
                    ? "Closing Soon"
                    : tag?.label?.replace(/_/g, " ").toProperCase() ==
                      "New Auctions"
                    ? "New Listings"
                    : tag?.label?.replace(/_/g, " ").toProperCase()}
                </span>
                <span className="close" onClick={() => onRemove(tag)}>
                  &times;
                </span>
              </div>
            )
        )}
      <div className="tags-input" />
    </div>
  );
}

export default TagsInput;
