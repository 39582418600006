import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import storageSession from "redux-persist/lib/storage/session";

export const actionTypes = {
  Login: "Login Action",
  Logout: "Logout Action",
  SetViewExpired: "View Expired",
  SetCreditCardBillingTracking: "Set Credit Card Billing Tracking",
  SetCreditCardItemTracking: "Set Credit Card Item Id",
  UpdateLoggedInUser: "Update LoggedIn User",
  UpdateAuctionItemData: "Update Auction Item Data",
  ResetAuctionItemData: "Reset Auction Item Data",
  UpdateMiscData: "Misc Data",
};

const initialAuthState = {
  user: {},
  loggedInUser: {},
  token: undefined,
  refreshToken: undefined,
  viewExpired: false,
  creditCardBillingTracking: undefined,
  creditCardItemTracking: undefined,
  auctionItemData: undefined,
  miscData: {},
};

export const reducer = persistReducer(
  {
    storage,
    key: "v10-bidly-auth",
    whitelist: [
      "loggedInUser",
      "token",
      "refreshToken",
      "user",
      "viewExpired",
      "creditCardBillingTracking",
      "creditCardItemTracking",
      "auctionItemData",
      "miscData",
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token, refreshToken, user } = action.payload;
        return { ...state, token, refreshToken, user };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("refreshToken");
        return initialAuthState;
      }

      case actionTypes.SetViewExpired: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...initialAuthState,
          viewExpired: action.payload.isExpired ?? false,
        };
      }
      case actionTypes.SetCreditCardBillingTracking: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...state,
          creditCardBillingTracking: {
            billingInfo: action.payload.billingInfo,
            customerId: action.payload.customerId,
          },
        };
      }
      case actionTypes.SetCreditCardItemTracking: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...state,
          creditCardItemTracking: {
            billingInfo: action.payload.billingInfo,
            itemId: action.payload.itemId,
            from: action.payload.from,
          },
        };
      }
      case actionTypes.UpdateLoggedInUser: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...state,
          loggedInUser: { ...state.loggedInUser, ...action.payload },
        };
      }
      case actionTypes.UpdateAuctionItemData: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...state,
          auctionItemData: { ...state.auctionItemData, ...action.payload },
        };
      }
      case actionTypes.ResetAuctionItemData: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...state,
          auctionItemData: {},
        };
      }
      case actionTypes.UpdateMiscData: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return {
          ...state,
          miscData: { ...state.miscData, ...action.payload },
        };
      }
      default:
        return state;
    }
  }
);
