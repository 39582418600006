import React from "react";
import classes from "./IconedInfo.module.css";
import infoIcon from "../../assets/images/info-icon-sm.svg";

const IconedInfo = ({ children }) => {
  return (
    <div className={classes.container}>
      <img src={infoIcon} />
      <p>{children}</p>
    </div>
  );
};

export default IconedInfo;
