import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import DropDownButton from "../../components/button/DropDownButton";
import DropDownItem from "../../components/button/DropDownItem";
import LotsTable from "../../components/table/LotsTable";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { BOUGHT_COMPLETED_URL } from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { fetchMyBids } from "../../services/MyBidsService";
import {
  formatUSD,
  formattedUTCDatetoUSCentral,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import ButtonsPanel from "./ButtonsPanel";
import classes from "./MyBids.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import certificateIcon from "../../assets/images/doc-icon.svg";
import receiptIcon from "../../assets/images/receipt-icon.svg";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";

const ItemBought = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [completedBids, setCompletedBids] = useState([]);

  const [totalCompleted, setTotalCompleted] = useState();
  const [currentPageCompleted, setCurrentPageCompleted] = useState(0);
  const [pageSizeCompleted, setPageSizeCompleted] = useState(30);
  const [totalPagesCompleted, setTotalPagesCompleted] = useState();

  const fetchCompletedBids = async () => {
    const pagingParams =
      currentPageCompleted + 1 && pageSizeCompleted
        ? "?" +
          ["page=" + currentPageCompleted, "size=" + pageSizeCompleted].join(
            "&"
          )
        : "";

    const rs = await fetchMyBids(BOUGHT_COMPLETED_URL + pagingParams);
    console.log("completed bids", rs);

    const content = rs?.content;
    const items = [];
    content?.map((c) => {
      let m = {
        ...c,
      };

      m.auction.endedOn = m.auction.endedOn
        ? formattedUTCtoUSCentral(m.auction.endedOn)
        : m.auction.endedOn;
      m.auction.paymentDate = m.auction.paymentDate
        ? formattedUTCDatetoUSCentral(m.auction.paymentDate)
        : m.auction.paymentDate;
      m.auction.pickupDate = m.auction.pickupDate
        ? formattedUTCDatetoUSCentral(m.auction.pickupDate)
        : m.auction.pickupDate;

      items.push(m);
    });

    setCompletedBids(items);

    setTotalCompleted(rs.totalElements);
    setTotalPagesCompleted(rs.totalPages);
  };

  const fetchBidsData = async () => {
    ctx.showProgress(true);
    try {
      await fetchCompletedBids();
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchBidsData();
  }, []);

  useEffect(() => {
    if (pageSizeCompleted && currentPageCompleted + 1) fetchCompletedBids();
  }, [pageSizeCompleted, currentPageCompleted]);

  const wonBidsMobileHeader = (
    <>
      <div className={tableClasses.selection}></div>
      <div className={tableClasses.label}>Item Description</div>
    </>
  );

  const completedBidsTableHeader = (
    <tr>
      <th>Asset Description</th>
      <th>Amount</th>
      <th>Buyer’s Premium</th>
      <th>Payment Date</th>
      <th>Pickup Date</th>
      <th></th>
    </tr>
  );

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myBuyNowActive={true} subMenu="item-bought" />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <h2 className="h4 font-weight-bold">Buy Now - Items Bought</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <ButtonsPanel>
                <DropDownButton
                  className={classes.item}
                  onItemClicked={(v) => {
                    console.log(v);
                    setCurrentPageCompleted(0);
                    setPageSizeCompleted(v);
                  }}
                >
                  <DropDownItem label="Show 30 Records" value="30" />
                  <DropDownItem label="Show 15 Records" value="15" />
                  <DropDownItem label="Show 7 Records" value="7" />
                </DropDownButton>
              </ButtonsPanel>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <LotsTable
                    label="Items Bought"
                    mobileHeader={wonBidsMobileHeader}
                    tableHeader={completedBidsTableHeader}
                    noRecordTableBody={
                      <>
                        <tr>
                          <td colSpan={7} className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      </>
                    }
                    tableBody={
                      completedBids && Object.keys(completedBids).length > 0
                        ? completedBids.map((bid, i) => {
                            return (
                              <ItemBought.WonBidsBodyRow
                                key={i}
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                auctionEnd={bid.auction.endedOn}
                                buyerFee={bid.buyerPremium}
                                lot={bid.auction.customId}
                                soldAmount={bid.soldAmount}
                                tax={bid.taxAmount}
                                total={bid.total}
                                currency={bid.auction.currency}
                                paymentDate={bid.auction.paymentDate}
                                pickupDate={bid.auction.pickupDate}
                                invoiceURL={bid.invoiceURL}
                                receiptURL={bid.paymentReceiptURL}
                                hideSelection={true}
                              />
                            );
                          })
                        : undefined
                    }
                    noRecordMobileBody={
                      <>
                        <div className={tableClasses.blockPanel}>
                          <div
                            className={`justify-content-center ${tableClasses.block}`}
                          >
                            <div className={tableClasses.blockText}>
                              <div className={tableClasses.item}>
                                <div className={tableClasses.productLabel}>
                                  No Records Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    mobileBody={
                      completedBids && Object.keys(completedBids).length > 0
                        ? completedBids.map((bid, i) => {
                            return (
                              <ItemBought.WonBidsMobileBodyRow
                                key={i}
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                auctionEnd={bid.auction.endedOn}
                                buyerFee={bid.buyerPremium}
                                lot={bid.auction.customId}
                                soldAmount={bid.soldAmount}
                                tax={bid.taxAmount}
                                total={bid.total}
                                currency={bid.auction.currency}
                                paymentDate={bid.auction.paymentDate}
                                pickupDate={bid.auction.pickupDate}
                                invoiceURL={bid.invoiceURL}
                                receiptURL={bid.paymentReceiptURL}
                                hideSelection={true}
                              />
                            );
                          })
                        : undefined
                    }
                  />
                </div>
              </div>

              {totalPagesCompleted > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPageCompleted}
                    total={totalCompleted}
                    pageSize={pageSizeCompleted}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPageCompleted + 1}
                    totalCount={totalCompleted}
                    pageSize={pageSizeCompleted}
                    onPageChange={(page) => {
                      setCurrentPageCompleted(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

ItemBought.WonBidsBodyRow = ({
  itemId,
  isSelected = false,
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
  status,
  paymentDate,
  pickupDate,
  invoiceURL,
  receiptURL,
  onChange = () => {},
  hideSelection = false,
}) => (
  <>
    <tr>
      {!hideSelection && (
        <td>
          {status === "INVOICE_GENERATED" && (
            <input type="checkbox" onChange={onChange} checked={isSelected} />
          )}
        </td>
      )}
      <td>
        {status && (
          <span className={tableClasses.uLabel}>
            {status === "INVOICE_GENERATED" && "PAYMENT PENDING"}
            {status === "WAITING_TO_RECEIVE_PAYMENT" &&
              "PAYMENT CONFIRMATION PENDING"}
            {status === "WAITING_FOR_ITEM_PICKUP" && "PICKUP PENDING"}
          </span>
        )}
        <span className={tableClasses.productLabel}>
          <Link to={`/auction-item/` + itemId}>{label}</Link>
        </span>
        <span className={tableClasses.lot}>
          <Link to={`/auction-item/` + itemId}>LOT # {lot}</Link>
        </span>
      </td>
      <td>{formatUSD(total)}</td>
      <td>{formatUSD(buyerFee)}</td>
      <td>{paymentDate}</td>
      <td>{pickupDate}</td>
      <td>
        {invoiceURL && (
          <a
            target="_blank"
            download={true}
            href={invoiceURL}
            className={`btn btn-outline-primary px-2 py-1 ${tableClasses.button}`}
          >
            <img src={certificateIcon} />
            Invoice
          </a>
        )}

        {receiptURL && (
          <a
            target="_blank"
            download={true}
            href={receiptURL}
            className={`btn btn-outline-primary px-2 py-1 ${tableClasses.button}`}
          >
            <img src={receiptIcon} />
            Receipt
          </a>
        )}
      </td>
    </tr>
  </>
);

ItemBought.WonBidsMobileBodyRow = ({
  itemId,
  isSelected = false,
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
  status,
  paymentDate,
  pickupDate,
  invoiceURL,
  receiptURL,
  onChange = () => {},
  hideSelection = false,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}>
          {!hideSelection && status === "INVOICE_GENERATED" && (
            <input type="checkbox" onChange={onChange} checked={isSelected} />
          )}
        </div>

        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.productLabel}>
              <Link to={`/auction-item/` + itemId}>{label}</Link>
            </div>
            <div className={tableClasses.lot}>
              <Link to={`/auction-item/` + itemId}>LOT # {lot}</Link>
            </div>
          </div>

          {status && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Status</div>
                <div className={tableClasses.value}>
                  {status === "INVOICE_GENERATED" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PAYMENT PENDING
                    </span>
                  )}
                  {status === "WAITING_TO_RECEIVE_PAYMENT" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PAYMENT CONFIRMATION PENDING
                    </span>
                  )}
                  {status === "WAITING_FOR_ITEM_PICKUP" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PICKUP PENDING
                    </span>
                  )}
                </div>
              </div>
            </>
          )}

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Buyer’s Premium</div>
            <div className={tableClasses.value}>{formatUSD(buyerFee)}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Date</div>
            <div className={tableClasses.value}>{paymentDate}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Pickup Date</div>
            <div className={tableClasses.value}>{pickupDate}</div>
          </div>
        </div>
      </div>
      <div className={`${tableClasses.block} ${tableClasses.bordered}`}>
        <div className={tableClasses.selection}>&nbsp;&nbsp;&nbsp;</div>

        <div className={tableClasses.totalRow}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Amount</div>
            <div className={tableClasses.value}>{formatUSD(total)}</div>
          </div>

          <div className={tableClasses.buttons}>
            {invoiceURL && (
              <a target="_blank" href={invoiceURL} download={true}>
                <img src={certificateIcon} />
                Invoice
              </a>
            )}

            {receiptURL && (
              <a target="_blank" href={receiptURL} download={true}>
                <img src={receiptIcon} />
                Receipt
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemBought;
