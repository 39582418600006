import React from "react";
import Layout from "../../layout/Layout";
import AlertCommon from "./AlertCommon";
import image from "../../assets/images/429.svg";
import { useAuthContext } from "../../redux/auth-context";
import { useEffect } from "react";

const CyberAttack = () => {
  const ctx = useAuthContext();

  useEffect(() => {
    ctx.showProgress(false);
  }, []);

  const description =
    "We're sorry, but recently we have received too many requests from you. Please try again later.";
  const heading = "Too many requests";

  return (
    <>
      <Layout>
        <div className="container mt-10 mb-10">
          <div className="row">
            <div className="offset-xl-3 col-xl-6">
              <AlertCommon
                description={description}
                heading={heading}
                image={image}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CyberAttack;
