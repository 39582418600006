import {
  SERVICE_URL,
  Verify_Identiy_SERVICE_URL,
  Auth_SERVICE_URL,
  Auth_SERVICE_GOOGLE_URL,
  USER_STATUS_SERVICE_URL,
  USER_BID_NOW_URL,
  CONTACT_US,
  SELLER_BANK_ACCOUNT_FETCH_URL,
  SELLER_BANK_ACCOUNT_SAVE_URL,
  BIDLY_BANK_ACCOUNT_FETCH_URL,
  SELLER_AUCTION_CONFIG_FETCH_URL,
  SELLER_TERMS_SAVE_URL,
} from "../Constants";
import authenticatedFetch from "./fetch";
import { simpleFetch } from "./fetch";
import store from "../redux/store";
import { actionTypes } from "../pages/_redux/authRedux";

export const Authenticate = async (requestBody) => {
  const response = await simpleFetch(`${Auth_SERVICE_URL}`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    store.dispatch({
      type: actionTypes.Login,
      payload: {
        token: res.accessToken,
        refreshToken: res.refreshToken,
        user: {
          name: res.fullName,
          username: res.userName,
          type: res.userType,
        },
      },
    });
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const AuthenticateWithGoogle = async (requestBody) => {
  const response = await simpleFetch(`${Auth_SERVICE_GOOGLE_URL}`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    store.dispatch({
      type: actionTypes.Login,
      payload: {
        token: res.accessToken,
        refreshToken: res.refreshToken,
        user: {
          name: res.fullName,
          username: res.userName,
          type: res.userType,
        },
      },
    });
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const VerifyIdentityRequest = async (requestBody) => {
  const response = await authenticatedFetch(`${Verify_Identiy_SERVICE_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const FetchUserProfile = async () => {
  const response = await authenticatedFetch(`${SERVICE_URL}user/profile`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const FetchAddressData = async () => {
  const response = await authenticatedFetch(`${SERVICE_URL}user/address-info`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const FetchPhoneData = async () => {
  const response = await authenticatedFetch(`${SERVICE_URL}user/phone-info`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const fetchBidlyBankData = async () => {
  const response = await authenticatedFetch(`${BIDLY_BANK_ACCOUNT_FETCH_URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const fetchSellerBankData = async () => {
  const response = await authenticatedFetch(
    `${SELLER_BANK_ACCOUNT_FETCH_URL}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const saveSellerBankInfo = async (requestBody) => {
  const response = await authenticatedFetch(`${SELLER_BANK_ACCOUNT_SAVE_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }
};

export const UpdateAccountInfoRequest = async (requestBody) => {
  const response = await authenticatedFetch(
    `${SERVICE_URL}user/update-account-info`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const UpdatePhoneNumbersRequest = async (requestBody) => {
  const response = await authenticatedFetch(
    `${SERVICE_URL}user/update-phone-numbers`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const UpdateAddressRequest = async (requestBody) => {
  const response = await authenticatedFetch(
    `${SERVICE_URL}user/update-address`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const ResetPasswordRequest = async (requestBody) => {
  const response = await authenticatedFetch(
    `${SERVICE_URL}user/reset-password`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export async function deleteBidderAlternatePhoneNumber(bidderId) {
  const response = await authenticatedFetch(
    `${SERVICE_URL}user/phone/${bidderId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export const StatusRequest = async () => {
  const response = await authenticatedFetch(`${USER_STATUS_SERVICE_URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const BidNow = async (requestBody) => {
  const response = await authenticatedFetch(`${USER_BID_NOW_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const UserWishList = async (param) => {
  let url = `${SERVICE_URL}user/wish-list` + (param ? param : "");

  const response = await authenticatedFetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export async function RemoveFromWishList(watchId) {
  const response = await authenticatedFetch(
    `${SERVICE_URL}user/wish-list/${watchId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export const ContactUsService = async (requestBody) => {
  const response = await simpleFetch(`${CONTACT_US}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const fetchSellerAuctionConfig = async () => {
  const response = await authenticatedFetch(
    `${SELLER_AUCTION_CONFIG_FETCH_URL}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const saveSellerTerms = async (requestBody) => {
  const response = await authenticatedFetch(`${SELLER_TERMS_SAVE_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }
};
