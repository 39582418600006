import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
  scrollTo,
} from "../../services/Utilities";
import { useDispatch } from "react-redux";
import { actionTypes } from "../_redux/authRedux";
import StepHeading from "./StepHeading";
import {
  ALL_STATES_LIBRARY_URL,
  CITIES_LIBRARY_URL,
  COUNTRIES_LIBRARY_URL,
  genericErrorMsg,
} from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import { Select } from "../../components/Select";
import ErrorMessage from "../../components/UI/ErrorMessage";
import { useAuthContext } from "../../redux/auth-context";
import { saveCustomerBillingInfoToGateway } from "../../services/PaymentService";

const AddCardBillingInfo = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [pageErrors, setPageErrors] = useState();
  const [serverErrors, setServerErrors] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const saveCustomerToGateway = (values) => {
    ctx.showProgress(true);
    saveCustomerBillingInfoToGateway(values)
      .then((res) => {
        ctx.showProgress(false);
        dispatch({
          type: actionTypes.SetCreditCardBillingTracking,
          payload: {
            billingInfo: { ...values },
            customerId: res.customerId,
          },
        });

        navigate("/add-card", { state: { clientSecret: res.clientSecret } });
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }
        }
      });
  };

  const fetchLocations = async () => {
    try {
      const countryRs = await LibraryService(COUNTRIES_LIBRARY_URL);
      let countryList = [];
      countryRs?.map((item) => {
        countryList.push({
          key: item.key,
          name: item.value,
        });
      });

      setCountries(countryList);

      const statesRs = await LibraryService(ALL_STATES_LIBRARY_URL);
      let stateList = [];
      statesRs?.map((item) => {
        stateList.push({
          id: item.id,
          name: item.key,
          country: item.value,
        });
      });

      setStates(stateList);

      const citiesRs = await LibraryService(CITIES_LIBRARY_URL);
      let cityList = [];
      citiesRs?.map((item) => {
        cityList.push({
          id: item.id,
          name: item.key,
          state: item.value,
        });
      });

      setCities(cityList);
    } catch (e) {
      console.log(e);
      navigate("/error");
    }
  };

  useEffect(() => {
    ctx.showProgress(true);
    fetchLocations();
    ctx.showProgress(false);
  }, []);

  const initialValue = {
    phoneNumber: "",
    street: "",
    pobox: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
  };

  const schema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .max(32, "Invalid phone number"),
    street: Yup.string().max(128, "Invalid street address"),
    pobox: Yup.string().max(32, "Invalid Apt/PO Box number"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string()
      .required("Zip Code is required")
      .max(32, "Invalid Zip Code"),
  });

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <AccountMenu addCardActive={true} />
          </div>

          <div className="offset-lg-1 col-lg-8 col-md-8">
            <StepHeading title="Add New Credit Card" step="1/2" />

            <div className="mb-5">
              <BHotrizontalProgress progress={33} />
            </div>

            <div className="row">
              <div className="col-lg-7 col-12">
                <p className="mt-5 instructions-desc">
                  Valid USA Credit/Debit Card is needed to place bid. Your card
                  will be used for the buyer's fee only.
                </p>

                <h5 className="card-billing-sub-heading mb-4">
                  1. Billing Information
                </h5>

                <div className="row" id="pageError">
                  <div className="col-md-12">
                    {pageErrors?.message && (
                      <div className="mb-4">
                        <ErrorMessage>{pageErrors.message}</ErrorMessage>
                      </div>
                    )}
                  </div>
                </div>

                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    saveCustomerToGateway(values);
                  }}
                >
                  {({ handleSubmit, errors, setFieldValue, validateForm }) => (
                    <form id="passportInfoForm">
                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          name="phoneNumber"
                          component={ZInputComponent}
                          placeholder="Enter phone number"
                          label="Phone Number"
                          required
                          serverError={serverErrors && serverErrors.phoneNumber}
                        />
                      </div>
                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          name="street"
                          component={ZInputComponent}
                          placeholder="Enter street address"
                          label="Street Address"
                          serverError={serverErrors && serverErrors.street}
                        />
                      </div>

                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          name="pobox"
                          component={ZInputComponent}
                          placeholder="Enter apt/p.o box number"
                          label="Apt/P.O Box Number"
                          serverError={serverErrors && serverErrors.pobox}
                        />
                      </div>

                      <div className="js-form-message mb-4">
                        <Select
                          name="country"
                          className="form-control"
                          label="Country"
                          mandatory={true}
                          defaultTouch
                          required
                          serverError={serverErrors && serverErrors.country}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("country", e.target.value);
                            setSelectedCountry(e?.target?.value);
                            setSelectedState(undefined);
                          }}
                        >
                          <option value="" disabled>
                            Select Country
                          </option>

                          {countries &&
                            countries.length > 0 &&
                            countries.map((country) => {
                              return (
                                <option key={country.key} value={country.key}>
                                  {country.name}
                                </option>
                              );
                            })}
                        </Select>
                      </div>

                      <div className="js-form-message mb-4">
                        <Select
                          name="state"
                          className="form-control"
                          label="State"
                          mandatory={true}
                          required
                          defaultTouch
                          serverError={serverErrors && serverErrors.state}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("state", e.target.value);
                            setSelectedState(e?.target?.value);
                          }}
                        >
                          <option value="" disabled>
                            Select State
                          </option>

                          {selectedCountry &&
                            states &&
                            states.length > 0 &&
                            states
                              .filter((item) => item.country == selectedCountry)
                              .map((state) => {
                                return (
                                  <option key={state.id} value={state.id}>
                                    {state.name}
                                  </option>
                                );
                              })}
                        </Select>
                      </div>

                      <div className="js-form-message mb-4">
                        <Select
                          name="city"
                          className="form-control"
                          label="City"
                          mandatory={true}
                          required
                          defaultTouch
                          serverError={serverErrors && serverErrors.city}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("city", e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select City
                          </option>

                          {selectedState &&
                            cities &&
                            cities.length > 0 &&
                            cities
                              .filter((item) => item.state == selectedState)
                              .map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                        </Select>
                      </div>

                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          name="zipCode"
                          component={ZInputComponent}
                          placeholder="Enter zip code"
                          label="Zip Code"
                          required
                          serverError={serverErrors && serverErrors.zipCode}
                        />
                      </div>

                      <div className="js-form-message mb-4 row">
                        <div className="col-md-6 mb-3 mb-md-0">
                          <button
                            className="btn-block btn btn-outline-primary px-5"
                            type="button"
                            onClick={async (e) => {
                              e.preventDefault();
                              navigate("/add-card-info");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="btn-block btn btn-primary-dark-w px-5"
                            type="submit"
                            onClick={async (e) => {
                              e.preventDefault();
                              let res = await validateForm();
                              if (res && Object.values(res)?.length > 0) {
                                scrollTo("pageError");
                                setPageErrors({ message: genericErrorMsg });
                              }
                              handleSubmit(e);
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddCardBillingInfo;
