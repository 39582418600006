export const sortOption = [
    { value: "ending-soon", label: "Ending Soon" },
    { value: "newest", label: "Newest" },
    { value: "low-to-high", label: "Price, Low to High" },
    { value: "high-to-low", label: "Price, High to Low" },
    { value: "no-of-bids", label: "Number of Bids" },
    { value: "a-to-z", label: "Title, A to Z" },
    { value: "z-to-a", label: "Title, Z to A" },
    { value: "preview", label: "Preview" },
  ];

 export const priceFrom = [
    { value: "", label: "any" },
    { value: "0", label: "$0" },
    { value: "10", label: "$10" },
    { value: "50", label: "$50" },
    { value: "100", label: "$100" },
    { value: "500", label: "$500" },
    { value: "1000", label: "$1,000" },
    { value: "2000", label: "$2,000" },
    { value: "3000", label: "$3,000" },
    { value: "4000", label: "$4,000" },
    { value: "5000", label: "$5,000" },
    { value: "7000", label: "$7,500" },
    { value: "10000", label: "$10,000" },
    { value: "25000", label: "$25,000" },
    { value: "50000", label: "$50,000" },
  ];

 export const priceTo = [
    { value: "", label: "any" },
    { value: "500000", label: "Over $50,000" },
    { value: "500000", label: "$50,000" },
    { value: "25000", label: "$25,000" },
    { value: "10000", label: "$10,000" },
    { value: "7500", label: "$7,500" },
    { value: "5000", label: "$5,000" },
    { value: "4000", label: "$4,000" },
    { value: "3000", label: "$3,000" },
    { value: "2000", label: "$2,000" },
    { value: "1000", label: "$1,000" },
    { value: "500", label: "$500" },
    { value: "100", label: "$100" },
    { value: "50", label: "$50" },
    { value: "10", label: "$10" },
  ];

  export const readiusOptions = [
    { value: "5", label: "5 Miles" },
    { value: "10", label: "10 Miles" },
    { value: "15", label: "15 Miles" },
    { value: "20", label: "20 Miles" },
  ];

  export const priceOption = [
    { value: "0-200", label: "200 & Below" },
    { value: "200-1000", label: "200-1000" },
    { value: "1000-5000", label: "1000-5000" },
    { value: "5000-999999", label: "5000 & Above" },
  ];

  export const badges = [
    { value: "popular_action", label: "Popular" },
    { value: "newly_adde", label: "Newly Added" }
  ];