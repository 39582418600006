import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import classes from "./Footer.module.css";

const Footer = (props) => {
  return (
    <footer>
      <div className="pt-8 pb-4 border-top">
        <div className="container mt-1">
          <div className="row">
            <div className="col-lg-4 d-none d-md-block">
              <div className="mb-6">
                <Link
                  to="/"
                  className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                >
                  <img src={logo} />
                </Link>
              </div>
              <div className="mb-4">
                <p className={classes.footerPara}>
                  BidlyAuction introduces a user-friendly and hassle-free online
                  auction platform designed specifically for the public. Our
                  platform offers an efficient and direct method to sell excess
                  items.
                  <br />
                  <br />
                  Auctions are conducted exclusively online, providing the
                  convenience of 24/7 bidding opportunities.
                </p>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-7 offset-xl-3 col-xl-5">
              <div className="row">
                <div className="col-6 col-md mb-4 mb-md-0">
                  <h6 className={`mb-3 ${classes.footerHeading2}`}>
                    Get to know us
                  </h6>
                  <ul
                    className={`list-group list-group-flush list-group-borderless mb-4 list-group-transparent ${classes.footerMainLinks}`}
                  >
                    {/* <li>
                      <Link
                        className="list-group-item list-group-item-action"
                        to="/"
                      >
                        Careers
                      </Link>
                    </li> */}

                    <li>
                      <Link
                        className="list-group-item list-group-item-action"
                        to="/about-us"
                      >
                        About Us
                      </Link>
                    </li>
                  </ul>

                  <h6 className={`mb-3 ${classes.footerHeading2}`}>
                    Need Help?
                  </h6>
                  <ul
                    className={`list-group list-group-flush list-group-borderless mb-0 list-group-transparent ${classes.footerMainLinks}`}
                  >
                    <li>
                      <Link
                        className="list-group-item list-group-item-action"
                        to="/advance-search"
                      >
                        Advance Search
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="list-group-item list-group-item-action"
                        to="/contact-us"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-6 col-md mb-4 mb-md-0">
                  <h6 className={`mb-3 ${classes.footerHeading2}`}>
                    Buy from Us
                  </h6>
                  <ul
                    className={`list-group list-group-flush list-group-borderless mb-4 list-group-transparent ${classes.footerMainLinks}`}
                  >
                    <li>
                      <Link
                        className="list-group-item list-group-item-action"
                        to="/buy"
                      >
                        How to buy
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={() => {
                          window.location.href = "/article/BIDDER";
                        }}
                      >
                        Bidder FAQ
                      </Link>
                    </li>
                  </ul>

                  <h6 className={`mb-3 ${classes.footerHeading2}`}>
                    Sell with Us
                  </h6>
                  <ul
                    className={`list-group list-group-flush list-group-borderless mb-0 list-group-transparent ${classes.footerMainLinks}`}
                  >
                    <li>
                      <Link
                        className="list-group-item list-group-item-action"
                        to="/become-seller"
                      >
                        How to sell
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="list-group-item list-group-item-action"
                        onClick={() => {
                          window.location.href = "/article/SELLER";
                        }}
                      >
                        Seller FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-top py-4">
        <div className="container">
          <div
            className={`flex-center-between d-block d-md-flex ${classes.copyRightsBar}`}
          >
            <div className="mb-3 mb-md-0">
              Copyrights © {new Date().getFullYear()} All Rights Reserved by
              BidlyAuction
            </div>
            <div className="text-md-right">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                  <Link to="/terms-of-use">Terms of Use</Link>
                </li>
                <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Footer-copy-right */}
    </footer>
  );
};

export default Footer;
