import React from "react";
import { useTimer } from "react-timer-hook";
import classes from "./Auction.module.css";

const MyTimer = ({ expiryTimestamp }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
      <div className={classes.timerP}>
        <span className={classes.timerElement}>{days}D</span><span className={classes.timerElement}>{hours}H</span><span className={classes.timerElement}>{minutes}M</span>
        <span className={classes.timerElement}>{seconds}S</span>
      </div>
  );
};

export default MyTimer;
