import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BidlyCarousel from "../../components/carousel/BidlyCarousel";
import { FEATURED_CATEGORIES_URL } from "../../Constants";
import { useAuthContext } from "../../redux/auth-context";
import { LibraryService } from "../../services/LibraryService";
import FeaturedCategory from "./FeaturedCategory";

const FeaturedCategories = () => {
  const navigate = useNavigate();
  const [featuredList, setFeaturedList] = useState([]);

  useEffect(() => {
    LibraryService(FEATURED_CATEGORIES_URL)
      .then((res) => {
        let catList = [];
        res.map((item) => {
          catList.push(
            <FeaturedCategory
              title={item.key}
              imageData={item.metaData}
              onClick={(e) => {
                navigate("/search-result", {
                  state: { filter: "?category=" + item.id },
                });
              }}
            />
          );
        });

        setFeaturedList(catList);
      })
      .catch((e) => {
        navigate("/error");
      });
  }, []);

  return (
    <>
      <BidlyCarousel
        gap={30}
        cols={5}
        hideArrow
        items={featuredList}
        mobileBreakpoint={0}
        responsiveLayout={[
          {
            breakpoint: 1199,
            cols: 4,
            rows: 1,
            gap: 15,
          },
          {
            breakpoint: 991,
            cols: 3,
            rows: 1,
            gap: 15,
          },
          {
            breakpoint: 767,
            cols: 2,
            rows: 1,
            gap: 15,
          },
          {
            breakpoint: 467,
            cols: 1,
            rows: 1,
            gap: 10,
          },
        ]}
      />
    </>
  );
};

export default FeaturedCategories;
