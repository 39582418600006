import { createSlice } from "@reduxjs/toolkit";

const initialUtilState = {
  tempEmail: "tempemail",
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const utilSlice = createSlice({
  name: "util",
  initialState: initialUtilState,
  reducers: {
    saveTempEmail: (state, action) => {
      state.tempEmail = action.payload.tempEmail;
    },
  },
});
