import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import CheckedoutItems from "./CheckedoutItems";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY);

const CheckoutCardPaymentInfo = () => {
  const location = useLocation();
  const params = location.state ?? {};
  const [clientSecret, setClientSecret] = useState();

  const [selected, setSelected] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [navigationFrom, setNavigationFrom] = useState();

  useEffect(() => {
    setSelected((prev) => {
      setTotalAmount(
        params?.selected.reduce((total, cur) => total + cur.total, 0)
      );
      return params?.selected;
    });

    if (params) setClientSecret(params.clientSecret);

    setNavigationFrom(params?.from);

    console.log(params);
  }, [params]);

  const options = {
    appearance: {
      theme: "stripe",
    },
    clientSecret,
  };

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <Layout>
            <div className="container mt-5 mb-5">
              <div className="row">
                <div className="offset-lg-1 col-lg-10 col-md-12">
                  <CheckedoutItems
                    selected={selected}
                    totalAmount={totalAmount}
                  />

                  <h2 className="h4 font-weight-bold">Credit Card Payment</h2>

                  <div className="mb-5">
                    <BHotrizontalProgress progress={33} />
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <CheckoutForm navigationFrom={navigationFrom} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        </Elements>
      )}
    </>
  );
};

export default CheckoutCardPaymentInfo;
