import React, { useState, useEffect, useContext } from "react";

const AuthContext = React.createContext({
  isLoggedIn: false,
  isShowProgress: false,
  successMessage: null,
  isSidebarOpen: false,
  isUserSidebarOpen: false,
  isSearchFilterSidebarOpen: false,
  menuData: null,
  onSetSuccessMessage: (message) => {},
  onSetMenuData: (data) => {},
  onLogout: () => {},
  onLogin: (identity) => {},
  showProgress: (isShowProgress) => {},
  sidebarOpen: (isSidebarOpen) => {},
  userSidebarOpen: (isUserSidebarOpen) => {},
  searchFilterSidebarOpen: (isSearchFilterSidebarOpen) => {},
});

export function useAuthContext() {
  return useContext(AuthContext);
}
export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isShowProgress, setIsShowProgress] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userSidebarOpen, setUserSidebarOpen] = useState(false);
  const [searchFilterSidebarOpen, setSearchFilterSidebarOpen] = useState(false);
  const [menuData, setMenuData] = useState({});

  useEffect(() => {
    const storedUserLoggedIn = localStorage.getItem("loggedin");

    if (storedUserLoggedIn === "1") {
      setIsLoggedIn(true);
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("loggedin");
    setIsLoggedIn(false);
  };

  const loginHandler = (identity) => {
    localStorage.setItem("loggedin", "1");
    setIsLoggedIn(true);
  };

  const showProgressHandler = (isShowProgress) => {
    setIsShowProgress(isShowProgress);
  };

  const sidebarOpenHandler = (isSidebarOpen) => {
    setSidebarOpen(isSidebarOpen);
  };

  const userSidebarOpenHandler = (isSidebarOpen) => {
    setUserSidebarOpen(isSidebarOpen);
  };

  const searchFilterSidebarOpenHandler = (isSidebarOpen) => {
    setSearchFilterSidebarOpen(isSidebarOpen);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        isShowProgress: isShowProgress,
        successMessage: successMessage,
        isSidebarOpen: sidebarOpen,
        isUserSidebarOpen: userSidebarOpen,
        isSearchFilterSidebarOpen: searchFilterSidebarOpen,
        menuData: menuData,
        onSetSuccessMessage: (message) => {
          setSuccessMessage(message);
        },
        onSetMenuData: (data) => {
          setMenuData(data);
        },
        onLogout: logoutHandler,
        onLogin: loginHandler,
        showProgress: showProgressHandler,
        sidebarOpen: sidebarOpenHandler,
        userSidebarOpen: userSidebarOpenHandler,
        searchFilterSidebarOpen: searchFilterSidebarOpenHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
