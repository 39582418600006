import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../../components/UI/HorizontalProgress";
import PlaceholderHeading from "../../credit-card/PlaceholderHeading";
import ErrorMessage from "../../../components/UI/ErrorMessage";
import ReviewInfoBox from "../../../components/UI/ReviewInfoBox";
import WizardButtons from "./WizardButtons";

const ListingReview = (props) => {
  const { step, data, serverErrors, tabDisable } = props;
  const { saveTab, closeWizard } = props;

  const [tabData, setTabData] = useState({});
  const [tabErrors, setTabErrors] = useState(serverErrors);

  const populateTabData = (inputData) => {
    if (inputData && Object.values(inputData)?.length > 0) {
      setTabData((state) => {
        const tempState = { ...state, ...inputData };
        return tempState;
      });
    }
  };

  useEffect(() => {
    populateTabData(data);
  }, []);

  useEffect(() => {
    populateTabData(data);
  }, [data]);

  useEffect(() => {
    setTabErrors(serverErrors);
  }, [serverErrors]);

  return (
    <>
      <PlaceholderHeading title="Review Your Listing">
        <button
          disabled={tabDisable}
          className="btn-block btn btn-outline-primary px-4"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            closeWizard();
          }}
        >
          Close
        </button>
      </PlaceholderHeading>
      <div className="mt-3 mb-2">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col-12">
          <div className="row" id="pageError">
            <div className="col-md-12">
              {tabErrors && tabErrors.message && (
                <div className="mb-4">
                  <ErrorMessage position="top">
                    {tabErrors.message}
                  </ErrorMessage>
                </div>
              )}
            </div>
          </div>

          <fieldset disabled={tabDisable}>
            <form>
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="js-form-message mb-4 mt-4">
                    <ReviewInfoBox>
                      Please review your information for accuracy before
                      clicking <b>"Post Listing"</b>. Clicking{" "}
                      <b>"Save Draft"</b> will save your listing so you can come
                      back and add more details. Once <b>"Post Listing"</b>{" "}
                      clicked, your auction will be viewable to all users. You
                      can edit most aspects of your listing once it is live by
                      clicking the edit button on the page of your item.
                      <br />
                      <br />
                      <b>Note: </b> Review & Edit item detail by clicking on
                      menu items on the left.
                    </ReviewInfoBox>
                  </div>
                </div>
              </div>

              <div className="js-form-message mb-4 row">
                <div className="col-12">
                  <WizardButtons>
                    <button
                      className="btn btn-primary-dark-w px-5"
                      type="submit"
                      onClick={async (e) => {
                        e.preventDefault();
                        saveTab(step);
                      }}
                    >
                      POST LISTING
                    </button>
                  </WizardButtons>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default ListingReview;
