import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../../components/UI/HorizontalProgress";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../../components/UI/ZInputComponent";
import * as Yup from "yup";
import Label from "../../../components/UI/Label";
import PlaceholderHeading from "../../credit-card/PlaceholderHeading";
import { scrollTo } from "../../../services/Utilities";
import { wizardGenericErrorMsg } from "../../../Constants";
import ErrorMessage from "../../../components/UI/ErrorMessage";
import ZRadioComponent from "../../../components/form/ZRadioComponent";
import ZCheckBoxComponent from "../../../components/form/ZCheckBoxComponent";
import DualErrorMessage from "../../../components/UI/DualErrorMessage";
import { Select } from "../../../components/Select";
import { useRef } from "react";
import LocationPicker from "../../../components/map/LocationPicker";
import WizardButtons from "./WizardButtons";

const LocationDetail = (props) => {
  const { step, data, serverErrors, tabDisable } = props;
  const { saveTab, closeWizard } = props;
  const { auctionTypeParam } = props;

  const { countries, states, cities } = props;
  const { filterStates, filterCities } = props;

  const draftSubmitRef = useRef();
  const [isDraft, setIsDraft] = useState(false);
  const [mapUpdate, setMapUpdate] = useState(true);

  const [latlng, setLatlng] = useState({});

  const [locationStates, setLocationStates] = useState([...states]);

  const tabInitValues = {
    country: "",
    state: "",
    city: "",
    shipping: "PICKUP_ONLY",
    pickupLocation: "",
    pickupLocationLat: "",
    pickupLocationLong: "",
    pickupLocationPublic: "yes",
  };

  const schema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    //state: Yup.string().required("State is required"),
    state: Yup.string()
      .required("State is required")
      .test("stateValidation", function (state) {
        if (
          auctionTypeParam !== "BUY_NOW" &&
          state &&
          state != 55 &&
          state != 16
        ) {
          return this.createError({
            path: this.path,
            message:
              "Online auctions are only open to sellers from the states of Illinois and Wisconsin.",
          });
        }

        return true;
      }),
    city: Yup.string().required("City is required"),
    pickupLocation: Yup.string()
      .required("Pickup information is required")
      .max(64, "Maximum 64 characters allowed"),
    pickupLocationLat: Yup.string().required("Please select a location on map"),
    pickupLocationLong: Yup.string().required(
      "Please select a location on map"
    ),
    pickupLocationPublic: Yup.string().optional(),
  });

  const [tabData, setTabData] = useState(tabInitValues);
  const [tabErrors, setTabErrors] = useState(serverErrors);

  const populateTabData = (inputData) => {
    if (inputData && Object.values(inputData)?.length > 0) {
      setTabData((state) => {
        const tempState = {
          ...state,
          ...inputData,
        };

        setLatlng({
          lat: tempState.pickupLocationLat,
          lng: tempState.pickupLocationLong,
        });

        console.log("data in location tab", tempState);

        return tempState;
      });
    }
  };

  useEffect(() => {
    populateTabData(data);
  }, []);

  useEffect(() => {
    populateTabData(data);
  }, [data]);

  useEffect(() => {
    setTabErrors(serverErrors);
  }, [serverErrors]);

  useEffect(() => {
    let tempStates = [...states];

    if (auctionTypeParam !== "BUY_NOW") {
      tempStates = [...states.filter((s) => s.id == 55 || s.id == 16)];
    }
    setLocationStates(tempStates);
  }, [states, auctionTypeParam]);

  return (
    <>
      <PlaceholderHeading title="Location Details">
        <button
          disabled={tabDisable}
          className="btn-block btn btn-outline-primary px-4"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            closeWizard();
          }}
        >
          Close
        </button>
      </PlaceholderHeading>
      <div className="mt-3 mb-2">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mt-5 instructions-desc">Pickup location details.</p>

          <div className="row" id="pageError">
            <div className="col-md-12">
              {tabErrors && tabErrors.message && (
                <div className="mb-4">
                  <ErrorMessage position="top">
                    {tabErrors.message}
                  </ErrorMessage>
                </div>
              )}
            </div>
          </div>
          <fieldset disabled={tabDisable}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={tabData}
              validationSchema={schema}
              onSubmit={(values) => {
                console.log("submitted values", values);

                saveTab(
                  step,
                  {
                    ...tabData,
                    ...values,
                    shipping: "PICKUP_ONLY",
                    pickupInformationPublic:
                      values.pickupLocationPublic === "yes",
                  },
                  isDraft
                );
              }}
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                validateForm,
              }) => (
                <form>
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Select
                          name="country"
                          className="form-control"
                          label="Country"
                          mandatory={true}
                          highlightedLabel
                          defaultTouch
                          serverError={serverErrors && serverErrors.country}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("country", e.target.value);
                            setFieldValue("state", "");
                            setFieldValue("city", "");
                            filterStates(e.target.value, auctionTypeParam);
                          }}
                        >
                          <option value="" disabled>
                            Select Country
                          </option>

                          {countries &&
                            countries.length > 0 &&
                            countries.map((country) => {
                              return (
                                <option key={country.code} value={country.code}>
                                  {country.name}
                                </option>
                              );
                            })}
                        </Select>
                      </div>

                      <div className="js-form-message mb-4">
                        <Select
                          name="state"
                          className="form-control"
                          label="State"
                          mandatory={true}
                          highlightedLabel
                          defaultTouch
                          serverError={serverErrors && serverErrors.state}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("state", e.target.value);
                            setFieldValue("city", "");
                            filterCities(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select State
                          </option>

                          {locationStates &&
                            locationStates.length > 0 &&
                            locationStates.map((state) => {
                              return (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              );
                            })}
                        </Select>

                        {auctionTypeParam !== "BUY_NOW" && (
                          <p className="mb-0 mt-2">
                            Online auctions are currently open to sellers from
                            the states of Illinois and Wisconsin.
                          </p>
                        )}
                      </div>

                      <div className="js-form-message mb-4">
                        <Select
                          name="city"
                          className="form-control"
                          label="City"
                          mandatory={true}
                          highlightedLabel
                          defaultTouch
                          serverError={serverErrors && serverErrors.city}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("city", e.target.value);
                            setMapUpdate(true);

                            const [selectedCity] = cities?.filter(
                              (c) => c.id == e.target.value
                            );

                            setLatlng({
                              lat: selectedCity?.lat,
                              lng: selectedCity?.lng,
                            });

                            setFieldValue(
                              "pickupLocationLat",
                              selectedCity?.lat
                            );
                            setFieldValue(
                              "pickupLocationLong",
                              selectedCity?.lng
                            );
                          }}
                        >
                          <option value="" disabled>
                            Select City
                          </option>

                          {cities &&
                            cities.length > 0 &&
                            cities.map((city) => {
                              return (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                      {false && (
                        <div className="js-form-message mb-4">
                          <Label hightlighted required>
                            Will you ship this item
                          </Label>
                          <Field
                            className="form-control"
                            name="shipping"
                            radioName="shipping"
                            items={[
                              {
                                value: "SHIPPING_AT_BIDDER_EXPENSE",
                                label: "Yes, at the expense of the bidder",
                              },
                              {
                                value: "PICKUP_ONLY",
                                label: "No, pickup only",
                              },
                            ]}
                            component={ZRadioComponent}
                            handleOnChange={(value) => {
                              setFieldValue("shipping", value);
                            }}
                          />

                          <DualErrorMessage
                            fieldName="shipping"
                            serverError={serverErrors && serverErrors.shipping}
                          />
                        </div>
                      )}

                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          name="pickupLocation"
                          highlightedLabel
                          required
                          component={ZInputComponent}
                          placeholder="Enter Pickup Location Details"
                          label="Pickup location details"
                          serverError={
                            serverErrors && serverErrors.pickupLocation
                          }
                        />
                        <p className="mb-0 mt-2">
                          Information on how winning bidder can pickup item
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      {latlng && latlng.lat && (
                        <div className="js-form-message mb-4">
                          <LocationPicker
                            update={mapUpdate}
                            initCompleted={() => {
                              setMapUpdate(false);
                            }}
                            updateCompleted={() => {
                              setMapUpdate(false);
                            }}
                            lat={latlng?.lat}
                            lng={latlng?.lng}
                            locationChanged={(lat, lng) => {
                              setFieldValue("pickupLocationLat", lat);
                              setFieldValue("pickupLocationLong", lng);

                              setLatlng({ lat: lat, lng: lng });

                              console.log("lat", lat, "lng", lng);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Pickup Location Latitude
                        </Label>
                        <input
                          type="text"
                          className="form-control"
                          name="pickupLocationLat"
                          placeholder="Pickup location latitude"
                          value={latlng.lat}
                          readOnly
                        />

                        <DualErrorMessage
                          fieldName="pickupLocationLat"
                          serverError={
                            serverErrors && serverErrors.pickupLocationLat
                          }
                        />
                      </div>

                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Pickup Location Longitude
                        </Label>
                        <input
                          type="text"
                          className="form-control"
                          name="pickupLocationLong"
                          placeholder="Pickup location longitude"
                          value={latlng.lng}
                          readOnly
                        />

                        <DualErrorMessage
                          fieldName="pickupLocationLat"
                          serverError={
                            serverErrors && serverErrors.pickupLocationLong
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Make pickup location information public
                        </Label>
                        <Field
                          className="form-control"
                          name="pickupLocationPublic"
                          fieldName="pickupLocationPublic"
                          items={[
                            {
                              value: "yes",
                              label: "Make pickup location information public",
                            },
                          ]}
                          component={ZCheckBoxComponent}
                          handleOnChange={(index, fieldName, checkedItems) => {
                            setFieldValue(
                              "pickupLocationPublic",
                              checkedItems?.includes("yes") ? "yes" : "no"
                            );
                          }}
                        />
                        <p className="mb-0 mt-2">
                          If selected, pickup information is visible to all
                          users. If not selected, information will only be shown
                          to winning bidder
                        </p>

                        <DualErrorMessage
                          fieldName="pickupLocationPublic"
                          serverError={
                            serverErrors && serverErrors.pickupLocationPublic
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="js-form-message mb-4 row">
                    <div className="col-12">
                      <WizardButtons>
                        <button
                          className="btn btn-primary-dark-w px-5"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(false);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save and Continue
                        </button>

                        <button
                          ref={draftSubmitRef}
                          className="btn btn-outline-primary px-5"
                          type="button"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(true);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save Draft
                        </button>
                      </WizardButtons>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default LocationDetail;
