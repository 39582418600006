import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const ZCheckBoxComponent = ({
  field,
  items,
  fieldName,
  checkedValue,
  handleOnChange = (index, fieldName, checkedItems, checked, target) => {},
  titleCase = true,
}) => {
  const [checkedValues, setCheckedValues] = useState([]);

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  let fieldValue = field.value || "";

  useEffect(() => {
    if (Array.isArray(fieldValue)) {
      setCheckedValues(fieldValue);
    } else {
      setCheckedValues([fieldValue]);
    }
  }, [fieldValue]);

  return (
    <>
      {items.map(({ value, label, disabled }, index) => {
        return (
          <div
            key={index}
            className={`custom-control custom-checkbox mt-1 text-blue-25`}
          >
            <input
              type="checkbox"
              className={`custom-control-input`}
              id={`${fieldName}-${index}`}
              name={fieldName}
              value={value}
              disabled={disabled ?? false}
              checked={
                checkedValue?.includes(value) ||
                fieldValue === value ||
                (Array.isArray(fieldValue) && fieldValue.includes(value))
              }
              onChange={(e) => {
                let checkedItems = [...checkedValues];

                if (e.target.checked) {
                  checkedItems.push(e.target.value);
                } else {
                  checkedItems = checkedItems.filter(
                    (elem) => elem !== e.target.value
                  );
                }

                setCheckedValues(checkedItems);

                handleOnChange(
                  index,
                  fieldName,
                  checkedItems,
                  e.target.checked,
                  e.target
                );
              }}
            />
            <label
              htmlFor={`${fieldName}-${index}`}
              className={`custom-control-label font-size-15 ${
                disabled ? "not-allowed" : ""
              }`}
            >
              {titleCase ? label.replace(/_/g, " ").toProperCase() : label}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default ZCheckBoxComponent;
