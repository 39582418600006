import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import ErrorMessage from "../../components/UI/ErrorMessage";
import arrowLeft from "../../assets/images/arrow-left.svg";
import {
  MARK_READ_MESSAGE_URL,
  newMessageErrorMsg,
  SEND_REPLY_MESSAGE,
  VIEW_MESSAGE,
} from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { fetchGetData, postUserMessage } from "../../services/MessageService";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
  scrollTo,
} from "../../services/Utilities";
import classes from "./messages.module.css";

const ViewMessage = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const params = useLocation()?.state ?? {};

  const [message, setMessage] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [isReply, setIsReply] = useState(false);

  const [pageErrors, setPageErrors] = useState();
  const [serverErrors, setServerErrors] = useState();

  const fetchMessageDetail = async (messageId, cameFrom) => {
    ctx.showProgress(true);
    fetchGetData(VIEW_MESSAGE.replace("{messageId}", messageId))
      .then((data) => {
        ctx.showProgress(false);
        console.log(data);
        setMessage(data);

        if (!cameFrom || cameFrom != "outbox")
          if (!data.read) markReadMessage(data.messageId);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  const sendMessage = (messageId, values) => {
    ctx.showProgress(true);
    postUserMessage(
      SEND_REPLY_MESSAGE.replace("{messageId}", messageId),
      values
    )
      .then((respose) => {
        ctx.showProgress(false);
        navigate("/outbox");
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          } else {
            if (json.data.status === "NOT_FOUND") {
              navigate("/error");
            }
          }
        }
      });
  };

  const markReadMessage = (messageId) => {
    postUserMessage(MARK_READ_MESSAGE_URL, { messageId: messageId })
      .then((respose) => {})
      .catch((e) => {
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  useEffect(() => {
    console.log("params", params);
    if (params && params.messageId) {
      fetchMessageDetail(params.messageId, params.cameFrom);
    }
  }, [params]);

  const initialValue = {
    content: "",
  };

  const schema = Yup.object().shape({
    content: Yup.string()
      .required("Message content is required")
      .max(2048, "Message is too big"),
  });

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu messagesActive={true} />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <div className="row" id="pageError">
                <div className="col-12">
                  {pageErrors?.message && (
                    <ErrorMessage>{pageErrors.message}</ErrorMessage>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={initialValue}
                    validationSchema={schema}
                    onSubmit={(values) => {
                      sendMessage(message.messageId, {
                        ...values,
                        receiverId:
                          params.cameFrom === "outbox"
                            ? message?.receiver?.id
                            : message?.sender?.id,
                      });
                    }}
                  >
                    {({
                      handleSubmit,
                      errors,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form>
                        <div className={classes.subjectPanel}>
                          <div className={`row ${classes.row}`}>
                            <div className={`col-auto ${classes.icon}`}>
                              <img src={arrowLeft} />
                            </div>
                            <div className={`col ${classes.subject}`}>
                              {message.subject}
                            </div>
                            <div className={`col-auto ${classes.date}`}>
                              {message.messageDateTime}
                            </div>
                          </div>
                        </div>

                        <div className={classes.recipientPanel}>
                          <div className={`row ${classes.row}`}>
                            <div className={`col-auto`}>To</div>
                            <div className={`col`}>
                              {message?.receiver?.name}{" "}
                              {message?.receiver?.username && (
                                <>
                                  {"<"}
                                  {message?.receiver?.username}
                                  {">"}
                                </>
                              )}
                            </div>
                          </div>
                          <div className={`row ${classes.row}`}>
                            <div className={`col-auto ${classes.type}`}>
                              From
                            </div>
                            <div className={`col ${classes.person}`}>
                              {message?.sender?.name}{" "}
                              {message?.sender?.username && (
                                <>
                                  {"<"}
                                  {message?.sender?.username}
                                  {">"}
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          className={`p-5 ${classes.viewMessagePanel} custom-scroll-self`}
                          dangerouslySetInnerHTML={{
                            __html: message.content,
                          }}
                        ></div>

                        {!isReply && (
                          <div className="js-form-message mb-4 mt-4">
                            <a
                              href="#"
                              className="btn btn-primary-dark-w px-5"
                              onClick={async (e) => {
                                e.preventDefault();
                                setIsReply(true);
                              }}
                            >
                              Reply
                            </a>
                          </div>
                        )}

                        {isReply && (
                          <>
                            <div className="js-form-message mb-4">
                              <ReactQuill
                                theme="snow"
                                value={replyMessage}
                                onChange={(m) => {
                                  console.log(m);
                                  setReplyMessage(m);
                                  setFieldValue("content", m);
                                }}
                                placeholder="Enter your message here"
                                rows
                              />

                              {errors && errors["content"] && (
                                <ErrorMessage>{errors["content"]}</ErrorMessage>
                              )}
                            </div>

                            <div className="js-form-message mb-4">
                              <button
                                className="btn btn-primary-dark-w px-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let res = await validateForm();
                                  if (res && Object.values(res)?.length > 0) {
                                    scrollTo("pageError");
                                    setPageErrors({
                                      message: newMessageErrorMsg,
                                    });
                                  }
                                  handleSubmit(e);
                                }}
                              >
                                Send Reply
                              </button>
                            </div>
                          </>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ViewMessage;
