import React, { useEffect, useState } from "react";
import classes from "./YoutubeVideoFetcher.module.css";
import ErrorMessage from "../UI/ErrorMessage";
import { useRef } from "react";
import { youtubeVideoThumbnail } from "../../services/Utilities";
import removeIcon from "../../assets/images/remove-red-icon.svg";

const YoutubeVideoFetcher = ({
  initialList,
  showErrors = true,
  errorEvent = (errors) => {},
  showList = true,
  videoSelectedEvent = (url) => {},
  listChanged = (list) => {},
}) => {
  const inputRef = useRef();

  const [shownList, setShownList] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setShownList(initialList);
  }, [initialList]);

  useEffect(() => {
    errorEvent(errors);
  }, [errors]);

  useEffect(() => {
    console.log("now in list", shownList);
    listChanged(shownList);
  }, [shownList]);

  return (
    <>
      <div>
        <input
          type="text"
          className="form-control"
          ref={inputRef}
          placeholder="Enter youtube video URL"
        />

        <button
          className="btn btn-primary-dark-w px-5 mt-2"
          type="button"
          onClick={async (e) => {
            e.preventDefault();

            const thumbnail = youtubeVideoThumbnail(inputRef.current.value);

            console.log("thumbnail", thumbnail);
            if (thumbnail) {
              setErrors({});

              const obj = {
                url: inputRef.current.value,
                thumb: thumbnail,
                id: Object.keys(shownList)?.length + Math.random() ?? 0,
                uploaded: false,
              };

              videoSelectedEvent(obj);
              setShownList((prev) => [...prev, obj]);
              inputRef.current.value = "";
            } else {
              setErrors({ invalidURL: "Please enter valid youtube video URL" });
            }
          }}
        >
          Add Video
        </button>
      </div>

      {showList && (
        <div className="mt-4 mb-4">
          <VideoItems
            items={shownList}
            removeClicked={(index, meta) => {
              let rem = [...shownList];
              rem.splice(index, 1);
              setShownList([...rem]);
            }}
          />
        </div>
      )}

      {showErrors && errors && (
        <ErrorMessage>
          {errors.maxNumber && (
            <span className="d-block">{errors.maxNumber}</span>
          )}
          {errors.invalidURL && (
            <span className="d-block">{errors.invalidURL}</span>
          )}
        </ErrorMessage>
      )}
    </>
  );
};

export const VideoItems = ({ items, removeClicked = (index, meta) => {} }) => {
  return (
    <div className={classes.uploadedRow}>
      {items?.map((uImage, index) => (
        <VideoTile
          key={index}
          index={index}
          meta={uImage}
          image={uImage.thumb}
          url={uImage.url}
          removeClicked={removeClicked}
        />
      ))}
    </div>
  );
};

export const VideoTile = ({
  index,
  meta,
  image,
  url,
  removeClicked = (index, meta) => {},
}) => {
  return (
    <div
      className={classes.tile}
      style={{
        backgroundImage: `url(${image})`,
      }}
      onClick={(e) => {
        e.preventDefault();
        window.open(url);
      }}
    >
      <img
        src={removeIcon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          removeClicked(index, meta);
        }}
      />
    </div>
  );
};

export default YoutubeVideoFetcher;
