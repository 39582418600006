import React from "react";
import classes from "./ReviewInfoBox.module.css";

const ReviewInfoBox = ({ children }) => {
  return (
    <div className={classes.box}>
      <p>{children}</p>
    </div>
  );
};

export default ReviewInfoBox;
