import { PAYMENT_SERVICE } from "../Constants";
import authenticatedFetch from "./fetch";

export async function saveCustomerBillingInfoToGateway(requestBody) {
  const response = await authenticatedFetch(`${PAYMENT_SERVICE}/customer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function saveCustomerBillingCreditCardInfo(requestBody) {
  const response = await authenticatedFetch(
    `${PAYMENT_SERVICE}/customer/card`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function fetchCustomerBillingCreditCards() {
  const response = await authenticatedFetch(
    `${PAYMENT_SERVICE}/customer/card`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function deleteCustomerBillingCreditCards(cardId) {
  const response = await authenticatedFetch(
    `${PAYMENT_SERVICE}/customer/card/${cardId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}
