import { useState } from "react";
import AccountMenuItem from "./AccountMenuItem";
import "./AccountMenu.css";
import { useNavigate, Link } from "react-router-dom";

const CreateListingMenu = ({ wizardState, itemClicked = (step) => {} }) => {
  const navigate = useNavigate();

  const instructions = wizardState?.tabs?.instructions?.step;
  const additional = wizardState?.tabs?.additional?.step;
  const location = wizardState?.tabs?.location?.step;
  const media = wizardState?.tabs?.media?.step;
  const pricing = wizardState?.tabs?.pricing?.step;
  const payment = wizardState?.tabs?.payment?.step;
  const dates = wizardState?.tabs?.dates?.step;
  const review = wizardState?.tabs?.review?.step;

  const [clicked, setClicked] = useState(instructions);

  const handleToggle = (index) => {
    if (clicked === index) {
      // return setClicked(instructions);
    }

    // setClicked(index);
  };

  return (
    <ul className="accordion">
      <AccountMenuItem
        single
        title="Listing Introduction"
        onToggle={() => {
          handleToggle(instructions);
          itemClicked(instructions);
        }}
        active={
          clicked === instructions ||
          instructions === wizardState?.status?.currentStep
        }
        key={instructions}
      />

      <AccountMenuItem
        single
        title={"Additional Details"}
        onToggle={() => {
          handleToggle(additional);
          itemClicked(additional);
        }}
        active={
          clicked === additional ||
          additional === wizardState?.status?.currentStep
        }
        key={additional}
      />

      <AccountMenuItem
        single
        title={"Pickup Location"}
        onToggle={() => {
          handleToggle(location);
          itemClicked(location);
        }}
        active={
          clicked === location || location === wizardState?.status?.currentStep
        }
        key={location}
      />

      <AccountMenuItem
        single
        title={"Add Media"}
        onToggle={() => {
          handleToggle(media);
          itemClicked(media);
        }}
        active={clicked === media || media === wizardState?.status?.currentStep}
        key={media}
      />

      <AccountMenuItem
        single
        title={"Pricing"}
        onToggle={() => {
          handleToggle(pricing);
          itemClicked(pricing);
        }}
        active={
          clicked === pricing || pricing === wizardState?.status?.currentStep
        }
        key={pricing}
      />

      <AccountMenuItem
        single
        title={"Payment Details"}
        onToggle={() => {
          handleToggle(payment);
          itemClicked(payment);
        }}
        active={
          clicked === payment || payment === wizardState?.status?.currentStep
        }
        key={payment}
      />

      <AccountMenuItem
        single
        title={"Start and End Date"}
        onToggle={() => {
          handleToggle(dates);
          itemClicked(dates);
        }}
        active={clicked === dates || dates === wizardState?.status?.currentStep}
        key={dates}
      />

      <AccountMenuItem
        single
        title={"Review your Listing"}
        onToggle={() => {
          handleToggle(review);
          itemClicked(review);
        }}
        active={
          clicked === review || review === wizardState?.status?.currentStep
        }
        key={review}
      />
    </ul>
  );
};

export default CreateListingMenu;
