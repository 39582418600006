import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import inboxNotificationIcon from "../../assets/images/inboxNotificationIcon.svg";
import { USER_MESSAGE_NOTIFICATION_URL } from "../../Constants";
import { fetchNotifications } from "../../services/NotificationService";
import { isStopFlowConditionMet } from "../../services/Utilities";
import classes from "./NotificationBar.module.css";
import MessageNotification from "./MessageNotification";

const MessageNotificationBar = () => {
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [data, setData] = useState([]);

  const documentClickEventHandler = (e) => {
    if (!menuRef?.current?.contains(e.target)) {
      setShowMenu(false);
    }
  };

  const fetchUserNotifications = async () => {
    try {
      const rs = await fetchNotifications(USER_MESSAGE_NOTIFICATION_URL);
      //console.log("notifications", rs);
      setData(rs);
    } catch (e) {
      // if (isStopFlowConditionMet(e.message)) return;
      // navigate("/error");
    }
  };

 

  useEffect(() => {
    fetchUserNotifications();

    const updateInterval = setInterval(fetchUserNotifications, 10000);

    return () => {
      if (updateInterval) clearInterval(updateInterval);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("click", documentClickEventHandler);

    return () => {
      window.removeEventListener("click", documentClickEventHandler);
    };
  }, []);

  // let data = [
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  // ];

  return (
    <>
      <div ref={menuRef} className={classes.dropdown}>
        <Link
          className={classes.icon}
          onClick={(e) => {
            e.preventDefault();
            setShowMenu((show) => !show);
          }}
        >
          <img src={inboxNotificationIcon} />
          {data?.length > 0 && <span>{data?.length}</span>}
        </Link>

        <div
          className={`${classes.main} ${classes.menu} ${
            showMenu ? classes.show : ""
          }`}
        >
          {data?.length == 0 && (
            <div className={classes.container}>
              <div className="row mb-3 align-items-center">
                <div className="col text-center">
                  <h6 className="mb-0">No new messages</h6>
                </div>
              </div>
            </div>
          )}
          {data?.length > 0 && (
            <>
              <div className={classes.container}>
                <div className="row mb-3 align-items-center">
                  <div className="col">
                    <h6 className="mb-0">Unread Messages</h6>
                  </div>
                  <div className="col-auto">
                    <Link to="/inbox">See All</Link>
                  </div>
                </div>
              </div>

              <div className={classes.container}>
                <div
                  className={`${classes.notificationArea} custom-scroll-self`}
                >
                  {data.map((d, i) => (
                    <MessageNotification
                      key={i}
                      data={d}
                      messageClicked={(message) => {
                        setData((prev) =>
                          prev.filter((m) => m.messageId !== message.messageId)
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageNotificationBar;
