import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import {
  FetchUserProfile,
  UpdateAccountInfoRequest,
} from "../../services/UserService";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { CATEGORY_LIBRARY_URL } from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import { useAuthContext } from "../../redux/auth-context";
import {
  isJSON,
  deflateErrors,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Alert from "../../components/UI/Alert";
import Label from "../../components/UI/Label";
import PictureUploader from "../../components/uploader/PictureUploader";

const SellerAccountInformation = () => {
  const ctx = useAuthContext();

  let navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [categories, setCategories] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);
  const [isValueLodaed, setIsValueLoaded] = useState(false);

  const updateAccountInfo = (values) => {
    let payload = {
      userName: values.userName,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      confirmEmail: values.confirmEmail,
      agencyName: values.agencyName,
      marketingConsent: "t",
      agencyLogo: values.agencyLogo,
    };

    console.log(payload);

    ctx.showProgress(true);

    UpdateAccountInfoRequest(payload)
      .then((data) => {
        console.log(data);
        setUpdatedSuccessfully(true);
        loadData();
        ctx.showProgress(false);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        console.log(json);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }
        }
      });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const initialValue = {
    userName: "",
    firstName: "",
    lastName: "",
    confirmEmail: "",
    email: "",
    agencyName: "",
    agencyLogo: "",
  };

  const schema = Yup.object().shape({
    userName: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string(),
    confirmEmail: Yup.string()
      .email("Not a valid email")
      .required("Confirm email is required"),
    agencyName: Yup.string().required("Agency name is required"),
    agencyLogo: Yup.string(),
  });

  const loadData = async () => {
    ctx.showProgress(true);

    try {
      const libRes = await LibraryService(CATEGORY_LIBRARY_URL);
      let myCategories = [];
      libRes.map((item) => {
        myCategories.push({ value: item.id, label: item.key });
      });
      setCategories(myCategories);

      const userData = await FetchUserProfile();

      setUserInfo(userData);
      setIsValueLoaded(true);
    } catch (e) {
      if (isStopFlowConditionMet(e.message)) return;

      navigate("/error");
    }

    ctx.showProgress(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                <AccountMenu accountInfoActive={true} subMenu={"info"} />
              </div>
              <div className="offset-lg-1 col-md-7">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="h3 font-weight-bold purple-500">
                      Account Information
                    </h2>
                  </div>
                  <div className="col-md-12 mb-5">
                    <BHotrizontalProgress progress={43} />
                  </div>
                  <div className="col-md-12">
                    {!setIsValueLoaded ? <div>Loading...</div> : ""}
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      enableReinitialize={true}
                      initialValues={userInfo}
                      validationSchema={schema}
                      onSubmit={(values) => {
                        updateAccountInfo(values);
                      }}
                    >
                      {({
                        handleSubmit,
                        isSubmitting,
                        errors,
                        touched,
                        validateForm,
                        setFieldValue,
                      }) => (
                        <form id="passportInfoForm">
                          {updatedSuccessfully && (
                            <Alert>
                              Success! Your account information has been saved.
                            </Alert>
                          )}
                          <div className="col-md-7">
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="userName"
                                component={ZInputComponent}
                                label="Username"
                                disabled={true}
                              />
                            </div>
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="firstName"
                                component={ZInputComponent}
                                label="First Name"
                                required={true}
                                disabled={true}
                              />
                            </div>
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="lastName"
                                component={ZInputComponent}
                                label="Last Name"
                                required={true}
                                disabled={true}
                              />
                            </div>
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="email"
                                component={ZInputComponent}
                                label="Email"
                                required={true}
                                disabled={true}
                              />
                            </div>
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="agencyName"
                                component={ZInputComponent}
                                label="Agency Name"
                                required={true}
                              />
                            </div>
                            {userInfo.agencyLogo && (
                              <div className="js-form-message mb-3">
                                <Label>Agency Logo</Label>

                                <img
                                  src={userInfo.agencyLogo}
                                  className="seller-logo"
                                />
                              </div>
                            )}

                            <div className="js-form-message mb-3">
                              <PictureUploader
                                label={"Upload Agency Logo"}
                                errorEvent={(errors) => {
                                  // setPictureError(errors);
                                }}
                                fileSelectedEvent={(event, file) => {
                                  setFieldValue(
                                    "agencyLogo",
                                    file.data_url.split(",")[1]
                                  );
                                  console.log("file selected", file);
                                  event.proceed(file);
                                }}
                              />
                              {errors && errors.agencyLogo && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.agencyLogo}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-10">
                            <div className="col-md-6">
                              <button
                                className="btn-block btn btn-primary-dark-w px-5 text-transform-upper mt-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let res = await validateForm();
                                  console.log("res", res);
                                  if (res && Object.values(res)?.length > 0) {
                                  }
                                  handleSubmit(e);
                                }}
                              >
                                update information
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default SellerAccountInformation;
