import React from "react";
import Layout from "../../layout/Layout";
import AlertCommon from "./AlertCommon";
import image from "../../assets/images/not-authorized.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useAuthContext } from "../../redux/auth-context";

const NotAuthorized = () => {
  const ctx = useAuthContext();

  useEffect(() => {
    ctx.showProgress(false);
  }, []);

  const description = (
    <span>
      It seems like you don't have permission to use this page. Please click
      your back button or Go Back to <Link to="/">Homepage</Link>.
      <br />
      <br />
      We apologize for the inconvenience.
    </span>
  );
  const heading = "You are not authorized";

  return (
    <>
      <Layout>
        <div className="container mt-10 mb-10">
          <div className="row">
            <div className="offset-xl-3 col-xl-6">
              <AlertCommon
                description={description}
                heading={heading}
                image={image}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NotAuthorized;
