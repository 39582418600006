import React from "react";
import MessageTableTile from "./MessageTableTile";

const MessagesTable = ({ messages, onClick = (id) => {} }) => {
  return (
    <>
      {messages?.length > 0 && (
        <>
          {messages?.map((m, i) => {
            return (
              <MessageTableTile
                key={i}
                id={m.messageId}
                name={m.name}
                dateTime={m.messageDateTime}
                subject={m.subject}
                isRead={m.read}
                onClick={(id) => {
                  onClick(id);
                }}
              />
            );
          })}
        </>
      )}
      {messages?.length == 0 && (
        <>
          <div className="row">
            <div className="col text-center">No Messages Found</div>
          </div>
        </>
      )}
    </>
  );
};

export default MessagesTable;
