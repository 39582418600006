import React from "react";
import Layout from "../layout/Layout";

const TermsOfUse = () => {
  return (
    <>
      <Layout>
        <div className="container">
          <div className="row mt-10 mb-10">
            <div className="col-md-12">
              <div className="pane-v10">
                <h2 className="link-green heading-v1 text-center ct-u-marginBottom20">
                  WEBSITE TERMS OF USE
                </h2>

                <h4>TERMS AND CONDITIONS OF USE</h4>
                <p className="p-v1 ct-u-marginBottom20 ct-u-paddingRight30">
                  Welcome to our website. This site is maintained as a service
                  to our customers. By using this site, you agree to comply with
                  and be bound by the following terms and conditions of use.
                  Please review these terms and conditions carefully. If you do
                  not agree to these terms and conditions, you should not use
                  this site.
                </p>

                <div className="row">
                  <div className="col-md-12 text-left ct-u-padding0">
                    <ul style={{ listStyle: "decimal", marginLeft: "3%" }}>
                      <li>
                        <p>
                          <b>Agreement.</b> This Term of Use agreement ("the
                          "Agreement") specifies the Terms and Conditions for
                          access to and use of Bidly Auction, bidlyauction.com
                          (the "Site") and describe the terms and conditions
                          applicable to your access of and use of the Site. This
                          Agreement may be modified at any time by Bidly Auction
                          upon posting of the modified Agreement. Any such
                          modifications shall be effective immediately. You can
                          view the most recent version of these terms at any
                          time at bidlyauction.com. Each use by you shall
                          constitute and be deemed your unconditional acceptance
                          of this Agreement.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Privacy. </b>Your visit to our site is also
                          governed by our Privacy Policy. Please review our
                          Privacy Policy on Bidly Auction’s website.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Ownership. </b>All content included on this site is
                          and shall continue to be the property of Bidly Auction
                          or its content suppliers and is protected under
                          applicable copyright, patent, trademark, and other
                          proprietary rights. Any copying, redistribution, use
                          or publication by you of any such content or any part
                          of the Site is prohibited, except as expressly
                          permitted in this Agreement. Under no circumstances
                          will you acquire any ownership rights or other
                          interest in any content by or through your use of this
                          Site.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Intended Audience. </b>
                          This website is exclusively designed for use by
                          adults. By accessing this site, you affirm that you
                          are at least 18 years old. It is not designed for
                          children under the age of 13. Individuals over 13 and
                          under 18 may only use the site under the supervision
                          and with the permission of a parent or guardian.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Trademarks. </b>
                          You recognize the exclusive rights of Bidly Auction in
                          its trademark and service mark. The trademarks,
                          service marks, logos, and copyrighted material
                          featured on this website belong to Bidly Auction or to
                          the entity that supplied such intellectual property to
                          Bidly Auction. Both Bidly Auction and any entities
                          supplying intellectual property to Bidly Auction
                          maintain all rights concerning their intellectual
                          property displayed on this site. No ownership rights
                          of such materials are conferred or delegated to you.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Site Use. </b>
                          Bidly Auction grants you a limited, revocable,
                          nonexclusive license to use this site solely for your
                          own personal use and not for republication,
                          distribution, assignment, sublicense, sale,
                          preparation of derivative works, or other use. You
                          agree not to copy materials on the site, reverse
                          engineer or break into the site, or use materials,
                          products or services in violation of any law. The use
                          of this website is at the discretion of Bidly Auction
                          and Bidly Auction may terminate your use of this
                          website at any time.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Compliance with Laws. </b>
                          You must abide by all Federal, State and local laws.
                          If you are outside the United States you must comply
                          with all local laws with respect to your online
                          conduct, as well as the export of data to the United
                          States or to your country or residence.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Indemnification. </b>
                          You agree to indemnify and hold Bidly Auction, its
                          subsidiaries, affiliates, licensors, content
                          providers, service providers, employees, agents,
                          officers, directors, and contractors (the “Indemnified
                          Parties”) harmless from any breach of this Agreement
                          by you. You agree that the Indemnified Parties will
                          have no liability in connection with any such breach
                          or unauthorized use, and you agree to indemnify any
                          and all resulting loss, damages, judgments, awards,
                          costs, expenses, and attorneys' fees of the
                          Indemnified Parties in connection therewith. You will
                          also indemnify and hold the Indemnified Parties
                          harmless from and against any claims brought by third
                          parties arising out of your use of any content or
                          information or service accessed from this Site.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Disclaimer. </b>
                          THE INFORMATION ON THIS SITE IS PROVIDED ON AN "AS
                          IS," "AS AVAILABLE" BASIS. YOU AGREE THAT USE OF THIS
                          SITE IS AT YOUR SOLE RISK. BIDLY AUCTION DISCLAIMS ALL
                          WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO
                          ANY EXPRESS WARRANTIES, STATUTORY WARRANTIES, AND ANY
                          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. TO THE
                          EXTENT YOUR JURISDICTION DOES NOT ALLOW LIMITATIONS ON
                          WARRANTIES, THIS LIMITATION MAY NOT APPLY TO YOU. YOUR
                          SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE
                          SITE SHALL BE TO DISCONTINUE USING THE SITE.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Limitation of Liability. </b>
                          UNDER NO CIRCUMSTANCES WILL BIDLY AUCTION BE LIABLE OR
                          RESPONSIBLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                          CONSEQUENTIAL (INCLUDING DAMAGES FROM LOSS OF
                          BUSINESS, LOST PROFITS, LITIGATION, OR THE LIKE),
                          SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, UNDER
                          ANY LEGAL THEORY, ARISING OUT OF OR IN ANY WAY
                          RELATING TO THE SITE, YOUR SITE USE, OR THE CONTENT,
                          EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                          YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE
                          AND/OR CONTENT IS TO CEASE ALL OF YOUR SITE USE.
                        </p>
                        <p>
                          You may have additional rights under certain laws
                          (including consumer laws) which do not allow the
                          exclusion of implied warranties, or the exclusion or
                          limitation of certain damages. If these laws apply to
                          you, the exclusions or limitations in this Agreement
                          that directly conflict with such laws may not apply to
                          you.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Use of Information. </b>
                          Bidly Auction reserves the right, and you authorize
                          us, to use and assign all information regarding site
                          uses by you and all information provided by you in any
                          manner consistent with our Privacy Policy.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Copyrights and Copyright Agent. </b>
                          Infringing on the trademark or copyright of others
                          constitutes a breach of these Terms. This site does
                          not grant, by implication, estoppel, or otherwise, any
                          license or right under any intellectual property
                          rights, including any trademarks or copyrights,
                          belonging to Bidly Auction or any third party. All
                          trademarks and trade names are owned by their
                          respective holders. Unless explicitly stated
                          otherwise, Bidly Auction owns all trademarks and
                          service marks displayed on this site, regardless of
                          their registration status. If you believe your work
                          has been copied in a way that constitutes copyright
                          infringement, or your intellectual property rights
                          have otherwise been violated, please provide a notice
                          containing all of the following information to our
                          Copyright Agent:
                        </p>
                        <ul>
                          <li>
                            (a) An electronic or physical signature of the
                            person authorized to act on behalf of the owner of
                            the copyright or other intellectual property
                            interest;
                          </li>
                          <li>
                            (b) A description of the copyrighted work that you
                            claim has been infringed;
                          </li>
                          <li>
                            (c) A description of where the material that you
                            claim is infringing is located on the Site;
                          </li>
                          <li>
                            (d) Your address, telephone number, and e-mail
                            address;
                          </li>
                          <li>
                            (e) A statement by you that you have a good faith
                            belief that the disputed use is not authorized by
                            the copyright owner, its agent, or the law; and
                          </li>
                          <li>
                            (f) A statement by you, made under penalty of
                            perjury, that the above information in your notice
                            is accurate and that you are the copyright owner or
                            authorized to act on the copyright owner's behalf.
                          </li>
                        </ul>
                        <p className="mt-2">
                          Our Copyright Agent for Notice of claims of copyright
                          infringement on the Site is MIH Financials, INC, who
                          can be reached as follows:
                          <br></br>
                          <address>
                            By Mail: 5600 N. River Rd. Ste 800, Rosemont, IL
                            60018
                          </address>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Applicable Law. </b>You agree that the laws of the
                          state of Illinois, without regard to conflicts of laws
                          provisions will govern these Terms and Condition of
                          Use and any dispute that may arise between you and
                          Bidly Auction or its affiliates.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Severability. </b>
                          If any provision of this Agreement shall be adjudged
                          by any court of competent jurisdiction to be
                          unenforceable or invalid, that provision shall be
                          limited or eliminated to the minimum extent necessary
                          so that this Agreement will otherwise remain in full
                          force and effect.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Waiver. </b>
                          The inability of Bidly Auction to act upon or uphold
                          any right or clause in this Agreement does not
                          constitute a waiver of that right or clause. Any
                          waiver of this Agreement by Bidly Auction must be in
                          writing and signed by an authorized representative of
                          Bidly Auction.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Termination. </b>
                          Bidly Auction may terminate this Agreement at any
                          time, with or without notice, for any reason.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Relationship of the Parties. </b>
                          Nothing contained in this Agreement or your use of the
                          Site shall be construed to constitute either party as
                          a partner, joint venturer, employee or agent of the
                          other party, nor shall either party hold itself out as
                          such. Neither party has any right or authority to
                          incur, assume or create, in writing or otherwise, any
                          warranty, liability or other obligation of any kind,
                          express or implied, in the name of or on behalf of the
                          other party, it being intended by both parties that
                          each shall remain independent contractors responsible
                          for its own actions.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Entire Agreement. </b>
                          This Terms of Use constitutes the entire agreement
                          between you and Bidly Auction and governs the terms
                          and conditions of your use of the Site, and supersedes
                          all prior or contemporaneous communications and
                          proposals, whether electronic, oral or written,
                          between you and Bidly Auction with respect to this
                          Site. Notwithstanding the foregoing, you may also be
                          subject to additional terms and conditions, posted
                          policies (including but not limited to the Privacy
                          Policy), guidelines, or rules that may apply when you
                          use the website. Bidly Auction may revise this Terms
                          of Use at any time by updating this Agreement and
                          posting it on the Site. Accordingly, you should visit
                          the Site and review the Terms of Use periodically to
                          determine if any changes have been made. Your
                          continued use of this website after any changes have
                          been made to the Terms of Use signifies and confirms
                          your acceptance of any such changes or amendments to
                          the Terms of Use.
                        </p>
                      </li>
                      <li>
                        <b>Contact Information</b>
                        <p>BidlyAuction</p>
                        <address>
                          2747 Belvidere RD Waukegan IL 60085<br></br>
                          <a href="tel:+12245090244">224-509-0244</a>
                          <br></br>
                          <a href="mailto:sales@bidlyauction.com">
                            sales@bidlyauction.com
                          </a>
                        </address>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TermsOfUse;
