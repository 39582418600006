import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import classes from "./Static.module.css";
import LinkButton from "../../components/button/LinkButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { isSeller } from "../../services/Utilities";
import { LibraryService } from "../../services/LibraryService";
import { ALL_REVIEWS, TOPIC_COUNT, TOPIC_DETALS } from "../../Constants";
import { useAuthContext } from "../../redux/auth-context";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Articles = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  let params = useParams();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const [topic, setTopic] = useState([]);

  useEffect(() => {
    ctx.showProgress(true);
    LibraryService(`${TOPIC_DETALS}/${params.type}`)
      .then((res) => {
        setTopic(res);
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
      });
  }, []);

  return (
    <Layout>
      <>
        <div className="mb-5">
          <div className={`bg-img-hero ${classes.bannerImgHelp}`}>
            <div className="container min-height-300 overflow-hidden d-flex justify-content-md-start justify-content-start align-items-center">
              <div className={` ${classes.bannerTextContainer} col-md-7 mt-5`}>
                <h2 className={classes.heading}>
                  Advice and answers from the BidlyAuction Team
                </h2>
                <p className={classes.para}>
                  Have a question? We'll be happy to help.
                </p>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className="mb-7">
            <div className="container min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
              <div className={classes.helpList}>
                <div className="col-12">
                  <h4 className="text-center">
                      {params.type === "BIDDER" && (<>Bidder FAQ</>)}

                      {params.type === "SELLER" && (<>Seller FAQ</>)}
                  </h4>
                </div>
                <div className={`${classes.textContainer}  p-4`}>
                  <div className="row">
                    {topic.map((data) => {
                      return (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={data.key}
                          >
                            <Typography>
                              <h4 className={`${classes.heading2} mb-0`}>
                                {data.value}
                              </h4>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div
                                className={`${classes.para} text-left`}
                                dangerouslySetInnerHTML={{
                                  __html: data.valueTwo,
                                }}
                              />
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </Layout>
  );
};

export default Articles;
