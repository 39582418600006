import React from "react";

const ZRadioComponent = ({ field, items, name, radioName, handleOnChange }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  let fieldValue = field.value || "";

  return (
    <>
      {items.map(({ value, label }, index) => {
        return (
          <div
            key={index}
            className="custom-control custom-radio mt-1 text-blue-25"
          >
            <input
              type="radio"
              className="custom-control-input"
              id={`${radioName}-${index}`}
              name={radioName}
              value={value}
              checked={fieldValue === value}
              onChange={() => handleOnChange(value, radioName)}
            />
            <label
              htmlFor={`${radioName}-${index}`}
              className="custom-control-label font-size-15"
            >
              {label.replace(/_/g, " ").toProperCase()}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default ZRadioComponent;
