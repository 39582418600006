import React from "react";
import classes from "./AddressCardsPanel.module.css";

const AddressCardsPanel = (props) => {
  return (
    <>
      <div className={classes.addressCardsContainer}>{props.children}</div>
    </>
  );
};

export default AddressCardsPanel;
