import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../../components/UI/HorizontalProgress";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Label from "../../../components/UI/Label";
import PlaceholderHeading from "../../credit-card/PlaceholderHeading";
import { localToUSCentralMoment, newDateObjectUsCentral, scrollTo } from "../../../services/Utilities";
import { TIMEZONE_NAME, wizardGenericErrorMsg } from "../../../Constants";
import ErrorMessage from "../../../components/UI/ErrorMessage";
import IconedInfo from "../../../components/UI/IconedInfo";
import ZDatePicker from "../../../components/form/ZDatePicker";
import InstructionMultibox from "../../../components/UI/InstructionMultibox";
import { useRef } from "react";
import WizardButtons from "./WizardButtons";
import DualErrorMessage from "../../../components/UI/DualErrorMessage";
import ZRadioComponent from "../../../components/form/ZRadioComponent";

const ListingDates = (props) => {
  const { step, data, serverErrors, tabDisable } = props;
  const { saveTab, closeWizard } = props;

  const draftSubmitRef = useRef();
  const [isDraft, setIsDraft] = useState(false);

  const tabInitValues = {
    startDate: "",
    endDate: "",
    days: "",
  };

  const schema = Yup.object().shape({
    // startDate: Yup.date()
    //   .min(new Date(), "Please choose future date and time (US Central Timezone)")
    //   .typeError("Enter valid date"),
    startDate: Yup.date()
      .optional()
      .nullable()
      .typeError("Enter valid date")
      .test("auctionStartDateValidation", function (startDate) {
        if (startDate) {
          const auctionStartDate = localToUSCentralMoment(startDate);
          const nowDate = localToUSCentralMoment(new Date(), false);

          console.log("local auction start", startDate);
          console.log("CST auction start", auctionStartDate.format());
          console.log("local now", new Date());
          console.log("CST now", nowDate.format());

          if (auctionStartDate.isBefore(nowDate)) {
            return this.createError({
              path: this.path,
              message:
                "Please choose future date and time (US Central Timezone)",
            });
          }
        }

        return true;
      }),

    days: Yup.string().required("Please select an option"),
    endDate: Yup.date()
      .optional()
      .nullable()
      .typeError("Enter valid date")
      // .required("End date time is required")
      // .when("startDate", (startDate, schema) => {
      //   if (startDate) {
      //     const timeAfter = new Date(startDate.getTime() + 1);

      //     return schema.min(
      //       timeAfter,
      //       "End date has to be after than start date in US Central Timezone"
      //     );
      //   }

      //   return schema;
      // }),
      .test("auctionEndDateValidation", function (endDate) {
        const { startDate, days } = this.parent;

        if (days && days == "CUSTOM") {
          if (!endDate) {
            return this.createError({
              path: this.path,
              message: "End date time is required",
            });
          }

          const auctionEndDate = localToUSCentralMoment(endDate);

          let errorMessage =
            "The end date and time must occur after the current date and time in the US Central Timezone.";

          let auctionStartDate = localToUSCentralMoment(
            new Date(new Date().getTime() + 1),
            false
          );

          if (startDate) {
            auctionStartDate = localToUSCentralMoment(
              new Date(startDate.getTime() + 1)
            );

            console.log("auction start date", auctionStartDate.format());

            errorMessage =
              "The end date and time must occur after the current date and time in the US Central Timezone.";
          }

          if (auctionStartDate.isAfter(auctionEndDate)) {
            return this.createError({
              path: this.path,
              message: errorMessage,
            });
          }
        }

        return true;
      }),
  });

  const [tabData, setTabData] = useState(tabInitValues);
  const [tabErrors, setTabErrors] = useState(serverErrors);

  const populateTabData = (inputData) => {
    if (inputData && Object.values(inputData)?.length > 0) {
      setTabData((state) => {
        const tempState = { ...state, ...inputData };

        console.log("data in dates tab", tempState);

        return tempState;
      });
    }
  };

  useEffect(() => {
    populateTabData(data);
  }, []);

  useEffect(() => {
    populateTabData(data);
  }, [data]);

  useEffect(() => {
    setTabErrors(serverErrors);
  }, [serverErrors]);

  const getTodayDate = () => {
    const today = newDateObjectUsCentral(new Date());

    console.log("Today's date", today);

    return today;
  };

  return (
    <>
      <PlaceholderHeading title="Start and End Date">
        <button
          disabled={tabDisable}
          className="btn-block btn btn-outline-primary px-4"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            closeWizard();
          }}
        >
          Close
        </button>
      </PlaceholderHeading>
      <div className="mt-3 mb-2">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mt-5 instructions-desc">
            Set the starting and ending dates and times of your auction
          </p>

          <div className="row" id="pageError">
            <div className="col-md-12">
              {tabErrors && tabErrors.message && (
                <div className="mb-4">
                  <ErrorMessage position="top">
                    {tabErrors.message}
                  </ErrorMessage>
                </div>
              )}
            </div>
          </div>

          <fieldset disabled={tabDisable}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={tabData}
              validationSchema={schema}
              onSubmit={(values) => {
                console.log("submitted values", values);

                saveTab(
                  step,
                  {
                    ...tabData,
                    ...values,
                    // startDate: values.startDate
                    //   ? new Date(values.startDate)?.toISOString()
                    //   : undefined,
                    // endDate: new Date(values.endDate)?.toISOString(),
                  },
                  isDraft
                );
              }}
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                validateForm,
              }) => (
                <form>
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <InstructionMultibox>
                          <InstructionMultibox.header
                            heading="Key to Success!"
                            description="Stick to these suggestions to find the ultimate success for your auction"
                          />
                          <InstructionMultibox.row>
                            A duration of 10-14 days is highly recommended. This
                            give us the ability to properly market your items in
                            order to maximize exposure. Items listed for longer
                            than 20 days tend to go state.
                          </InstructionMultibox.row>

                          <InstructionMultibox.row>
                            Try to avoid ending your items on holidays or
                            weekends
                          </InstructionMultibox.row>
                        </InstructionMultibox>
                      </div>
                      <div className="js-form-message mb-4">
                        <Label hightlighted>Start Date and Time</Label>
                        <ZDatePicker
                          showTimeInput
                          dateFormat="MM/dd/yyyy h:mm aa"
                          minDate={getTodayDate()}
                          errors={errors}
                          serverError={serverErrors && serverErrors.startDate}
                          placeholderText="Enter Start Date and Time"
                          name="startDate"
                          onChange={(newValue) => {
                            if (values?.days === "CUSTOM") {
                              setFieldValue("endDate", "");
                            }
                          }}
                        />
                        <p className="mt-2 mb-2">
                          Leave blank to start your listing immediately
                        </p>
                      </div>

                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Auction ending in?
                        </Label>
                        <Field
                          className="form-control"
                          name="days"
                          radioName="days"
                          items={[
                            {
                              value: "DAYS_7",
                              label: "07 Days",
                            },
                            {
                              value: "DAYS_14",
                              label: "14 Days",
                            },
                            {
                              value: "DAYS_30",
                              label: "30 Days",
                            },
                            {
                              value: "CUSTOM",
                              label: "Select End Date and Time",
                            },
                          ]}
                          component={ZRadioComponent}
                          handleOnChange={(value) => {
                            setFieldValue("days", value);
                          }}
                        />

                        <DualErrorMessage
                          fieldName="days"
                          serverError={serverErrors && serverErrors.days}
                        />
                      </div>

                      {values.days === "CUSTOM" && (
                        <>
                          <div className="js-form-message mb-4">
                            <Label hightlighted required>
                              Ending Date and Time
                            </Label>
                            <ZDatePicker
                              showTimeInput
                              dateFormat="MM/dd/yyyy h:mm aa"
                              minDate={values?.startDate ?? getTodayDate()}
                              errors={errors}
                              serverError={serverErrors && serverErrors.endDate}
                              placeholderText="Enter Ending Date and Time"
                              name="endDate"
                            />
                          </div>

                          <div className="js-form-message mb-4">
                            <IconedInfo>
                              If you are creating multiple listings, stagger
                              your ending times by 5 or more minutes to help
                              entice bidding
                            </IconedInfo>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="js-form-message mb-4 row">
                    <div className="col-12">
                      <WizardButtons>
                        <button
                          className="btn btn-primary-dark-w px-5"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(false);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save and Continue
                        </button>

                        <button
                          ref={draftSubmitRef}
                          className="btn btn-outline-primary px-5"
                          type="button"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(true);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save Draft
                        </button>
                      </WizardButtons>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default ListingDates;
