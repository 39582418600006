import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { searchTerm } from "../services/SearchService";
import { formatUSD } from "../services/Utilities";
import classes from "./SearchArea.module.css";

const SearchBox = () => {
  const navigate = useNavigate();

  const [term, setTerm] = useState();
  const [searchInProgress, setSearchInProgress] = useState(true);
  const [searchResult, setSearchResult] = useState({});

  const fetchDataWithSearchTerm = (term) => {
    setSearchInProgress(true);
    if (term?.length >= 3) {
      //setSearchResult([]);
      searchTerm(term)
        .then((res) => {
          setSearchResult(res);
          setSearchInProgress(false);
        })
        .catch((e) => {
          navigate("/error");
        });
    }
  };

  const handleSearch = () => {
    if (term?.length >= 3) {
      navigate("/search-result", {
        state: { filter: term ? "?title=" + term : "" },
      });
    } else {
      console.log(
        "Please provide a keyword of at least three characters to complete your search."
      );
    }
  };

  useEffect(() => {
    fetchDataWithSearchTerm(term);
  }, [term]);

  return (
    <>
      <label className="sr-only" htmlFor="searchproduct">
        Search
      </label>

      <form>
        <div className={classes.searchContainer}>
          <div className="input-group">
            <input
              type="text"
              className="form-control py-2 pl-4 font-size-15 border-right-0 height-40 border-width-2 border-secondary"
              name="search"
              placeholder="Search for Products"
              autoComplete="off"
              onChange={(e) => {
                setTerm(e.target.value);
              }}
            />
            <div className="input-group-append">
              <button
                className="btn btn-secondary height-40 py-2 px-3"
                type="submit"
                id="searchProduct1"
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
              >
                <span className="ec ec-search font-size-24"></span>
              </button>
            </div>
          </div>
          {term?.length >= 3 &&
            searchResult &&
            Object.keys(searchResult)?.length > 0 && (
              <div className={classes.resultsContainer}>
                {searchResult.titles && (
                  <div className={classes.searchResult}>
                    <ul>
                      {searchResult.titles.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={(e) => {
                              navigate("/search-result", {
                                state: {
                                  filter: item.key ? "?title=" + item.key : "",
                                },
                              });
                            }}
                          >
                            <span>{item.key}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {searchResult.categories && (
                  <div className={classes.searchResult}>
                    <h5>Categories</h5>
                    <ul>
                      {searchResult.categories.map((item) => {
                        return (
                          <li
                            key={item.id}
                            onClick={(e) => {
                              navigate("/search-result", {
                                state: {
                                  filter: item.id
                                    ? "?sub_category=" + item.id
                                    : "",
                                },
                              });
                            }}
                          >
                            <span>{item.key}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {searchResult.auctions && (
                  <div
                    className={`${classes.auctions} ${classes.searchResult}`}
                  >
                    <h5>Popular Searches</h5>
                    <ul>
                      {searchResult.auctions.map((item) => {
                        return (
                          <li
                            key={item.itemId}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/auction/${item.itemId}`);
                            }}
                          >
                            <div
                              className={classes.auctionImage}
                              style={{
                                backgroundImage: `url('${item.defaultImage}')`,
                              }}
                            ></div>
                            <div className={classes.auctionTextContainer}>
                              <div className={classes.type}>
                                {item.type.replaceAll("_", " ").toLowerCase()}
                              </div>
                              <div className={classes.title}>{item.title}</div>
                              <div className={classes.price}>
                                USD <span>{formatUSD(item.price)}</span>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            )}

          {term?.length >= 3 &&
            !searchInProgress &&
            searchResult &&
            Object.keys(searchResult)?.length == 0 && (
              <div className={classes.resultsContainer}>
                <div className="text-center py-3">No Result Found</div>
              </div>
            )}
        </div>
      </form>

      <div className="mt-2 text-right">
        <Link to="/advance-search" className="adv-search-link">
          Advanced Search
        </Link>
      </div>
    </>
  );
};

export default SearchBox;
