import React from "react";
import { Link } from "react-router-dom";
import LinkButton from "../../components/button/LinkButton";
import classes from "./Wishlist.module.css";
import clock from "../../assets/images/search-clock.svg";

const WishListItem = ({ data, onFilterChange, watchListHadler, ...props }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  return (
    <li className="product-item remove-divider mx-1">
      <div className="product-item__outer w-100">
        <div className="product-item__inner remove-prodcut-hover py-4 row">
          <div className="product-item__header col-md-3">
            <div className="mb-2">
              <Link to={`/auction/${data.itemId}`}>
                <img
                  className={`${classes.auctionImg} img-fluid`}
                  src={data.defaultImage}
                  alt="Image Description"
                  style={{ borderRadius: "10px" }}
                />
              </Link>
            </div>
          </div>
          <div className="pl-2 product-item__body col-md-6">
            <div className="pr-lg-0">
              <div className={`${classes.searchItemEndLabel} d-flex flex-center mb-2`}>
                {data.type}
              </div>
              <div className="mb-2">
                <Link to={`/auction/${data.itemId}`} className={`${classes.searchItemTitle}`} >
                  {data.title.toProperCase()}
                </Link>
              </div>
              <div className="mb-2">
                <div className={`${classes.searchItemEndLabel} d-flex flex-center`}>
                  Lot# :{" "} {data.itemLot}
                </div>
              </div>
              <div className="mb-2">
                <div className={`${classes.searchItemEndLabel} d-flex flex-center`}>
                  Start Date :{" "}
                  {/* <img className={`${classes.clockIcon} my-auto mx-1`} src={clock} /> */}
                    {" "} {data.startedOn}
                </div>
              </div>
              <div className="mb-2">
                <div className={`${classes.searchItemEndLabel} d-flex flex-center`}>
                  End Date :{" "}
                  {/* <img className={`${classes.clockIcon} my-auto mx-1`} src={clock} /> */}
                    {" "} {data.endingIn}
                </div>
              </div>
              {data.auctionType != "BUY NOW" && (
                <div className="mb-2">
                  <div className={`${classes.searchItemBids}`}>
                    Current Price :{" "}
                    <span className={`${classes.searchItemBidsValue}`}>
                      ${data.price}
                    </span>
                  </div>
                </div>
              )}
              <div className="mb-2">
                Location : <br></br>
                <a href="#" className={`${classes.searchItemCityState}`} >
                  {data.city.toProperCase()} {data.state.toProperCase()}{" "}
                  {", USA"}
                </a>
              </div>
          
            </div>
          </div>
          <div className="product-item__footer col-md-3 d-md-block my-auto">
            <div className="mb-3 d-flex justify-content-center">
              <div className="prodcut-add-cart w-75">
                {data.status === "ACTIVE" ? (
                  <Link to={`/auction/${data.itemId}`} className="btn-block btn btn-primary-dark-w px-5 py-1 text-transform-upper">
                    {(data.type == "ONLINE AUCTION" ||  data.type == "SEALED AUCTION") && "Bid Now"}
                    {data.type == "MAKE AN OFFER" && "Make an Offer" }
                    {data.type == "BUY NOW" && "Buy Now" }
                  </Link>
                ):(
                  <div className="badge bg-danger px-2 py-2 font-size-13 d-block">
                    {(data.status === "SUCCESSFUL_ENDED") && "SOLD"}
                    {(data.status === "UNSUCCESSFUL_ENDED") && "UNSOLD"}
                    {(data.status === "TIMED_OUT" || data.status === "FINALIZING_WINNER" || data.status === "INVOICE_GENERATED" || 
                      data.status === "WAITING_TO_RECEIVE_PAYMENT") && "AUCTION CLOSED"}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 d-flex justify-content-center">
              <button onClick={()=>{watchListHadler(data)}} className="btn btn-outline-primary px-5 py-1 text-transform-upper" >
                Remove
              </button>
            </div>
          </div>
        </div>
        <div className={`${classes.buttonPanel} w-100`}>
          <div className="col-12 p-0">
            <div className="mb-3 d-flex justify-content-center">
              <div className="prodcut-add-cart">
                {data.status === "ACTIVE" ? (
                  <Link to={`/auction/${data.itemId}`} className="btn-block btn btn-primary-dark-w px-5 py-1 text-transform-upper">
                    {(data.type == "ONLINE AUCTION" ||  data.type == "SEALED AUCTION") && "Bid Now"}
                    {data.type == "MAKE AN OFFER" && "Make an Offer" }
                    {data.type == "BUY NOW" && "Buy Now" }
                  </Link>
                ):(
                  <button disabled="disabled" className="btn btn-outline-secondary px-5 py-1 text-transform-upper">
                    {(data.status === "SUCCESSFUL_ENDED") && "SOLD"}
                    {(data.status === "UNSUCCESSFUL_ENDED") && "UNSOLD"}
                    {(data.status === "TIMED_OUT" || data.status === "FINALIZING_WINNER" || data.status === "INVOICE_GENERATED" || 
                      data.status === "WAITING_TO_RECEIVE_PAYMENT") && "AUCTION CLOSED"}
                  </button>
                )}
              </div>
              <button onClick={()=>{watchListHadler(data)}} className="btn btn-outline-primary px-5 py-1 text-transform-upper ml-2" >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default WishListItem;
