import React from "react";
import AlertCommon from "./AlertCommon";
import image from "../../assets/images/404.svg";
import Layout from "../../layout/Layout";
import { useEffect } from "react";
import { useAuthContext } from "../../redux/auth-context";

const Error404 = () => {
  const ctx = useAuthContext();

  useEffect(() => {
    ctx.showProgress(false);
  }, []);

  const description =
    "The page you are looking for no longer exists. Perhaps you can return back to the site's homepage and see if you can find what you are looking for. Or, you can try finding it by using the search from on homepage.";
  const heading = "This page doesn't exist.";
  return (
    <>
      <Layout>
        <div className="container mt-10 mb-10">
          <div className="row">
            <div className="offset-xl-3 col-xl-6">
              <AlertCommon
                description={description}
                heading={heading}
                image={image}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Error404;
