import React, { useState } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import GroupSearchSelect from "../../components/form/GroupSearchSelect";
import SearchSelect from "../../components/form/SearchSelect";
import { Select } from "../../components/Select";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import {
  ALL_CATEGORY_SUBCATEGORY_COUNT_LIBRARY_URL,
  CATEGORY_LIBRARY_URL,
} from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { LibraryService } from "../../services/LibraryService";
import classes from "./AllCategories.module.css";
import CategoryPanel from "./CategoryPanel";

const AllCategories = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const [categories, setCategories] = useState();
  const [searchCategoryOptions, setSearchCategoryOptions] = useState([]);
  const [searchSubCategoryOptions, setSearchSubCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();

  const fetchData = () => {
    ctx.showProgress(true);
    LibraryService(ALL_CATEGORY_SUBCATEGORY_COUNT_LIBRARY_URL)
      .then((res) => {
        let catList = [];
        res.map((item) => {
          let subCategories = [];
          item?.subList?.map((si) => {
            subCategories.push({
              id: si.id,
              name: si.key,
              count: si.valueNumber,
            });
          });

          catList.push({
            id: item.id,
            name: item.key,
            subCategories: subCategories,
          });
        });
        setCategories(catList);

        let catSearchOptions = [];
        let subCatSearchOptions = [];
        catList.map((cat) => {
          if (cat.subCategories?.length > 0) {
            cat.subCategories.map((sub) => {
              subCatSearchOptions.push({
                label: sub.name,
                value: sub.id,
                parent: cat.id,
              });
            });

            catSearchOptions.push({
              label: cat.name,
              value: cat.id,
            });
          }
        });
        setSearchCategoryOptions(catSearchOptions);
        setSearchSubCategoryOptions(subCatSearchOptions);

        ctx.showProgress(false);
      })
      .catch((e) => {
        ctx.showProgress(false);
        navigate("/error");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Layout>
        <div className="container mb-10 mt-10">
          <div className="mb-3">
            <Breadcrumb
              items={[
                <BreadcrumbItem title="Home" to="/" />,
                <BreadcrumbItem title="Categories" />,
              ]}
            />
          </div>

          <div className="row justify-content-end align-items-center">
            <div className="col">
              <h1 className={`${classes.mainHeading} text-left`}>Categories</h1>
            </div>
            <div className="col-lg-3 mb-2 mb-lg-0">
              <SearchSelect
                placeholder="Search Category"
                name="category"
                options={searchCategoryOptions}
                onChange={(e) => {
                  console.log(e.value);
                  setSelectedCategory(e.value);
                }}
              />
            </div>

            <div className="col-lg-3 mb-2 mb-lg-0">
              <SearchSelect
                placeholder="Search Sub-Category"
                name="subCategory"
                options={
                  selectedCategory
                    ? searchSubCategoryOptions.filter(
                        (sub) => sub.parent == selectedCategory
                      )
                    : searchSubCategoryOptions
                }
                onChange={(e) => {
                  console.log(e.value);
                  setSelectedSubCategory(e.value);
                }}
              />
            </div>
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-secondary height-40 py-2 px-3"
                disabled={!selectedCategory && !selectedSubCategory}
                onClick={(e) => {
                  e.preventDefault();
                  if (selectedSubCategory) {
                    navigate("/search-result", {
                      state: { filter: "?sub_category=" + selectedSubCategory },
                    });
                    return;
                  }

                  if (selectedCategory) {
                    navigate("/search-result", {
                      state: { filter: "?category=" + selectedCategory },
                    });
                  }
                }}
              >
                Go
              </button>
            </div>
          </div>
          <br />
          <br />

          {categories &&
            categories.map((category, i) => {
              return (
                <Fragment key={i}>
                  {category?.subCategories?.length > 0 && (
                    <div className="pt-5 border-top">
                      <CategoryPanel
                        categoryId={category.id}
                        title={category.name}
                        categories={category.subCategories}
                      />
                    </div>
                  )}
                </Fragment>
              );
            })}
        </div>
      </Layout>
    </>
  );
};

export default AllCategories;
