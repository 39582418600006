import React from "react";
import { Link } from "react-router-dom";
import LinkButton from "../../components/button/LinkButton";
import classes from "./Search.module.css";
import clock from "../../assets/images/search-clock.svg";

const SearchActionItemGrid = ({
  data,
  sellers,
  onFilterChange,
  watchListHadler,
  ...props
}) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <li className={`col-xl-4 col-lg-4 col-md-6 mb-5`}>
      <div className={`product-item__outer h-100  ${classes.bidlyItem} m-2`}>
        <div className="product-item__inner">
          <div className="product-item__body pb-xl-2">
            <div className="mb-2">
              <Link to={`/auction/${data.itemId}`}>
                <div
                  className={classes.imagePanel}
                  style={{
                    backgroundImage: `url(${data.defaultImage})`,
                  }}
                ></div>
              </Link>
            </div>
            <div className="px-4 py-2">
              <div
                className={`${classes.searchItemEndLabel} d-flex flex-center mb-2`}
              >
                {data.auctionType.toProperCase()} | LOT # : {data.lotNo}
              </div>
              <h5 className="mb-2">
                <Link
                  to={`/auction/${data.itemId}`}
                  className={`${classes.searchItemTitle}`}
                >
                  {data.title.toProperCase()}
                </Link>
              </h5>

              <div className="mb-2">
                <a href="#" className={`${classes.searchItemCityState}`}>
                  {data.city.toProperCase()} {data.state.toProperCase()}{" "}
                  {", USA"}
                </a>
              </div>

              {sellers
                .filter((seller) => seller.value == data.sellerId)
                .map((seller) => {
                  return (
                    <>
                      <div className="mb-2">
                        <Link
                          onClick={() =>
                            onFilterChange(false, "seller", {
                              checked: true,
                              value: seller.value,
                            })
                          }
                          className={`${classes.searchItemCityName}`}
                        >
                          {seller.label}
                        </Link>
                      </div>
                    </>
                  );
                })}
              {data.auctionType == "ONLINE AUCTION" && (
                <>
                  <div className="flex-center-between mb-2">
                    <div className="prodcut-price">
                      <div className={`${classes.searchItemBids}`}>
                        Bids:{" "}
                        <span className={`${classes.searchItemBidsValue}`}>
                          {data.bids.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex-center-between mb-2">
                    <div className="prodcut-price">
                      <div className={`${classes.searchItemBids}`}>
                        Current Price:{" "}
                        <span className={`${classes.searchItemBidsValue}`}>
                          ${data.maxBid > 0 ? data.maxBid : data.startingPrice}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {data.auctionType == "BUY NOW" && (
                <div className="flex-center-between mb-2">
                  <div className="prodcut-price">
                    <div className={`${classes.searchItemBids}`}>
                      Price:{" "}
                      <span className={`${classes.searchItemBidsValue}`}>
                        ${data.maxBid > 0 ? data.maxBid : data.startingPrice}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex-center-between mb-2">
                <div
                  className={`${classes.searchItemEndLabel} d-flex flex-center`}
                >
                  Ends in:{" "}
                  <img
                    className={`${classes.clockIcon} my-auto mx-1`}
                    src={clock}
                  />{" "}
                  {data.endsDaysAndHour}
                </div>
              </div>
              <div
                className="flex-center-between mb-2 mt-4"
                style={{ alignItems: "flex-end", flexDirection: "row" }}
              >
                <div className="d-flex justify-content-center">
                  <div className="prodcut-add-cart">
                    <LinkButton to={`/auction/${data.itemId}`} className="mt-5">
                      {(data.auctionType == "ONLINE AUCTION" ||
                        data.auctionType == "SEALED AUCTION") &&
                        "Bid Now"}
                      {data.auctionType == "MAKE AN OFFER" && "Make an Offer"}
                      {data.auctionType == "BUY NOW" && "Buy Now"}
                    </LinkButton>
                  </div>
                </div>
                <div className="flex-horizontal-center justify-content-center justify-content-wd-center flex-wrap pb-2">
                  <button
                    onClick={() => {
                      watchListHadler(data);
                    }}
                    className="text-gray-6 font-size-16 mx-wd-3"
                    style={{ border: "none", background: "none" }}
                  >
                    <i
                      className={
                        data.inWishList
                          ? `yith-wcwl-icon fa fa-heart mr-1 font-size-16`
                          : `ec ec-favorites mr-1 font-size-16`
                      }
                    ></i>{" "}
                    Watch
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default SearchActionItemGrid;
