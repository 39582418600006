import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import LotsTable from "../../components/table/LotsTable";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { BOUGHT_CART_ITEMS_URL } from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import {
  fetchMyBids,
  initBuyerFeeProcess,
  removeBuyNow,
} from "../../services/MyBidsService";
import {
  formatUSD,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
  isUserAuthorized,
} from "../../services/Utilities";
import tableClasses from "../../components/table/LotsTable.module.css";
import certificateIcon from "../../assets/images/doc-icon.svg";
import receiptIcon from "../../assets/images/receipt-icon.svg";
import AlertModal from "../../components/UI/AlertModal";
import ConfirmModal from "../../components/UI/ConfirmModal";
import TimeLeft from "../../components/UI/TimeLeft";

const ItemBoughtCart = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [wonBids, setWonBids] = useState([]);
  const [showFeeProcessStartAlert, setShowFeeProcessStartAlert] =
    useState(false);
  const [showRemoveConfirmBox, setShowRemoveConfirmBox] = useState(false);
  const [itemId, setItemId] = useState();
  const [showRemoveFailureAlert, setShowRemoveFailureAlert] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  const fetchWonBids = async () => {
    const rs = await fetchMyBids(BOUGHT_CART_ITEMS_URL);
    console.log("cart items", rs);

    setRefreshList(false);

    const content = rs?.content;
    const items = [];
    content?.map((c) => {
      let m = {
        ...c,
      };

      m.auction.endedOn = m.auction.endedOn
        ? formattedUTCtoUSCentral(m.auction.endedOn)
        : m.auction.endedOn;

      if (
        m.buyerFeeStatus === "INITIATED" ||
        (m.buyerFeeStatus === "PAID" && !m.invoiceURL) ||
        m.buyerFeeStatus === "PENDING"
      ) {
        setRefreshList(true);
      }

      items.push(m);
    });

    setWonBids(items);
  };

  const fetchBidsData = async (showProgress = true) => {
    if (showProgress) ctx.showProgress(true);
    try {
      await fetchWonBids();
      if (showProgress) ctx.showProgress(false);
    } catch (e) {
      if (showProgress) ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchBidsData();
  }, []);

  useEffect(() => {
    let refreshIntv = null;
    if (refreshList) {
      console.log("refresh list init");
      refreshIntv = setTimeout(() => {
        setRefreshList(false);
        fetchBidsData(false);
      }, 10000);
    }

    return () => {
      if (refreshIntv) clearTimeout(refreshIntv);
      console.log("refresh list destroy");
    };
  }, [refreshList]);

  const prepareItemPaymentInitiated = (itemId) => {
    const wonList = [];
    wonBids?.map((i) => {
      let item = {
        ...i,
      };

      item.buyerFeeStatus =
        item.auction.itemId === itemId ? "INITIATED" : item.buyerFeeStatus;

      wonList.push(item);
    });

    return wonList;
  };

  const initiatePaymentRequest = (itemId) => {
    ctx.showProgress(true);
    initBuyerFeeProcess(itemId)
      .then((res) => {
        ctx.showProgress(false);
        setShowFeeProcessStartAlert(true);

        // setWonBids(prepareItemPaymentInitiated(itemId));
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  const removeBuyNowItem = (itemId) => {
    ctx.showProgress(true);
    removeBuyNow(itemId)
      .then((res) => {
        fetchBidsData();
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (isUserAuthorized(e.message)) {
          setShowRemoveFailureAlert(true);
          return;
        }

        navigate("/error");
      });
  };

  const wonBidsTableHeader = (
    <tr>
      <th>Asset Description</th>
      <th>Amount</th>
      <th>Buyer’s Premium</th>
      <th>Tax</th>
      <th>Total Due</th>
      <th>Invoice</th>
      <th>Buyer Fee</th>
    </tr>
  );

  const wonBidsMobileHeader = (
    <>
      <div className={tableClasses.selection}></div>
      <div className={tableClasses.label}>Item Description</div>
    </>
  );

  return (
    <>
      {showFeeProcessStartAlert && (
        <AlertModal
          type="info"
          message={`Please wait while system deducts buyer fee and generates invoice!`}
          okClicked={(e) => {
            e.preventDefault();
            setShowFeeProcessStartAlert(false);
            fetchBidsData();
          }}
        />
      )}

      {showRemoveFailureAlert && (
        <AlertModal
          type="error"
          message={`Item cannot be removed from your cart!`}
          okClicked={(e) => {
            e.preventDefault();
            setShowRemoveFailureAlert(false);
            fetchBidsData();
          }}
        />
      )}

      {showRemoveConfirmBox && (
        <ConfirmModal
          title=""
          message="Are you sure you want to remove this item from your cart?"
          cancelClicked={(e) => {
            e.preventDefault();
            setShowRemoveConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowRemoveConfirmBox(false);
            removeBuyNowItem(itemId);
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myBuyNowActive={true} subMenu="item-cart" />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <h2 className="h4 font-weight-bold">
                Buy Now - Generate Invoice & Pay Buyer Fee
              </h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <p className="mt-5 pg-2 instructions-desc mb-1">
                Note: Follow these steps to Buy the Item Now
              </p>
              <ol className="pg-2 instructions-desc ">
                <li> Pay Buyer Fees & generate Invoice </li>
                <li> Make Payment for item </li>
                <li> Wait for seller to acknowledge Payment </li>
                <li> Contact Seller & Pickup the item</li>
              </ol>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <LotsTable
                    label="Invoice & Buyer Fee"
                    mobileHeader={wonBidsMobileHeader}
                    tableHeader={wonBidsTableHeader}
                    noRecordTableBody={
                      <>
                        <tr>
                          <td colSpan={8} className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      </>
                    }
                    tableBody={
                      wonBids && Object.keys(wonBids).length > 0
                        ? wonBids.map((bid, i) => {
                            const expiryTime = new Date();
                            expiryTime.setSeconds(
                              expiryTime.getSeconds() + bid.endsInSeconds
                            );

                            return (
                              <ItemBoughtCart.WonBidsBodyRow
                                key={i}
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                auctionEnd={bid.auction.endedOn}
                                buyerFee={bid.buyerPremium}
                                lot={bid.auction.customId}
                                soldAmount={bid.soldAmount}
                                tax={bid.taxAmount}
                                total={bid.total}
                                currency={bid.auction.currency}
                                status={bid.status}
                                invoiceURL={bid.invoiceURL}
                                buyerFeeStatus={bid.buyerFeeStatus}
                                requestInitiated={
                                  bid.buyerFeeStatus === "INITIATED"
                                }
                                endingIn={expiryTime}
                                removingOn={formattedUTCtoUSCentral(
                                  bid.removingOn
                                )}
                                onPayClicked={(itemId) => {
                                  initiatePaymentRequest(itemId);
                                }}
                                onCancelBuy={(itemId) => {
                                  setItemId(itemId);
                                  setShowRemoveConfirmBox(true);
                                }}
                                onTimeExpire={(itemId) => {
                                  fetchBidsData();
                                }}
                              />
                            );
                          })
                        : undefined
                    }
                    noRecordMobileBody={
                      <>
                        <div className={tableClasses.blockPanel}>
                          <div
                            className={`justify-content-center ${tableClasses.block}`}
                          >
                            <div className={tableClasses.blockText}>
                              <div className={tableClasses.item}>
                                <div className={tableClasses.productLabel}>
                                  No Records Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    mobileBody={
                      wonBids && Object.keys(wonBids).length > 0
                        ? wonBids.map((bid, i) => {
                            const expiryTime = new Date();
                            expiryTime.setSeconds(
                              expiryTime.getSeconds() + bid.endsInSeconds
                            );

                            return (
                              <ItemBoughtCart.WonBidsMobileBodyRow
                                key={i}
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                auctionEnd={bid.auction.endedOn}
                                buyerFee={bid.buyerPremium}
                                lot={bid.auction.customId}
                                soldAmount={bid.soldAmount}
                                tax={bid.taxAmount}
                                total={bid.total}
                                currency={bid.auction.currency}
                                status={bid.status}
                                invoiceURL={bid.invoiceURL}
                                buyerFeeStatus={bid.buyerFeeStatus}
                                requestInitiated={
                                  bid.buyerFeeStatus === "INITIATED"
                                }
                                endingIn={expiryTime}
                                removingOn={formattedUTCtoUSCentral(
                                  bid.removingOn
                                )}
                                onPayClicked={(itemId) => {
                                  initiatePaymentRequest(itemId);
                                }}
                                onCancelBuy={(itemId) => {
                                  setItemId(itemId);
                                  setShowRemoveConfirmBox(true);
                                }}
                                onTimeExpire={(itemId) => {
                                  fetchBidsData();
                                }}
                              />
                            );
                          })
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

ItemBoughtCart.WonBidsBodyRow = ({
  itemId,
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
  status,
  invoiceURL,
  receiptURL,
  buyerFeeStatus,
  requestInitiated = false,
  endingIn,
  removingOn,
  onPayClicked = (itemId) => {},
  onCancelBuy = (itemId) => {},
  onTimeExpire = (item) => {},
}) => (
  <>
    <tr>
      <td>
        <span className={tableClasses.productLabel}>
          <Link to={`/auction-item/` + itemId}>{label}</Link>
        </span>
        <span className={tableClasses.lot}>
          <Link to={`/auction-item/` + itemId}>LOT # {lot}</Link>
        </span>
      </td>
      <td>{formatUSD(soldAmount)}</td>
      <td>{formatUSD(buyerFee)}</td>
      <td>{formatUSD(tax)}</td>
      <td>{formatUSD(total)}</td>
      <td>
        {!invoiceURL && <span className={tableClasses.uLabel}>PENDING</span>}

        {invoiceURL && (
          <a
            target="_blank"
            download={true}
            href={invoiceURL}
            className={`btn btn-outline-primary px-2 py-1 ${tableClasses.button}`}
          >
            <img src={certificateIcon} />
            Invoice
          </a>
        )}
      </td>
      <td>
        {buyerFeeStatus === "PENDING" && (
          <>
            {!requestInitiated && (
              <>
                <a
                  href="#"
                  className="btn btn-block btn-primary-dark-w p-1"
                  onClick={(e) => {
                    e.preventDefault();
                    onPayClicked(itemId);
                  }}
                >
                  PAY BUYER FEE
                </a>

                <a
                  href="#"
                  className="btn btn-block btn-secondary p-1"
                  onClick={(e) => {
                    e.preventDefault();
                    onCancelBuy(itemId);
                  }}
                >
                  CANCEL BUY NOW
                </a>
              </>
            )}

            {requestInitiated && (
              <span className={tableClasses.uLabel}>PAYMENT IN PROCESS</span>
            )}
          </>
        )}

        <span className={tableClasses.uLabel}>
          {buyerFeeStatus === "INITIATED" && "PAYMENT IN PROCESS"}
          {buyerFeeStatus === "PAID" && "PAID"}
          {buyerFeeStatus === "ERROR" && "CARD DECLINED"}
        </span>
      </td>
    </tr>
    {status === "INVOICE_GENERATED" &&
      buyerFeeStatus === "PENDING" &&
      !requestInitiated && (
        <tr>
          <td colSpan={8} className={tableClasses.notificationAlertCell}>
            If the buyer fee is not paid, this item will be automatically
            removed from your cart on {removingOn} US Central Time.
          </td>
        </tr>
      )}
  </>
);

ItemBoughtCart.WonBidsMobileBodyRow = ({
  itemId,
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
  status,
  invoiceURL,
  receiptURL,
  buyerFeeStatus,
  requestInitiated = false,
  endingIn,
  removingOn,
  onPayClicked = (itemId) => {},
  onCancelBuy = (itemId) => {},
  onTimeExpire = (item) => {},
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}></div>

        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.productLabel}>
              <Link to={`/auction-item/` + itemId}>{label}</Link>
            </div>
            <div className={tableClasses.lot}>
              <Link to={`/auction-item/` + itemId}>LOT # {lot}</Link>
            </div>
          </div>

          {!invoiceURL && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Invoice</div>
                <div className={tableClasses.value}>
                  <span className={`${tableClasses.badge} badge bg-warning`}>
                    PAYMENT PENDING
                  </span>
                </div>
              </div>
            </>
          )}

          {buyerFeeStatus !== "PENDING" && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Buyer Fee</div>
                <div className={tableClasses.value}>
                  {!status === "INITIATED" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PAYMENT IN PROCESS
                    </span>
                  )}
                  {status === "PAID" && (
                    <span className={`${tableClasses.badge} badge bg-success`}>
                      BUYER FEE PAID
                    </span>
                  )}
                  {status === "ERROR" && (
                    <span className={`${tableClasses.badge} badge bg-danger`}>
                      CARD DECLINED
                    </span>
                  )}
                </div>
              </div>
            </>
          )}

          {buyerFeeStatus === "PENDING" && !requestInitiated && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Buyer Fee</div>
                <div className={tableClasses.value}>
                  <span className={`${tableClasses.badge} badge bg-warning`}>
                    PENDING
                  </span>
                </div>
              </div>
            </>
          )}

          {status === "INVOICE_GENERATED" &&
            buyerFeeStatus === "PENDING" &&
            !requestInitiated && (
              <div className={tableClasses.item}>
                <div className={tableClasses.value}>
                  If the buyer fee is not paid, this item will be automatically
                  removed from your cart on {removingOn} US Central Time.
                </div>
              </div>
            )}

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Amount</div>
            <div className={tableClasses.value}>{formatUSD(soldAmount)}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Buyer’s Premium</div>
            <div className={tableClasses.value}>{formatUSD(buyerFee)}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Tax</div>
            <div className={tableClasses.value}>{formatUSD(tax)}</div>
          </div>
        </div>
      </div>
      <div className={`${tableClasses.block} ${tableClasses.bordered}`}>
        <div className={tableClasses.selection}>&nbsp;&nbsp;&nbsp;</div>

        <div className={tableClasses.totalRow}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Total Due</div>
            <div className={tableClasses.value}>{formatUSD(total)}</div>
          </div>

          <div className={tableClasses.buttons}>
            {invoiceURL && (
              <a target="_blank" href={invoiceURL} download={true}>
                <img src={certificateIcon} />
                Invoice
              </a>
            )}

            {buyerFeeStatus === "PENDING" && !requestInitiated && (
              <>
                <a
                  target="_blank"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onPayClicked(itemId);
                  }}
                >
                  <img src={certificateIcon} />
                  PAY BUYER FEE
                </a>
                <a
                  target="_blank"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onCancelBuy(itemId);
                  }}
                >
                  <img src={certificateIcon} />
                  CANCEL BUY NOW
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemBoughtCart;
