import React, { useEffect, useState } from "react";
import classes from "./AlertModal.module.css";
import Card from "./Card";
// import fileUploadErrorImg from "../../../images/icon_file_error.svg";
// import errorImg from "../../../images/error_generic_icon.svg";

const AlertModal = ({ type, title, message, okClicked }) => {
  const [btnClass, setBtnClass] = useState();

  useEffect(() => {
    if (type === "info") {
      setBtnClass("btn-primary-dark-w");
    } else if (type === "success") {
      setBtnClass("btn-secondary");
    } else if (type === "error" || type === "file_upload_error") {
      setBtnClass("btn-danger");
    }
  }, [type]);

  return (
    <div>
      <div className="backdrop" />
      <Card className={classes.modal}>
        <div className={`${classes.content}`}>
          {/* {type === "file_upload_error" && (
            <div className={classes.imgContainer}>
              <img src={fileUploadErrorImg} className={classes.img} />
            </div>
          )}

          {type === "error" && (
            <div className={classes.imgContainer}>
              <img src={errorImg} className={classes.img} />
            </div>
          )} */}

          <h2>{title}</h2>
          <p className="mb-0">{message}</p>
        </div>
        <footer className={classes.actions}>
          <button
            type="button"
            className={`btn ${btnClass}`}
            onClick={okClicked}
          >
            OK
          </button>
        </footer>
      </Card>
    </div>
  );
};

export default AlertModal;
