import { ITEM_URL, SEARCH_TERM_URL, SEARCH_URL } from "../Constants";
import authenticatedFetch, { simpleFetch } from "./fetch";

export const SearchService = async (param, isAuthorized) => {
  let url = `${SEARCH_URL}` + (param ? param : "");

  let response = null;

  if (!isAuthorized) {
    response = await simpleFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } else {
    response = await authenticatedFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const ItemService = async (url, isAuthorized) => {
  let response = undefined;
  if (!isAuthorized) {
    response = await simpleFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } else {
    response = await authenticatedFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const searchTerm = async (term) => {
  let url = `${SEARCH_TERM_URL}` + "?term=" + encodeURIComponent(term);

  const response = await simpleFetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};
