import React from "react";
import classes from "./dashboard.module.css";

const CardHeading = ({ heading, children }) => {
  return (
    <>
      <div className={classes.doubleHeading}>
        <h3 className={classes.dashboardCardHeading}>{heading}</h3>
        {children}
      </div>
    </>
  );
};

export default CardHeading;
