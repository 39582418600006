import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import SetupFormStatus from "./SetupFormStatus";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { saveCustomerBillingCreditCardInfo } from "../../services/PaymentService";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import { shallowEqual, useSelector } from "react-redux";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY);

const AddCardStatus = () => {
  const navigate = useNavigate();

  const { creditCardBillingTracking } = useSelector(
    ({ auth }) => ({
      creditCardBillingTracking: auth.creditCardBillingTracking,
    }),
    shallowEqual
  );

  const updateCardAddStatus = (setupIntent) => {
    saveCustomerBillingCreditCardInfo({
      customerId: creditCardBillingTracking.customerId,
      customer: creditCardBillingTracking.billingInfo,
      paymentMethodId: setupIntent.payment_method,
    })
      .then((resp) => {
        navigate("/add-card-info", {
          state: { cardAddStatus: setupIntent.status },
        });
      })
      .catch((e) => {
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            navigate("/error");
          }
        }
      });
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <SetupFormStatus updateCardAddStatus={updateCardAddStatus} />
      </Elements>
    </>
  );
};
export default AddCardStatus;
