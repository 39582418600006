import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import ZPictureUploader from "../../components/UI/ZPictureUploader";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import PictureUploader from "../../components/uploader/PictureUploader";
import regsuccess from "../../assets/images/regsuccess.svg";
import {
  StatusRequest,
  VerifyIdentityRequest,
} from "../../services/UserService";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import ZDatePicker from "../../components/form/ZDatePicker";
import { DOCUMENT_TYPES_URL } from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import Select from "react-select";
import { useAuthContext } from "../../redux/auth-context";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";

const VerifyIdentity = () => {
  const ctx = useAuthContext();
  const [docs, setDocs] = useState([]);
  const [accountStatusData, setAccountStatusData] = useState();
  const [uploaded, setUploaded] = useState(false);
  const [serverErrors, setServerErrors] = useState();

  let navigate = useNavigate();

  const verifyUserIdentity = (values) => {
    let payload = {
      dateOfBirth: values.dob,
      licenseNo: values.license_no,
      base64documentData: values.base64Photo,
    };

    ctx.showProgress(true);

    VerifyIdentityRequest(payload)
      .then((res) => {
        setUploaded(true);
        StatusRequest()
          .then((data) => {
            setAccountStatusData(data);
            ctx.showProgress(false);
          })
          .catch((error) => {
            ctx.showProgress(false);
            navigate("/error");
          });
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;

        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }
        const json = JSON.parse(e.message);

        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }
        }

        console.log(e);
      });
  };

  const [pictureError, setPictureError] = useState();

  const initialValue = {
    dob: "",
    license_no: "",
    base64Photo: "",
  };

  const schema = Yup.object().shape({
    license_no: Yup.string()
      .min(4, "Please enter valid License number")
      .max(12, "Document number cannot be more than 12 characters")
      .required("Document number is required")
      .matches(/^[a-zA-Z0-9_.-]*$/, "Only alpha numeric charaters are allowed"),
    dob: Yup.date().required("Date of birth is required"),
    base64Photo: Yup.string().required("Photograph is required"),
    document: Yup.string().required("Document type is required"),
  });

  useEffect(() => {
    ctx.showProgress(true);
    LibraryService(DOCUMENT_TYPES_URL)
      .then((res) => {
        let myDocs = [];
        res.map((item) => {
          myDocs.push({ value: item.id, label: item.key });
        });
        setDocs(myDocs);
      })
      .catch((error) => {
        console.log(error);
      });
    StatusRequest()
      .then((data) => {
        setAccountStatusData(data);
        console.log(data);
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
        navigate("/error");
      });
  }, []);

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-lg-3">
                <AccountMenu identityActive={true} />
              </div>

              {accountStatusData == undefined ||
              accountStatusData?.documentStatus == undefined ||
              (accountStatusData?.documentStatus == "EMPTY" && !uploaded) ? (
                <div className="offset-lg-1 col-lg-8">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="h2 font-weight-bold purple-500">
                        Upload your ID for Verification
                      </h2>
                    </div>
                    <div className="col-md-12 mb-5">
                      <BHotrizontalProgress progress={33} />
                    </div>
                    <div className="col-md-6 order-2 order-md-1">
                      <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        initialValues={initialValue}
                        validationSchema={schema}
                        onSubmit={(values) => {
                          verifyUserIdentity(values);
                        }}
                      >
                        {({
                          handleSubmit,
                          isSubmitting,
                          errors,
                          validateForm,
                          setFieldValue,
                        }) => (
                          <form id="passportInfoForm">
                            <div className="js-form-message mb-4">
                              <label class="form-label">Date of Birth</label>
                              <ZDatePicker
                                maxDate={new Date()}
                                errors={errors}
                                serverError={serverErrors && serverErrors.dob}
                                className="form-control"
                                placeholderText="Date of Birth"
                                name="dob"
                              />
                            </div>
                            <div className="js-form-message mb-4">
                              <label class="form-label">
                                Select Document Type
                              </label>
                              <Select
                                placeholder="Select Document Type"
                                name="document"
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    border: "1px solid #339966",
                                    borderRadius: "4px",
                                    minHeight: "45px",
                                  }),
                                }}
                                defaultTouch
                                serverError={
                                  serverErrors && serverErrors.document
                                }
                                options={docs}
                                onChange={(e) => {
                                  setFieldValue("document", e.value);
                                }}
                              />
                              {errors["document"] && (
                                <div
                                  className="invalid-feedback pl-3"
                                  style={{ display: "block" }}
                                >
                                  {errors["document"]}
                                </div>
                              )}
                            </div>
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                type="text"
                                name="license_no"
                                component={ZInputComponent}
                                placeholder="Document Number"
                                label="Document Number"
                                serverError={
                                  serverErrors && serverErrors.license_no
                                }
                              />
                            </div>
                            <div className="js-form-message mb-4 text-center">
                              <PictureUploader
                                label={"Upload a photo of your ID"}
                                errorEvent={(errors) => {
                                  setPictureError(errors);
                                }}
                                fileSelectedEvent={(event, file) => {
                                  setFieldValue(
                                    "base64Photo",
                                    file.data_url.split(",")[1]
                                  );
                                  console.log("file selected", file);
                                  event.proceed(file);
                                }}
                              />
                              {errors && errors.base64Photo && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.base64Photo}
                                </div>
                              )}
                            </div>
                            <div className="js-form-message mb-4">
                              <button
                                className="btn-block btn btn-primary-dark-w px-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let res = await validateForm();
                                  if (res && Object.values(res)?.length > 0) {
                                  }
                                  handleSubmit(e);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <div className="col-md-6 order-1">
                      <h4 className="h4 font-weight-bold">
                        Why do I need to verify my ID to Bid?
                      </h4>
                      <h4 className="h5 font-weight-bold">
                        How secure is this verification process?
                      </h4>
                      <p className="instructions-desc">
                        We need to verify you are of legal age (18+) to bid on
                        BidlyAuction and a US resident. ID’s are also a
                        requirement of some of our government sellers.
                      </p>
                      <h4 className="h5 font-weight-bold">
                        How secure is this verification process?
                      </h4>
                      <p className="instructions-desc">
                        All of your data will be kept on the same server as your
                        BidlyAuction account. Our servers are secured by the
                        best up-to-date security standards.
                      </p>
                    </div>
                  </div>
                </div>
              ) : accountStatusData?.documentStatus == "VERIFIED" ? (
                <>
                  <div className="offset-lg-1 col-lg-8 pt-10 text-center">
                    <img
                      src={regsuccess}
                      className="notification-logo img-responsive"
                      alt="envelop img"
                    />
                    <h4 className="h5 font-weight-bold mt-5">
                      Identity Document Verified Successfully by BidlyAuction.
                    </h4>
                  </div>
                </>
              ) : accountStatusData?.documentStatus == "SUBMITTED" ? (
                <>
                  <div className="offset-lg-1 col-lg-8 pt-10 text-center">
                    <img
                      src={regsuccess}
                      className="notification-logo img-responsive"
                      alt="envelop img"
                    />
                    <h4 className="h5 font-weight-bold mt-5">
                      Identity Document Successfully uploaded, please wait for
                      verification & approval.
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyIdentity;
