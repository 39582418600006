import React, { useEffect, useState } from "react";
import classes from "./AuctionBlock.module.css";
import clockImg from "../../assets/images/small-clock.svg";
import { formatUSD } from "../../services/Utilities";
import { Link } from "react-router-dom";

const AuctionBlock = (props) => {
  const { auctionType, heading, description, price, time, lot, image, itemId } =
    props;
  const [auctionTime, setAuctionTime] = useState();

  useEffect(() => {
    setAuctionTime(time);
  }, [time]);

  return (
    <>
      <div className={`col-xl-3 col-lg-4 col-md-6 mb-5`}>
        <div className={classes.mainPanel}>
          <div className={classes.blockOne}>
            <Link to={`/auction/${itemId}`}>
              <div
                className={classes.imagePanel}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              ></div>
            </Link>
            <div className={classes.textContainer}>
              <div className={classes.auctionType}>
                <p>{auctionType}</p>
              </div>
              <div className={classes.headingPanel}>
                <h2 title={heading}>{heading}</h2>
              </div>
              <div className={classes.descPanel}>{description}</div>
              <div className={classes.timePanel}>
                <span>
                  <img src={clockImg} />
                </span>
                <span>{auctionTime}</span>
              </div>
              <div className={classes.lot}>{lot}</div>
            </div>
          </div>
          {price && auctionType != "make an offer" && (
            <>
              <div className={classes.line}></div>
              <div className={classes.blockTwo}>
                <div className={classes.pricePanel}>
                  USD <span>{formatUSD(price)}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AuctionBlock;
