import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate, Link } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import * as Yup from "yup";
import LinkButton from "../../components/button/LinkButton";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Form, Formik } from "formik";
import SearchSelect from "../../components/form/SearchSelect";
import {
  STATE_LIBRARY_URL,
  COUNTRIES_LIBRARY_URL,
  STATE_CITIES_LIBRARY_URL,
} from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import { useAuthContext } from "../../redux/auth-context";
import { UpdateAddressRequest } from "../../services/UserService";
import Alert from "../../components/UI/Alert";
import Select from "react-select";

const AddAgencyAddress = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState();

  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);

  useEffect(() => {
    ctx.showProgress(true);
    LibraryService(COUNTRIES_LIBRARY_URL)
      .then((res) => {
        let myCountires = [];
        res.map((item) => {
          myCountires.push({ value: item.key, label: item.value });
        });
        setCountries(myCountires);
      })
      .catch((error) => {
        console.log(error);
      });

    LibraryService(STATE_LIBRARY_URL)
      .then((res) => {
        let myStates = [];
        res.map((item) => {
          myStates.push({ value: item.stateId, label: item.name });
        });
        setStates(myStates);
      })
      .catch((error) => {
        console.log(error);
      });

    ctx.showProgress(false);
  }, []);

  const findCitiesByState = async (value) => {
    ctx.showProgress(true);

    const citiesURL = `${STATE_CITIES_LIBRARY_URL}?state=${value}`;

    LibraryService(citiesURL)
      .then((res) => {
        let myCities = [];
        res.map((item) => {
          myCities.push({ value: item.id, label: item.key });
        });
        setCities(myCities);
      })
      .catch((error) => {
        console.log(error);
      });

    ctx.showProgress(false);
  };

  const schema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Zip Code is required"),
  });

  const initialValue = {
    country: "",
    state: "",
    city: "",
    postalCode: "",
  };

  const addNewAddress = (values) => {
    let payload = {
      country: values.country,
      state: values.state,
      city: values.city,
      postalCode: values.postalCode,
    };

    ctx.showProgress(true);

    UpdateAddressRequest(payload)
      .then((data) => {
        console.log(data);
        ctx.showProgress(false);
        setUpdatedSuccessfully(true);
        navigate("/add-address-info", {
          state: { addressAddStatus: true },
        });
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        console.log(json);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          } else if (json.data.status === "NOT_FOUND") {
            setServerErrors(json.data.message);
          }
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-3 col-md-3">
          <AccountMenu accountInfoActive={true} subMenu={"address"} />
          </div>
          <div className="offset-lg-1 col-lg-7 col-md-7">
            <div className="row">
              <div className="col-md-12">
                <h2 className="h3 font-weight-bold purple-500">Addresses</h2>
              </div>
              <div className="col-md-12 mb-4">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="col-md-12">
                <p className="pg-2 instructions-desc">
                  You may add as many addresses as you like. If you have
                  multiple addresses please be sure to designate a contact
                  address. You will receive correspondence at this address.
                </p>
              </div>

              <div className="col-md-6">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    console.log(values);
                    addNewAddress(values);
                  }}
                >
                  {({
                    handleSubmit,
                    isSubmitting,
                    errors,
                    validateForm,
                    setFieldValue,
                  }) => (
                    <form id="passportInfoForm">
                      {updatedSuccessfully && (
                        <Alert
                          type="success"
                          children="Address updated successfully."
                        />
                      )}
                      {serverErrors && (
                        <Alert
                          type="error"
                          children="Please update your account information first."
                        />
                      )}
                      <div className="js-form-message mb-3">
                        <label className="form-label">
                          Country<span className="color-red"> *</span>
                        </label>

                        <Select
                          placeholder="Select country"
                          name="country"
                          options={countries}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              border: "1px solid #339966",
                              borderRadius: "4px",
                              minHeight: "45px",
                            }),
                          }}
                          defaultTouch
                          onChange={(e) => {
                            setFieldValue("country", e.value);
                          }}
                        />
                        {errors["country"] && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors["country"]}
                          </div>
                        )}
                      </div>

                      <div className="js-form-message mb-3">
                        <label className="form-label">
                          State<span className="color-red"> *</span>
                        </label>
                        <SearchSelect
                          placeholder="Select state"
                          name="state"
                          options={states}
                          onChange={(e) => {
                            setFieldValue("state", e.value);
                            findCitiesByState(e.value);
                          }}
                        />
                        {errors["state"] && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors["state"]}
                          </div>
                        )}
                      </div>

                      <div className="js-form-message mb-3">
                        <label className="form-label">
                          City<span className="color-red"> *</span>
                        </label>
                        <SearchSelect
                          placeholder="Select city"
                          name="city"
                          options={cities}
                          onChange={(e) => {
                            setFieldValue("city", e.value);
                          }}
                        />
                        {errors["city"] && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors["city"]}
                          </div>
                        )}
                      </div>
                      <div className="js-form-message mb-3">
                        <Field
                          className="form-control"
                          type="text"
                          name="postalCode"
                          required="true"
                          component={ZInputComponent}
                          placeholder="Enter postal code"
                          label="Zip Code"
                          serverError={serverErrors && serverErrors.postalCode}
                        />
                      </div>

                      <div className="row">
                        <div className="d-inline p-3 col-md-6">
                          <Link
                            className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                            type="submit"
                            onClick={async (e) => {
                              e.preventDefault();
                              let res = await validateForm();
                              console.log(res);
                              console.log(res);
                              if (res && Object.values(res)?.length > 0) {
                              }
                              handleSubmit(e);
                            }}
                          >
                            save
                          </Link>
                        </div>
                        <div className="d-inline p-3 col-md-6 order-md-first">
                          <Link
                            className="btn-block btn btn-outline-primary px-5 text-transform-upper"
                            type="button"
                            onClick={async (e) => {
                              e.preventDefault();
                              navigate("/add-address-info");
                            }}
                          >
                            cancel
                          </Link>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default AddAgencyAddress;
