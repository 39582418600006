import React from "react";
import { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import PaymentFormStatus from "./PaymentFormStatus";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { savePaymentCompleted } from "../../services/MyBidsService";
import { isJSON, isStopFlowConditionMet } from "../../services/Utilities";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY);

const CardPaymentCompleted = () => {
  const navigate = useNavigate();

  const { creditCardItemTracking } = useSelector(
    ({ auth }) => ({
      creditCardItemTracking: auth.creditCardItemTracking,
    }),
    shallowEqual
  );

  const updatePaymentStatusHandler = (paymentIntent) => {
    console.log(paymentIntent);

    console.log(creditCardItemTracking);

    if (paymentIntent.status == "succeeded") {
      savePaymentCompleted(
        {
          customer: creditCardItemTracking.billingInfo,
          paymentId: paymentIntent.id,
        },
        creditCardItemTracking.itemId
      )
        .then((resp) => {
          navigate("/bid-card-payment-status", {
            state: {
              status: "success",
              receiptURL: resp.receiptURL,
              from: creditCardItemTracking.from,
            },
          });
        })
        .catch((e) => {
          if (!isJSON(e.message)) {
            navigate("/error");
            return;
          }

          if (isStopFlowConditionMet(e.message)) return;

          const json = JSON.parse(e.message);
          if (json.status === "BAD_REQUEST") {
            if (json.data.status === "VALIDATION_FAILED") {
              navigate("/error");
            }
          }
        });
    } else {
      navigate("/bid-card-payment-status", {
        state: { status: "error", from: creditCardItemTracking.from },
      });
    }
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentFormStatus updatePaymentStatus={updatePaymentStatusHandler} />
      </Elements>
    </>
  );
};

export default CardPaymentCompleted;
