import { SERVICE_URL } from "../Constants";
import authenticatedFetch from "./fetch";
import { simpleFetch } from "./fetch";
import store from "../redux/store";
import { actionTypes } from "../pages/_redux/authRedux";

export const BookedSlots = async (requestBody) => {
  const response = await simpleFetch(
    `${SERVICE_URL}user/schedule-demo/booked-slots`,
    {
      method: "POST",
      body: JSON.stringify(requestBody),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const ScheduleADemoRequest = async (requestBody) => {
  const response = await simpleFetch(
    `${SERVICE_URL}user/schedule-demo/confirm-slot`,
    {
      method: "POST",
      body: JSON.stringify(requestBody),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};
