import React from "react";
import rightArrowIcon from "../../assets/images/arrow-forward.svg";
import classes from "./MessagesTable.module.css";

const MessageTableTile = ({
  id,
  name,
  subject,
  dateTime,
  isRead = false,
  onClick = (id) => {},
}) => {
  return (
    <>
      <div
        className={`row ${isRead ? classes.read : ""} ${classes.row}`}
        onClick={() => {
          onClick(id);
        }}
      >
        <div className={`col-auto ${classes.icon}`}>
          <img src={rightArrowIcon} />
        </div>
        <div className={`col ${classes.receipant}`}>{name}</div>
        <div className={`col ${classes.subject}`}>{subject}</div>
        <div className={`col-auto ${classes.date}`}>{dateTime}</div>
      </div>
    </>
  );
};

export default MessageTableTile;
