import React from "react";
import classes from "./StepHeading.module.css";

const PlaceholderHeading = ({ title, step, children, headingSize = "lg" }) => {
  return (
    <>
      <div className="row d-flex">
        <div className="col-auto flex-grow-1">
          <h2
            className={`${headingSize === "lg" ? "h4" : "h5"} font-weight-bold`}
          >
            {title}
          </h2>
        </div>
        <div className={`col-md-8 col-xs-12 ${classes.step}`}>{children}</div>
      </div>
    </>
  );
};

export default PlaceholderHeading;
