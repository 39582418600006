import React, { useEffect, useState, useCallback } from "react";
import GoogleRecaptchaComponent from "./GoogleRecaptchaComponent";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const Login = () => {
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

  return (
    <div className="">
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        <GoogleRecaptchaComponent />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Login;
