import React, { useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import LocationMap from "./LocationMap";

const LocationPicker = ({
  lat,
  lng,
  locationChanged = (lat, lng) => {},
  update = false,
  initCompleted = () => {},
  updateCompleted = () => {},
}) => {
  useEffect(() => {
    updateCompleted();
  }, [update]);

  const locationMap = (
    <LocationMap
      lat={lat}
      lng={lng}
      locationChanged={locationChanged}
      initCompleted={initCompleted}
    />
  );

  const render = (status) => {
    switch (status) {
      case Status.SUCCESS:
        return locationMap;
    }
  };

  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
      {locationMap}
    </Wrapper>
  );
};

export default React.memo(
  LocationPicker,
  (prevProps, nextProps) => !prevProps.update && !nextProps.update
);
