import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import LinkButton from "../../components/button/LinkButton";
import classes from "./BigBanner.module.css";

const BigBanner = () => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
    }),
    shallowEqual
  );

  return (
    <>
      <div className="mb-5">
        <div className={`bg-img-hero ${classes.bannerImg}`}>
          <div className="container min-height-420 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
            <div className={classes.textContainer}>
              <h2 className={classes.heading}>Your Next Auction Adventure </h2>
              <p className={classes.para}>
                Experience the thrill of bidding with us, where every bid
                becomes a chapter in your success story of unbeatable savings.
              </p>
              {!isAuthorized && (
                <div className={`${classes.buttons} d-none d-md-inline-flex`}>
                  <LinkButton to="/account-type" type="secondary">
                    SIGN UP
                  </LinkButton>
                  <LinkButton type="primary" to="/login">
                    SIGN IN
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BigBanner;
