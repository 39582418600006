import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Field, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import {
  ALL_CATEGORY_SUBCATEGORY_COUNT_LIBRARY_URL,
  STATE_LIBRARY_URL,
  SUB_CATEGORY_LIBRARY_URL,
  COUNTRIES_LIBRARY_URL,
  SELLER_URL,
  FILTER_BASE_URL,
} from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import "react-datepicker/dist/react-datepicker.css";
import ZDatePicker from "../../components/form/ZDatePicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SearchSelect from "../../components/form/SearchSelect";
import ASLabel from "./ASLabel";
import classes from "./Search.module.css";
import { useAuthContext } from "../../redux/auth-context";
import {
  sortOption,
  priceFrom,
  priceTo,
  readiusOptions,
} from "./SearchContant";
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import { Select } from "../../components/Select";
import { categoryParser, countryParser } from "./LibraryParser";

const AdvanceSearch = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [seller, setSeller] = useState([]);
  const [radius, setRadius] = useState(readiusOptions);

  const initialValue = { category: "" };

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const parseArray = (array, type) => {
    let parseArray = [];

    switch (type) {
      case 1:
        array.map((item) => {
          parseArray.push({
            value: item.id,
            label: item.key.replace(/_/g, " ").toProperCase(),
          });
        });
        break;
      case 2:
        array.map((item) => {
          parseArray.push({
            value: item.key,
            label: item.value.replace(/_/g, " ").toProperCase(),
          });
        });
        break;
      case 3:
        array.map((item) => {
          parseArray.push({
            value: item.stateId,
            label: item.name.replace(/_/g, " ").toProperCase(),
          });
        });
        break;
      default:
        break;
    }

    return parseArray;
  };

  useEffect(() => {
    const asyncFn = async () => {
      try {
        ctx.showProgress(true);
        
        let categories = await LibraryService(ALL_CATEGORY_SUBCATEGORY_COUNT_LIBRARY_URL);
        categories && setCategories(categoryParser(categories, 1));

        let countries = await LibraryService(COUNTRIES_LIBRARY_URL);
        countries && setCountries(parseArray(countries, 2));

        let states = await LibraryService(`${FILTER_BASE_URL}/states`);
        states && setStates(countryParser(states));

        let sellers = await LibraryService(`${FILTER_BASE_URL}/seller`);
        sellers && setSeller(countryParser(sellers));

        ctx.showProgress(false);
      } catch (error) {
        ctx.showProgress(false);
      }
    };
    asyncFn();
  }, []);

  const findSubCategories = async (value) => {
    ctx.showProgress(true);

    let categories = await LibraryService(SUB_CATEGORY_LIBRARY_URL + value);
    categories && setSubCategories(parseArray(categories, 1));

    ctx.showProgress(false);
  };

  const handleSearch = (values) => {
    let filter = "?advance_search&";
    let mEndDate = values.endDate
      ? moment(values.endDate).format("YYYY-MM-DD")
      : null;
    let mStartDate = values.startDate
      ? moment(values.startDate).format("YYYY-MM-DD")
      : null;
    let mFromDate = values.fromDate
      ? moment(values.fromDate).format("YYYY-MM-DD")
      : null;
    let toDate = values.toDate
      ? moment(values.toDate).format("YYYY-MM-DD")
      : null;

    filter += values.title ? "title=" + values.title + "&" : "";
    filter += values.listing ? "listing=" + values.listing + "&" : "";
    filter += values.category ? "category=" + values.category + "&" : "";
    filter += values.subcategory
      ? "sub_category=" + values.subcategory + "&"
      : "";
    filter += values.priceFrom ? "priceFrom=" + values.priceFrom + "&" : "";
    filter += values.priceTo ? "priceTo=" + values.priceTo + "&" : "";
    filter += values.state ? "state=" + values.state + "&" : "";
    filter += values.zip ? "zip=" + values.zip + "&" : "";
    filter += values.radius ? "radius=" + values.radius + "&" : "";
    filter += values.seller ? "seller=" + values.seller + "&" : "";
    filter += mEndDate ? "endDate=" + mEndDate + "&" : "";
    filter += mStartDate ? "startDate=" + mStartDate + "&" : "";
    filter += mFromDate ? "fromDate=" + mFromDate + "&" : "";
    filter += toDate ? "toDate=" + toDate + "&" : "";
    filter += values.sort ? "sort=" + values.sort : "";

    navigate("/search-result", { state: { filter: filter } });
  };

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="mb-3">
          <Breadcrumb
            items={[
              <BreadcrumbItem title="Home" to="/" />,
              <BreadcrumbItem title="Advanced Search" />,
            ]}
          />
        </div>
        <div className="row">
          <div className="offset-lg-3 offset-md-3 col-lg-6 col-md-6 col-sm-12">
            <h3
              className={`nav-classic ${classes.searchSection} ${classes.asSearchTitle} mb-0 pb-2 font-size-25 section-title text-center`}
            >
              Advanced Search
            </h3>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={initialValue}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleSubmit, errors, setFieldValue, resetForm }) => (
                <form id="advanceSearchForm">
                  <div className="row">
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel label={"Keyword"} icon={"keyword"} />
                    </div>
                    <div className="col-md-12 mt-2">
                      <Field
                        type="text"
                        name="title"
                        component={ZInputComponent}
                        placeholder="Search by Keyword"
                      />
                    </div>
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel label={"Listing"} icon={"listing"} />
                    </div>
                    <div className="col-md-12 mt-2">
                      <Field
                        type="text"
                        name="listing"
                        component={ZInputComponent}
                        placeholder="Search by listing number"
                      />
                    </div>
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel label={"Category"} icon={"category"} />
                    </div>
                    <div className="col-md-12">
                      <p className={`$${classes.labelP} mb-0`}>
                        Results will only show for items in your selected
                        category.
                      </p>
                      <p className={`$${classes.labelP} mb-0`}>
                        Search results will show for all categories if one is
                        not selected.
                      </p>
                    </div>
                    <div className="col-md-12 mt-2">
                      <Select
                        placeholder="Search by category"
                        name="category"
                        onChange={(e) => {
                          setFieldValue("category", e.target.value);
                          findSubCategories(e.target.value);
                        }}
                      >
                        {categories.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-12 mt-2">
                      <Select
                        placeholder="Search by sub-category"
                        name="subcategory"
                        onChange={(e) => {
                          setFieldValue("subcategory", e.target.value);
                        }}
                      >
                        <option value={""} disabled={"disabled"}>{"Search by sub-category"}</option>
                        {subcategories.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel label={"Price Range"} icon={"price"} />
                    </div>
                    <div className="col-md-6 mt-2">
                      <Select
                        placeholder="Price From"
                        name="priceFrom"
                        onChange={(e) => {
                          setFieldValue("priceFrom", e.target.value);
                        }}
                      >
                        {priceFrom.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-6 mt-2">
                      <Select
                        placeholder="Price To"
                        name="priceTo"
                        onChange={(e) => {
                          setFieldValue("priceTo", e.target.value);
                        }}
                      >
                        {priceTo.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel
                        label={"Location (country and state)"}
                        icon={"location"}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <SearchSelect
                        placeholder="Country"
                        name="country"
                        defaultValue={{ label: "United State of America", value: "usa" }}
                        options={countries}
                        onChange={(e) => {
                          setFieldValue("country", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <Select
                        placeholder="State"
                        name="state"
                        onChange={(e) => {
                          setFieldValue("state", e.target.value);
                        }}
                      >
                        {states.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel label={"Postal Code and Radius"} icon={"zip"} />
                    </div>
                    <div className="col-md-6 mt-2">
                      <Field
                        type="text"
                        name="zip"
                        component={ZInputComponent}
                        placeholder="Zip Code"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <Select
                        placeholder="Radius"
                        name="radius"
                        onChange={(e) => {
                          setFieldValue("radius", e.target.value);
                        }}
                      >
                        {readiusOptions.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel label={"Seller"} icon={"seller"} />
                    </div>
                    <div className="col-md-12 mt-2">
                      <Select
                        placeholder="Select Seller"
                        name="seller"
                        onChange={(e) => {
                          setFieldValue("seller", e.target.value);
                        }}
                      >
                        {seller.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel
                        label={"Date (Start Date & End Date)"}
                        icon={"date"}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <ZDatePicker
                        errors={errors}
                        placeholderText="Start Date"
                        maxDate={new Date()}
                        name="startDate"
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <ZDatePicker
                        errors={errors}
                        placeholderText="Ending Date"
                        name="endDate"
                        minDate={new Date()}
                      />
                    </div>
                    
                    {/*<div className="col-md-12 mt-2">
                      <label className="form-label pl-3">
                        Or Search by Ending Date Range{" "}
                      </label>
                    </div>
                     <div className="col-md-12">
                      <ZDatePicker
                        errors={errors}
                        placeholderText="Date From"
                        name="fromDate"
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <ZDatePicker
                        errors={errors}
                        placeholderText="To Date"
                        name="toDate"
                      />
                    </div> */}
                    {/*
                    <div className="col-md-12 mt-5 d-flex text-center">
                      <ASLabel label={"Sorting"} icon={"sort"} />
                    </div>
                     <div className="col-md-12 mt-2">
                      <SearchSelect
                        placeholder="Sort By"
                        name="sort"
                        options={sortOption}
                        onChange={(e) => {
                          setFieldValue("sort", e.value);
                        }}
                      />
                    </div> */}
                    <div className="col-md-6 mt-5">
                      <button
                        className="btn-block btn btn-outline-primary px-5"
                        type="reset"
                        onClick={() => resetForm()}
                      >
                        Clear
                      </button>
                    </div>
                    <div className="col-md-6 mt-5">
                      <button
                        className="btn-block btn btn-primary-dark-w px-5"
                        type="submit"
                        onClick={async (e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdvanceSearch;
