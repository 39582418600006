import { combineReducers } from "redux";
import * as auth from "../pages/_redux/authRedux";
import * as wizard from "../pages/_redux/wizardRedux";
import { utilSlice } from "../pages/_redux/utilRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  util: utilSlice.reducer,
  wizard: wizard.reducer,
});
