import React from "react";
import classes from "./AddressCard.module.css";
import deleteIcon from "../../assets/images/cross-icon.svg";
import addressCardImage from "../../assets/images/address-card.svg";

const AddressCard = ({
  title = true,
  applicantName,
  address,
  isRemove = true,
  onRemove = () => {},
}) => {
  return (
    <div
      className={classes.cardDiv}
      style={{ backgroundImage: `url(${addressCardImage})` }}
    >
      <div className={classes.actionBar}>
        <div className={classes.info}>
          <h3 className={classes.heading}>{title}</h3>
        </div>
        {isRemove && (
          <div className={classes.actionButton}>
            <span className={classes.remove} onClick={onRemove}>
              <img src={deleteIcon} />
            </span>
          </div>
        )}
      </div>
      <div className={classes.body}>{applicantName}</div>
      <div className={classes.body}>
        {" "}
        <p className="pg-2 mb-0 mr-6">{address}</p>{" "}
      </div>
    </div>
  );
};

export default AddressCard;
