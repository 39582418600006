import React from "react";
import classes from "./AuctionsPanel.module.css";
import TitleUnderline from "../../components/common/TitleUnderline";
import { Link } from "react-router-dom";

const AuctionsPanel = (props) => {
  const { title, children, viewAllClicked } = props;
  return (
    <>
      <div className="mb-5">
        <div className="d-flex align-items-center justify-content-space-between">
          <h2 className={classes.title}>{title}</h2>
          <a
            className={classes.viewAll}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewAllClicked();
            }}
          >
            View All
          </a>
        </div>
        <TitleUnderline />
      </div>

      <div className={`row mb-5`}>{children}</div>
    </>
  );
};

export default AuctionsPanel;
