import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ZImageCarousal = ({ items }) => {
  const [image, setImage] = useState([]);
  const [showVideo, setShowVideo] = useState([]);

  const toggleShowVideo = (status) => {
    setShowVideo();
  }

  const renderVideo = (item) => {
    return (
      <div>
        {showVideo ? (
          <div className="video-wrapper">
            <button className="close-video" onClick={toggleShowVideo(false)} />
            <iframe
              title="sample video"
              width="560"
              height="315"
              src={item.embedUrl}
              style={{ border: "none" }}
              allowFullScreen
            />
          </div>
        ) : (
          <>
            <button className="play-button" onClick={toggleShowVideo(true)} />
            <img
              alt="sample video cover"
              className="image-gallery-image"
              src={item.original}
            />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {item.description}
              </span>
            )}
          </>
        )}
      </div>
    );
  };

  function getIDfromURL(url) {
    const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  
    const match = url.match(regExp);
  
    if (match && match[2].length === 11) {
      return match[2];
    }
  
    console.log('The supplied URL is not a valid youtube URL');
  
    return '';
  }

  useEffect(() => {
    let images = [];
    items.forEach(element => {
      if(element.mediaType == 'GALLERY')
        images.push({original: element.link, thumbnail: element.link})
      else if(element.mediaType == 'VIDEO_LINK')
        images.push({embedUrl: element.link.replace("watch?v=", "embed/"), original: element.link,thumbnail: `https://img.youtube.com/vi/${getIDfromURL(element.link)}/0.jpg`,  renderItem: renderVideo.bind(this)})
    });
    setImage(images);
  }, [items]);

  return <ReactImageGallery items={image} />;
};

export default ZImageCarousal;
