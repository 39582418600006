import React from "react";
import classes from "./dashboard.module.css";
import { Link } from "react-router-dom";

const ListingInfoBlock = ({ label, value, url }) => {
  return (
    <div className={classes.listingInfoBlock}>
      <div className={`col-9 ${classes.label}`}>{label}</div>
      <div className={`col-3 text-center ${classes.value}`}><Link to={url}>{value}</Link></div>
    </div>
  );
};

export default ListingInfoBlock;
