import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import Layout from "../../layout/Layout";
import publicimg from "../../assets/images/public_.svg";
import qualify from "../../assets/images/qualify.svg";
import { useAuthContext } from "../../redux/auth-context";

const AccountType = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const initialValue = {
    accountType: "",
  };

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-12">
            <div className="col-md-12">
              <section className="section-onsale-product ">
                <h2 className="title-2 text-center">
                  Choose Your Account Type
                </h2>
                <p className="reg-account-type-text text-center p-2">
                  BidlyAuction offers surplus bidding for the public on items
                  from <br></br>governments, schools, and related agencies.
                </p>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5 p-2">
                    <Card className="border-green">
                      <Card.Body>
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="title-1 text-center mt-2">
                              I want to Bid
                            </h2>
                          </div>
                          <div className="col-md-12">
                            <h2 className="text-center pg-2 mt-1 mb-2">
                              {/* <img src={publicimg} className="img-responsive mr-1 mb-1" alt="envelop img"/> */}
                              Open to the Public
                            </h2>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <BHotrizontalProgress progress={0} />

                          <p
                            className="pg mt-4"
                            style={{ marginBottom: "31px" }}
                          >
                            Bidding on BidlyAuction is open to the public.
                            Anyone can bid unless stated otherwise.<br></br>
                            Valid US Credit/Debit Card needed to
                            place bid. The Card is for the buyers fee.
                          </p>

                          <p className="pg font-weight-bold mb-0">
                            If you are a registered seller with BidlyAuction and
                            wants to bid, please register again as a bidder.
                          </p>
                        </div>
                      </Card.Body>
                      <Card.Footer className="card-custom-footer">
                        <div className="col-md-12  pl-3 pr-3 pb-2">
                          <Link
                            className="btn-block btn btn-primary-dark-w text-transform-upper"
                            onClick={(e) => {
                              e.preventDefault();

                              navigate("/register", {
                                state: { accountType: "BIDDER" },
                              });
                            }}
                          >
                            Create A Bidder Account
                          </Link>
                        </div>
                      </Card.Footer>
                    </Card>
                  </div>

                  <div className="col-md-5 p-2">
                    <Card className="border-purpel">
                      <Card.Body style={{ height: "343px" }}>
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="title-1 text-center mt-2">
                              I want to Sell
                            </h2>
                          </div>
                          <div className="col-md-12">
                            <h2 className="text-center pg-2 mt-1 mb-2">
                              {/* <img src={qualify} className="img-responsive mr-1 mb-1" alt="envelop img"/> */}
                              Open to Public & Agencies
                            </h2>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <BHotrizontalProgress progress={0} />
                          <p className="mt-4 mb-2">
                            Join BidlyAuction now and unlock your selling
                            potential, where anyone, whether an individual <br></br>
                            or business, can showcase their items to a <br></br>
                            global audience. 
                          </p>
                          {/* <ul className="at-ul ml-3 font-weight-bold">
                            <li>Municipalities, Counties, States</li>
                            <li>Police, Fire, EMS</li>
                            <li>Utilities and Authorities</li>
                            <li>Schools, Colleges, Universities</li>
                            <li>Other related agencies</li>
                          </ul> */}
                          <p className="pg font-weight-bold mb-0">
                            <br></br>
                            Not sure if you qualify?{" "}
                            <Link to="/contact-us">Ask us</Link>
                          </p>
                        </div>
                      </Card.Body>
                      <Card.Footer className="card-custom-footer">
                        <div className="col-md-12 pl-3 pr-3 pb-2">
                          <Link
                            className="btn-block btn btn-secondary text-transform-upper"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/seller-account-type", {
                                state: { accountType: "SELLER" },
                              });
                            }}
                          >
                            Apply for Seller Account
                          </Link>
                        </div>
                      </Card.Footer>
                    </Card>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AccountType;
