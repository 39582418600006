import React from "react";
import classes from "./FeaturedCategory.module.css";

const FeaturedCategory = ({ title, imageData, onClick = () => {} }) => {
  return (
    <>
      <div className={classes.box} onClick={onClick}>
        <div
          className={classes.image}
          style={{
            backgroundImage: `url('${imageData?.documentUrl}')`,
          }}
        ></div>
        <div className={classes.name}>{title}</div>
      </div>
    </>
  );
};

export default FeaturedCategory;
