//const BASE_URL = `http://localhost:9090`;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// export const TIMEZONE_NAME = "US/Central";
export const TIMEZONE_NAME = "America/Chicago";
// export const TIMEZONE_NAME = "America/New_York";
export const DATETIME_SHOW_FORMAT = "MM/DD/YYYY hh:mm A";
export const DATE_SHOW_FORMAT = "MM/DD/YYYY";

export const SERVICE_URL = `${BASE_URL}/`;

export const Verify_Identiy_SERVICE_URL = `${BASE_URL}/user/verify`;
export const VERIFY_EMAIL_SERVICE_URL = `${BASE_URL}/user/check-email`;
export const CREATE_ACCOUNT_SERVICE_URL = `${BASE_URL}/user/create-account`;
export const CREATE_ACCOUNT_SELLER_SERVICE_URL = `${BASE_URL}/user/create-account-seller`;
export const VERIFY_LINK_SERVICE_URL = `${BASE_URL}/user/verify-link`;
export const SEARCH_URL = `${BASE_URL}/auction/public/`;
export const ITEM_URL = `${BASE_URL}/auction/public/item/`;
export const ITEM_TERMS_AND_CONDITIONS_URL = `${BASE_URL}/auction/public/item/seller-terms/`;
export const PUBLIC_ITEM_URL = `${BASE_URL}/auction/public/auction-item/`;

export const USER_STATUS_SERVICE_URL = `${BASE_URL}/user/status`;

export const FILTER_BASE_URL = `${BASE_URL}/library/filter`;
export const STATE_LIBRARY_URL = `${BASE_URL}/library/states/`;
export const COUNTRY_ITEM_COUNT_LIBRARY_URL = `${BASE_URL}/library/country/all/count`;
export const STATE_ITEM_COUNT_LIBRARY_URL = `${BASE_URL}/library/states/all/count`;
export const CITY_ITEM_COUNT_LIBRARY_URL = `${BASE_URL}/library/city/minified/count`;
export const CATEGORY_LIBRARY_URL = `${BASE_URL}/library/categories/`;
export const SUB_CATEGORY_LIBRARY_URL = `${BASE_URL}/library/subcategories/`;
export const CONDITION_LIBRARY_URL = `${BASE_URL}/library/conditions/`;
export const ALL_CONDITION_LIBRARY_URL = `${BASE_URL}/library/condition`;
export const SALES_TYPE_LIBRARY_URL = `${BASE_URL}/library/sale-types/`;
export const ACCOUNT_TYPE_LIBRARY_URL = `${BASE_URL}/library/account-types/`;

export const COUNTRIES_LIBRARY_URL = `${BASE_URL}/library/countries`;
export const COUNTRY_STATE_LIBRARY_URL = `${BASE_URL}/library/country/{countryCode}/state`;
export const STATE_CITY_LIBRARY_URL = `${BASE_URL}/library/state/{stateId}/city`;

export const CONTACT_US = `${BASE_URL}/library/contact-us`;
export const CITIES_LIBRARY_URL = `${BASE_URL}/library/cities`;
export const ALL_STATES_LIBRARY_URL = `${BASE_URL}/library/states/all`;
export const STATE_CITIES_LIBRARY_URL = `${BASE_URL}/library/city-by-states/`;
export const DOCUMENT_TYPES_URL = `${BASE_URL}/library/document-types/`;
export const SELLER_URL = `${BASE_URL}/library/seller/all/count`;
export const CURRENCY_URL = `${BASE_URL}/library/currency`;
export const STARTING_PRICE_URL = `${BASE_URL}/library/starting-price`;
export const MENU_CATEGORIES_URL = `${BASE_URL}/library/category/menu`;
export const FEATURED_CATEGORIES_URL = `${BASE_URL}/library/category/featured`;
export const Auth_SERVICE_URL = `${BASE_URL}/auth/login`;
export const Auth_SERVICE_GOOGLE_URL = `${BASE_URL}/auth/google`;

export const FORGOT_PASSWORD_SEND_EMAIL_URL = `${BASE_URL}/user/forgot-password/send-email`;
export const FORGOT_PASSWORD_VERIFY_LINK_URL = `${BASE_URL}/user/forgot-password/verify-link`;
export const FORGOT_PASSWORD_SET_PASSWOED_URL = `${BASE_URL}/user/forgot-password/set-password`;

export const PAYMENT_SERVICE = `${BASE_URL}/payment`;

export const POPULAR_AUCTIONS_URL = `${BASE_URL}/auction/public/popular`;
export const NEWLY_ADDED_AUCTIONS_URL = `${BASE_URL}/auction/public/new`;
export const ENDING_SOON_AUCTIONS_URL = `${BASE_URL}/auction/public/ending`;

export const CATEGORY_MENU_URL = `${BASE_URL}/library/category/subcategory/count`;
export const ALL_CATEGORY_SUBCATEGORY_COUNT_LIBRARY_URL = `${BASE_URL}/library/category/subcategory/all/count`;
export const ALL_REVIEWS = `${BASE_URL}/library/review`;
export const TOPIC_COUNT = `${BASE_URL}/library/topic`;
export const TOPIC_DETALS = `${BASE_URL}/library/topic/details`;

export const USER_WATCHED_AUCTIONS_URL = `${BASE_URL}/auction/watched/me`;

export const USER_ADD_AUCTIONS_URL = `${BASE_URL}/auction/watch/`;
export const USER_AUCTIONS_QUESTION_URL = `${BASE_URL}/auction/question/`;
export const USER_BID_NOW_URL = `${BASE_URL}/bid/`;

export const SEARCH_TERM_URL = `${BASE_URL}/auction/public/term`;

export const BIDS_WON_URL = `${BASE_URL}/bid/won/me`;
export const BIDS_OPEN_URL = `${BASE_URL}/bid/open/me`;
export const BIDS_LOST_URL = `${BASE_URL}/bid/lost/me`;
export const BIDS_COMPLETED_URL = `${BASE_URL}/bid/completed/me`;

export const BOUGHT_CART_ITEMS_URL = `${BASE_URL}/bid/bought/cart/me`;
export const ITEM_BOUGHT_URL = `${BASE_URL}/bid/bought/me`;
export const BOUGHT_COMPLETED_URL = `${BASE_URL}/bid/bought/completed/me`;
export const BOUGHT_BUYER_FEE_PROCESS_URL = `${BASE_URL}/bid/bought/cart/{itemId}/fee`;
export const BOUGHT_REMOVE_ITEM_URL = `${BASE_URL}/bid/bought/cart/{itemId}`;

export const OFFERS_WON_URL = `${BASE_URL}/bid/offer/won/me`;
export const OFFERS_OPEN_URL = `${BASE_URL}/bid/offer/open/me`;
export const OFFERS_LOST_URL = `${BASE_URL}/bid/offer/lost/me`;
export const OFFERS_COMPLETED_URL = `${BASE_URL}/bid/offer/completed/me`;

export const INVOICE_ACCOUNT_INFO_URL = `${BASE_URL}/payment/auction/{item}/invoice/wire`;
export const INVOICE_CARD_PAYMENT_INIT_URL = `${BASE_URL}/payment/auction/{item}/init`;
export const INVOICE_CARD_PAYMENT_SUCESS_URL = `${BASE_URL}/payment/auction/{item}`;
export const WIRE_INVOICE_RECEIPT_UPLOAD_URL = `${BASE_URL}/payment/auction/{item}/invoice/wire`;
export const CASHIER_CHEQUE_INVOICE_ACCOUNT_INFO_URL = `${BASE_URL}/payment/auction/{item}/invoice/cashier-cheque`;
export const CASHIER_CHEQUE_UPLOAD_URL = `${BASE_URL}/payment/auction/{item}/invoice/cashier-cheque`;

export const INBOX_URL = `${BASE_URL}/message/inbox`;
export const OUTBOX_URL = `${BASE_URL}/message/outbox`;
export const NEW_MESSAGE_URL = `${BASE_URL}/message/`;
export const SEND_REPLY_MESSAGE = `${BASE_URL}/message/{messageId}`;
export const VIEW_MESSAGE = `${BASE_URL}/message/{messageId}`;
export const NEW_MESSAGE_RECIPIENT_URL = `${BASE_URL}/message/recipient`;
export const MARK_READ_MESSAGE_URL = `${BASE_URL}/message/read`;

export const USER_MESSAGE_NOTIFICATION_URL = `${BASE_URL}/message/notification/me`;

export const USER_NOTIFICATIONS_URL = `${BASE_URL}/user/notification/`;
export const READ_USER_NOTIFICATION_URL = `${BASE_URL}/user/notification/{notificationId}`;
export const NOTIFICATION_WINNING_BID_INFO_URL = `${BASE_URL}/bid/won/{itemId}`;

export const MUNCIPALITY_POPULATION_LIBRARY_URL = `${BASE_URL}/library/muncipality-population`;
export const SELLER_LIST_CREATION_PLAN_LIBRARY_URL = `${BASE_URL}/library/list-creation-plan`;

export const SELLER_CREATE_AUCTION_ITEM_URL = `${BASE_URL}/auction/wizard/listing`;
export const SELLER_AUCTION_ITEM_LIST_URL = `${BASE_URL}/auction/listing/me`;
export const SELLER_AUCTION_ITEM_DECISION_URL = `${BASE_URL}/auction/listing/{itemId}/decision`;
export const SELLER_AUCTION_ITEM_BIDS_LIST_URL = `${BASE_URL}/auction/listing/{itemId}/bid`;
export const SELLER_AUCTION_ITEM_OFFER_LIST_URL = `${BASE_URL}/auction/listing/{itemId}/offer`;
export const SELLER_AUCTION_CHOOSE_WINNER_URL = `${BASE_URL}/auction/listing/{itemId}/decision/{winningId}`;
export const SELLER_AUCTION_WINNER_REJECT_URL = `${BASE_URL}/auction/listing/{itemId}/decision/reject`;
export const SELLER_AUCTION_SEND_INVOICE_URL = `${BASE_URL}/auction/listing/{itemId}/invoice`;
export const SELLER_AUCTION_CONFIRM_PAYMENT_RECEIVED_URL = `${BASE_URL}/auction/listing/{itemId}/payment`;
export const SELLER_AUCTION_CONFIRM_ITEM_PICKUP_URL = `${BASE_URL}/auction/listing/{itemId}/pickup`;
export const SELLER_AUCTION_ITEM_DETAIL_URL = `${BASE_URL}/auction/listing/{itemId}`;
export const SELLER_AUCTION_SIMILAR_ITEM_URL = `${BASE_URL}/auction/listing/{itemId}/similar`;
export const SELLER_AUCTION_EDIT_ITEM_FETCH_URL = `${BASE_URL}/auction/listing/{itemId}/edit`;
export const SELLER_AUCTION_DELETE_ITEM_URL = `${BASE_URL}/auction/listing/{itemId}`;
export const SELLER_AUCTION_CLOSE_URL = `${BASE_URL}/auction/listing/{itemId}/close`;

export const AUCTION_ITEM_SAVE_INSTRUCTIONS_URL = `${BASE_URL}/auction/wizard/listing/instructions`;
export const AUCTION_ITEM_SAVE_ADDITIONAL_URL = `${BASE_URL}/auction/wizard/listing/additional`;
export const AUCTION_ITEM_SAVE_LOCATION_URL = `${BASE_URL}/auction/wizard/listing/location`;
export const AUCTION_ITEM_SAVE_MEDIA_URL = `${BASE_URL}/auction/wizard/listing/media`;
export const AUCTION_ITEM_SAVE_PRICING_URL = `${BASE_URL}/auction/wizard/listing/pricing`;
export const AUCTION_ITEM_SAVE_PAYMENT_URL = `${BASE_URL}/auction/wizard/listing/payment`;
export const AUCTION_ITEM_SAVE_DATES_URL = `${BASE_URL}/auction/wizard/listing/dates`;
export const AUCTION_ITEM_SAVE_REVIEW_URL = `${BASE_URL}/auction/wizard/listing/review`;

export const AUCTION_ITEM_SALES_REPORT_URL = `${BASE_URL}/auction/report/sale`;

export const SELLER_DASHBOARD_STATS_STATUS_URL = `${BASE_URL}/auction/dashboard/me/status`;
export const SELLER_DASHBOARD_DEMO_STATS_STATUS_URL = `${BASE_URL}/auction/dashboard/me/demo-status`;
export const SELLER_DASHBOARD_STATS_SALES_INTERVAL_URL = `${BASE_URL}/auction/dashboard/me/sales/interval`;
export const SELLER_DASHBOARD_STATS_SALES_STATUS_URL = `${BASE_URL}/auction/dashboard/me/sales/status`;
export const SELLER_DASHBOARD_STATS_SALES_DAY_URL = `${BASE_URL}/auction/dashboard/me/sales/day`;
export const SELLER_DASHBOARD_RECENT_LISTINGS_URL = `${BASE_URL}/auction/dashboard/me/recent/listing`;
export const BIDDER_ALL_QUESTION = `${BASE_URL}/auction/dashboard/me/all-question/`;
export const DELETE_QUESTION = `${BASE_URL}/auction/dashboard/me/delete-question/`;
export const PUBLISH_QUESTION = `${BASE_URL}/auction/dashboard/me/publish-question/`;

export const BIDDER_DASHBOARD_STATS_STATUS_URL = `${BASE_URL}/bid/dashboard/me/status`;
export const BIDDER_DASHBOARD_STATS_ACCOUNT_UPDATES_URL = `${BASE_URL}/bid/dashboard/me/updates`;
export const BIDDER_DASHBOARD_STATS_SALES_STATUS_URL = `${BASE_URL}/bid/dashboard/me/sales/status`;
export const BIDDER_DASHBOARD_STATS_SALES_DAY_URL = `${BASE_URL}/bid/dashboard/me/sales/day`;
export const BIDDER_DASHBOARD_RECENT_LISTINGS_URL = `${BASE_URL}/bid/dashboard/me/recent/bidding`;

export const AUCTION_VERIFY_VIN_URL = `${BASE_URL}/auction/thirdparty/verify-vin`;

export const SELLER_BANK_ACCOUNT_FETCH_URL = `${BASE_URL}/user/bank-info`;
export const SELLER_BANK_ACCOUNT_SAVE_URL = `${BASE_URL}/user/bank-info`;
export const BIDLY_BANK_ACCOUNT_FETCH_URL = `${BASE_URL}/user/bidly-bank-info`;

export const SELLER_AUCTION_CONFIG_FETCH_URL = `${BASE_URL}/user/seller-auction-config`;
export const SELLER_TERMS_SAVE_URL = `${BASE_URL}/user/seller-terms`;

export const BIDDER_ITEM_PICKUP_DETAILS = `${BASE_URL}/auction/bidder/{itemId}/pickup`;

export const newMessageErrorMsg =
  "Please fix the errors and click on send reply/message button to continue!";
export const genericErrorMsg =
  "Please fix the errors below and click on submit button to continue!";
export const wizardGenericErrorMsg =
  "Please fix the errors below and click on save and continue button to continue!";
export const genericTableErrorMsg =
  "Please remove highlighted row(s) and type again!";

export const maxFileUploadSize = 7340032;

export const LIVE_CHAT_LICENSE = 14911389;

export const NAVIGATION_PAGES = {
  BIDS_WON: { url: "/bids-won", type: "bids" },
  OFFERS_WON: { url: "/offers-won", type: "offers" },
  ITEMS_BOUGHT: { url: "/item-bought-checkout", type: "buy_now" },
};
