import React from "react";
import ImageUploading from 'react-images-uploading';

const ZPictureUploader = ({
    label,
}) => {
    const [images, setImages] = React.useState([]);

    const maxNumber = 1;

    const onImageChange = (imageList, addUpdateIndex) => {
        debugger;
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    return (
        <div>
            <ImageUploading
                multiple
                value={images}
                onChange={onImageChange}
                maxNumber={maxNumber}
                dataURLKey="data_url">
                {({
                    imageList,
                    onImageUpload,
                    onChange = { onImageChange },
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <>
                        <button onClick={onImageUpload} className="btn-block btn btn-outline-primary border-width-2 rounded-pill py-2 px-4 font-size-15 text-lh-19 font-size-15-md">{label}</button>
                        {
                            imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img src={image['data_url']} alt="" width="100" />
                                    <div className="image-item__btn-wrapper">
                                        <button onClick={() => onImageUpdate(index)}>Update</button>
                                        <button onClick={() => onImageRemove(index)}>Remove</button>
                                    </div>
                                </div>
                            ))}
                    </>
                )}

            </ImageUploading>
        </div>
    )
}

export default ZPictureUploader;