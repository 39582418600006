import React, { useEffect, useState, useCallback } from "react";
import ForgotPasswordCaptcha from './ForgotPasswordCaptcha';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ForgotPassword = () => {
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

  return (
    <div className="">
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <ForgotPasswordCaptcha/>
        </GoogleReCaptchaProvider>
    </div >
  );
};

export default ForgotPassword;
