// events
// finished(blob/base64)
// uploaded (blob/base64, event)
// event.proceed(), event.cancel()
// initaliavalue={base64}

import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import classes from "./picture-uploader.module.css";
import ErrorMessage from "../UI/ErrorMessage";

const PictureUploader = ({
  label = "Upload a photo of your ID",
  initialImage,
  fileSelectedEvent,
  enableICAO = async (file) => false,
  checkICAO = (file) => {},
  showErrors = false,
  errorEvent,
  showICAOonMobile = false,
}) => {
  const [image, setImage] = useState([]);
  const [initImage, setInitImage] = useState(initialImage);
  const [showICAO, setShowICAO] = useState();
  const [errorICAO, setErrorICAO] = useState();
  const maxNumber = 1;

  useEffect(() => {
    if (initialImage && initialImage?.length > 0) {
      setInitImage(initialImage);
      setImage([initialImage]);
    }
  }, [initialImage]);

  useEffect(() => {
    console.log("errorICAO updated", errorICAO);
  }, [errorICAO]);

  const uploadEvent = {
    proceed: (image) => {
      setImage([image]);
      setInitImage(null);
    },
    cancel: () => {
      setImage([]);
      //setInitImage(profileImage);
    },
  };

  const icaoEvent = {
    proceed: (image) => {
      if (fileSelectedEvent) fileSelectedEvent(uploadEvent, image);
      setShowICAO("");
    },
    reject: (errorMessage) => {
      setImage([]);
      //setInitImage(profileImage);
      setErrorICAO("");
      setErrorICAO(errorMessage);
    },
  };

  const onChange = (uploadedImage) => {
    const callBack = async (uploadedImage) => {
      let skipICAO = true;

      setImage(uploadedImage);
      setInitImage(null);

      if (await enableICAO(uploadedImage[0])) {
        skipICAO = false;

        if (!showICAOonMobile && window.innerWidth <= 768) {
          skipICAO = true;
        }
      }

      if (!skipICAO) {
        setShowICAO(uploadedImage[0]);
        return;
      }

      if (fileSelectedEvent) fileSelectedEvent(uploadEvent, uploadedImage[0]);
    };

    callBack(uploadedImage);
  };

  return (
    <>
      <ImageUploading
        value={image}
        maxFileSize={2000000}
        onChange={onChange}
        onError={(errors) => {
          if (errorEvent) errorEvent(errors);
        }}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemoveAll, errors }) => (
          <>
            <button
              type="button"
              onClick={onImageUpload}
              className="btn-block btn btn-outline-primary border-width-2 py-2 px-4 font-size-15 text-lh-19 font-size-15-md"
            >
              {label}
            </button>
            {!initImage &&
              imageList.map((uImage, index) => (
                <span key={index} className={classes["picture-box-span"]}>
                  <img
                    style={{ margin: "20px", maxHeight: "200px" }}
                    src={uImage.data_url}
                    alt=""
                    className={classes.avatar}
                  />
                </span>
              ))}
            {initImage && (
              <span className={classes["picture-box-span"]}>
                <img src={initImage} alt="" className={classes.avatar} />
              </span>
            )}

            {showErrors && errors && (
              <ErrorMessage>
                {errors.maxNumber && (
                  <span className="block">
                    Number of selected images exceed maximum number
                  </span>
                )}
                {errors.acceptType && (
                  <span className="block">
                    Your selected file type is not allowed
                  </span>
                )}
                {errors.maxFileSize && (
                  <span className="block">
                    Selected file size exceed maximum file size
                  </span>
                )}
                {errors.resolution && (
                  <span className="block">
                    Selected file is not match your desired resolution
                  </span>
                )}
              </ErrorMessage>
            )}
          </>
        )}
      </ImageUploading>
    </>
  );
};

PictureUploader.defaultProps = {
  initialImage: "",
};

export default PictureUploader;
