import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import bellIcon from "../../assets/images/bellIcon.svg";
import { USER_NOTIFICATIONS_URL } from "../../Constants";
import { fetchNotifications } from "../../services/NotificationService";
import { isStopFlowConditionMet } from "../../services/Utilities";
import classes from "./NotificationBar.module.css";
import PaymentNotification from "./PaymentNotification";
import GeneralNotification from "./GeneralNotification";

const NotificationBar = () => {
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [data, setData] = useState([]);

  const documentClickEventHandler = (e) => {
    if (!menuRef?.current?.contains(e.target)) {
      setShowMenu(false);
    }
  };

  const fetchUserNotifications = async () => {
    try {
      const rs = await fetchNotifications(USER_NOTIFICATIONS_URL);
      // console.log("notifications", rs);
      setData(rs);
    } catch (e) {
      //if (isStopFlowConditionMet(e.message)) return;
      //navigate("/error");
    }
  };

  useEffect(() => {
    fetchUserNotifications();

    const updateInterval = setInterval(fetchUserNotifications, 10000);

    return () => {
      if (updateInterval) clearInterval(updateInterval);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("click", documentClickEventHandler);

    return () => {
      window.removeEventListener("click", documentClickEventHandler);
    };
  }, []);

  // let data = [
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  //   {
  //     notification: "18 weeks pass due - 2013 Ford F-350 SD",
  //     time: "18 weeks ago",
  //     lot: "Lot # 8831 - 485",
  //   },
  // ];

  return (
    <>
      <div ref={menuRef} className={classes.dropdown}>
        <Link
          className={classes.icon}
          onClick={(e) => {
            e.preventDefault();
            setShowMenu((show) => !show);
          }}
        >
          <img src={bellIcon} />
          {data?.length > 0 && <span>{data?.length}</span>}
        </Link>

        <div
          className={`${classes.main} ${classes.menu} ${
            showMenu ? classes.show : ""
          }`}
        >
          {data?.length == 0 && (
            <div className={classes.container}>
              <div className="row mb-3 align-items-center">
                <div className="col text-center">
                  <h6 className="mb-0">No new notification</h6>
                </div>
              </div>
            </div>
          )}
          {data?.length > 0 && (
            <>
              <div className={classes.container}>
                <h5>Notifications</h5>

                {false && (
                  <div className="row mb-3 align-items-center">
                    <div className="col">
                      <h6 className="mb-0">Payment is due</h6>
                    </div>
                    <div className="col-auto">
                      <Link to="/bids-won">See All</Link>
                    </div>
                  </div>
                )}
              </div>

              <div className={classes.container}>
                <div
                  className={`${classes.notificationArea} custom-scroll-self`}
                >
                  {data.map((d, i) => {
                    return (
                      <Fragment key={i}>
                        {d.notificationType === "ITEM_PAYMENT_DUE" && (
                          <PaymentNotification key={i} data={d} />
                        )}

                        {d.notificationType !== "ITEM_PAYMENT_DUE" && (
                          <GeneralNotification
                            key={i}
                            data={d}
                            onNotificationRead={(id) => {
                              setData((state) =>
                                state?.filter((n) => n.notificationId !== id)
                              );
                            }}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationBar;
