import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import SearchBox from "./SearchBox";
import { useAuthContext } from "../redux/auth-context";
import burgerIcon from "../assets/images/burger.svg";
import profileIcon from "../assets/images/profileIcon.svg";
import bellIcon from "../assets/images/bellIcon.svg";
import { shallowEqual, useSelector } from "react-redux";
import NotificationBar from "./notification/NotificationBar";

const SearchArea = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
    }),
    shallowEqual
  );

  return (
    <div className="py-2 pt-md-5 pb-xl-2">
      <div className="container my-0dot5 my-xl-0">
        <div className="d-none d-md-block">
          <div className="row align-items-center justify-content-space-between">
            <div className="col-auto d-flex align-items-center justify-content-space-between">
              <nav className="navbar navbar-expand u-header__navbar py-0 pb-3 justify-content-xl-between max-width-270 min-width-270">
                <Link
                  className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                  to="/"
                >
                  <img src={logo} />
                </Link>
              </nav>
              <button
                className="navbar-toggler navbar-toggle-hamburger d-none d-md-block d-xl-none "
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  ctx.sidebarOpen(true);
                }}
              >
                <img src={burgerIcon} />
              </button>
            </div>
            <div className="col-lg-5 col-md-7 d-none d-md-block ml-auto">
              <SearchBox />
            </div>
          </div>
        </div>

        <div className="d-block d-md-none py-3">
          <div className="row align-items-center justify-content-space-between">
            <div className="col-auto d-flex align-items-center">
              <button
                className="navbar-toggler navbar-toggle-hamburger"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  ctx.sidebarOpen(true);
                }}
              >
                <img src={burgerIcon} />
              </button>
            </div>
            <div className="col-auto d-flex align-items-center">
              <nav className="navbar navbar-expand u-header__navbar py-0 justify-content-xl-between max-width-270 min-width-270">
                <Link
                  className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                  to="/"
                >
                  <img src={logo} />
                </Link>
              </nav>
            </div>
            <div className="col-auto d-flex align-items-center">
              <div
                style={{ visibility: !isAuthorized ? "hidden" : "visible" }}
                className="navbar-toggler navbar-toggle-hamburger"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {window.screen.width <= 767 && <NotificationBar />}
              </div>

              <button
                style={{ visibility: !isAuthorized ? "hidden" : "visible" }}
                className="navbar-toggler navbar-toggle-hamburger"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  ctx.userSidebarOpen(true);
                }}
              >
                <img src={profileIcon} />
              </button>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-12 d-md-none">
            <SearchBox />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchArea;
