import {
  SERVICE_URL,
  VERIFY_EMAIL_SERVICE_URL,
  CREATE_ACCOUNT_SERVICE_URL, CREATE_ACCOUNT_SELLER_SERVICE_URL,
  VERIFY_LINK_SERVICE_URL,
} from "../Constants";
import { simpleFetch } from "./fetch";

export const EmailCheckServerRequest = async (requestBody) => {
  const response = await simpleFetch(`${VERIFY_EMAIL_SERVICE_URL}`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const SignupServerRequest = async (requestBody) => {
  const response = await simpleFetch(`${CREATE_ACCOUNT_SERVICE_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 201) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const SellerSignupServerRequest = async (requestBody) => {
  const response = await simpleFetch(`${CREATE_ACCOUNT_SELLER_SERVICE_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  let errorMessage = { status: "ERROR" };
  if (response.status !== 201) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    let res = await response.json();
    return res;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const VerifyRegistrationLinkRequest = async (requestBody) => {
  const response = await simpleFetch(`${VERIFY_LINK_SERVICE_URL}`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const resendLinkEmailServerRequest = async (requestBody) => {
  const response = await simpleFetch(`${SERVICE_URL}/user/resend-email`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};
