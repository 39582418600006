import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../redux/auth-context";
import { actionTypes } from "../_redux/authRedux";

export default function Logout() {
  const uiContext = useAuthContext();
  const dispatch = useDispatch();

  useEffect(() => {
    uiContext.onLogout();
    dispatch({ type: actionTypes.Logout });
  }, [dispatch, uiContext]);

  return <></>;
}
