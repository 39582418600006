import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import DropDownButton from "../../components/button/DropDownButton";
import DropDownItem from "../../components/button/DropDownItem";
import LotsTable from "../../components/table/LotsTable";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import {
  BIDS_COMPLETED_URL,
  BIDS_WON_URL,
  NAVIGATION_PAGES,
} from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { fetchMyBids } from "../../services/MyBidsService";
import {
  formatUSD,
  formattedUTCDatetoUSCentral,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import ButtonsPanel from "./ButtonsPanel";
import classes from "./MyBids.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import certificateIcon from "../../assets/images/doc-icon.svg";
import receiptIcon from "../../assets/images/receipt-icon.svg";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";

const BidsWon = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [wonBids, setWonBids] = useState([]);
  const [allWonBids, setAllWonBids] = useState([]);

  const [completedBids, setCompletedBids] = useState([]);

  const [totalCompleted, setTotalCompleted] = useState();
  const [currentPageCompleted, setCurrentPageCompleted] = useState(0);
  const [pageSizeCompleted, setPageSizeCompleted] = useState(30);
  const [totalPagesCompleted, setTotalPagesCompleted] = useState();

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const fetchWonBids = async () => {
    const rs = await fetchMyBids(BIDS_WON_URL);
    console.log("won bids", rs);

    const content = rs?.content;
    const items = [];
    content?.map((c) => {
      let m = {
        ...c,
      };

      m.auction.endedOn = m.auction.endedOn
        ? formattedUTCtoUSCentral(m.auction.endedOn)
        : m.auction.endedOn;
      m.auction.paymentDate = m.auction.paymentDate
        ? formattedUTCDatetoUSCentral(m.auction.paymentDate)
        : m.auction.paymentDate;

      items.push(m);
    });

    setWonBids(items);
    setAllWonBids(items);
  };

  const fetchCompletedBids = async () => {
    const pagingParams =
      currentPageCompleted + 1 && pageSizeCompleted
        ? "?" +
          ["page=" + currentPageCompleted, "size=" + pageSizeCompleted].join(
            "&"
          )
        : "";

    const rs = await fetchMyBids(BIDS_COMPLETED_URL + pagingParams);
    console.log("completed bids", rs);
    // setCompletedBids(rs?.content);

    const content = rs?.content;
    const items = [];
    content?.map((c) => {
      let m = {
        ...c,
      };

      m.auction.endedOn = m.auction.endedOn
        ? formattedUTCtoUSCentral(m.auction.endedOn)
        : m.auction.endedOn;
      m.auction.paymentDate = m.auction.paymentDate
        ? formattedUTCDatetoUSCentral(m.auction.paymentDate)
        : m.auction.paymentDate;
      m.auction.pickupDate = m.auction.pickupDate
        ? formattedUTCDatetoUSCentral(m.auction.pickupDate)
        : m.auction.pickupDate;

      items.push(m);
    });
    setCompletedBids(items);

    setTotalCompleted(rs.totalElements);
    setTotalPagesCompleted(rs.totalPages);
  };

  const fetchBidsData = async () => {
    ctx.showProgress(true);
    try {
      await fetchWonBids();
      await fetchCompletedBids();
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchBidsData();
  }, []);

  useEffect(() => {
    if (pageSizeCompleted && currentPageCompleted + 1) fetchCompletedBids();
  }, [pageSizeCompleted, currentPageCompleted]);

  const onChangeWonBidSelection = (e, bid) => {
    if (e.target.checked) {
      setSelectedInvoices((prev) => {
        //const items = [...prev];
        const items = [];
        items.push(bid);
        return items;
      });
    } else {
      setSelectedInvoices((prev) => {
        return [...prev].filter(
          (item) => item.auction.itemId !== bid.auction.itemId
        );
      });
    }
  };

  const wonBidsTableHeader = (
    <tr>
      <th></th>
      <th>Asset Description</th>
      <th>Auction End Time</th>
      <th>Sold Amount</th>
      <th>Buyer’s Premium</th>
      <th>Estimated Tax</th>
      <th>Total Due</th>
      <th>Payment Date</th>
      <th></th>
    </tr>
  );

  const wonBidsMobileHeader = (
    <>
      <div className={tableClasses.selection}></div>
      <div className={tableClasses.label}>Item Description</div>
    </>
  );

  const completedBidsTableHeader = (
    <tr>
      <th>Asset Description</th>
      <th>Auction End Time</th>
      <th>Sold Amount</th>
      <th>Buyer’s Premium</th>
      <th>Payment Date</th>
      <th>Pickup Date</th>
      <th></th>
    </tr>
  );

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myBidsActive={true} subMenu="bids-won" />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <h2 className="h4 font-weight-bold">My Bids - Won Lots</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <ButtonsPanel>
                <DropDownButton
                  className={classes.item}
                  onItemClicked={(v) => {
                    // console.log(v);
                    setSelectedInvoices([]);
                    if (v === "ALL") {
                      setWonBids(allWonBids);
                    } else {
                      const items = allWonBids.filter((item) =>
                        item.auction.paymentMethods.includes(v)
                      );
                      setWonBids(items);
                    }
                  }}
                >
                  <DropDownItem label="ALL PAYMENT METHODS" value="ALL" />
                  <DropDownItem label="CREDIT CARD" value="CREDIT_CARD" />
                  <DropDownItem label="WIRE TRANSFER" value="WIRE_TRANSFER" />
                  <DropDownItem label="CASHIER CHEQUE" value="CASHIER_CHEQUE" />
                </DropDownButton>

                {selectedInvoices &&
                  Object.keys(selectedInvoices).length > 0 && (
                    <Link
                      className={`btn px-4 py-2 btn-primary-dark-w ${classes.item}`}
                      onClick={(e) => {
                        e.preventDefault();
                        console.log("selected invoices", selectedInvoices);
                        navigate("/bid-won-checkout-selection", {
                          state: {
                            selected: selectedInvoices,
                            from: NAVIGATION_PAGES.BIDS_WON,
                          },
                        });
                      }}
                    >
                      CHECKOUT
                    </Link>
                  )}

                {(!selectedInvoices ||
                  Object.keys(selectedInvoices).length == 0) && (
                  <span
                    className={`btn px-4 py-2 btn-primary-dark-w disabled ${classes.item}`}
                  >
                    CHECKOUT
                  </span>
                )}
              </ButtonsPanel>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <LotsTable
                    label="Lots Won"
                    mobileHeader={wonBidsMobileHeader}
                    tableHeader={wonBidsTableHeader}
                    noRecordTableBody={
                      <>
                        <tr>
                          <td colSpan={8} className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      </>
                    }
                    tableBody={
                      wonBids && Object.keys(wonBids).length > 0
                        ? wonBids.map((bid, i) => {
                            return (
                              <BidsWon.WonBidsBodyRow
                                key={i}
                                isSelected={
                                  [...selectedInvoices].filter(
                                    (item) =>
                                      item.auction.itemId === bid.auction.itemId
                                  ).length > 0
                                }
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                auctionEnd={bid.auction.endedOn}
                                buyerFee={bid.buyerPremium}
                                lot={bid.auction.customId}
                                soldAmount={bid.soldAmount}
                                tax={bid.taxAmount}
                                total={bid.total}
                                paymentDate={bid.auction.paymentDate}
                                currency={bid.auction.currency}
                                status={bid.status}
                                invoiceURL={bid.invoiceURL}
                                receiptURL={bid.paymentReceiptURL}
                                onChange={(e) => {
                                  onChangeWonBidSelection(e, bid);
                                }}
                                onPickupDetails={(itemId) => {
                                  navigate("/item-pickup-detail/" + itemId, {
                                    state: {
                                      from: "bids-won",
                                    },
                                  });
                                }}
                                onContactSeller={(itemId) => {
                                  navigate("/new-message/", {
                                    state: {
                                      subject:
                                        "Lot# " +
                                        bid.auction.customId +
                                        ", " +
                                        bid.auction.title,
                                      to: bid.sellerId,
                                    },
                                  });
                                }}
                              />
                            );
                          })
                        : undefined
                    }
                    noRecordMobileBody={
                      <>
                        <div className={tableClasses.blockPanel}>
                          <div
                            className={`justify-content-center ${tableClasses.block}`}
                          >
                            <div className={tableClasses.blockText}>
                              <div className={tableClasses.item}>
                                <div className={tableClasses.productLabel}>
                                  No Records Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    mobileBody={
                      wonBids && Object.keys(wonBids).length > 0
                        ? wonBids.map((bid, i) => {
                            return (
                              <BidsWon.WonBidsMobileBodyRow
                                key={i}
                                isSelected={
                                  [...selectedInvoices].filter(
                                    (item) =>
                                      item.auction.itemId === bid.auction.itemId
                                  ).length > 0
                                }
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                auctionEnd={bid.auction.endedOn}
                                buyerFee={bid.buyerPremium}
                                lot={bid.auction.customId}
                                soldAmount={bid.soldAmount}
                                tax={bid.taxAmount}
                                total={bid.total}
                                paymentDate={bid.auction.paymentDate}
                                currency={bid.auction.currency}
                                status={bid.status}
                                invoiceURL={bid.invoiceURL}
                                receiptURL={bid.paymentReceiptURL}
                                onChange={(e) => {
                                  onChangeWonBidSelection(e, bid);
                                }}
                                onPickupDetails={(itemId) => {
                                  navigate("/item-pickup-detail/" + itemId, {
                                    state: {
                                      from: "bids-won",
                                    },
                                  });
                                }}
                                onContactSeller={(itemId) => {
                                  navigate("/new-message/", {
                                    state: {
                                      subject:
                                        "Lot# " +
                                        bid.auction.customId +
                                        ", " +
                                        bid.auction.title,
                                      to: bid.sellerId,
                                    },
                                  });
                                }}
                              />
                            );
                          })
                        : undefined
                    }
                  />
                </div>
              </div>

              {completedBids && Object.keys(completedBids).length > 0 && (
                <>
                  <ButtonsPanel>
                    <DropDownButton
                      className={classes.item}
                      onItemClicked={(v) => {
                        console.log(v);
                        setCurrentPageCompleted(0);
                        setPageSizeCompleted(v);
                      }}
                    >
                      <DropDownItem label="Show 30 Records" value="30" />
                      <DropDownItem label="Show 15 Records" value="15" />
                      <DropDownItem label="Show 7 Records" value="7" />
                    </DropDownButton>

                    {false && (
                      <DropDownButton
                        className={classes.item}
                        onItemClicked={(v) => {
                          console.log(v);
                        }}
                      >
                        <DropDownItem
                          label="AUCTION ENDING ASC"
                          value="end-asc"
                        />
                        <DropDownItem
                          label="AUCTION ENDING DESC"
                          value="end-desc"
                        />
                      </DropDownButton>
                    )}
                  </ButtonsPanel>

                  <div className="row">
                    <div className="col mt-5 mb-5">
                      <LotsTable
                        label="Lots Completed"
                        mobileHeader={wonBidsMobileHeader}
                        tableHeader={completedBidsTableHeader}
                        noRecordTableBody={
                          <>
                            <tr>
                              <td colSpan={7} className="text-center">
                                No Records Found
                              </td>
                            </tr>
                          </>
                        }
                        tableBody={
                          completedBids && Object.keys(completedBids).length > 0
                            ? completedBids.map((bid, i) => {
                                return (
                                  <BidsWon.WonBidsBodyRow
                                    key={i}
                                    itemId={bid.auction.itemId}
                                    label={bid.auction.title}
                                    auctionEnd={bid.auction.endedOn}
                                    buyerFee={bid.buyerPremium}
                                    lot={bid.auction.customId}
                                    soldAmount={bid.soldAmount}
                                    tax={bid.taxAmount}
                                    total={bid.total}
                                    currency={bid.auction.currency}
                                    showPickupDate={true}
                                    paymentDate={bid.auction.paymentDate}
                                    pickupDate={bid.auction.pickupDate}
                                    invoiceURL={bid.invoiceURL}
                                    receiptURL={bid.paymentReceiptURL}
                                    hideSelection={true}
                                  />
                                );
                              })
                            : undefined
                        }
                        noRecordMobileBody={
                          <>
                            <div className={tableClasses.blockPanel}>
                              <div
                                className={`justify-content-center ${tableClasses.block}`}
                              >
                                <div className={tableClasses.blockText}>
                                  <div className={tableClasses.item}>
                                    <div className={tableClasses.productLabel}>
                                      No Records Found
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                        mobileBody={
                          completedBids && Object.keys(completedBids).length > 0
                            ? completedBids.map((bid, i) => {
                                return (
                                  <BidsWon.WonBidsMobileBodyRow
                                    key={i}
                                    itemId={bid.auction.itemId}
                                    label={bid.auction.title}
                                    auctionEnd={bid.auction.endedOn}
                                    buyerFee={bid.buyerPremium}
                                    lot={bid.auction.customId}
                                    soldAmount={bid.soldAmount}
                                    tax={bid.taxAmount}
                                    total={bid.total}
                                    currency={bid.auction.currency}
                                    showPickupDate={true}
                                    paymentDate={bid.auction.paymentDate}
                                    pickupDate={bid.auction.pickupDate}
                                    invoiceURL={bid.invoiceURL}
                                    receiptURL={bid.paymentReceiptURL}
                                    hideSelection={true}
                                  />
                                );
                              })
                            : undefined
                        }
                      />
                    </div>
                  </div>

                  {totalPagesCompleted > 1 && (
                    <div className="d-block d-md-flex flex-center-between mb-3">
                      <Pagination
                        currentPage={currentPageCompleted}
                        total={totalCompleted}
                        pageSize={pageSizeCompleted}
                      />
                      <PaginationComponent
                        className="pagination-bar"
                        currentPage={currentPageCompleted + 1}
                        totalCount={totalCompleted}
                        pageSize={pageSizeCompleted}
                        onPageChange={(page) => {
                          setCurrentPageCompleted(page - 1);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

BidsWon.WonBidsBodyRow = ({
  itemId,
  isSelected = false,
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
  showPickupDate = false,
  paymentDate,
  pickupDate,
  status,
  invoiceURL,
  receiptURL,
  onChange = () => {},
  hideSelection = false,
  onPickupDetails = (itemId) => {},
  onContactSeller = (itemId) => {},
}) => (
  <>
    <tr>
      {!hideSelection && (
        <td>
          {status === "INVOICE_GENERATED" && (
            <input type="checkbox" onChange={onChange} checked={isSelected} />
          )}
        </td>
      )}
      <td>
        {status && (
          <span className={`${tableClasses.uLabel} ${tableClasses.highlight}`}>
            {status === "FINALIZING_WINNER" && "INVOICE GENERATION PENDING"}
            {status === "INVOICE_GENERATED" && "PAYMENT PENDING"}
            {status === "WAITING_TO_RECEIVE_PAYMENT" &&
              "PAYMENT CONFIRMATION PENDING"}
            {status === "WAITING_FOR_ITEM_PICKUP" && "PICKUP PENDING"}
          </span>
        )}
        <span className={tableClasses.productLabel}>
          <Link to={`/auction-item/` + itemId}>{label}</Link>
        </span>
        <span className={tableClasses.lot}>
          <Link to={`/auction-item/` + itemId}>LOT # {lot}</Link>
        </span>
      </td>
      <td>{auctionEnd}</td>
      {!showPickupDate && (
        <>
          <td>{formatUSD(soldAmount)}</td>
          <td>{formatUSD(buyerFee)}</td>
          <td>{formatUSD(tax)}</td>
          <td>{formatUSD(total)}</td>
          <td>{paymentDate}</td>
        </>
      )}
      {showPickupDate && (
        <>
          <td>{formatUSD(total)}</td>
          <td>{formatUSD(buyerFee)}</td>
          <td>{paymentDate}</td>
          <td>{pickupDate}</td>
        </>
      )}
      <td>
        {status && status === "WAITING_FOR_ITEM_PICKUP" && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onPickupDetails(itemId);
            }}
            className={`btn btn-primary-dark-w px-2 py-1 ${tableClasses.button}`}
          >
            Pickup Details
          </a>
        )}

        {(status === "INVOICE_GENERATED" ||
          status === "WAITING_TO_RECEIVE_PAYMENT" ||
          status === "FINALIZING_WINNER") && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onContactSeller(itemId);
            }}
            className={`btn btn-primary-dark-w px-2 py-1 ${tableClasses.button}`}
          >
            Contact Seller
          </a>
        )}

        {invoiceURL && status !== "FINALIZING_WINNER" && (
          <a
            target="_blank"
            download={true}
            href={invoiceURL}
            className={`btn btn-outline-primary px-2 py-1 ${tableClasses.button}`}
          >
            <img src={certificateIcon} />
            Certificate
          </a>
        )}

        {receiptURL && (
          <a
            target="_blank"
            download={true}
            href={receiptURL}
            className={`btn btn-outline-primary px-2 py-1 ${tableClasses.button}`}
          >
            <img src={receiptIcon} />
            Receipt
          </a>
        )}
      </td>
    </tr>
  </>
);

BidsWon.WonBidsMobileBodyRow = ({
  itemId,
  isSelected = false,
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
  showPickupDate = false,
  paymentDate,
  pickupDate,
  status,
  invoiceURL,
  receiptURL,
  onChange = () => {},
  hideSelection = false,
  onPickupDetails = (itemId) => {},
  onContactSeller = (itemId) => {},
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}>
          {!hideSelection && status === "INVOICE_GENERATED" && (
            <input type="checkbox" onChange={onChange} checked={isSelected} />
          )}
        </div>

        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.productLabel}>
              <Link to={`/auction-item/` + itemId}>{label}</Link>
            </div>
            <div className={tableClasses.lot}>
              <Link to={`/auction-item/` + itemId}>LOT # {lot}</Link>
            </div>
          </div>

          {status && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Status</div>
                <div className={tableClasses.value}>
                  {status === "FINALIZING_WINNER" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      INVOICE GENERATION PENDING
                    </span>
                  )}
                  {status === "INVOICE_GENERATED" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PAYMENT PENDING
                    </span>
                  )}
                  {status === "WAITING_TO_RECEIVE_PAYMENT" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PAYMENT CONFIRMATION PENDING
                    </span>
                  )}
                  {status === "WAITING_FOR_ITEM_PICKUP" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PICKUP PENDING
                    </span>
                  )}
                </div>
              </div>
            </>
          )}

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Auction End Time</div>
            <div className={tableClasses.value}>{auctionEnd}</div>
          </div>

          {!showPickupDate && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Sold Amount</div>
                <div className={tableClasses.value}>
                  {formatUSD(soldAmount)}
                </div>
              </div>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Buyer’s Premium</div>
                <div className={tableClasses.value}>{formatUSD(buyerFee)}</div>
              </div>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Estimated Tax</div>
                <div className={tableClasses.value}>{formatUSD(tax)}</div>
              </div>
            </>
          )}

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Payment Date</div>
            <div className={tableClasses.value}>{paymentDate}</div>
          </div>

          {showPickupDate && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Pickup Date</div>
                <div className={tableClasses.value}>{pickupDate}</div>
              </div>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Buyer’s Premium</div>
                <div className={tableClasses.value}>{formatUSD(buyerFee)}</div>
              </div>
            </>
          )}

          {(status === "INVOICE_GENERATED" ||
            status === "WAITING_TO_RECEIVE_PAYMENT" ||
            status === "FINALIZING_WINNER") && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Contact Seller</div>
              <div className={tableClasses.value}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onContactSeller(itemId);
                  }}
                  className={`btn btn-primary-dark-w px-2 py-1 ${tableClasses.button}`}
                >
                  Click here
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`${tableClasses.block} ${tableClasses.bordered}`}>
        <div className={tableClasses.selection}>&nbsp;&nbsp;&nbsp;</div>

        <div className={tableClasses.totalRow}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>
              {!showPickupDate && "Total Due"}
              {showPickupDate && "Amount"}
            </div>
            <div className={tableClasses.value}>{formatUSD(total)}</div>
          </div>

          <div className={tableClasses.buttons}>
            {status && status === "WAITING_FOR_ITEM_PICKUP" && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onPickupDetails(itemId);
                }}
              >
                <img src={certificateIcon} />
                Pickup Details
              </a>
            )}

            {invoiceURL && status !== "FINALIZING_WINNER" && (
              <a target="_blank" href={invoiceURL} download={true}>
                <img src={certificateIcon} />
                Certificate
              </a>
            )}

            {receiptURL && (
              <a target="_blank" href={receiptURL} download={true}>
                <img src={receiptIcon} />
                Receipt
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidsWon;
