import React from "react";
import classes from "./CreditCardsPanel.module.css";

const CreditCardsPanel = (props) => {
  return (
    <>
      <div className={classes.creditCardsContainer}>{props.children}</div>
    </>
  );
};

export default CreditCardsPanel;
