import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import PlaceholderHeading from "../credit-card/PlaceholderHeading";
import { useAuthContext } from "../../redux/auth-context";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import ZInputComponent from "../../components/UI/ZInputComponent";
import ConfirmModal from "../../components/UI/ConfirmModal";
import { DELETE_QUESTION, PUBLISH_QUESTION } from "../../Constants";
import { allQuestion, publishQuestion } from "../../services/AuctionItemService";

const QuestionDetails = () => {
  const { state } = useLocation();
  console.log(state);
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { miscData } = useSelector(
    ({ auth }) => ({
      miscData: auth.miscData,
    }),
    shallowEqual
  );

  const initialValue = { answer: "" };

  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const [answer, setAnswer] = useState();

  const schema = Yup.object().shape({
    answer: Yup.string().required("Answer is required"),
  });

  const deleteQuestion = () => {
    ctx.showProgress(true);
    publishQuestion(encodeURI(`${PUBLISH_QUESTION}${state.questionId}/${answer}`))
      .then((items) => {
        ctx.showProgress(false);
        navigate("/bidder-questions");
      })
      .catch((err) => {
        navigate("/bidder-questions");
      });
  };

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message="Do you confirm to publish this question"
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            deleteQuestion();
            //confirmPaymentReceived(itemId);
          }}
        />
      )}
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu questionActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <PlaceholderHeading title="Bidder Question Details"></PlaceholderHeading>
              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>
              <div className="row mt-3">
                <div className="col-9">
                  <div
                    className="table-responsive"
                    style={{ overflow: "hidden" }}
                  >
                    <table className={`table`}>
                      <tbody>
                        <tr>
                          <td>
                            <h5>Title : {state.itemName}</h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>Lot # : {state.lotNumber}</h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>Question: {state.qestion}</h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Formik
                              validateOnChange={false}
                              validateOnBlur={false}
                              enableReinitialize={true}
                              onSubmit={(values) => {setAnswer(values.answer);setShowConfirmBox(true);}}
                              validationSchema={schema}
                              initialValues={initialValue}
                            >
                              {({
                                handleSubmit,
                                errors,
                                setFieldValue,
                                resetForm,
                              }) => (
                                <form id="contactForm">
                                  <div className="row">
                                    <div className="col-md-12 mt-2">
                                      <label>
                                        <h5>Response</h5>
                                      </label>
                                      <Field
                                        type="textarea"
                                        name="answer"
                                        component={ZInputComponent}
                                        placeholder="Response"
                                      />
                                    </div>
                                    <div className="col-md-6 mt-5 text-left">
                                      <button
                                        className="btn btn-primary-dark-w px-5"
                                        type="button"
                                        onClick={async (e) => {
                                          navigate("/bidder-questions");
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="btn btn-secondary px-5 ml-5"
                                        type="submit"
                                        onClick={async (e) => {
                                          e.preventDefault();
                                          handleSubmit(e);
                                        }}
                                      >
                                        Publish
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-3">
                  <img
                    className="img-fluid"
                    src={state.imageLink}
                    alt="Image Description"
                    style={{
                      borderRadius: "10px",
                      width: "90%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default QuestionDetails;
