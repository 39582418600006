import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import classes from "./RegisterModule.css";
import ProgressBar from "react-bootstrap/ProgressBar";

function BiddingInfo() {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <label className="pg-4 pl-3 mt-2">
            Important Information on Bidding
          </label>
        </Modal.Header>
        <Modal.Body>
          {/* <ProgressBar variant="success" now={70} style={{height: '2px', background:'#E8E8E8'}} /> */}
          <div className="col-md-12">
            <p className="pg-1 mb-4">
              BidlyAuction provides an excellent avenue for SERIOUS BIDDERS to
              uncover exceptional deals. Before you register for bidding, please
              take note of these vital guidelines:
            </p>
            <label className="form-label font-weight-bold">
              01. Your Bid Equals a Binding Agreement.
            </label>
            <p className="pg-1 mb-4 pl-4">
              Placing a bid commits you to a legal agreement with BidlyAuction,
              government agencies, and schools, obligating you to purchase the
              item at your bid price if you win.
            </p>
            <label className="form-label font-weight-bold">
              02. Adequate Funds for Items and Bidder's Fee.
            </label>

            <p className="pg-1 mb-4 pl-4">
              Your bid signals that you possess adequate funds to acquire the
              item and cover the relevant bidder's fee using your credit card.
            </p>

            <label className="form-label font-weight-bold">
              03. Non-Retractable Bids.
            </label>
            <p className="pg-1 mb-4 pl-4">
              Bids should only be placed if you are genuinely prepared to buy
              the item(s) in their listed condition. We discourage bidder's
              remorse.
            </p>

            <p className="pg-1 mb-4 font-weight-bold">
              At BidlyAuction, we highly value our reputation. In collaboration
              with BidlyAuction and Sellers we support, we strictly condemn
              bidders who fail to honor their winning bids. Non-compliance with
              these rules may lead to permanent account restrictions and/or
              penalty charges by BidlyAuction.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-6">
            <p className="pg-1 mt-3">
              You may close this message after reading.
            </p>
          </div>
          <div className="col-md-6">
            <Button
              className="btn-add-cart btn-add-cart__wide btn-primary transition-3d-hover float-right"
              style={{ width: 100 }}
              variant="default"
              onClick={handleClose}
            >
              CLOSE
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default BiddingInfo;
