import React from "react";
import classes from "./HeadingArrow.module.css";

const HeadingArrow = ({ title }) => {
  return (
    <div className="position-relative bg-white text-center z-index-2">
      <ul className="nav nav-classic nav-tab justify-content-center">
        <li className="nav-item">
          <div
            className={`nav-link active bidly d-md-flex justify-content-md-center align-items-md-center ${classes.heading}`}
          >
            {title}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HeadingArrow;
