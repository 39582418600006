import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../../components/UI/HorizontalProgress";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../../components/UI/ZInputComponent";
import * as Yup from "yup";
import Label from "../../../components/UI/Label";
import PlaceholderHeading from "../../credit-card/PlaceholderHeading";
import {
  areAllPropertiesUndefined,
  scrollTo,
} from "../../../services/Utilities";
import { wizardGenericErrorMsg } from "../../../Constants";
import ErrorMessage from "../../../components/UI/ErrorMessage";
import ZRadioComponent from "../../../components/form/ZRadioComponent";
import DualErrorMessage from "../../../components/UI/DualErrorMessage";
import { Select } from "../../../components/Select";
import { useRef } from "react";
import WizardButtons from "./WizardButtons";
import { Link } from "react-router-dom";
import { verifyVinandFetchDetails } from "../../../services/AuctionItemService";
import { isJSON } from "../../../services/Utilities";
import { useAuthContext } from "../../../redux/auth-context";
import ReactQuill from "react-quill";

const AdditionalDetail = (props) => {
  const ctx = useAuthContext();
  const { step, data, serverErrors, tabDisable } = props;
  const { saveTab, closeWizard } = props;
  const { conditions } = props;
  const { isVehicleParam } = props;
  const { fetchSellerTerms } = props;

  const draftSubmitRef = useRef();
  const [isDraft, setIsDraft] = useState(false);
  const [enteredVin, setEnteredVin] = useState();
  const [vinVerificationError, setVinVerificationError] = useState();
  const [itemInspectionInstructions, setItemInspectionInstructions] =
    useState();
  const [itemRemovalInstructions, setItemRemovalInstructions] = useState();
  const [itemSpecialInstructions, setItemSpecialInstructions] = useState();
  const [itemAdditionalInformation, setItemAdditionalInformation] = useState();
  const [itemSellerTerms, setItemSellerTerms] = useState();

  const removalDefaultText =
    "The buyer is responsible for all aspects of pick-up / removal, including but not limited to packing, loading, transportation and exporting. Note: Shipping is NOT available.\nRemoval is by appointment only. All items must be picked-up / removed within 10 (ten) business days from auction closure, unless otherwise stated in the asset description. If any item(s) is not removed within the allotted timeframe, the item(s) may be declared abandoned.\nAt the time of pick-up / removal, you will be required to present the seller with a copy of your Buyer’s Certificate and/or bill of sale, and potentially other documentation (e.g., copy of driver's license, Authorization of Release, etc). When making a removal appointment, please confirm with the seller what is needed to release the item(s).";

  const specialDefaultText =
    "NOTICE: If you are the winning bidder and default by failing to adhere to this seller's terms and conditions, your account with BidlyAuction WILL BE LOCKED.\nGuaranty Waiver: All property is offered for sale “AS IS, WHERE IS.” BidlyAuction makes no warranty, guaranty or representation of any kind, expressed or implied, as to the merchantability or fitness for any purpose of the property offered for sale. Please note that upon removal of the property, all sales are final.";

  const inspectionDefaultText =
    "Most items offered for sale which are used may contain defects not immediately detectable. Bidders may inspect the property prior to bidding. Inspection is by appointment only. Please use the 'Ask a Question' feature to schedule an appointment for Inspection.";

  const tabInitValues = {
    customId: "",
    condition: "",
    inspectionPersonName: "",
    inspectionPersonContact: "",
    inspectionInformation: inspectionDefaultText,
    inspectionInformationStyled: inspectionDefaultText,
    removalInformation: removalDefaultText,
    removalInformationStyled: removalDefaultText,
    specialInstructions: specialDefaultText,
    specialInstructionsStyled: specialDefaultText,
    additionalInformation: "",
    additionalInformationStyled: "",
    sellerTerms: "",
    sellerTermsStyled: "",
    isVehicle: "",
    vehicleStarts: undefined,
    vehicleDriveable: undefined,
    vehicleTransferrable: undefined,
    vehicleMiles: undefined,
    vin: "",
    vehicleMake: "",
    vehicleYear: "",
    vehicleModel: "",
  };

  const sellerTermsError = (
    <>
      Please provide Seller Terms and conditions. You may save your terms and
      conditions for future use by clicking{" "}
      <Link to="/add-seller-terms">Here</Link>
    </>
  );

  const schema = Yup.object().shape({
    customId: Yup.string().optional().max(32, "Maximum 32 characters allowed"),
    condition: Yup.string().required("Item condition is required"),
    inspectionPersonName: Yup.string().max(32, "Maximum 32 characters allowed"),
    inspectionPersonContact: Yup.string().max(
      32,
      "Maximum 32 characters allowed"
    ),
    inspectionInformation: Yup.string().max(
      2048,
      "Maximum 2048 characters allowed"
    ),
    inspectionInformationStyled: Yup.string().optional(),
    removalInformation: Yup.string().max(
      2048,
      "Maximum 2048 characters allowed"
    ),
    removalInformationStyled: Yup.string().optional(),
    specialInstructions: Yup.string().max(
      2048,
      "Maximum 2048 characters allowed"
    ),
    specialInstructionsStyled: Yup.string().optional(),
    additionalInformation: Yup.string().max(
      2048,
      "Maximum 2048 characters allowed"
    ),
    additionalInformationStyled: Yup.string().optional(),
    sellerTerms: Yup.string()
      .required(sellerTermsError)
      .max(20000, "Maximum 20000 characters allowed"),
    sellerTermsStyled: Yup.string().optional(),
    isVehicle: Yup.string().required("Vehicle information is required"),
    vehicleStarts: Yup.string().when("isVehicle", {
      is: "yes",
      then: Yup.string().required("Information is required"),
    }),
    vehicleDriveable: Yup.string().when("isVehicle", {
      is: "yes",
      then: Yup.string().required("Information is required"),
    }),
    vehicleTransferrable: Yup.string().when("isVehicle", {
      is: "yes",
      then: Yup.string().required("Information is required"),
    }),
    vehicleMiles: Yup.string().when("isVehicle", {
      is: "yes",
      then: Yup.string().required("Information is required"),
    }),
    vin: Yup.string().when("isVehicle", {
      is: "yes",
      then: Yup.string().required("Information is required"),
    }),
    vehicleMake: Yup.string().when("isVehicle", {
      is: "yes",
      then: Yup.string().required("Information is required"),
    }),
    vehicleYear: Yup.string().when("isVehicle", {
      is: "yes",
      then: Yup.string().required("Information is required"),
    }),
    vehicleModel: Yup.string().when("isVehicle", {
      is: "yes",
      then: Yup.string().required("Information is required"),
    }),
  });

  const [tabData, setTabData] = useState(tabInitValues);
  const [tabErrors, setTabErrors] = useState(serverErrors);

  const populateTabData = async (inputData) => {
    console.log("data in additional tab", inputData);

    let sellerTerms = undefined;
    try {
      sellerTerms = await fetchSellerTerms();
    } catch (e) {
      console.log(e);
    }

    console.log("fetch seller terms", sellerTerms);

    setTabData((state) => {
      let tempState = {
        ...state,
        sellerTerms: sellerTerms,
        sellerTermsStyled: sellerTerms,
      };
      console.log("temp state", tempState);

      setItemInspectionInstructions(tempState?.inspectionInformationStyled);
      setItemRemovalInstructions(tempState?.removalInformationStyled);
      setItemSpecialInstructions(tempState?.specialInstructionsStyled);
      setItemAdditionalInformation(tempState?.additionalInformationStyled);
      setItemSellerTerms(tempState?.sellerTermsStyled);

      if (
        inputData &&
        Object.values(inputData)?.length > 0 &&
        !areAllPropertiesUndefined(inputData)
      ) {
        console.log("input data", inputData);
        tempState = { ...tempState, ...inputData };
        console.log("merged data", { ...tempState, ...inputData });

        setItemInspectionInstructions(tempState?.inspectionInformationStyled);
        setItemRemovalInstructions(tempState?.removalInformationStyled);
        setItemSpecialInstructions(tempState?.specialInstructionsStyled);
        setItemAdditionalInformation(tempState?.additionalInformationStyled);
        setItemSellerTerms(tempState?.sellerTermsStyled);
      }

      return tempState;
    });
  };

  useEffect(() => {
    populateTabData(data);
  }, []);

  useEffect(() => {
    populateTabData(data);
  }, [data]);

  useEffect(() => {
    setTabErrors(serverErrors);
  }, [serverErrors]);

  useEffect(() => {
    console.log("is vehicle param", isVehicleParam);

    setTabData((state) => {
      const tempState = {
        ...state,
        isVehicle: isVehicleParam,
      };
      return tempState;
    });
  }, [isVehicleParam]);

  const verifyVin = (setFieldValue) => {
    ctx.showProgress(true);
    console.log("VIN Verification Request: " + enteredVin);
    const vinRequest = {};
    vinRequest.vin = enteredVin; //"1GNALDEK9FZ108495";
    verifyVinandFetchDetails(vinRequest)
      .then((data) => {
        ctx.showProgress(false);
        console.log("Data:: " + data);
        setFieldValue("vehicleMake", data.make);
        setFieldValue("vehicleModel", data.model);
        setFieldValue("vehicleYear", data.year);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          setVinVerificationError(
            "Something went wrong! Please try again later"
          );
          return;
        }
        console.log("Error: " + e.message);
        const json = JSON.parse(e.message);
        setVinVerificationError(json.data.message);

        setFieldValue("vehicleMake", "");
        setFieldValue("vehicleModel", "");
        setFieldValue("vehicleYear", "");
      });
  };

  return (
    <>
      <PlaceholderHeading title="Additional Details">
        <button
          disabled={tabDisable}
          className="btn-block btn btn-outline-primary px-4"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            closeWizard();
          }}
        >
          Close
        </button>
      </PlaceholderHeading>
      <div className="mt-3 mb-2">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mt-5 instructions-desc">
            Specific details on your auction and items.
          </p>

          <div className="row" id="pageError">
            <div className="col-md-12">
              {tabErrors && tabErrors.message && (
                <div className="mb-4">
                  <ErrorMessage position="top">
                    {tabErrors.message}
                  </ErrorMessage>
                </div>
              )}
            </div>
          </div>

          <fieldset disabled={tabDisable}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={tabData}
              validationSchema={schema}
              onSubmit={(values) => {
                console.log("submitted values", values);

                saveTab(
                  step,
                  {
                    ...tabData,
                    ...values,
                  },
                  isDraft
                );
              }}
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                validateForm,
              }) => (
                <form>
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          name="customId"
                          highlightedLabel
                          component={ZInputComponent}
                          placeholder="Enter Custom ID"
                          label="Custom ID"
                          serverError={serverErrors && serverErrors.customId}
                        />
                        <p className="mb-0 mt-2">
                          An internal asset number or any other identifier you
                          might need for this item
                        </p>
                      </div>

                      <div className="js-form-message mb-4">
                        <Select
                          name="condition"
                          className="form-control"
                          label="Item Condition"
                          mandatory={true}
                          highlightedLabel
                          defaultTouch
                          serverError={serverErrors && serverErrors.condition}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFieldValue("condition", e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Item Condition
                          </option>

                          {conditions &&
                            conditions.length > 0 &&
                            conditions.map((condition) => {
                              return (
                                <option key={condition.id} value={condition.id}>
                                  {condition.name}
                                </option>
                              );
                            })}
                        </Select>
                      </div>

                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          name="inspectionPersonName"
                          highlightedLabel
                          component={ZInputComponent}
                          placeholder="Enter item inspection contact person"
                          label="Inspection Contact Person"
                          serverError={
                            serverErrors && serverErrors.inspectionPersonName
                          }
                        />
                      </div>

                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          name="inspectionPersonContact"
                          highlightedLabel
                          component={ZInputComponent}
                          placeholder="Enter item inspection contact person"
                          label="Inspection Person Contact Number"
                          serverError={
                            serverErrors && serverErrors.inspectionPersonContact
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted>Inspection Instructions</Label>

                        <ReactQuill
                          theme="snow"
                          value={itemInspectionInstructions}
                          onChange={(m) => {
                            setItemInspectionInstructions(m);

                            const ele = document.createElement("div");
                            ele.innerHTML = m;

                            setFieldValue(
                              "inspectionInformation",
                              ele.textContent
                            );
                            setFieldValue("inspectionInformationStyled", m);
                          }}
                          placeholder="Enter Inspection Instructions"
                          rows
                        />

                        <DualErrorMessage
                          fieldName="inspectionInformation"
                          serverError={
                            serverErrors && serverErrors.inspectionInformation
                          }
                        />
                      </div>

                      <div className="js-form-message mb-4">
                        <Label hightlighted>Removal Instructions</Label>

                        <ReactQuill
                          theme="snow"
                          value={itemRemovalInstructions}
                          onChange={(m) => {
                            setItemRemovalInstructions(m);

                            const ele = document.createElement("div");
                            ele.innerHTML = m;

                            setFieldValue(
                              "removalInformation",
                              ele.textContent
                            );
                            setFieldValue("removalInformationStyled", m);
                          }}
                          placeholder="Enter Removal Instructions"
                          rows
                        />

                        <DualErrorMessage
                          fieldName="removalInformation"
                          serverError={
                            serverErrors && serverErrors.removalInformation
                          }
                        />
                      </div>

                      <div className="js-form-message mb-4">
                        <Label hightlighted>Special Instructions</Label>

                        <ReactQuill
                          theme="snow"
                          value={itemSpecialInstructions}
                          onChange={(m) => {
                            setItemSpecialInstructions(m);

                            const ele = document.createElement("div");
                            ele.innerHTML = m;

                            setFieldValue(
                              "specialInstructions",
                              ele.textContent
                            );
                            setFieldValue("specialInstructionsStyled", m);
                          }}
                          placeholder="Enter Special Instructions"
                          rows
                        />

                        <DualErrorMessage
                          fieldName="specialInstructions"
                          serverError={
                            serverErrors && serverErrors.specialInstructions
                          }
                        />
                      </div>

                      <div className="js-form-message mb-4">
                        <Label hightlighted>Additional Instructions</Label>

                        <ReactQuill
                          theme="snow"
                          value={itemAdditionalInformation}
                          onChange={(m) => {
                            setItemAdditionalInformation(m);

                            const ele = document.createElement("div");
                            ele.innerHTML = m;

                            setFieldValue(
                              "additionalInformation",
                              ele.textContent
                            );
                            setFieldValue("additionalInformationStyled", m);
                          }}
                          placeholder="Enter Additional Information"
                          rows
                        />

                        <DualErrorMessage
                          fieldName="additionalInformation"
                          serverError={
                            serverErrors && serverErrors.additionalInformation
                          }
                        />
                      </div>

                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Seller Terms & Conditions
                        </Label>

                        <ReactQuill
                          theme="snow"
                          value={itemSellerTerms}
                          onChange={(m) => {
                            setItemSellerTerms(m);

                            const ele = document.createElement("div");
                            ele.innerHTML = m;

                            setFieldValue("sellerTerms", ele.textContent);
                            setFieldValue("sellerTermsStyled", m);
                          }}
                          placeholder="Enter Seller Terms & Conditions"
                          rows
                        />

                        <DualErrorMessage
                          fieldName="sellerTerms"
                          serverError={serverErrors && serverErrors.sellerTerms}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7 col-12">
                      {tabData.isVehicle === "yes" && (
                        <>
                          <div className="js-form-message mb-4">
                            <Label hightlighted required>
                              Does it start
                            </Label>
                            <Field
                              className="form-control"
                              name="vehicleStarts"
                              radioName="vehicleStarts"
                              items={[
                                {
                                  value: "YES",
                                  label: "Yes",
                                },
                                {
                                  value: "NO",
                                  label: "No",
                                },
                                { value: "UNSURE", label: "Unsure" },
                              ]}
                              component={ZRadioComponent}
                              handleOnChange={(value) => {
                                setFieldValue("vehicleStarts", value);
                              }}
                            />

                            <DualErrorMessage
                              fieldName="vehicleStarts"
                              serverError={
                                serverErrors && serverErrors.vehicleStarts
                              }
                            />
                          </div>

                          <div className="js-form-message mb-4">
                            <Label hightlighted required>
                              Is it lot tested
                            </Label>
                            <Field
                              className="form-control"
                              name="vehicleDriveable"
                              radioName="vehicleDriveable"
                              items={[
                                {
                                  value: "YES",
                                  label: "Yes",
                                },
                                {
                                  value: "NO",
                                  label: "No",
                                },
                                { value: "UNSURE", label: "Unsure" },
                              ]}
                              component={ZRadioComponent}
                              handleOnChange={(value) => {
                                setFieldValue("vehicleDriveable", value);
                              }}
                            />

                            <DualErrorMessage
                              fieldName="vehicleDriveable"
                              serverError={
                                serverErrors && serverErrors.vehicleDriveable
                              }
                            />
                          </div>

                          <div className="js-form-message mb-4">
                            <Label hightlighted required>
                              Vehicle has a transferable title
                            </Label>
                            <Field
                              className="form-control"
                              name="vehicleTransferrable"
                              radioName="vehicleTransferrable"
                              items={[
                                {
                                  value: "YES",
                                  label: "Yes",
                                },
                                {
                                  value: "NO",
                                  label: "No",
                                },
                                { value: "UNSURE", label: "Unsure" },
                              ]}
                              component={ZRadioComponent}
                              handleOnChange={(value) => {
                                setFieldValue("vehicleTransferrable", value);
                              }}
                            />
                            <p className="mb-0 mt-2">
                              Does this vehicle come with a title that can be
                              transferred to the bidder?
                            </p>

                            <DualErrorMessage
                              fieldName="vehicleTransferrable"
                              serverError={
                                serverErrors &&
                                serverErrors.vehicleTransferrable
                              }
                            />
                          </div>

                          <div className="js-form-message mb-4">
                            <Field
                              className="form-control"
                              name="vehicleMiles"
                              highlightedLabel
                              required
                              component={ZInputComponent}
                              placeholder="Enter Miles"
                              label="Miles"
                              serverError={
                                serverErrors && serverErrors.vehicleMiles
                              }
                            />
                          </div>

                          <div className="js-form-message mb-4">
                            <label className="form-label highlighted">
                              Vin <span className="color-red">*</span>
                            </label>
                            <div class="input-group mb-3">
                              <input
                                type="text"
                                class="form-control"
                                name="vin"
                                placeholder="Enter Vin"
                                value={values.vin}
                                onChange={(e) => {
                                  setEnteredVin(e.target.value);
                                  setFieldValue("vin", e.target.value);
                                  console.log(e.target.value);
                                }}
                              />
                              <div class="input-group-append">
                                <span
                                  class="input-group-text p-0"
                                  id="basic-addon2"
                                  style={{
                                    borderRadius: "7px",
                                    borderStartStartRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                  }}
                                >
                                  <Link
                                    className="btn-block btn btn-primary-dark-w px-5"
                                    style={{
                                      height: "43px",
                                      borderStartStartRadius: "0px",
                                      borderBottomLeftRadius: "0px",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (!enteredVin) {
                                        setVinVerificationError(
                                          "Information is required"
                                        );
                                        return;
                                      }
                                      verifyVin(setFieldValue);
                                    }}
                                  >
                                    Verify
                                  </Link>
                                </span>
                              </div>
                              <DualErrorMessage
                                fieldName="vin"
                                serverError={
                                  (serverErrors && serverErrors.vin) ||
                                  vinVerificationError
                                }
                              />
                            </div>
                          </div>

                          {/* <div className="js-form-message mb-4">
                            <label className="form-label highlighted">Vin <span className="color-red">*</span></label>
                            <div class="input-group mb-3">
                              <Field
                                className="form-control"
                                name="vin"
                                required
                                component={ZInputComponent}
                                placeholder="Enter Vin"
                                onChange={(e) => {
                                  setEnteredVin(e.target.value);
                                  setFieldValue("vin", e.target.value);
                                }}
                              />
                              <div class="input-group-append">
                                <span class="input-group-text p-0" id="basic-addon2"
                                style={{borderRadius: "7px", borderStartStartRadius: "0px", borderBottomLeftRadius: "0px"}}>
                                <Link className="btn-block btn btn-primary-dark-w px-5"
                                  style={{height: "43px", borderStartStartRadius: "0px", borderBottomLeftRadius: "0px"}}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if(!enteredVin){
                                      setVinVerificationError("Information is required");
                                      return;
                                    }
                                    verifyVin(setFieldValue);
                                    }}>
                                    Verify
                                </Link>
                                </span>
                              </div>
                              <DualErrorMessage
                                fieldName="vin"
                                serverError={
                                  (serverErrors && serverErrors.vin) || vinVerificationError
                                }
                              />
                            </div>
                          </div> */}

                          <div className="js-form-message mb-4">
                            <Field
                              className="form-control"
                              name="vehicleYear"
                              highlightedLabel
                              required
                              component={ZInputComponent}
                              placeholder="Enter Year"
                              label="Year"
                              serverError={
                                serverErrors && serverErrors.vehicleYear
                              }
                            />
                          </div>

                          <div className="js-form-message mb-4">
                            <Field
                              className="form-control"
                              name="vehicleMake"
                              highlightedLabel
                              required
                              component={ZInputComponent}
                              placeholder="Enter Make"
                              label="Make"
                              serverError={
                                serverErrors && serverErrors.vehicleMake
                              }
                            />
                          </div>

                          <div className="js-form-message mb-4">
                            <Field
                              className="form-control"
                              name="vehicleModel"
                              highlightedLabel
                              required
                              component={ZInputComponent}
                              placeholder="Enter Model"
                              label="Model"
                              serverError={
                                serverErrors && serverErrors.vehicleModel
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="js-form-message mb-4 row">
                    <div className="col-12">
                      <WizardButtons>
                        <button
                          className="btn btn-primary-dark-w px-5"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(false);
                            setTabErrors({});
                            let res = await validateForm();
                            console.log("validation errors", res);
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save and Continue
                        </button>

                        <button
                          ref={draftSubmitRef}
                          className="btn btn-outline-primary px-5"
                          type="button"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(true);
                            setTabErrors({});
                            let res = await validateForm();
                            console.log("validation errors", res);
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save Draft
                        </button>
                      </WizardButtons>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default AdditionalDetail;
