import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import regsuccess from "../../assets/images/regsuccess.svg";
import style from "./RegisterModule.css";
import classes from "../search/Search.module.css";
import Layout from "../../layout/Layout";

const RegistrationSuccessfull = () => {
  return (
    <Layout>
      <div className="container">
        <div className="row mt-5 mb-10">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <section className="section-onsale-product ">
              <div className="text-center mb-5">
                <img
                  src={regsuccess}
                  className="notification-logo img-responsive"
                  alt="envelop img"
                />
              </div>
              <h2 className="pg-4 font-weight-bold text-center purple-500">
                Successfully Registered
              </h2>
              <h3
                className={`nav-classic ${classes.searchSection} ${classes.asSearchTitle} mb-0 pb-2 font-size-25 section-title text-center`}
              ></h3>
              <p className="pg-1 text-center mt-5 font-weight-bold">
                Welcome to BidlyAuction!
              </p>
              <p className="text-center font-weight-bold">
                We're excited to have you here!
              </p>
              <p className="text-center">
                Discover amazing deals on items for your business, hobbies, or
                daily needs with just a few clicks.
              </p>
              <p className="text-center pg-1">
                Our auctions feature a wide range of unique items sourced
                directly from government agencies, schools, organizations,
                community groups, or individuals. These items are scheduled for
                replacement, no longer required, forfeited, or from lost and
                found collections.
              </p>
              <p className="text-center pg-1">
                To ensure you have the best BidlyAuction experience, let us
                guide you through important details about how BidlyAuction
                Works.
              </p>
              {/* <div className="text-center">
                <Link className="link-v1">How BidlyAuction Works.</Link>
              </div> */}
              <div className="d-flex flex-row justify-content-center">
                <Link
                  to="/login"
                  className="btn btn-primary-dark-w text-transform-upper mt-1"
                >
                  Start Browsing
                </Link>
              </div>
            </section>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </Layout>
  );
};

export default RegistrationSuccessfull;
