export const PhoneTypes = [
    {
      label: "Business",
      value: "BUSINESS"
    },
    {
      label: "Cell",
      value: "CELL"
    },
    {
      label: "Fax",
      value: "FAX"
    },
    {
      label: "Home",
      value: "HOME"
    },
    {
      label: "Pager",
      value: "PAGER"
    },
    {
      label: "Other",
      value: "OTHER"
    }
  ];
  





