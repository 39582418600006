import {
  SELLER_AUCTION_CHOOSE_WINNER_URL,
  SELLER_AUCTION_CONFIRM_PAYMENT_RECEIVED_URL,
  SELLER_AUCTION_DELETE_ITEM_URL,
  SELLER_AUCTION_SEND_INVOICE_URL,
  SELLER_AUCTION_WINNER_REJECT_URL,
  AUCTION_VERIFY_VIN_URL,
  SELLER_AUCTION_CLOSE_URL,
  SELLER_AUCTION_CONFIRM_ITEM_PICKUP_URL,
} from "../Constants";
import authenticatedFetch, { fetchWithFormData } from "./fetch";

export async function verifyVinandFetchDetails(requestBody) {
  const response = await authenticatedFetch(`${AUCTION_VERIFY_VIN_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });
  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function fetchMyListings(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function fetchMyItem(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function listSimilaryMyItem(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function chooseAuctionWinner(itemId, winnerId) {
  const response = await authenticatedFetch(
    `${SELLER_AUCTION_CHOOSE_WINNER_URL.replace("{itemId}", itemId).replace(
      "{winningId}",
      winnerId
    )}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function rejectAuctionWinner(itemId) {
  const response = await authenticatedFetch(
    `${SELLER_AUCTION_WINNER_REJECT_URL.replace("{itemId}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function paymentConfirmation(itemId, winnerId) {
  const response = await authenticatedFetch(
    `${SELLER_AUCTION_CONFIRM_PAYMENT_RECEIVED_URL.replace(
      "{itemId}",
      itemId
    )}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function itemPickupConfirmation(itemId) {
  const response = await authenticatedFetch(
    `${SELLER_AUCTION_CONFIRM_ITEM_PICKUP_URL.replace("{itemId}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function deleteItem(itemId) {
  const response = await authenticatedFetch(
    `${SELLER_AUCTION_DELETE_ITEM_URL.replace("{itemId}", itemId)}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function endAuctionEarly(itemId) {
  const response = await authenticatedFetch(
    `${SELLER_AUCTION_CLOSE_URL.replace("{itemId}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function saveTabServerRequest(
  URL,
  requestBody,
  expectingResponseBody = true
) {
  const response = await authenticatedFetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: requestBody ? JSON.stringify(requestBody) : "",
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  if (expectingResponseBody) {
    try {
      return await response.json();
    } catch (e) {
      throw new Error(JSON.stringify({ ...errorMessage, data: e }));
    }
  }

  return response;
}

export async function saveMediaServerRequest(URL, media) {
  const formData = new FormData();

  try {
    formData.append("itemId", media?.itemId);

    const removeImages = [];
    media?.removeImages?.map((v) => {
      removeImages.push(v);
    });
    formData.append("removeImages", removeImages);

    const removeYoutubeUrls = [];
    media?.removeYoutubeUrls?.map((v) => {
      removeYoutubeUrls.push(v);
    });
    formData.append("removeYoutubeUrls", removeYoutubeUrls);

    const removeDocuments = [];
    media?.removeDocuments?.map((v) => {
      removeDocuments.push(v);
    });
    formData.append("removeDocuments", removeDocuments);

    const youtubeLinks = [];
    media?.youtubeURLs?.map((v) => {
      youtubeLinks.push(v.url);
    });
    if (youtubeLinks || Object.keys(youtubeLinks).length > 0) {
      formData.append("youtubeURLs", youtubeLinks);
    }

    media?.galleryImages?.map((v) => {
      formData.append("galleryImages[]", v.file);
    });

    media?.documents?.map((v) => {
      formData.append("documents[]", v.file);
    });
  } catch (e) {
    console.log(e);
  }

  const response = await fetchWithFormData(`${URL}`, {
    method: "POST",
    body: formData,
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function sendAuctionInvoice(itemId) {
  const response = await authenticatedFetch(
    `${SELLER_AUCTION_SEND_INVOICE_URL.replace("{itemId}", itemId)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  return response;
}

export async function allQuestion(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}

export async function publishQuestion(URL) {
  const response = await authenticatedFetch(`${URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}
