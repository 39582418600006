import React from "react";
import unreadIcon from "../../assets/images/green-dot.svg";
import optionsIcon from "../../assets/images/dots-more.svg";
import importantIcon from "../../assets/images/exclaim.svg";
import classes from "./NotificationBar.module.css";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { useAuthContext } from "../../redux/auth-context";
import { fetchMyBids } from "../../services/MyBidsService";
import {
  NAVIGATION_PAGES,
  NOTIFICATION_WINNING_BID_INFO_URL,
} from "../../Constants";
import { isStopFlowConditionMet, newDateObjectUTC } from "../../services/Utilities";

const PaymentNotification = ({ data }) => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const proceedToCheckout = (itemId) => {
    ctx.showProgress(true);
    fetchMyBids(NOTIFICATION_WINNING_BID_INFO_URL.replace("{itemId}", itemId))
      .then((rs) => {
        ctx.showProgress(false);

        let fromPage = undefined;
        if (
          rs?.auction?.type === "ONLINE_AUCTION" ||
          rs?.auction?.type === "SEALED_AUCTION"
        )
          fromPage = NAVIGATION_PAGES.BIDS_WON;
        else if (rs?.auction?.type === "MAKE_AN_OFFER")
          fromPage = NAVIGATION_PAGES.OFFERS_WON;
        else fromPage = NAVIGATION_PAGES.ITEMS_BOUGHT;

        navigate("/bid-won-checkout-selection", {
          state: { selected: [rs] },
          from: fromPage,
        });
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  return (
    <>
      <div className={`row ${classes.bar}`}>
        <div className="col-auto">
          <div className={`d-flex ${classes.icons} `}>
            {false && <img src={unreadIcon} />}
            <img src={importantIcon} />
          </div>
        </div>
        <div className="col pl-0">
          <p className="purple-500">Payment is due</p>
          <p>
            {data.item.title} - Lot # {data.item.customId}
          </p>
          <p>
            <span className="secondary-500">
              <ReactTimeAgo
                date={new Date(newDateObjectUTC(data.createdOn ? data.createdOn?.replaceAll("T", " ") + "GMT" : new Date()))}
                locale="en-US"
                timeStyle="round"
              />
            </span>
          </p>

          <Link
            className="btn px-4 py-2 my-3 btn-primary-dark-w"
            onClick={(e) => {
              e.preventDefault();
              proceedToCheckout(data.item.itemId);
            }}
          >
            PAY NOW
          </Link>
        </div>
        {false && (
          <div className="col-auto pl-0">
            <Link>
              <img src={optionsIcon} />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
export default PaymentNotification;
