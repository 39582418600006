import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import classes from "./Static.module.css";
import LinkButton from "../../components/button/LinkButton";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { isSeller } from "../../services/Utilities";
import { LibraryService } from "../../services/LibraryService";
import { ALL_REVIEWS, TOPIC_COUNT } from "../../Constants";
import { useAuthContext } from "../../redux/auth-context";

const Help = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const [topic, setTopic] = useState([]);

  useEffect(() => {
    ctx.showProgress(true);
    LibraryService(TOPIC_COUNT)
      .then((res) => {
        setTopic(res);
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
      });
  }, []);

  return (
    <Layout>
      <>
        <div className="mb-5">
          <div className={`bg-img-hero ${classes.bannerImgHelp}`}>
            <div className="container min-height-300 overflow-hidden d-flex justify-content-md-start justify-content-start align-items-center">
              <div className={` ${classes.bannerTextContainer} col-md-7 mt-5`}>
                <h2 className={classes.heading}>
                  Advice and answers from the BidlyAuction Team
                </h2>
                <p className={classes.para}>
                  Have a question? We'll be happy to help.
                </p>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className="mb-7">
            <div className="container min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
              <div className={classes.helpList}>
                <div className={`${classes.textContainer}  p-4`}>
                  <div className="row">
                    {topic.map((data) => {
                      return (
                        <div className="col-md-12 mt-2">
                          <div className={classes.stepsCard}>
                            <div className="p-3 text-left">
                              <h4 className={`${classes.heading2} mb-0 text-left`}>
                                <Link to={`/article/${data.key}`}>{data.key}</Link>
                              </h4>
                              <p className={`${classes.para} text-left m-0`}>
                                Help documents for Bidder
                              </p>
                              <p className={`${classes.para} text-left m-0`}>
                                {data.value} Articles Written By BidlyAuction Team
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </Layout>
  );
};

export default Help;
