import React, { useEffect, useMemo, useState } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { useField, useFormikContext } from "formik";
import ReactSelect from "react-select";
import Icon from "../assets/images/select_icon.svg";

export function Select({
  label,
  highlightedLabel = false,
  multiple = false,
  withFeedbackLabel = true,
  customFeedbackLabel,
  children,
  searchable = true,
  mandatory = false,
  onChange = (e) => {},
  serverError = "",
  ...props
}) {
  const [localValue, setLocalValue] = useState();
  const [localOptions, setLocalOptions] = useState();

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  const [placeholderText, setPlaceholderText] = useState("");

  useEffect(() => {
    if (field.value == "") {
      setLocalValue("");
    } else {
      setLocalValue(localOptions?.filter((opt) => opt.value == field.value));
    }
  }, [field.value, localOptions]);

  useEffect(() => {
    let templist = [];
    if (children && children.length > 0) {
      children?.forEach((child) => {
        if (Array.isArray(child)) {
          child?.forEach(
            (innerChild) =>
              innerChild &&
              templist.push({
                value: innerChild?.props?.value,
                label: innerChild?.props?.children,
              })
          );
        } else {
          templist.push({
            value: child?.props?.value,
            label: child?.props?.children,
          });
        }
      });
    }
    setLocalOptions(templist);

    setPlaceholderText(
      props?.placeholder ??
        (label
          ? `Select ${label}`
          : localOptions
          ? localOptions[0]?.label
          : "Select ...")
    );
  }, [children, field.value]);

  return (
    <>
      {label && (
        <label className={`${highlightedLabel ? "highlighted" : ""}`}>
          {label}
          {mandatory
            ? true && (
                <>
                  <span className="color-red"> *</span>
                </>
              )
            : ""}
        </label>
      )}
      <ReactSelect
        isMulti={multiple}
        value={localValue}
        options={localOptions}
        isSearchable={searchable}
        onChange={(object) => {
          setLocalValue(object);
          setFieldValue(field.name, object);
          onChange({
            target: {
              value: object.value,
            },
          });
        }}
        onBlur={(e) => {
          setFieldTouched(field.name, true, false);
        }}
        components={{
          DropdownIndicator: () => <img className="mr-3" src={Icon} />,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (styles) => ({
            ...styles,
            border: "1px solid #339966",
            borderRadius: "6px",
            minHeight: "45px",
          }),
        }}
        defaultTouch
        placeholder={placeholderText}
        isDisabled={props.disabled}
      />

      {withFeedbackLabel && serverError && (
        <FieldFeedbackLabel
          error={serverError}
          touched={true}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}

      {withFeedbackLabel && error && !serverError && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
