import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import classes from "./FileUploader.module.css";
import ErrorMessage from "../UI/ErrorMessage";
import addIcon from "../../assets/images/add-img-icon.svg";
import removeIcon from "../../assets/images/remove-red-icon.svg";
import pdfIcon from "../../assets/images/pdf-icon-lg.svg";
import { useRef } from "react";

const FileUploader = ({
  label = "Add File",
  initialFiles,
  showErrors = false,
  errorEvent = (errors) => {},
  showUploaded = true,
  listChanged = (list) => {},
}) => {
  const [file, setFile] = useState([]);
  const [shownList, setShownList] = useState([]);
  const [errors, setErrors] = useState({});
  const maxNumber = 10;

  const inputRef = useRef();

  useEffect(() => {
    setShownList(initialFiles);
  }, [initialFiles]);

  useEffect(() => {
    listChanged(shownList);
  }, [shownList]);

  const onChange = (event) => {
    const fileObj = {
      fileName: event.target.files[0].name,
      file: event.target.files[0],
    };
    setFile(event.target.files[0]);
    setShownList((prev) => [...prev, { ...fileObj, uploaded: false }]);
    event.target.value = null;
  };

  return (
    <>
      <input
        type="file"
        multiple
        onChange={onChange}
        dataURLKey="data_url"
        accept=".pdf"
        ref={inputRef}
        style={{ display: "none" }}
      />

      <div className="upload__image-wrapper">
        <button
          className="btn btn-primary-dark-w px-5"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            inputRef.current.click();
          }}
        >
          {label}
        </button>
      </div>

      {showUploaded && (
        <div className="mt-4 mb-4">
          <FileItems
            items={shownList}
            removeClicked={(index, meta) => {
              let rem = [...shownList];
              rem.splice(index, 1);
              setShownList([...rem]);
            }}
          />
        </div>
      )}

      {showErrors && errors && (
        <ErrorMessage>
          {errors.maxNumber && (
            <span className="block">
              Number of selected images exceed maximum number
            </span>
          )}
          {errors.acceptType && (
            <span className="block">
              Your selected file type is not allowed
            </span>
          )}
          {errors.maxFileSize && (
            <span className="block">
              Selected file size exceed maximum file size
            </span>
          )}
          {errors.resolution && (
            <span className="block">
              Selected file is not match your desired resolution
            </span>
          )}
        </ErrorMessage>
      )}
    </>
  );
};

export const FileItems = ({ items, removeClicked = (index, meta) => {} }) => {
  return (
    <div className={classes.uploadedRow}>
      {items?.map((uImage, index) => {
        return (
          <FileTile
            key={index}
            index={index}
            meta={uImage}
            fileName={uImage.fileName}
            image={uImage.data_url}
            removeClicked={removeClicked}
          />
        );
      })}
    </div>
  );
};

const FileTile = ({
  index,
  meta,
  fileName,
  removeClicked = (index, meta) => {},
}) => {
  const tile = (
    <>
      <div className={classes.fileName} title={fileName}>
        {fileName}
      </div>
      <img
        src={removeIcon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          removeClicked(index, meta);
        }}
      />
    </>
  );

  return (
    <div
      className={classes.tile}
      style={{
        backgroundImage: `url(${pdfIcon})`,
      }}
    >
      {meta?.uploaded && (
        <a
          href={`${meta?.uploaded ? meta.value : "#"}`}
          download={true}
          className={classes.tile}
        >
          {tile}
        </a>
      )}

      {!meta?.uploaded && tile}
    </div>
  );
};

export default FileUploader;
