import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import CheckedoutItems from "./CheckedoutItems";
import * as Yup from "yup";
import { LibraryService } from "../../services/LibraryService";
import {
  ALL_STATES_LIBRARY_URL,
  CITIES_LIBRARY_URL,
  COUNTRIES_LIBRARY_URL,
  genericErrorMsg,
  INVOICE_CARD_PAYMENT_INIT_URL,
} from "../../Constants";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../../redux/auth-context";
import ErrorMessage from "../../components/UI/ErrorMessage";
import { Field, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Select } from "../../components/Select";
import {
  isJSON,
  isStopFlowConditionMet,
  scrollTo,
} from "../../services/Utilities";
import { initCardPayment } from "../../services/MyBidsService";
import { actionTypes } from "../_redux/authRedux";

const CheckoutCardBillingInfo = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [navigationFrom, setNavigationFrom] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const params = useLocation()?.state;

  const [pageErrors, setPageErrors] = useState();
  const [serverErrors, setServerErrors] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  useEffect(() => {
    console.log(params?.selected);
    setSelected((prev) => {
      setTotalAmount(
        params?.selected.reduce((total, cur) => total + cur.total, 0)
      );
      return params?.selected;
    });

    setNavigationFrom(params?.from);
  }, [params]);

  const initPayment = (values) => {
    ctx.showProgress(true);

    initCardPayment(values, [...selected].shift().auction.itemId)
      .then((data) => {
        ctx.showProgress(false);

        dispatch({
          type: actionTypes.SetCreditCardItemTracking,
          payload: {
            billingInfo: { ...values },
            itemId: [...selected].shift().auction.itemId,
            from: { ...navigationFrom },
          },
        });

        navigate("/bid-card-info", {
          state: {
            selected: selected,
            clientSecret: data.clientSecret,
            from: navigationFrom,
          },
        });
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            navigate("/error");
          }
        }
      });
  };

  const fetchLocations = async () => {
    try {
      const countryRs = await LibraryService(COUNTRIES_LIBRARY_URL);
      let countryList = [];
      countryRs?.map((item) => {
        countryList.push({
          key: item.key,
          name: item.value,
        });
      });

      setCountries(countryList);

      const statesRs = await LibraryService(ALL_STATES_LIBRARY_URL);
      let stateList = [];
      statesRs?.map((item) => {
        stateList.push({
          id: item.id,
          name: item.key,
          country: item.value,
        });
      });

      setStates(stateList);

      const citiesRs = await LibraryService(CITIES_LIBRARY_URL);
      let cityList = [];
      citiesRs?.map((item) => {
        cityList.push({
          id: item.id,
          name: item.key,
          state: item.value,
        });
      });

      setCities(cityList);
    } catch (e) {
      console.log(e);
      navigate("/error");
    }
  };

  useEffect(() => {
    ctx.showProgress(true);
    fetchLocations();
    ctx.showProgress(false);
  }, []);

  const initialValue = {
    phoneNumber: "",
    street: "",
    pobox: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
  };

  const schema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .max(32, "Invalid phone number"),
    street: Yup.string().max(128, "Invalid street address"),
    pobox: Yup.string().max(32, "Invalid Apt/PO Box number"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string()
      .required("Zip Code is required")
      .max(32, "Invalid zip code"),
  });

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
              <CheckedoutItems selected={selected} totalAmount={totalAmount} />

              <h2 className="h4 font-weight-bold">Credit Card Payment</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <h5 className="card-billing-sub-heading mb-4">
                Billing Information
              </h5>

              <p className="instructions-desc">
                Please make sure this is the same as the billing address for
                your credit card.
              </p>

              <div className="row" id="pageError">
                <div className="col-12">
                  {pageErrors?.message && (
                    <div className="mb-4">
                      <ErrorMessage>{pageErrors.message}</ErrorMessage>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-5">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={initialValue}
                    validationSchema={schema}
                    onSubmit={(values) => {
                      initPayment(values);
                    }}
                  >
                    {({
                      handleSubmit,
                      errors,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form>
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            name="phoneNumber"
                            component={ZInputComponent}
                            placeholder="Enter phone number"
                            label="Phone Number"
                            required
                            serverError={
                              serverErrors && serverErrors.phoneNumber
                            }
                          />
                        </div>
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            name="street"
                            component={ZInputComponent}
                            placeholder="Enter street address"
                            label="Street Address"
                            serverError={serverErrors && serverErrors.street}
                          />
                        </div>

                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            name="pobox"
                            component={ZInputComponent}
                            placeholder="Enter apt/p.o box number"
                            label="Apt/P.O Box Number"
                            serverError={serverErrors && serverErrors.pobox}
                          />
                        </div>

                        <div className="js-form-message mb-4">
                          <Select
                            name="country"
                            className="form-control"
                            label="Country"
                            mandatory={true}
                            defaultTouch
                            serverError={serverErrors && serverErrors.country}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setFieldValue("country", e.target.value);
                              setSelectedCountry(e?.target?.value);
                              setSelectedState(undefined);
                            }}
                          >
                            <option value="" disabled>
                              Select Country
                            </option>

                            {countries &&
                              countries.length > 0 &&
                              countries.map((country) => {
                                return (
                                  <option key={country.key} value={country.key}>
                                    {country.name}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>

                        <div className="js-form-message mb-4">
                          <Select
                            name="state"
                            className="form-control"
                            label="State"
                            mandatory={true}
                            defaultTouch
                            serverError={serverErrors && serverErrors.state}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setFieldValue("state", e.target.value);
                              setSelectedState(e?.target?.value);
                            }}
                          >
                            <option value="" disabled>
                              Select State
                            </option>

                            {selectedCountry &&
                              states &&
                              states.length > 0 &&
                              states
                                .filter(
                                  (item) => item.country == selectedCountry
                                )
                                .map((state) => {
                                  return (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  );
                                })}
                          </Select>
                        </div>

                        <div className="js-form-message mb-4">
                          <Select
                            name="city"
                            className="form-control"
                            label="City"
                            mandatory={true}
                            defaultTouch
                            serverError={serverErrors && serverErrors.city}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setFieldValue("city", e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select City
                            </option>

                            {selectedState &&
                              cities &&
                              cities.length > 0 &&
                              cities
                                .filter((item) => item.state == selectedState)
                                .map((city) => {
                                  return (
                                    <option key={city.id} value={city.id}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                          </Select>
                        </div>

                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            name="zipCode"
                            component={ZInputComponent}
                            placeholder="Enter zip code"
                            label="Zip Code"
                            serverError={serverErrors && serverErrors.zipCode}
                          />
                        </div>

                        <div className="js-form-message mb-4 row">
                          <div className="col-md-6 mb-3 mb-md-0">
                            <button
                              className="btn-block btn btn-outline-primary px-5"
                              type="button"
                              onClick={async (e) => {
                                e.preventDefault();
                                navigate(navigationFrom?.url);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              className="btn-block btn btn-primary-dark-w px-5"
                              type="submit"
                              onClick={async (e) => {
                                e.preventDefault();
                                let res = await validateForm();
                                if (res && Object.values(res)?.length > 0) {
                                  scrollTo("pageError");
                                  setPageErrors({ message: genericErrorMsg });
                                }
                                handleSubmit(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CheckoutCardBillingInfo;
