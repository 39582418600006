import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useAuthContext } from "../../redux/auth-context";
import { useNavigate } from "react-router-dom";

const CheckoutForm = ({ navigationFrom }) => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    ctx.showProgress(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          process.env.REACT_APP_STRIPE_BID_INVOICE_CARD_PAY_RETURN_URL,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
    ctx.showProgress(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    wallets: {
      googlePay: "never",
      applePay: "never",
    },
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className="js-form-message mb-4 mt-4 row">
        <div className="col-md-6 mb-3 mb-md-0">
          <button
            className="btn-block btn btn-outline-primary px-5"
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              navigate(navigationFrom?.url);
            }}
          >
            Back
          </button>
        </div>
        <div className="col-md-6">
          <button
            disabled={isLoading || !stripe || !elements}
            className="btn-block btn btn-primary-dark-w px-5"
            type="submit"
          >
            {isLoading ? "Please wait..." : "Pay now"}
          </button>
        </div>
      </div>

      {message && (
        <div style={{ display: "block" }} className="invalid-feedback mt-3">
          {message}
        </div>
      )}
    </form>
  );
};

export default CheckoutForm;
