import React from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { StatusRequest } from "../../services/UserService";
import { useEffect } from "react";
import { useAuthContext } from "../../redux/auth-context";

const VerifyIdentityWelcome = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  useEffect(() => {
    ctx.showProgress(true);
    StatusRequest()
      .then((data) => {
        console.log(data);
        ctx.showProgress(false);
        if(data.documentStatus == 'SUBMITTED' || data.documentStatus == 'VERIFIED'){
            navigate("/verify-identity");
        }
      })
      .catch((error) => {
        ctx.showProgress(false);
        navigate("/error");
      });
  }, []);

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-lg-3">
                <AccountMenu identityActive={true} />
              </div>
              <div className="offset-lg-1 col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="h2 font-weight-bold">
                      Let's get you verified
                    </h2>
                  </div>
                  <div className="col-md-12">
                    <BHotrizontalProgress progress={33} />
                  </div>
                  <div className="col-md-12 py-5">
                    <p className="instructions-desc">
                      As the online landscape evolves, we're committed to
                      ensuring the quality and integrity of our online auction
                      marketplace. One of the steps we have taken is verifying
                      the identification of bidders..
                    </p>
                    <p className="instructions-desc">
                      More and more of our government sellers are requiring the
                      verification of bidder's identification. In addition to
                      our seller's requirements, we also use ID verification to
                      confirm you are of legal age to bid with BidlyAuction and
                      you are a US resident.
                    </p>
                    <p className="font-weight-bold  my-0 instructions-desc">
                      You'll be required to verify your ID before you can place
                      your first a bid on an item.
                    </p>
                  </div>
                  <div className="col-md-2">
                    <Link
                      className="btn-add-cart btn-add-cart__wide btn-primary transition-3d-hover"
                      to="/verify-identity-instruction"
                    >
                      Verify
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyIdentityWelcome;
