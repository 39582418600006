import { useField } from "formik";
import React from "react";
import ErrorMessage from "./ErrorMessage";

const DualErrorMessage = ({ serverError, fieldName, topGap, bottomGap }) => {
  const [field, meta] = useField(fieldName ?? "");
  const { touched, error } = meta;

  return (
    <>
      {(serverError || (touched && !serverError)) && topGap && (
        <div style={{ marginTop: topGap }} />
      )}
      {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
      {touched && !serverError && <ErrorMessage>{error}</ErrorMessage>}
      {(serverError || (touched && !serverError)) && bottomGap && (
        <div style={{ marginBottom: bottomGap }} />
      )}
    </>
  );
};

export default DualErrorMessage;
