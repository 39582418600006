import { useEffect } from "react";
import { useRef } from "react";

const LocationMap = ({
  lat,
  lng,
  locationChanged = (lat, lng) => {},
  initCompleted = () => {},
}) => {
  useEffect(() => {
    initCompleted();
    console.log("Im here in map", lat, lng);
  }, []);

  const ref = useRef();

  function updateInfoWindow(infoWindow, marker) {
    infoWindow.setContent(
      "Longitude: " +
        marker.getPosition().lng() +
        "<br>" +
        "Latitude: " +
        marker.getPosition().lat()
    );
  } // end of if_gmap_bindInfoWindow

  useEffect(() => {
    var curPoint = new window.google.maps.LatLng(lat, lng);

    let map = new window.google.maps.Map(ref.current, {
      center: curPoint,
      zoom: 10,
      mapTypeId: "roadmap",
    });

    let marker = new window.google.maps.Marker({
      position: curPoint,
      map,
    });

    let infoWindow = new window.google.maps.InfoWindow();
    map.addListener("click", function (event) {
      marker.setPosition(event.latLng);
      locationChanged(event.latLng.lat(), event.latLng.lng());
      updateInfoWindow(infoWindow, marker);
    });

    marker.addListener("click", function () {
      updateInfoWindow(infoWindow, marker);
      infoWindow.open(map, marker);
    });
  });

  return <div ref={ref} id="map" style={{ minHeight: "350px" }} />;
};
export default LocationMap;
