import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import {
  FetchUserProfile,
  UpdateAccountInfoRequest,
} from "../../services/UserService";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { CATEGORY_LIBRARY_URL } from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import { useAuthContext } from "../../redux/auth-context";
import {
  isJSON,
  deflateErrors,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Alert from "../../components/UI/Alert";

const SellerAccountMessage = () => {
  const ctx = useAuthContext();

  let navigate = useNavigate();

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                <AccountMenu accountInfoActive={true} subMenu={"info"} />
              </div>
              <div className="offset-lg-1 col-md-7">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="h3 font-weight-bold purple-500">
                      Account Information
                    </h2>
                  </div>
                  <div className="col-md-12 mb-5">
                    <BHotrizontalProgress progress={43} />
                  </div>
                  <div className="col-md-12">
                    <h4>
                      You do not have authorization by BidlyAuction to place
                      bids on items. Please create a Bidder account to proceed.
                   </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default SellerAccountMessage;
