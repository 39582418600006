import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import PlaceholderHeading from "../credit-card/PlaceholderHeading";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import {
  formatUSD,
  formattedUTCDatetoUSCentral,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import {
  SELLER_DASHBOARD_DEMO_STATS_STATUS_URL,
  SELLER_DASHBOARD_RECENT_LISTINGS_URL,
  SELLER_DASHBOARD_STATS_SALES_DAY_URL,
  SELLER_DASHBOARD_STATS_SALES_INTERVAL_URL,
  SELLER_DASHBOARD_STATS_SALES_STATUS_URL,
  SELLER_DASHBOARD_STATS_STATUS_URL,
} from "../../Constants";
import { fetchRecords } from "../../services/DashboardService";
import DashboardCard from "./DashboardCard";
import CardHeading from "./CardHeading";
import ListingInfoBlocks from "./ListingInfoBlocks";
import ListingInfoBlock from "./ListingInfoBlock";
import LabelValue from "./LabelValue";
import DetailLinkDashboardCard from "./DetailLinkDashboardCard";
import DetailedLink from "./DetailedLink";
import ListItem from "./ListItem";
import ListCard from "./ListCard";
import LastDaysSaleBarChart from "./LastDaysSaleBarChart";
import classes from "./dashboard.module.css";
import { useDispatch } from "react-redux";
import { actionTypes as authActionTypes } from "../../pages/_redux/authRedux";
import LinkButton from "../../components/button/LinkButton";

const Dashboard = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [statusStats, setStatusStats] = useState([]);
  const [salesIntervalStats, setSalesIntervalStats] = useState([]);
  const [salesStatusStats, setSalesStatusStats] = useState([]);
  const [salesDayStats, setSalesDayStats] = useState([]);
  const [demoStatusStats, setDemoStatusStats] = useState([]);
  const [overAllSales, setOverAllSales] = useState(0);

  const [question, setQuestion] = useState(0);
  const [message, setMessage] = useState(0);

  const [itemList, setItemList] = useState();

  const getValueOrZero = (key, arr) => {
    return arr[key] ?? 0;
  };

  const fetchDashboardData = async () => {
    try {
      const response = await Promise.all([
        fetchRecords(SELLER_DASHBOARD_STATS_STATUS_URL),
        fetchRecords(SELLER_DASHBOARD_STATS_SALES_INTERVAL_URL),
        fetchRecords(SELLER_DASHBOARD_STATS_SALES_STATUS_URL),
        fetchRecords(SELLER_DASHBOARD_STATS_SALES_DAY_URL),
        fetchRecords(SELLER_DASHBOARD_RECENT_LISTINGS_URL),
        fetchRecords(SELLER_DASHBOARD_DEMO_STATS_STATUS_URL),
      ]);

      const itemStatusesCount = [];
      for (const [key, value] of Object.entries(response[0])) {
        itemStatusesCount[value.key] = value.valueNumber;
      }
      setStatusStats(itemStatusesCount);

      setSalesIntervalStats(response[1]);

      let sum = response[1].reduce((accumulator, object) => {
        return accumulator + object.valueDouble;
      }, 0);
      setOverAllSales(sum);

      const salesStatuses = [];
      for (const [key, value] of Object.entries(response[2])) {
        salesStatuses[value.key] = value.valueDouble;
      }
      setSalesStatusStats(salesStatuses);

      const daySalesCounts = [];
      for (const [key, value] of Object.entries(response[3])) {
        daySalesCounts[value.key] = value.valueDouble;
      }
      setSalesDayStats(daySalesCounts);

      const items = [];
      response[4]?.map((c) => {
        let m = {
          ...c,
          startedOn: c.startedOn
            ? formattedUTCtoUSCentral(c.startedOn)
            : c.startedOn,
          endingIn: c.endingIn
            ? formattedUTCtoUSCentral(c.endingIn)
            : c.endingIn,
          createdOn: c.createdOn
            ? formattedUTCDatetoUSCentral(c.createdOn)
            : c.createdOn,
        };

        items.push(m);
      });

      setItemList(items);
      setDemoStatusStats(response[5]);
      let ques = response[5]?.filter((item) => item.key == "QUESTION");
      if (ques != undefined) setQuestion(ques[0].value);

      let mess = response[5]?.filter((item) => item.key == "MESSAGE");
      if (mess != undefined) setMessage(mess[0].value);
    } catch (e) {
      console.log("error", e);
      if (isStopFlowConditionMet(e.message)) return;
      // navigate("/error");
    }
  };

  useEffect(() => {
    async function fetchData() {
      ctx.showProgress(true);
      await fetchDashboardData();
      ctx.showProgress(false);
    }

    fetchData();

    const updateInterval = setInterval(fetchDashboardData, 60000);

    return () => {
      if (updateInterval) clearInterval(updateInterval);
    };
  }, []);

  const navigateToListing = (listing) => {
    dispatch({
      type: authActionTypes.UpdateMiscData,
      payload: {
        listingStatus: listing,
      },
    });
    navigate("/my-listing");
  };

  return (
    <>
      <Layout grey>
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col">
              <PlaceholderHeading title="Dashboard" headingSize="sm">
                <Link to="/my-listing" className="font-size-16">
                  Back to My Listings
                </Link>
              </PlaceholderHeading>

              <div className="row">
                <div className="col-lg-4 col-12">
                  <DashboardCard>
                    <CardHeading heading="Seller Account Status" />
                    <ListingInfoBlocks>
                      <ListingInfoBlock
                        label="Primary Address"
                        value={
                          demoStatusStats.filter(
                            (item) =>
                              item.key == "ADDRESS" && item.value == "Added"
                          ).length > 0 ? (
                            <div className="p-2 badge bg-success px-2 py-1 font-size-14 d-block">
                              <Link
                                style={{ color: "white" }}
                                to={"/add-address-info"}
                              >
                                {"ADDED"}
                              </Link>
                            </div>
                          ) : (
                            <div className="p-2 badge bg-danger px-2 py-1 font-size-14 d-block">
                              <Link
                                style={{ color: "white" }}
                                to={"/add-address-info"}
                              >
                                {"NOT ADDED"}
                              </Link>
                            </div>
                          )
                        }
                      />
                      <ListingInfoBlock
                        label="Primary Contact"
                        value={
                          demoStatusStats.filter(
                            (item) =>
                              item.key == "PHONE" && item.value == "Added"
                          ).length > 0 ? (
                            <div className="p-2 badge bg-success px-2 py-1 font-size-14 d-block">
                              <Link
                                style={{ color: "white" }}
                                to={"/add-phone-info"}
                              >
                                {"ADDED"}
                              </Link>
                            </div>
                          ) : (
                            <div className="p-2 badge bg-danger px-2 py-1 font-size-14 d-block">
                              <Link
                                style={{ color: "white" }}
                                to={"/add-phone-info"}
                              >
                                {"NOT ADDED"}
                              </Link>
                            </div>
                          )
                        }
                      />
                      <ListingInfoBlock
                        label="Bank Account"
                        value={
                          demoStatusStats.filter(
                            (item) =>
                              item.key == "BANK" && item.value == "Added"
                          ).length > 0 ? (
                            <div className="p-2 badge bg-success px-2 py-1 font-size-14 d-block">
                              <Link
                                style={{ color: "white" }}
                                to={"/seller-bank-info"}
                              >
                                {"ADDED"}
                              </Link>
                            </div>
                          ) : (
                            <div className="p-2 badge bg-danger px-2 py-1 font-size-14 d-block">
                              <Link
                                style={{ color: "white" }}
                                to={"/seller-bank-info"}
                              >
                                {"NOT ADDED"}
                              </Link>
                            </div>
                          )
                        }
                      />
                      <ListingInfoBlock
                        label="Term & Condition"
                        value={
                          demoStatusStats.filter(
                            (item) =>
                              item.key == "AGREEMENT" && item.value == "Added"
                          ).length > 0 ? (
                            <div className="p-2 badge bg-success px-2 py-1 font-size-14 d-block">
                              <Link
                                style={{ color: "white" }}
                                to={"/seller-terms-info"}
                              >
                                {"ADDED"}
                              </Link>
                            </div>
                          ) : (
                            <div className="p-2 badge bg-danger px-2 py-1 font-size-14 d-block">
                              <Link
                                style={{ color: "white" }}
                                to={"/seller-terms-info"}
                              >
                                {"NOT ADDED"}
                              </Link>
                            </div>
                          )
                        }
                      />
                    </ListingInfoBlocks>
                  </DashboardCard>
                  <div className="col-lg-4 col-12 mt-5"></div>
                  <DashboardCard>
                    <CardHeading heading="Action Required" />
                    <ListingInfoBlocks>
                      <ListingInfoBlock
                        label="Bidder Messages"
                        value={
                          <div className="p-2 px-2 py-1 font-size-14 d-block">
                            <Link
                              style={{ color: "black", fontSize: "18px" }}
                              to={"/inbox"}
                            >
                              {message}
                            </Link>
                          </div>
                        }
                      ></ListingInfoBlock>
                      <ListingInfoBlock
                        label="Bidder Questions"
                        value={
                          <div className="p-2 px-2 py-1 font-size-14 d-block">
                            <Link
                              style={{ color: "black", fontSize: "18px" }}
                              to={"/bidder-questions"}
                            >
                              {question}
                            </Link>
                          </div>
                        }
                      ></ListingInfoBlock>
                    </ListingInfoBlocks>
                  </DashboardCard>
                </div>
                <div className="col-lg-5 col-12">
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <DashboardCard height="130">
                        <LabelValue
                          label={"Active Listings"}
                          value={
                            <a
                              onClick={() => navigateToListing("ACTIVE")}
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero("ACTIVE", statusStats)}
                            </a>
                          }
                        />
                      </DashboardCard>
                    </div>
                    <div className="col-lg-4 col-12">
                      <DashboardCard height="130">
                        <LabelValue
                          label={"Acknowledge Results"}
                          value={
                            <a
                              onClick={() => navigateToListing("TIMED_OUT")}
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero("TIMED_OUT", statusStats)}
                            </a>
                          }
                        />
                      </DashboardCard>
                    </div>
                    <div className="col-lg-4 col-12">
                      <DashboardCard height="130">
                        <LabelValue
                          label={"Pending Invoice Generation"}
                          value={
                            <a
                              onClick={() =>
                                navigateToListing("FINALIZING_WINNER")
                              }
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero("FINALIZING_WINNER", statusStats)}
                            </a>
                          }
                        />
                      </DashboardCard>
                    </div>
                    <div className="col-lg-4 col-12 col-12 mt-5">
                      <DashboardCard height="130">
                        <LabelValue
                          label={"Awaiting Bidder Payment"}
                          value={
                            <a
                              onClick={() =>
                                navigateToListing("INVOICE_GENERATED")
                              }
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero("INVOICE_GENERATED", statusStats)}
                            </a>
                          }
                        />
                      </DashboardCard>
                    </div>
                    <div className="col-lg-4 col-12 col-12 mt-5">
                      <DashboardCard height="130">
                        <LabelValue
                          label={"Confirm Payment"}
                          value={
                            <a
                              onClick={() =>
                                navigateToListing("WAITING_TO_RECEIVE_PAYMENT")
                              }
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero(
                                "WAITING_TO_RECEIVE_PAYMENT",
                                statusStats
                              )}
                            </a>
                          }
                        />
                      </DashboardCard>
                    </div>
                    <div className="col-lg-4 col-12 mt-5">
                      <DashboardCard height="130">
                        <LabelValue
                          label={"Confirm Item Pickup"}
                          value={
                            <a
                              onClick={() =>
                                navigateToListing("WAITING_FOR_ITEM_PICKUP")
                              }
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero(
                                "WAITING_FOR_ITEM_PICKUP",
                                statusStats
                              )}
                            </a>
                          }
                        />
                      </DashboardCard>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col mt-5">
                      <DashboardCard height="280">
                        <p className="text-center mt-10" style={{fontWeight: "700",fontSize: "16px",lineHeight: "30px",marginBottom: "20px"}}>
                          Create your auction listing in just a few easy steps
                          by adding item details & images
                        </p>
                        <p className="text-center"><Link to={"/listing-new"} className="btn btn-primary" style={{color:"white"}}>Add New Listing</Link></p>
                      </DashboardCard>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div>
                    <DashboardCard>
                      <CardHeading heading="Your Listings" />

                      <ListingInfoBlocks>
                        <ListingInfoBlock
                          label="Draft"
                          value={
                            <a
                              onClick={() => navigateToListing("DRAFT")}
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero("DRAFT", statusStats)}
                            </a>
                          }
                        />
                        <ListingInfoBlock
                          label="Scheduled"
                          value={
                            <a
                              onClick={() => navigateToListing("SCHEDULED")}
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero("SCHEDULED", statusStats)}
                            </a>
                          }
                        />
                        <ListingInfoBlock
                          label="Sold"
                          value={
                            <a
                              onClick={() =>
                                navigateToListing("SUCCESSFUL_ENDED")
                              }
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero("SUCCESSFUL_ENDED", statusStats)}
                            </a>
                          }
                        />
                        <ListingInfoBlock
                          label="Unsold"
                          value={
                            <a
                              onClick={() =>
                                navigateToListing("UNSUCCESSFUL_ENDED")
                              }
                              style={{ cursor: "pointer", color: "black" }}
                            >
                              {getValueOrZero(
                                "UNSUCCESSFUL_ENDED",
                                statusStats
                              )}
                            </a>
                          }
                        />
                      </ListingInfoBlocks>
                      <DetailedLink
                        label="View All Listings"
                        onClick={() => {
                          navigate("/my-listing");
                        }}
                      />
                    </DashboardCard>
                    <div className="col-lg-4 col-12 mt-5"></div>
                    <DashboardCard bottomPadding={false}>
                      <CardHeading heading="Sales Summary" />

                      <ListCard>
                        {salesIntervalStats?.map(
                          (item, i) =>
                            item.key == "Today" && (
                              <ListItem
                                key={i}
                                label={item.key}
                                value={formatUSD(item.valueDouble)}
                              />
                            )
                        )}

                        <ListItem
                          key={"oa"}
                          label={"Overall Sale"}
                          value={formatUSD(overAllSales)}
                        />

                        <DetailedLink
                          border={false}
                          label="View Sales Report"
                          onClick={() => {
                            navigate("/sales-report");
                          }}
                        />
                      </ListCard>
                    </DashboardCard>
                  </div>

                  {/* <div className="mt-5">
                    <DashboardCard bottomPadding={false}>
                      <CardHeading heading="Payment Summary" />

                      <ListCard>
                        <ListItem
                          label="Awaiting Acknowledgment"
                          value={formatUSD(
                            getValueOrZero("TIMED_OUT", salesStatusStats)
                          )}
                        />
                        <ListItem
                          label="Awaiting Payments"
                          value={formatUSD(
                            getValueOrZero(
                              "FINALIZING_WINNER",
                              salesStatusStats
                            )
                          )}
                        />
                      </ListCard>
                    </DashboardCard>
                  </div> */}
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-5">
                  <DashboardCard>
                    <CardHeading heading="Recent Live Postings" />

                    <ListingTable
                      tableBody={itemList?.map((item, i) => {
                        return (
                          <Dashboard.TableBodyRow
                            key={i}
                            itemId={item.itemId}
                            auctionType={item.type}
                            image={item.defaultImage}
                            title={item.title}
                            lot={item.lot}
                            listingDate={item.createdOn}
                            startDate={item.startedOn}
                            endDate={item.endingIn}
                            startingPrice={item.startingPrice}
                            currentPrice={item.price}
                            reserveMet={item.reserveMet}
                            reservePrice={item.reservePrice}
                            currency={item.currency}
                            bids={item.bids}
                            watching={item.watching}
                            status={item.status}
                          />
                        );
                      })}
                      mobileBody={itemList?.map((item, i) => {
                        return (
                          <Dashboard.MobileBodyRow
                            key={i}
                            itemId={item.itemId}
                            auctionType={item.type}
                            image={item.defaultImage}
                            title={item.title}
                            lot={item.lot}
                            listingDate={item.createdOn}
                            startDate={item.startedOn}
                            endDate={item.endingIn}
                            startingPrice={item.startingPrice}
                            currentPrice={item.price}
                            reserveMet={item.reserveMet}
                            reservePrice={item.reservePrice}
                            currency={item.currency}
                            bids={item.bids}
                            watching={item.watching}
                            status={item.status}
                          />
                        );
                      })}
                    />
                  </DashboardCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

Dashboard.TableBodyRow = ({
  itemId,
  auctionType,
  image,
  title,
  lot,
  listingDate,
  startDate,
  endDate,
  startingPrice,
  currentPrice,
  reservePrice,
  reserveMet,
  currency,
  bids,
  watching,
  status,
}) => {
  return (
    <>
      <tr>
        <td>
          {image && (
            <div
              className={tableClasses.imgContainer}
              style={{ backgroundImage: `url(${image})` }}
            />
          )}
        </td>

        <td>
          <div className={`${tableClasses.label}`}>
            <span className="text-uppercase font-weight-bold">
              {(status === "ACTIVE" ||
                status === "DRAFT" ||
                status === "SCHEDULED") &&
                status}
              {status === "TIMED_OUT" && "TIME ENDED"}
              {status === "FINALIZING_WINNER" && "SEND INVOICE"}
              {status === "INVOICE_GENERATED" && "WAITING FOR PAYMENT"}
              {status === "WAITING_TO_RECEIVE_PAYMENT" && "CONFIRM PAYMENT"}
              {status === "WAITING_FOR_ITEM_PICKUP" && "CONFIRM PICKUP"}
              {status === "SUCCESSFUL_ENDED" && "SOLD"}
              {status === "UNSUCCESSFUL_ENDED" && "UNSOLD"}
            </span>{" "}
            |{" "}
            <span className="text-capitalize">
              {auctionType.replaceAll("_", " ").toLowerCase()}
            </span>
          </div>
          <div className={tableClasses.heading}>
            {status === "ACTIVE" && (
              <Link to={`/auction/${itemId}`}>{title}</Link>
            )}
            {status !== "ACTIVE" && title}
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>Listing ID: </span>
            <span className={tableClasses.value}> {lot}</span>
          </div>
          <div>
            <span className={tableClasses.label}>Dated: </span>
            <span className={tableClasses.value}> {listingDate}</span>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Start Date</div>
          <div className={tableClasses.value}>{startDate}</div>
          <div className={tableClasses.label}>Ending Date</div>
          <div className={tableClasses.value}>{endDate}</div>
        </td>
        <td>
          <div className={tableClasses.label}>
            {auctionType === "BUY NOW" && "Price"}
            {auctionType !== "BUY NOW" && "Starting Price"}
          </div>
          <div className={tableClasses.value}>{formatUSD(startingPrice)}</div>

          {(auctionType === "ONLINE AUCTION" ||
            auctionType === "SEALED AUCTION" ||
            auctionType === "MAKE AN OFFER") && (
            <>
              {currentPrice && (
                <>
                  <div className={tableClasses.label}>
                    {(auctionType === "ONLINE AUCTION" ||
                      auctionType === "SEALED AUCTION") &&
                      "Highest Bid"}
                    {auctionType === "MAKE AN OFFER" && "Highest Offer"}
                  </div>
                  <div className={tableClasses.value}>
                    {formatUSD(currentPrice)}
                  </div>
                </>
              )}
            </>
          )}
        </td>
        <td>
          {auctionType === "ONLINE AUCTION" &&
            (status === "ACTIVE" ||
              status === "TIMED_OUT" ||
              status === "FINALIZING_WINNER" ||
              status === "INVOICE_GENERATED" ||
              status === "WAITING_TO_RECEIVE_PAYMENT" ||
              status === "WAITING_FOR_ITEM_PICKUP") && (
              <>
                {reserveMet && (
                  <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                    Reserve is Met
                  </div>
                )}
              </>
            )}

          {auctionType === "ONLINE AUCTION" ||
            auctionType === "SEALED AUCTION" ||
            (auctionType === "MAKE AN OFFER" && reservePrice && (
              <>
                <div className={`${tableClasses.label} mt-2`}>
                  Reserve Price
                </div>
                <div className={tableClasses.value}>
                  {formatUSD(reservePrice)}
                </div>
              </>
            ))}
        </td>

        {status !== "DRAFT" && status !== "SCHEDULED" && (
          <>
            <td>
              {(auctionType === "ONLINE AUCTION" ||
                auctionType === "SEALED AUCTION") && (
                <div className={tableClasses.label}>Bids</div>
              )}
              {auctionType === "MAKE AN OFFER" && (
                <div className={tableClasses.label}>Offers</div>
              )}
              {(auctionType === "ONLINE AUCTION" ||
                auctionType === "SEALED AUCTION" ||
                auctionType === "MAKE AN OFFER") && (
                <div className={tableClasses.value}>{bids}</div>
              )}

              {(status === "ACTIVE" || status === "TIMED_OUT") && (
                <>
                  <div className={tableClasses.label}>Watching</div>
                  <div className={tableClasses.value}>{watching}</div>
                </>
              )}
            </td>
          </>
        )}
      </tr>
    </>
  );
};

Dashboard.MobileBodyRow = ({
  itemId,
  auctionType,
  image,
  title,
  lot,
  listingDate,
  startDate,
  endDate,
  startingPrice,
  currentPrice,
  reservePrice,
  reserveMet,
  currency,
  bids,
  watching,
  status,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={`${tableClasses.label} `}>
              <span className="text-uppercase font-weight-bold">
                {(status === "ACTIVE" ||
                  status === "DRAFT" ||
                  status === "SCHEDULED") &&
                  status}
                {status === "TIMED_OUT" && "TIME ENDED"}
                {status === "FINALIZING_WINNER" && "SEND INVOICE"}
                {status === "INVOICE_GENERATED" && "WAITING FOR PAYMENT"}
                {status === "WAITING_TO_RECEIVE_PAYMENT" && "CONFIRM PAYMENT"}
                {status === "WAITING_FOR_ITEM_PICKUP" && "CONFIRM ITEM PICKUP"}
                {status === "SUCCESSFUL_ENDED" && "SOLD"}
                {status === "UNSUCCESSFUL_ENDED" && "UNSOLD"}
              </span>{" "}
              |{" "}
              <span className="text-capitalize">
                {auctionType.replaceAll("_", " ").toLowerCase()}
              </span>
            </div>
            <div className={tableClasses.heading}>{title}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing ID</div>
            <div className={tableClasses.value}>{lot}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing Date</div>
            <div className={tableClasses.value}>{listingDate}</div>
          </div>
          {startDate && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Start Date</div>
              <div className={tableClasses.value}>{startDate}</div>
            </div>
          )}

          {endDate && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Ending Date</div>
              <div className={tableClasses.value}>{endDate}</div>
            </div>
          )}

          {startingPrice && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>
                {auctionType === "BUY NOW" && "Price"}
                {auctionType !== "BUY NOW" && "Starting Price"}
              </div>
              <div className={tableClasses.value}>
                {formatUSD(startingPrice)}
              </div>
            </div>
          )}

          {(auctionType === "ONLINE AUCTION" ||
            auctionType === "SEALED AUCTION" ||
            auctionType === "MAKE AN OFFER") && (
            <>
              {currentPrice ? (
                <div className={tableClasses.item}>
                  <div className={tableClasses.label}>
                    {(auctionType === "ONLINE AUCTION" ||
                      auctionType === "SEALED AUCTION") &&
                      "Highest Bid"}
                    {auctionType === "MAKE AN OFFER" && "Highest Offer"}
                  </div>
                  <div className={tableClasses.value}>
                    {formatUSD(currentPrice)}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}

          {auctionType === "ONLINE AUCTION" ||
            auctionType === "SEALED AUCTION" ||
            (auctionType === "MAKE AN OFFER" && reservePrice && (
              <>
                <div className={tableClasses.item}>
                  <div className={tableClasses.label}>Reserve Price</div>
                  <div className={tableClasses.value}>
                    <div className="row">
                      <div className="col">{formatUSD(reservePrice)}</div>

                      {auctionType === "ONLINE AUCTION" &&
                        (status === "ACTIVE" ||
                          status === "TIMED_OUT" ||
                          status === "FINALIZING_WINNER" ||
                          status === "INVOICE_GENERATED" ||
                          status === "WAITING_TO_RECEIVE_PAYMENT" ||
                          status === "WAITING_FOR_ITEM_PICKUP") && (
                          <div className="col-auto">
                            {reserveMet && (
                              <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                                Reserve is Met
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </>
            ))}

          {status !== "DRAFT" && status !== "SCHEDULED" && (
            <>
              <div className={tableClasses.item}>
                {(auctionType === "ONLINE AUCTION" ||
                  auctionType === "SEALED AUCTION") && (
                  <div className={tableClasses.label}>Bids</div>
                )}
                {auctionType === "MAKE AN OFFER" && (
                  <div className={tableClasses.label}>Offers</div>
                )}
                <div className={tableClasses.value}>{bids}</div>
              </div>

              {(status === "ACTIVE" || status === "TIMED_OUT") && (
                <div className={tableClasses.item}>
                  <div className={tableClasses.label}>Watching</div>
                  <div className={tableClasses.value}>{watching}</div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
