import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import DropDownButton from "../../components/button/DropDownButton";
import DropDownItem from "../../components/button/DropDownItem";
import LotsTable from "../../components/table/LotsTable";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import {
  BOUGHT_COMPLETED_URL,
  ITEM_BOUGHT_URL,
  NAVIGATION_PAGES,
} from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { fetchMyBids } from "../../services/MyBidsService";
import {
  formatUSD,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import ButtonsPanel from "./ButtonsPanel";
import classes from "./MyBids.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import certificateIcon from "../../assets/images/doc-icon.svg";
import receiptIcon from "../../assets/images/receipt-icon.svg";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";

const ItemBoughtCheckout = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [wonBids, setWonBids] = useState([]);
  const [allWonBids, setAllWonBids] = useState([]);

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const fetchWonBids = async () => {
    const rs = await fetchMyBids(ITEM_BOUGHT_URL);
    console.log("won bids", rs);

    const content = rs?.content;
    const items = [];
    content?.map((c) => {
      let m = {
        ...c,
      };

      m.auction.endedOn = m.auction.endedOn
        ? formattedUTCtoUSCentral(m.auction.endedOn)
        : m.auction.endedOn;

      if (m.invoiceURL) items.push(m);
    });

    setWonBids(items);
    setAllWonBids(items);
  };

  const fetchBidsData = async () => {
    ctx.showProgress(true);
    try {
      await fetchWonBids();
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchBidsData();
  }, []);

  const onChangeWonBidSelection = (e, bid) => {
    if (e.target.checked) {
      setSelectedInvoices((prev) => {
        //const items = [...prev];
        const items = [];
        items.push(bid);
        return items;
      });
    } else {
      setSelectedInvoices((prev) => {
        return [...prev].filter(
          (item) => item.auction.itemId !== bid.auction.itemId
        );
      });
    }
  };

  const wonBidsTableHeader = (
    <tr>
      <th></th>
      <th>Asset Description</th>
      <th>Amount</th>
      <th>Buyer’s Premium</th>
      <th>Tax</th>
      <th>Total Due</th>
      <th></th>
    </tr>
  );

  const wonBidsMobileHeader = (
    <>
      <div className={tableClasses.selection}></div>
      <div className={tableClasses.label}>Item Description</div>
    </>
  );

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myBuyNowActive={true} subMenu="item-checkout" />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <h2 className="h4 font-weight-bold">
                Buy Now - Make Payment & Pickup Item
              </h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <p className="mt-5 pg-2 instructions-desc mb-5">
                Note: Once you have paid for the item, contact Seller to arrange
                for pickup
              </p>

              <ButtonsPanel>
                <DropDownButton
                  className={classes.item}
                  onItemClicked={(v) => {
                    // console.log(v);
                    setSelectedInvoices([]);
                    if (v === "ALL") {
                      setWonBids(allWonBids);
                    } else {
                      const items = allWonBids.filter((item) =>
                        item.auction.paymentMethods.includes(v)
                      );
                      setWonBids(items);
                    }
                  }}
                >
                  <DropDownItem label="ALL PAYMENT METHODS" value="ALL" />
                  <DropDownItem label="CREDIT CARD" value="CREDIT_CARD" />
                  <DropDownItem label="WIRE TRANSFER" value="WIRE_TRANSFER" />
                  <DropDownItem label="CASHIER CHEQUE" value="CASHIER_CHEQUE" />
                </DropDownButton>

                {selectedInvoices &&
                  Object.keys(selectedInvoices).length > 0 && (
                    <Link
                      className={`btn px-4 py-2 btn-primary-dark-w ${classes.item}`}
                      onClick={(e) => {
                        e.preventDefault();
                        console.log("selected invoices", selectedInvoices);
                        navigate("/bid-won-checkout-selection", {
                          state: {
                            selected: selectedInvoices,
                            from: NAVIGATION_PAGES.ITEMS_BOUGHT,
                          },
                        });
                      }}
                    >
                      CHECKOUT
                    </Link>
                  )}

                {(!selectedInvoices ||
                  Object.keys(selectedInvoices).length == 0) && (
                  <span
                    className={`btn px-4 py-2 btn-primary-dark-w disabled ${classes.item}`}
                  >
                    CHECKOUT
                  </span>
                )}
              </ButtonsPanel>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <LotsTable
                    label="Payment & Pickup Item"
                    mobileHeader={wonBidsMobileHeader}
                    tableHeader={wonBidsTableHeader}
                    noRecordTableBody={
                      <>
                        <tr>
                          <td colSpan={8} className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      </>
                    }
                    tableBody={
                      wonBids && Object.keys(wonBids).length > 0
                        ? wonBids.map((bid, i) => {
                            return (
                              <ItemBoughtCheckout.WonBidsBodyRow
                                key={i}
                                isSelected={
                                  [...selectedInvoices].filter(
                                    (item) =>
                                      item.auction.itemId === bid.auction.itemId
                                  ).length > 0
                                }
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                auctionEnd={bid.auction.endedOn}
                                buyerFee={bid.buyerPremium}
                                lot={bid.auction.customId}
                                soldAmount={bid.soldAmount}
                                tax={bid.taxAmount}
                                total={bid.total}
                                currency={bid.auction.currency}
                                status={bid.status}
                                invoiceURL={bid.invoiceURL}
                                receiptURL={bid.paymentReceiptURL}
                                onChange={(e) => {
                                  onChangeWonBidSelection(e, bid);
                                }}
                                onPickupDetails={(itemId) => {
                                  navigate("/item-pickup-detail/" + itemId, {
                                    state: {
                                      from: "item-checkout",
                                    },
                                  });
                                }}
                              />
                            );
                          })
                        : undefined
                    }
                    noRecordMobileBody={
                      <>
                        <div className={tableClasses.blockPanel}>
                          <div
                            className={`justify-content-center ${tableClasses.block}`}
                          >
                            <div className={tableClasses.blockText}>
                              <div className={tableClasses.item}>
                                <div className={tableClasses.productLabel}>
                                  No Records Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    mobileBody={
                      wonBids && Object.keys(wonBids).length > 0
                        ? wonBids.map((bid, i) => {
                            return (
                              <ItemBoughtCheckout.WonBidsMobileBodyRow
                                key={i}
                                isSelected={
                                  [...selectedInvoices].filter(
                                    (item) =>
                                      item.auction.itemId === bid.auction.itemId
                                  ).length > 0
                                }
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                auctionEnd={bid.auction.endedOn}
                                buyerFee={bid.buyerPremium}
                                lot={bid.auction.customId}
                                soldAmount={bid.soldAmount}
                                tax={bid.taxAmount}
                                total={bid.total}
                                currency={bid.auction.currency}
                                status={bid.status}
                                invoiceURL={bid.invoiceURL}
                                receiptURL={bid.paymentReceiptURL}
                                onChange={(e) => {
                                  onChangeWonBidSelection(e, bid);
                                }}
                                onPickupDetails={(itemId) => {
                                  navigate("/item-pickup-detail/" + itemId, {
                                    state: {
                                      from: "item-checkout",
                                    },
                                  });
                                }}
                              />
                            );
                          })
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

ItemBoughtCheckout.WonBidsBodyRow = ({
  itemId,
  isSelected = false,
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
  status,
  invoiceURL,
  receiptURL,
  onChange = () => {},
  hideSelection = false,
  onPickupDetails = (itemId) => {},
}) => (
  <>
    <tr>
      {!hideSelection && (
        <td>
          {status === "INVOICE_GENERATED" && (
            <input type="checkbox" onChange={onChange} checked={isSelected} />
          )}
        </td>
      )}
      <td>
        {status && (
          <span className={`${tableClasses.uLabel} ${tableClasses.highlight}`}>
            {status === "INVOICE_GENERATED" && "PAYMENT PENDING"}
            {status === "WAITING_TO_RECEIVE_PAYMENT" &&
              "PAYMENT CONFIRMATION PENDING"}
            {status === "WAITING_FOR_ITEM_PICKUP" && "PICKUP PENDING"}
          </span>
        )}
        <span className={tableClasses.productLabel}>
          <Link to={`/auction-item/` + itemId}>{label}</Link>
        </span>
        <span className={tableClasses.lot}>
          <Link to={`/auction-item/` + itemId}>LOT # {lot}</Link>
        </span>
      </td>
      <td>{formatUSD(soldAmount)}</td>
      <td>{formatUSD(buyerFee)}</td>
      <td>{formatUSD(tax)}</td>
      <td>{formatUSD(total)}</td>
      <td>
        {status && status === "WAITING_FOR_ITEM_PICKUP" && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onPickupDetails(itemId);
            }}
            className={`btn btn-primary-dark-w px-2 py-1 ${tableClasses.button}`}
          >
            Pickup Details
          </a>
        )}

        {invoiceURL && (
          <a
            target="_blank"
            download={true}
            href={invoiceURL}
            className={`btn btn-outline-primary px-2 py-1 ${tableClasses.button}`}
          >
            <img src={certificateIcon} />
            Invoice
          </a>
        )}

        {receiptURL && (
          <a
            target="_blank"
            download={true}
            href={receiptURL}
            className={`btn btn-outline-primary px-2 py-1 ${tableClasses.button}`}
          >
            <img src={receiptIcon} />
            Receipt
          </a>
        )}
      </td>
    </tr>
  </>
);

ItemBoughtCheckout.WonBidsMobileBodyRow = ({
  itemId,
  isSelected = false,
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
  status,
  invoiceURL,
  receiptURL,
  onChange = () => {},
  hideSelection = false,
  onPickupDetails = (itemId) => {},
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}>
          {!hideSelection && status === "INVOICE_GENERATED" && (
            <input type="checkbox" onChange={onChange} checked={isSelected} />
          )}
        </div>

        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.productLabel}>
              <Link to={`/auction-item/` + itemId}>{label}</Link>
            </div>
            <div className={tableClasses.lot}>
              <Link to={`/auction-item/` + itemId}>LOT # {lot}</Link>
            </div>
          </div>

          {status && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Status</div>
                <div className={tableClasses.value}>
                  {status === "INVOICE_GENERATED" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PAYMENT PENDING
                    </span>
                  )}
                  {status === "WAITING_TO_RECEIVE_PAYMENT" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PAYMENT CONFIRMATION PENDING
                    </span>
                  )}
                  {status === "WAITING_FOR_ITEM_PICKUP" && (
                    <span className={`${tableClasses.badge} badge bg-warning`}>
                      PICKUP PENDING
                    </span>
                  )}
                </div>
              </div>
            </>
          )}

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Amount</div>
            <div className={tableClasses.value}>{formatUSD(soldAmount)}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Buyer’s Premium</div>
            <div className={tableClasses.value}>{formatUSD(buyerFee)}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Tax</div>
            <div className={tableClasses.value}>{formatUSD(tax)}</div>
          </div>
        </div>
      </div>
      <div className={`${tableClasses.block} ${tableClasses.bordered}`}>
        <div className={tableClasses.selection}>&nbsp;&nbsp;&nbsp;</div>

        <div className={tableClasses.totalRow}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Total Due</div>
            <div className={tableClasses.value}>{formatUSD(total)}</div>
          </div>

          <div className={tableClasses.buttons}>
            {status && status === "WAITING_FOR_ITEM_PICKUP" && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onPickupDetails(itemId);
                }}
              >
                <img src={certificateIcon} />
                Pickup Details
              </a>
            )}

            {invoiceURL && (
              <a target="_blank" href={invoiceURL} download={true}>
                <img src={certificateIcon} />
                Invoice
              </a>
            )}

            {receiptURL && (
              <a target="_blank" href={receiptURL} download={true}>
                <img src={receiptIcon} />
                Receipt
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemBoughtCheckout;
