import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import envelop from "../../assets/images/envelop-open.svg";
import style from "../registration/RegisterModule.css";
import Layout from "../../layout/Layout";
import classes from "../search/Search.module.css";

const VerifyEmail = () => {
  return (
    <Layout>
      <div className="row mt-10 mb-20">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <section className="section-onsale-product ">
            <div className="text-center mb-4">
              <img
                src={envelop}
                className="notification-logo img-responsive"
                alt="envelop img"
              />
            </div>
            <h2 className="pg-4 font-weight-bold text-center purple-500">
              Check your email inbox
            </h2>
            <h3 className={`nav-classic ${classes.searchSection} ${classes.asSearchTitle} mb-0 pb-2 font-size-25 section-title text-center`}></h3>
            <p className="pg-1 text-center mt-5">
              Your reset password email is on it's way!<br></br>
              If you still haven't received your email after 5 minutes please
            </p>
            <div className="row">
              <div className="offset-lg-3 offset-md-3 col-lg-6 col-md-6 col-sm-12">
                <div className="col-md-12">
                  <Link
                    to="/forgot-password"
                    className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                  >
                    TRY AGAIN
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-4"></div>
      </div>
    </Layout>
  );
};

export default VerifyEmail;
