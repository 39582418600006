import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  deflateErrors,
  isBadRequest,
  isJSON,
  validatePassword,
  validateUsername,
  Regex,
} from "../../services/Utilities";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./ScheduleDemo.module.css";
import profilePic from "../../assets/images/seller-pic.png";
import smallClock from "../../assets/images/small-clock-grey.svg";
import smallCamera from "../../assets/images/small-camera.svg";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { isSeller } from "../../services/Utilities";
import { shallowEqual, useSelector } from "react-redux";

const DemoDate = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedDay, setSelectedDay] = useState(utils().getToday());

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const initialValue = {
    dayForMeeting: "",
    durationOfMeeting: location.state.timeDuration,
  };

  const handleDemoDay = (values) => {
    ctx.showProgress(true);
    let dayForMeeting =
      selectedDay.year + "-" + selectedDay.month + "-" + selectedDay.day;
    let dateObj = new Date(dayForMeeting);
    let momentDate = moment(dateObj);
    console.log("dayForMeeting", dayForMeeting);
    console.log("dateObj", dateObj);

    // let demoDate = selectedDay
    //   ? moment(selectedDay).format("YYYY-MM-DD")
    //   : null;

    let payload = {
      dayForMeeting: dateObj,
      dateStr: dayForMeeting,
      timeDuration: values.durationOfMeeting,
    };
    console.log("demo date payload :: " + payload.dayForMeeting);
    ctx.showProgress(false);
    navigate("/demo-time", { state: payload });
  };

  return (
    <>
      {/* {!isSeller(user.type) && (
      <Navigate to="/not-authorized" />
    )} */}

      <Layout>
        <div className="row mb-18 mr-1 ml-1">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 mt-7">
                <div className="row">
                  <div className="d-inline col-7">
                    <h2 className="pg-5 font-weight-bold purple-500">
                      Select date for a demo
                    </h2>
                  </div>
                  <div className="d-inline col-5 text-right">
                    <h2
                      className=""
                      style={{ color: "#AFB0B5", fontSize: "24px" }}
                    >
                      Step: <b className="color-2">2/4</b>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="offset-lg-3 col-md-6 mt-3">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={initialValue}
                  onSubmit={(values) => {
                    handleDemoDay(values);
                  }}
                >
                  {({ handleSubmit, errors, setFieldValue, resetForm }) => (
                    <form id="advanceSearchForm">
                      <div className="text-center">
                        <img
                          src={profilePic}
                          className={`align-self-center mt-4 ${classes.sellerPicture}`}
                          alt="..."
                        />
                      </div>

                      {/* <p className={`${classes.sellerName} mt-3 mb-2`}>Emma Charlotte</p> */}
                      <p className={`${classes.sellerTimeLink} text-center`}>
                        {location.state.timeDuration} Minutes Meeting
                      </p>
                      <p className="mb-3">
                        <img src={smallClock} alt="..." className="mr-3" />{" "}
                        {location.state.timeDuration} min
                      </p>
                      <p className="mb-2">
                        <img src={smallCamera} alt="..." className="mr-3" /> Web
                        conferencing details provided upon confirmation
                      </p>

                      <hr className={`${classes.sellerLine}`}></hr>
                      <p
                        className={`${classes.sellerTimeLink} text-center mb-2`}
                      >
                        Select a Day
                      </p>

                      <div className="d-flex justify-content-md-center justify-content-start align-items-center mb-2">
                        <Calendar
                          value={selectedDay}
                          onChange={setSelectedDay}
                          minimumDate={utils().getToday()}
                          colorPrimary="#339966" // added this
                          calendarClassName={classes.customCalendar} // and this
                          calendarTodayClassName={classes.customTodayDay} // also this
                          shouldHighlightWeekends
                        />
                      </div>

                      <div className="mt-4">
                        <div className="row">
                          <div className="d-inline p-3 col-md-6">
                            <Link
                              className="btn-block btn btn-outline-primary px-5 text-transform-upper"
                              type="button"
                              onClick={async (e) => {
                                e.preventDefault();
                                navigate("/demo-duration");
                              }}
                            >
                              back
                            </Link>
                          </div>
                          <div className="d-inline p-3 col-md-6">
                            <Link
                              className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                              type="submit"
                              onClick={async (e) => {
                                e.preventDefault();
                                handleSubmit(e);
                              }}
                            >
                              next
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </Layout>
    </>
  );
};

export default DemoDate;
