import React from "react";
import { useEffect } from "react";
import image from "../../assets/images/session-expired.svg";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import AlertCommon from "./AlertCommon";

const ViewExpired = () => {
  const ctx = useAuthContext();

  useEffect(() => {
    ctx.showProgress(false);
  }, []);

  const description = "Your session has expired. Please refresh and try again.";
  const heading = "Session Expired!";
  return (
    <>
      <Layout>
        <div className="container mt-10 mb-10">
          <div className="row">
            <div className="offset-xl-3 col-xl-6">
              <AlertCommon
                description={description}
                heading={heading}
                image={image}
                buttonText="TRY AGAIN"
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ViewExpired;
