import React from "react";
import classes from "./PhoneCard.module.css";
import deleteIcon from "../../assets/images/cross-icon.svg";
import phoneCardImage from "../../assets/images/phone-card.svg";

const PhoneCard = ({
  isActive = true,
  identifier,
  title,
  mobileNumber,
  extention,
  onRemove = () => {},
}) => {
  return (
    <div className={classes.cardDiv} style={{ backgroundImage: `url(${phoneCardImage})` }}>
      <div className={classes.actionBar}>
        <div className={classes.info}>
          <span className={classes.status}>
            {isActive && "ACTIVE"} {!isActive && "INACTIVE"}
          </span>
        </div>
        <div className={classes.actionButton}>
          <span className={classes.remove} onClick={() => onRemove(identifier)}>
            <img src={deleteIcon} />
          </span>
        </div>
      </div>
      <div className={classes.heading}>{title}</div>
      <div className={classes.numberBar}>+{mobileNumber}</div>
      <div className={classes.validity}>
        Phone Type:
        <span className={classes.date}>{extention}</span>
      </div>
    </div>
  );
};

export default PhoneCard;
