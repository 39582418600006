import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import StepHeading from "../credit-card/StepHeading";
import classes from "./MyBids.module.css";
import CheckoutTable from "../../components/table/CheckoutTable";
import creditCardIcon from "../../assets/images/credit-card-icon.svg";
import wireTransferIcon from "../../assets/images/wire-transfer-icon.svg";
import tableClasses from "../../components/table/CheckoutTable.module.css";
import { formatUSD } from "../../services/Utilities";
import CheckedoutItems from "./CheckedoutItems";
import { useDispatch } from "react-redux";

const CheckoutSelection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [creditCardAllowed, setCreditCardAllowed] = useState(true);
  const [wireTransferAllowed, setWireTransferAllowed] = useState(true);
  const [cashierChequeAllowed, setCashierChequeAllowed] = useState(true);
  const params = useLocation()?.state;
  const [navigationFrom, setNavigationFrom] = useState();

  useEffect(() => {
    console.log(params?.selected);
    setSelected((prev) => {
      setTotalAmount(
        params?.selected.reduce((total, cur) => total + cur.total, 0)
      );

      const selectedItem = [...params?.selected]?.shift();

      setCreditCardAllowed(
        selectedItem?.auction?.paymentMethods?.includes("CREDIT_CARD")
      );
      setWireTransferAllowed(
        selectedItem?.auction?.paymentMethods?.includes("WIRE_TRANSFER")
      );
      setCashierChequeAllowed(
        selectedItem?.auction?.paymentMethods?.includes("CASHIER_CHEQUE")
      );

      return params?.selected;
    });

    console.log("navigation from", params?.from);

    setNavigationFrom(params?.from);
  }, [params]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
              <CheckedoutItems selected={selected} totalAmount={totalAmount} />

              <h2 className="h4 font-weight-bold">
                Please choose a payment method
              </h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`${classes.checkoutButtonsPanel} mb-5`}>
                {creditCardAllowed && (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/bid-card-billing", {
                        state: { selected: selected, from: navigationFrom },
                      });
                    }}
                    className="btn btn-secondary d-block d-lg-inline-block"
                  >
                    <img src={creditCardIcon} className="mr-2" />
                    Credit Cards
                  </Link>
                )}

                {wireTransferAllowed && (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/bid-wire-transfer", {
                        state: { selected: selected, from: navigationFrom },
                      });
                    }}
                    className="btn btn-secondary d-block d-lg-inline-block"
                  >
                    <img src={wireTransferIcon} className="mr-2" />
                    Wire Transfer
                  </Link>
                )}

                {cashierChequeAllowed && (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/bid-cashier-cheque", {
                        state: { selected: selected, from: navigationFrom },
                      });
                    }}
                    className="btn btn-secondary d-block d-lg-inline-block"
                  >
                    <img src={wireTransferIcon} className="mr-2" />
                    Cashier Cheque
                  </Link>
                )}

                <Link
                  to={navigationFrom?.url}
                  className="btn btn-secondary-dark-w px-4 d-block d-lg-inline-block"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CheckoutSelection;
