import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuctionBlock from "../../components/auction/AuctionBlock";
import HeadingArrow from "../../components/common/HeadingArrow";
import {
  ENDING_SOON_AUCTIONS_URL,
  NEWLY_ADDED_AUCTIONS_URL,
  POPULAR_AUCTIONS_URL,
} from "../../Constants";
import Layout from "../../layout/Layout";
import { userWatchedAuctions } from "../../services/AuctionService";
import { LibraryService } from "../../services/LibraryService";
import { isBadRequest, isStopFlowConditionMet } from "../../services/Utilities";
import AuctionsPanel from "./AuctionsPanel";
import BigBanner from "./BigBanner";
import FeaturedCategories from "./FeaturedCategories";
import SmallBanner from "./SmallBanner";

const Home = (props) => {
  const navigate = useNavigate();

  const [popularAuctions, setPopularAuctions] = useState([]);
  const [newAuctions, setNewAuctions] = useState([]);
  const [watchedList, setWatchedList] = useState([]);

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthorized) {
      userWatchedAuctions()
        .then((res) => {
          setWatchedList(res);
        })
        .catch((e) => {
          if (isStopFlowConditionMet(e.message)) return;

          navigate("/error");
        });
    }
  }, [isAuthorized]);

  useEffect(() => {
    LibraryService(POPULAR_AUCTIONS_URL)
      .then((res) => {
        setPopularAuctions(res);
      })
      .catch((e) => {
        navigate("/error");
      });

    LibraryService(NEWLY_ADDED_AUCTIONS_URL)
      .then((res) => {
        setNewAuctions(res);
      })
      .catch((e) => {
        navigate("/error");
      });

    // LibraryService(ENDING_SOON_AUCTIONS_URL)
    //   .then((res) => {
    //     setEndingAuctions(res);
    //   })
    //   .catch((e) => {
    //     navigate("/error");
    //   });
  }, []);

  return (
    <Layout verticalMenuVisible nonClickable>
      <>
        <BigBanner />
        <div className="container mb-5">
          {true && (
            <div className="mb-10">
              <div className="mb-7">
                <HeadingArrow title="Featured Categories" />
              </div>

              <FeaturedCategories />
            </div>
          )}

          {watchedList && watchedList?.length >= 4 && (
            <AuctionsPanel
              title="Your Watched Items"
              viewAllClicked={() => {
                navigate("/wish-list");
              }}
            >
              {watchedList.map((item, i) => {
                return (
                  i < 4 && (
                    <AuctionBlock
                      key={i}
                      auctionType={item.type.replaceAll("_", " ").toLowerCase()}
                      heading={item.title}
                      description={`${item.city}, ${item.state}`}
                      price={item.price}
                      image={item.defaultImage}
                      time={item.endingIn}
                      itemId={item.itemId}
                      lot={`LOT # ${item.customId}`}
                    />
                  )
                );
              })}
            </AuctionsPanel>
          )}

          {popularAuctions && Object.keys(popularAuctions)?.length > 0 && (
            <AuctionsPanel
              title="Popular Auctions"
              viewAllClicked={() => {
                navigate("/search-result", {
                  state: { filter: "?badge=popular_auctions" },
                });
              }}
            >
              {popularAuctions.map((item, i) => {
                return (
                  <AuctionBlock
                    key={i}
                    auctionType={item.type.replaceAll("_", " ").toLowerCase()}
                    heading={item.title}
                    description={`${item.city}, ${item.state}`}
                    price={item.price}
                    itemId={item.itemId}
                    image={item.defaultImage}
                    time={item.endingIn}
                    lot={`LOT # ${item.customId}`}
                  />
                );
              })}
            </AuctionsPanel>
          )}
        </div>

        {!isAuthorized && (
          <div className="container mb-10">
            <div className="row">
              <div className="offset-xl-1 col-xl-10 col-lg-12">
                <SmallBanner />
              </div>
            </div>
          </div>
        )}

        <div className="container mb-10">
          {newAuctions && Object.keys(newAuctions)?.length > 0 && (
            <AuctionsPanel
              title="Newly Listed Auctions"
              viewAllClicked={() => {
                navigate("/search-result", {
                  state: { filter: "?badge=new_auctions" },
                });
              }}
            >
              {newAuctions.map((item, i) => {
                return (
                  <AuctionBlock
                    key={i}
                    auctionType={item.type.replaceAll("_", " ").toLowerCase()}
                    heading={item.title}
                    description={`${item.city}, ${item.state}`}
                    price={item.price}
                    itemId={item.itemId}
                    image={item.defaultImage}
                    time={item.endingIn}
                    lot={`LOT # ${item.customId}`}
                  />
                );
              })}
            </AuctionsPanel>
          )}
        </div>
      </>
    </Layout>
  );
};
export default Home;
