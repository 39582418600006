import React, { useState } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import showpsw from "../assets/images/show-psw.svg";
import hidepsw from "../assets/images/hide-psw.svg";

const getFieldCSSClasses = (touched, errors, value) => {
  const classes = ["d-flex flex-row form-control-psw"];

  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && value && value.toString().length > 0 && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function PasswordGroup({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, isValid }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "password",
  serverError,
  defaultTouch = false,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div
        className={getFieldCSSClasses(
          touched[field.name] || defaultTouch,
          errors[field.name],
          field.value
        )}
      >
        <input
          type={`${showPassword ? "text" : "password"}`}
          className="psw-field"
          {...field}
          {...props}
          value={field.value ?? ""}
        />
          <span className="p-2">
            <img src={showPassword ? showpsw : hidepsw} className="img-responsive mr-2 mt-1 cursor-pointer" alt="img" onClick={(e) => {
                e.preventDefault();
                setShowPassword((prev) => !prev);
              }}/>

            {/* <i
              className={`m fa ${
                showPassword ? "fa-eye-slash" : "fa-eye"
              } pointer`}
              aria-hidden="true"
              onClick={(e) => {
                e.preventDefault();
                setShowPassword((prev) => !prev);
              }}
            /> */}
          </span>
      </div>
      {withFeedbackLabel &&
        ((touched[field.name] || defaultTouch) ?? false) &&
        (!isValid[field.name] ?? false) &&
        errors[field.name] && (
          <FieldFeedbackLabel
            error={errors[field.name]}
            touched={touched[field.name]}
            type={type}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}

      {withFeedbackLabel && serverError && (
        <FieldFeedbackLabel
          error={serverError}
          touched={touched[field.name] || defaultTouch}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
