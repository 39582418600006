import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import SetupForm from "./SetupForm";
import StepHeading from "./StepHeading";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY);

const AddCard = () => {
  const location = useLocation();
  const params = location.state ?? {};

  let options = {
    appearance: {
      theme: "stripe",
    },
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "40px",
      border: "1px solid #dddddd",
    },
  };
  useEffect(() => {
    options.clientSecret = params.clientSecret;
  }, [params]);

  const [stripeOptions, setStripeOptions] = useState(options);

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <Layout>
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <AccountMenu addCardActive={true} />
              </div>

              <div className="offset-lg-1 col-lg-8 col-md-8">
                <StepHeading title="Add New Credit Card" step="2/2" />

                <div className="mb-5">
                  <BHotrizontalProgress progress={33} />
                </div>
                <div className="row">
                  <div className="col-lg-7 col-12">
                    <p className="mt-5 instructions-desc">
                      Valid USA Credit/Debit Card is needed to place bid. Your
                      card will be used for the buyer's fee only.
                    </p>

                    <h5 className="card-billing-sub-heading mb-4">
                      2. Credit Card Information
                    </h5>

                    <SetupForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Elements>
    </>
  );
};

export default AddCard;
