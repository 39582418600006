import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../../components/UI/HorizontalProgress";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../../components/UI/ZInputComponent";
import * as Yup from "yup";
import Label from "../../../components/UI/Label";
import PlaceholderHeading from "../../credit-card/PlaceholderHeading";
import { scrollTo } from "../../../services/Utilities";
import ZCheckBoxComponent from "../../../components/form/ZCheckBoxComponent";
import { useRef } from "react";
import ErrorMessage from "../../../components/UI/ErrorMessage";
import DualErrorMessage from "../../../components/UI/DualErrorMessage";
import { wizardGenericErrorMsg } from "../../../Constants";
import WizardButtons from "./WizardButtons";

const PricingDetail = (props) => {
  const { step, data, serverErrors, tabDisable } = props;
  const { saveTab, closeWizard } = props;

  const { auctionTypeParam } = props;

  const draftSubmitRef = useRef();
  const [isDraft, setIsDraft] = useState(false);

  const tabInitValues = {
    startingPrice: "",
    reservePrice: "",
    addTaxAmount: "",
    taxAmount: "",
    auctionType: "",
  };

  const schema = Yup.object().shape({
    // startingPrice: Yup.number()
    //   .required("Starting price is required")
    //   .typeError("Invalid starting price"),
    startingPrice: Yup.number().when("auctionType", {
      is: (auctionType) =>
        auctionType === "ONLINE_AUCTION" ||
        auctionType === "SEALED_AUCTION" ||
        auctionType === "MAKE_AN_OFFER",
      then: Yup.number()
        .required("Starting price is required")
        .typeError("Invalid starting price"),
      otherwise: Yup.number()
        .required("Price is required")
        .typeError("Invalid price"),
    }),
    reservePrice: Yup.number().when("auctionType", {
      is: (auctionType) =>
        auctionType === "ONLINE_AUCTION" ||
        auctionType === "SEALED_AUCTION" ||
        auctionType === "MAKE_AN_OFFER",
      then: Yup.number()
        .required("Reserve price is required")
        .typeError("Invalid reserve price"),
    }),
    addTaxAmount: Yup.string().optional(),
    taxAmount: Yup.number().when("addTaxAmount", {
      is: "yes",
      then: Yup.number()
        .required("Tax amount is required")
        .typeError("Invalid tax amount"),
    }),
  });

  const [tabData, setTabData] = useState(tabInitValues);
  const [tabErrors, setTabErrors] = useState(serverErrors);

  const populateTabData = (inputData) => {
    if (inputData && Object.values(inputData)?.length > 0) {
      setTabData((state) => {
        console.log("input data", inputData);
        const tempState = {
          ...state,
          ...inputData,
          addTaxAmount: inputData.addTaxAmount === "yes" ? "yes" : "no",
        };
        console.log("merged data", { ...tempState });

        return tempState;
      });
    }
  };

  useEffect(() => {
    populateTabData(data);
  }, []);

  useEffect(() => {
    populateTabData(data);
  }, [data]);

  useEffect(() => {
    setTabErrors(serverErrors);
  }, [serverErrors]);

  useEffect(() => {
    setTabData((state) => {
      const tempState = {
        ...state,
        auctionType: auctionTypeParam,
      };
      return tempState;
    });
  }, [auctionTypeParam]);

  return (
    <>
      <PlaceholderHeading title="Pricing Details">
        <button
          disabled={tabDisable}
          className="btn-block btn btn-outline-primary px-4"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            closeWizard();
          }}
        >
          Close
        </button>
      </PlaceholderHeading>
      <div className="mt-3 mb-2">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mt-5 instructions-desc">
            Set the starting and reserve price for your item. Reserve is
            optional
          </p>

          <p className="mt-2 instructions-desc">
            Tips for Success! Set your starting price at 10% - 20% of your
            estimated value. Setting a low starting price entices bidding,
            giving you better results!
          </p>

          <div className="row" id="pageError">
            <div className="col-md-12">
              {tabErrors && tabErrors.message && (
                <div className="mb-4">
                  <ErrorMessage position="top">
                    {tabErrors.message}
                  </ErrorMessage>
                </div>
              )}
            </div>
          </div>
          <fieldset disabled={tabDisable}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={tabData}
              validationSchema={schema}
              onSubmit={(values) => {
                console.log("submitted values", values);

                saveTab(
                  step,
                  {
                    ...tabData,
                    ...values,
                    addTaxAmount: values.addTaxAmount == "yes",
                  },
                  isDraft
                );
              }}
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                validateForm,
              }) => (
                <form id="passportInfoForm">
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          {tabData.auctionType === "BUY_NOW" && "Price"}
                          {tabData.auctionType !== "BUY_NOW" &&
                            "Starting Price"}
                        </Label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">USD</span>
                          </div>
                          <Field
                            className="form-control"
                            name="startingPrice"
                            component={ZInputComponent}
                            placeholder={`Enter${
                              tabData.auctionType !== "BUY_NOW"
                                ? " Starting "
                                : " "
                            }Price`}
                            serverError={
                              serverErrors && serverErrors.startingPrice
                            }
                          />
                        </div>
                      </div>

                      {(tabData.auctionType === "ONLINE_AUCTION" ||
                        tabData.auctionType === "SEALED_AUCTION" ||
                        tabData.auctionType === "MAKE_AN_OFFER") && (
                        <div className="js-form-message mb-4">
                          <Label
                            hightlighted
                            required={
                              tabData.auctionType === "ONLINE_AUCTION" ||
                              tabData.auctionType === "SEALED_AUCTION" ||
                              tabData.auctionType === "MAKE_AN_OFFER"
                            }
                          >
                            Reserve Price
                          </Label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">USD</span>
                            </div>
                            <Field
                              className="form-control"
                              name="reservePrice"
                              required
                              component={ZInputComponent}
                              placeholder="Enter Reserve Price"
                              serverError={
                                serverErrors && serverErrors.reservePrice
                              }
                            />
                          </div>
                        </div>
                      )}

                      <div className="js-form-message mb-4">
                        <Field
                          className="form-control"
                          fieldName="addTaxAmount"
                          name="addTaxAmount"
                          items={[
                            {
                              value: "yes",
                              label: "Add tax to this item",
                            },
                          ]}
                          component={ZCheckBoxComponent}
                          handleOnChange={(index, fieldName, checkedItems) => {
                            setFieldValue(
                              "addTaxAmount",
                              checkedItems?.includes("yes") ? "yes" : "no"
                            );
                          }}
                        />
                        {false && (
                          <p className="mb-0 mt-2">
                            This is your default tax amount. Changing this
                            amount will charge the tax amount on all of your
                            current listings and any which have not been
                            invoiced
                          </p>
                        )}

                        <DualErrorMessage
                          fieldName="addTaxAmount"
                          serverError={
                            serverErrors && serverErrors.addTaxAmount
                          }
                        />
                      </div>

                      {values.addTaxAmount === "yes" && (
                        <div className="js-form-message mb-4">
                          <Label hightlighted required>
                            Tax Amount
                          </Label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">USD</span>
                            </div>
                            <Field
                              className="form-control"
                              name="taxAmount"
                              required
                              component={ZInputComponent}
                              placeholder="Enter Tax Amount"
                              serverError={
                                serverErrors && serverErrors.taxAmount
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="js-form-message mb-4 row">
                    <div className="col-12">
                      <WizardButtons>
                        <button
                          className="btn btn-primary-dark-w px-5"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(false);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save and Continue
                        </button>

                        <button
                          ref={draftSubmitRef}
                          className="btn btn-outline-primary px-5"
                          type="button"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(true);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save Draft
                        </button>
                      </WizardButtons>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default PricingDetail;
