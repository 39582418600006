import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { PasswordGroup } from "../../components/PasswordGroup";
import { validatePassword } from "../../services/Utilities";
import { ResetPasswordRequest } from "../../services/ForgotPasswordService";
import style from "../login/LoginModule.css";
import classes from "../search/Search.module.css";
import forgotpsw from "../../assets/images/forgotpsw.svg";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";

const ResetPassword = () => {
  const params = useLocation().state ?? {};
  console.log(params.email);
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Not a valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    repassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const initialValues = {
    email: params.email,
    password: "",
    repassword: "",
  };

  const passwordTooltip = (
    <div className="tooltip-info">
      <p>
        Password must be atleast 8 characters and must contain an upper-case
        character, a lower-case character, a numeric character, and a special
        character.
      </p>
    </div>
  );

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="offset-lg-4 offset-md-4 col-lg-4 col-md-4 col-sm-12">
            <section className="section-onsale-product ">
              <div className="text-center mb-4">
                <img
                  src={forgotpsw}
                  className="notification-logo img-responsive"
                  alt="forgotpsw img"
                />
              </div>
              <h2 className="pg-3 font-weight-bold text-center purple-500">
                Reset Password
              </h2>
              <h3 className={`nav-classic ${classes.searchSection} ${classes.asSearchTitle} mb-0 pb-2 font-size-25 section-title text-center`}></h3>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values) => {
                  let payload = {
                    email: values.email,
                    password: values.password,
                    confirmPassword: values.repassword,
                  };
                  ResetPasswordRequest(payload)
                    .then((result) => {
                      navigate("/login", {
                        state: {
                          from: "resetpassword",
                          message:
                            result.message ?? "Password reset successfull",
                        },
                      });
                    })
                    .catch((error) => {});
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  validateForm,
                  setFieldValue,
                }) => (
                  <form>
                    {/* <p className="p-v1 text-center ct-u-marginBottom40">
                      {values.email}
                    </p> */}
                    <div className="js-form-message mb-4 mt-4">
                        {passwordTooltip}
                    </div>
                    <div className="js-form-message mb-4 mt-4">
                      <label className="form-label">
                        New Password<span className="color-red"> *</span>
                      </label>
                      <Field
                        name="password"
                        component={PasswordGroup}
                        placeholder="Enter new password"
                        label="New Password"
                        validate={validatePassword}
                      />
                    </div>

                    <div className="js-form-message mb-4">
                      <label className="form-label">
                        Confirm Password<span className="color-red"> *</span>
                      </label>
                      <Field
                        name="repassword"
                        component={PasswordGroup}
                        placeholder="Confirm New Password"
                      />
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <Link
                          className="btn-block btn btn-primary-dark-w px-5 float-right"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();

                            let res = await validateForm();
                            console.log(res);
                            if (res && Object.values(res)?.length > 0) {
                            }
                            handleSubmit(e);
                          }}
                        >
                          SAVE
                        </Link>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
