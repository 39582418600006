import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MapComponent from "./MapComponent";

const render = (status) => {
  switch (status) {
    case Status.SUCCESS:
      return <MapComponent />;
  }
};

const Map = ({ item }) => (
  <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={render}>
    <MapComponent item={item} />
  </Wrapper>
);

export default Map;
