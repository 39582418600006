import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  deflateErrors,
  isBadRequest,
  isJSON,
  validatePassword, validateUsername,
  Regex,
} from "../../services/Utilities";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./ScheduleDemo.module.css";
import profilePic from "../../assets/images/seller-pic.png";
import calendarIcon from "../../assets/images/datepicker-calender.svg";
import forwardArrowIcon from "../../assets/images/gte.svg";
import { isSeller } from "../../services/Utilities";
import { shallowEqual, useSelector } from "react-redux";

const DemoDuration = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const initialValue = {
    timeDuration: "",
  };

  return (
    <>
    {/* {!isSeller(user.type) && (
      <Navigate to="/not-authorized" />
    )} */}

    <Layout>
      <div className="row mb-18 mr-1 ml-1">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 mt-7">
              <div className="row">
                <div className="d-inline col-7">
                  <h2 className="pg-5 font-weight-bold purple-500">
                  Select duration for a demo
                  </h2>
                </div>
                <div className="d-inline col-5 text-right">
                  <h2
                    className=""
                    style={{ color: "#AFB0B5", fontSize: "24px" }}
                  >
                    Step: <b className="color-2">1/4</b>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <BHotrizontalProgress progress={33} />
            </div>

            <div className="offset-lg-3 col-md-6 mt-3 text-center">
              <img src={profilePic} className={`align-self-center mt-4 ${classes.sellerPicture}`} alt="..."/>
              <p className={`${classes.sellerName} mt-3 mb-2`}>{user.name}</p>
              <p className={`${classes.sellerpg}`}>Welcome to BidlyAuction, please use this page to schedule a demo with me. I look forward to meeting you!</p>

              <hr className={`${classes.sellerLine}`}></hr>

              <Link className={`${classes.sellerTimeLink}`} 
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/demo-date", {
                    state: { timeDuration: "30" },
                  });
                }}> 
                <img src={calendarIcon} className="align-self-center mr-4 mb-1" alt="..."/>
                30 Minutes Meeting
                <img src={forwardArrowIcon} className="align-self-center ml-4" alt="..."/>
              </Link>
              <hr className={`${classes.sellerLine}`}></hr>
              <Link className={`${classes.sellerTimeLink}`} 
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/demo-date", {
                    state: { timeDuration: "60" },
                  });
                }}> 
                <img src={calendarIcon} className="align-self-center mr-4 mb-1" alt="..."/>
                60 Minutes Meeting
                <img src={forwardArrowIcon} className="align-self-center ml-4" alt="..."/>
              </Link>
              <hr className={`${classes.sellerLine}`}></hr>

              {/* <div className="mt-4">
                <Link
                  className="btn-block btn btn-primary-dark-w px-5 w-40 float-right"
                  type="submit"
                  onClick={async (e) => {
                    e.preventDefault();
                  }}
                >
                  Next
                </Link>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Layout>
    </>
  );
};

export default DemoDuration;
