import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import {
  FetchUserProfile,
  UpdateAccountInfoRequest,
} from "../../services/UserService";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import { CATEGORY_LIBRARY_URL } from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import { useAuthContext } from "../../redux/auth-context";
import {
  isJSON,
  deflateErrors,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Alert from "../../components/UI/Alert";

const AccountInformation = () => {
  const ctx = useAuthContext();

  let navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [categories, setCategories] = useState([]);
  const [checkedEmailPrefrences, setCheckedEmailPrefrences] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);
  const [isValueLodaed, setIsValueLoaded] = useState(false);

  const updateAccountInfo = (values) => {
    let pref = [];
    checkedEmailPrefrences.forEach((item, index) => {
      if (item) pref.push(categories[index].value);
    });

    let payload = {
      userName: values.userName,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      confirmEmail: values.confirmEmail,
      emailPrefrences: pref,
      marketingConsent: values.marketingConsent,
    };

    console.log(payload);

    ctx.showProgress(true);

    UpdateAccountInfoRequest(payload)
      .then((data) => {
        console.log(data);
        ctx.showProgress(false);
        setUpdatedSuccessfully(true);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        console.log(json);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }
        }
      });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const initialValue = {
    userName: "",
    firstName: "",
    lastName: "",
    confirmEmail: "",
    email: "",
    companyName: "",
    position: "",
    vatRegNumber: "",
    emailPrefrences: [],
    marketingConsent: false,
  };

  const schema = Yup.object().shape({
    userName: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string(),
    confirmEmail: Yup.string()
      .email("Not a valid email")
      .required("Confirm email is required"),
    emailPrefrences: Yup.array(),
    marketingConsent: Yup.string().required("Marketing consent is required"),
  });

  const loadData = async () => {
    ctx.showProgress(true);

    try {
      const libRes = await LibraryService(CATEGORY_LIBRARY_URL);
      let myCategories = [];
      libRes.map((item) => {
        myCategories.push({ value: item.id, label: item.key });
      });
      setCategories(myCategories);

      const userData = await FetchUserProfile();
      const updatedCheckedPrefrences = myCategories.map((item) => {
        if (userData.emailPrefrences?.includes(item.value.toString()))
          return true;
        else return false;
      });
      setCheckedEmailPrefrences(updatedCheckedPrefrences);
      setUserInfo(userData);
      setIsValueLoaded(true);
    } catch (e) {
      if (isStopFlowConditionMet(e.message)) return;

      navigate("/error");
    }

    ctx.showProgress(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleEmailPrefrencesOnChange = (position) => {
    let prefValues = checkedEmailPrefrences
      .flatMap((bool, index) => (bool ? index : []))
      .map((i) => categories[i].value);

    const updatedCheckedPrefrences = checkedEmailPrefrences.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedEmailPrefrences(updatedCheckedPrefrences);
    console.log(updatedCheckedPrefrences);
  };

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                <AccountMenu accountInfoActive={true} subMenu={"info"} />
              </div>
              <div className="offset-lg-1 col-md-7">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="h3 font-weight-bold purple-500">
                      Account Information
                    </h2>
                  </div>
                  <div className="col-md-12 mb-5">
                    <BHotrizontalProgress progress={43} />
                  </div>
                  <div className="col-md-12">
                    {!setIsValueLoaded ? <div>Loading...</div> : ""}
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      enableReinitialize={true}
                      initialValues={userInfo}
                      validationSchema={schema}
                      onSubmit={(values) => {
                        updateAccountInfo(values);
                      }}
                    >
                      {({
                        handleSubmit,
                        isSubmitting,
                        errors,
                        touched,
                        validateForm,
                        setFieldValue,
                      }) => (
                        <form id="passportInfoForm">
                          {updatedSuccessfully && (
                            <Alert>
                              Success! Your account information has been saved.
                            </Alert>
                          )}
                          <div className="col-md-7">
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="userName"
                                component={ZInputComponent}
                                label="Username"
                                disabled={true}
                              />
                            </div>
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="firstName"
                                component={ZInputComponent}
                                label="First Name"
                                required={true}
                                disabled={true}
                              />
                            </div>
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="lastName"
                                component={ZInputComponent}
                                label="Last Name"
                                required={true}
                                disabled={true}
                              />
                            </div>
                            <div className="js-form-message mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name="email"
                                component={ZInputComponent}
                                label="Email"
                                required={true}
                                disabled={true}
                              />
                            </div>
                            {/* <div className="js-form-message mb-3">
                              <h2 className="h5 text-center purple-500">
                                Please confirm your email address
                                <span className="color-red"> *</span>
                              </h2>
                            </div>
                            <div className="js-form-message mb-4">
                              <Field
                                className="form-control"
                                type="text"
                                name="confirmEmail"
                                component={ZInputComponent}
                                placeholder="Confirm Email Address"
                                label="Confirm Email Address"
                                serverError={
                                  serverErrors && serverErrors.confirmEmail
                                }
                              />
                            </div> */}
                          </div>
                          <div className="col-md-10">
                            <div className="js-form-message mb-3">
                              <label className="form-label font-weight-bold">
                                Please select your email preferences, you may
                                refine these categories by assets in your
                                preferences at any time.
                              </label>
                              <div className="row js-form-message mb-4">
                                {categories.map(({ value, label }, index) => {
                                  return (
                                    <div
                                      className="col-md-6"
                                      key={index}
                                      style={{ display: "block" }}
                                    >
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={`ep-checkbox-${index}`}
                                          name="emailPrefrences"
                                          value={value}
                                          checked={
                                            checkedEmailPrefrences[index]
                                          }
                                          onChange={() =>
                                            handleEmailPrefrencesOnChange(index)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`ep-checkbox-${index}`}
                                        >
                                          {label}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="js-form-message mb-3">
                              <label className="form-label font-weight-bold">
                                Please give your consent for electronic
                                marketing communications
                                <span className="color-red"> *</span>
                              </label>
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="marketingConsent"
                                  value="t"
                                  id="marketingConsent1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="marketingConsent1"
                                >
                                  Yes, I would like to receive electronic
                                  marketing communications
                                </label>
                              </div>
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="marketingConsent"
                                  value="f"
                                  id="marketingConsent2"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="marketingConsent2"
                                >
                                  No, please do not send any electronic
                                  marketing communications
                                </label>
                              </div>
                              {(touched.marketingConsent ||
                                errors.marketingConsent) && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.marketingConsent}
                                </div>
                              )}

                              {serverErrors &&
                                serverErrors.marketingConsent && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {serverErrors.marketingConsent}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <button
                                className="btn-block btn btn-primary-dark-w px-5 text-transform-upper mt-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let res = await validateForm();
                                  console.log("res", res);
                                  if (res && Object.values(res)?.length > 0) {
                                  }
                                  handleSubmit(e);
                                }}
                              >
                                update information
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default AccountInformation;
