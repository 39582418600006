import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import plus from "../../assets/images/plus.svg";
import updateIcon from "../../assets/images/edit-icon.svg";
import LinkButton from "../../components/button/LinkButton";
import {
  FetchPhoneData,
  deleteBidderAlternatePhoneNumber,
} from "../../services/UserService";
import {
  isJSON,
  isSeller,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import PhoneCardsPanel from "./PhoneCardsPanel";
import PhoneCard from "./PhoneCard";
import Alert from "../../components/UI/Alert";
import { useAuthContext } from "../../redux/auth-context";
import ConfirmModal from "../../components/UI/ConfirmModal";
import AlertModal from "../../components/UI/AlertModal";
import { shallowEqual, useSelector } from "react-redux";

const AddPhoneInfo = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useLocation().state ?? {};

  const [phoneInfo, setPhoneInfo] = useState();
  const [phoneAddStatus, setPhoneAddStatus] = useState();

  const [deletePhoneId, setDeletePhoneId] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showPrimaryPhoneDeleteAlert, setShowPrimaryPhoneDeleteAlert] =
    useState(false);

  useEffect(() => {
    setPhoneAddStatus(params.phoneAddStatus);
  }, [params]);

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    FetchPhoneData()
      .then((resp) => {
        setPhoneInfo(resp);
      })
      .catch((e) => {
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        navigate("/error");
      });
  }, []);

  const removeAlternatePhoneInfo = async (id) => {
    ctx.showProgress(true);
    console.log("clicked on remove button", id);
    try {
      await deleteBidderAlternatePhoneNumber(id);
      const resp = await FetchPhoneData();
      setPhoneInfo(resp);
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);

      if (isStopFlowConditionMet(e.message)) return;

      navigate("/error");
    }
  };

  return (
    <>
      {showDeleteConfirm && (
        <ConfirmModal
          title="Delete Alternate Phone Information"
          message="Are you sure want to remove alternate phone information?"
          cancelClicked={(e) => {
            e.preventDefault();
            setShowDeleteConfirm(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowDeleteConfirm(false);
            removeAlternatePhoneInfo(deletePhoneId);
          }}
        />
      )}

      {showPrimaryPhoneDeleteAlert && (
        <AlertModal
          type="info"
          title="Delete Primary Phone Information"
          message="You can not remove your primary phone information?"
          okClicked={(e) => {
            setShowPrimaryPhoneDeleteAlert(false);
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <AccountMenu accountInfoActive={true} subMenu={"phoneNumber"} />
            </div>
            <div className="offset-lg-1 col-lg-7 col-md-7">
              <div className="row">
                <div className="col-md-12">
                  <h2 class="h3 font-weight-bold">Phone Number</h2>
                </div>
                <div className="col-md-12 mb-4">
                  <BHotrizontalProgress progress={33} />
                </div>

                <div className="col-md-12">
                  {phoneAddStatus && (
                    <Alert>
                      Success! Your phone number information has been saved.
                    </Alert>
                  )}
                </div>
                <div className="col-md-12">
                  <p className="pg-2 instructions-desc">
                    If you have multiple phone numbers, please be sure to
                    designate an active phone number. You will receive all calls
                    to this number.
                  </p>
                </div>

                <div className="col-md-12">
                  {isSeller(user.type) && (
                    <LinkButton to="/add-agency-phone" className="mt-5">
                      UPDATE PHONE NUMBER
                    </LinkButton>
                  )}

                  {!isSeller(user.type) && (
                    <LinkButton to="/add-phone" className="mt-5">
                      UPDATE PHONE NUMBER
                    </LinkButton>
                  )}
                </div>

                <div className="col-md-12 mt-4 mb-10">
                  {phoneInfo && (
                    <PhoneCardsPanel>
                      {phoneInfo.phoneNumber && (
                        <PhoneCard
                          key={101}
                          isActive={"ACTIVE"}
                          identifier={phoneInfo.phoneInfoId}
                          title={"Primary Phone Number"}
                          mobileNumber={phoneInfo.phoneNumber}
                          extention={phoneInfo.phoneType}
                          onRemove={(id) => {
                            console.log("clicked on remove button");
                            setShowPrimaryPhoneDeleteAlert(true);
                            // setDeletePhoneId(id);
                            // setShowDeleteConfirm(true);
                          }}
                        />
                      )}
                      {phoneInfo.alternatePhoneNumber && (
                        <PhoneCard
                          key={102}
                          isActive={"ACTIVE"}
                          identifier={phoneInfo.phoneInfoId}
                          title={"Alternate Phone Number"}
                          mobileNumber={phoneInfo.alternatePhoneNumber}
                          extention={phoneInfo.alternatePhoneType}
                          onRemove={(id) => {
                            console.log("clicked on remove button");
                            setDeletePhoneId(id);
                            setShowDeleteConfirm(true);
                          }}
                        />
                      )}
                    </PhoneCardsPanel>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddPhoneInfo;
