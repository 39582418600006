import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import classes from "./Static.module.css";
import LinkButton from "../../components/button/LinkButton";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { isSeller } from "../../services/Utilities";
import { LibraryService } from "../../services/LibraryService";
import { ALL_REVIEWS } from "../../Constants";
import { useAuthContext } from "../../redux/auth-context";
import mobile from "../../assets/images/mobile_mockup.png";

const Privacy = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <Layout>
      <>
        <div className="mb-5">
          <div className={`bg-img-hero ${classes.bannerImgBuy}`}>
            <div className="container min-height-300 overflow-hidden d-flex justify-content-md-start justify-content-start align-items-center">
              <div className={` ${classes.bannerTextContainer} col-md-7 mt-5`}>
                <h3 className={classes.heading}>
                  BidlyAuction, Privacy Policy
                </h3>
                <p className={classes.para}>
                  BidlyAuction respects data privacy and all data privacy
                  rights. BidlyAuction aims to process and store Personal Data
                  received from Users and Customers or any third-party in
                  accordance with applicable data protection laws.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-7 mt-5">
          <div className="container min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start">
            <div className={classes.textContainer}>
              <div className="row">
                <div className="offset-xl-2 col-xl-8 col-lg-12 text-left">
                  <h2 className="text-center">BIDLY PRIVACY POLICY</h2>
                  <h4 className="text-center">Our Commitment to Privacy</h4>
                  <p>
                    Your privacy is very important to us. Part of our operation
                    of this website involves the collection and use of
                    information about you. This privacy policy explains what
                    type of information we collect and what we do with that
                    information to allow you to make choices about the way your
                    information is collected and used. This privacy policy may
                    change from time to time, so please check it often.
                  </p>

                  <h4 className="text-center">
                    What Information Do We Collect?
                  </h4>
                  <p>
                    In general, you can visit this website without identifying
                    who you are or revealing any information about yourself.
                    Information collected online can generally be categorized as
                    anonymous or personally identifiable. Anonymous information
                    is information that cannot be connected to the identity of a
                    specific individual. Personally identifiable information is
                    information that specifically identifies a particular user,
                    such as name, address, or phone number. An example of
                    anonymous information is the fact that, while this website
                    may record the number of visits to a particular page that
                    occur in a given period of time, it does not necessarily
                    tell us the names or other identifying information of every
                    visitor. Many users of this website will choose not to
                    provide any personally identifiable information; therefore,
                    those individuals are anonymous to us, and any data
                    collected about their use of this website is anonymous
                    information.
                  </p>

                  <h4 className="text-center">
                    Automatic Anonymous Information
                  </h4>
                  <p>
                    When you visit our site, we collect certain technical and
                    routing information about your computer. For example, we log
                    environmental variables such as browser type, operating
                    system and CPU speed, and the Internet Protocol (IP) address
                    of your originating Internet Service Provider, to try to
                    bring you the best possible service. We also record search
                    requests and results to try to ensure the accuracy and
                    efficiency of our search engine. We use your IP address to
                    track your use of the site, including pages visited and the
                    time spent on each page. We collect this information and use
                    it to measure the use of this website and to improve its
                    content and performance. All of the information that is
                    automatically submitted to us by your browser is considered
                    anonymous information. To the extent we share such
                    information with third parties, it is not traceable to any
                    particular user and will not be used to contact you.
                  </p>

                  <h4 className="text-center">Cookies</h4>
                  <p>
                    This site uses cookies. Cookies are small data files,
                    typically made up of a string of text and numbers, that
                    assign you a unique identifier. This information enables
                    your computer to have a “dialogue” with our site and permits
                    us to administer our site more efficiently and to provide a
                    more tailored and user-friendly service to you. You may set
                    your browser to notify you when you receive a cookie or to
                    prevent cookies from being sent; if you do so, this may
                    limit the functionality we can provide you when you visit
                    our site.
                  </p>

                  <p>
                    Third parties that link on this site may use cookies or
                    collect other information when you go to their site. We do
                    not control the collection or use of your information by
                    these companies. You should contact these companies directly
                    if you have any questions about their collection or use of
                    information about you.
                  </p>

                  <h4 className="text-center">
                    How Do We Use Information We Collect?
                  </h4>
                  <p>
                    We collect personally identifiable information only for
                    providing the services you request, generating statistical
                    studies, conducting marketing research, improving products
                    and services, sending you surveys, and notifying you of new
                    products and any other changes to our site or services that
                    may affect you. When you submit personally identifiable
                    information to us, you understand that you are agreeing to
                    allow us to access, store, and use that information for
                    those purposes. We will not sell or give any personally
                    identifiable information to any third parties.
                  </p>

                  <p>
                    Occasionally, it may be necessary to share data with
                    third-party service providers to facilitate the delivery of
                    services via the Website. Users will be notified whenever
                    their data is shared, and the data management by third
                    parties will be consistent with their own privacy policies.
                  </p>

                  <p>
                    We may be required by law enforcement or judicial
                    authorities to provide personally identifiable information
                    to the appropriate governmental authorities. If requested by
                    law enforcement or judicial authorities, we will provide
                    this information on receipt of the appropriate
                    documentation. We may also release information to law
                    enforcement agencies or other third parties if we feel it is
                    necessary to protect the safety and welfare of our personnel
                    or to enforce our terms of use.
                  </p>

                  <h4 className="text-center">Opt-Out Policy</h4>
                  <p>
                    If at any time you do not wish to receive offers and emails
                    from us, we ask that you tell us. You may remove your name
                    from our mailing list by sending us an email addressed to
                    sales@bidlyauction.com and indicating in the subject line
                    “No Offers or Email.”
                  </p>

                  <h4 className="text-center">Security</h4>
                  <p>
                    We operate secure data networks protected by industry
                    standard firewall and password protection systems. Our
                    security and privacy policies are periodically reviewed and
                    enhanced as necessary, and only authorized individuals have
                    access to the personally identifiable information provided
                    by our users. We do not, however, guarantee that
                    unauthorized, inadvertent disclosure will never occur.
                  </p>

                  <h4 className="text-center">Transfer of Customer Information</h4>
                  <p>
                    Customer lists and information are properly considered
                    assets of a business. Accordingly, if we merge with another
                    entity or if we sell our assets to another entity, our
                    customer lists and information, including personally
                    identifiable information you have provided us, would be
                    included among the assets that would be transferred.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Privacy;
