import React from "react";
import classes from "./dashboard.module.css";

const LabelValue = ({ label, value }) => {
  return (
    <>
      <div className={classes.countsDiv}>
        <div className={classes.value}>{value}</div>
        <div className={classes.label}>{label}</div>
      </div>
    </>
  );
};

export default LabelValue;
