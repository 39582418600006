import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import LinkButton from "../../components/button/LinkButton";
import classes from "../my-bids/MyBids.module.css";
import {
  fetchSellerAuctionConfig,
  fetchSellerBankData,
} from "../../services/UserService";
import {
  isBadRequest,
  isJSON,
  isSeller,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import PhoneCardsPanel from "./PhoneCardsPanel";
import PhoneCard from "./PhoneCard";
import Alert from "../../components/UI/Alert";
import { useAuthContext } from "../../redux/auth-context";
import ConfirmModal from "../../components/UI/ConfirmModal";
import AlertModal from "../../components/UI/AlertModal";
import { shallowEqual, useSelector } from "react-redux";

const SellerTermsInfo = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useLocation().state ?? {};

  const [data, setData] = useState();
  const [addStatus, setAddStatus] = useState();

  useEffect(() => {
    setAddStatus(params.addStatus);
  }, [params]);

  useEffect(() => {
    fetchSellerAuctionConfig()
      .then((resp) => {
        setData(resp);
      })
      .catch((e) => {
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  }, []);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <AccountMenu accountInfoActive={true} subMenu={"sellerTerms"} />
            </div>
            <div className="offset-lg-1 col-lg-7 col-md-7">
              <div className="row">
                <div className="col-md-12">
                  <h2 class="h3 font-weight-bold">Seller Terms & Conditions</h2>
                </div>
                <div className="col-md-12 mb-4">
                  <BHotrizontalProgress progress={33} />
                </div>

                <div className="col-md-12">
                  {addStatus && (
                    <Alert>
                      Success! Seller Terms & Conditions has been saved.
                    </Alert>
                  )}
                </div>
                <div className="col-md-12">
                  <p className="pg-2 instructions-desc">
                    The seller terms and conditions field will be filled
                    automatically with this information when you list a new
                    item. You may update this information when creating a new
                    item.
                  </p>
                </div>

                {data && (
                  <>
                    <div className="col-md-12 mt-4">
                      <h5>Seller Terms & Conditions</h5>
                      <BHotrizontalProgress progress={33} />
                    </div>
                    <div className="col-md-12 mt-1 mb-5 mt-5">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.sellerTerms,
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="col-md-12">
                  <LinkButton to="/add-seller-terms" className="mt-5">
                    UPDATE SELLER TERMS AND CONDITIONS
                  </LinkButton>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SellerTermsInfo;
