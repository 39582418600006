import { useState } from "react";
import AccountMenuItem from "./AccountMenuItem";
import "./AccountMenu.css";
import { useNavigate, Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isSeller } from "../../services/Utilities";
import { actionTypes as authActionTypes } from "../../pages/_redux/authRedux";
import AccountMenuHeading from "./AccountMenuHeading";
import { useAuthContext } from "../../redux/auth-context";
import { StatusRequest } from "../../services/UserService";

const AccountMenu = ({
  items,
  identityActive,
  myBidsActive,
  myBuyNowActive,
  addCardActive,
  watchlistActive,
  accountInfoActive,
  messagesActive,
  myItemsActive,
  reportsActive,
  questionActive,
  subMenu,
}) => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clicked, setClicked] = useState("0");

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <ul className="accordion">
      {isSeller(user.type) && (
        <AccountMenuHeading title={"Sell on BidlyAuction"} />
      )}

      {isSeller(user.type) && (
        <AccountMenuItem
          single
          title={`Dashboard`}
          onToggle={() => {
            handleToggle(8);
            navigate("/my-dashboard");
          }}
          active={clicked === 8}
          key={8}
        />
      )}

      {isSeller(user.type) && (
        <AccountMenuItem
          single
          title={`My Listings`}
          onToggle={() => {
            handleToggle(6);

            dispatch({
              type: authActionTypes.UpdateMiscData,
              payload: {
                listingStatus: "ACTIVE",
              },
            });

            navigate("/my-listing");
          }}
          active={clicked === 6 || myItemsActive}
          key={6}
        />
      )}

      {isSeller(user.type) && (
        <AccountMenuItem
          single
          title={`Sales Report`}
          onToggle={() => {
            handleToggle(7);
            navigate("/sales-report");
          }}
          active={clicked === 7 || reportsActive}
          key={7}
        />
      )}

      {false && isSeller(user.type) && (
        <AccountMenuItem
          title={"Reports and Invoices"}
          onToggle={() => handleToggle(7)}
          active={clicked === 7 || reportsActive}
          key={7}
        >
          <ul className="innerpanel-links">
            <li>
              <Link className={subMenu === "sales-invoice" ? "active" : ""}>
                Sales Invoice
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "sales-report" ? "active" : ""}
                to="/sales-report"
              >
                Sales Report
              </Link>
            </li>
          </ul>
        </AccountMenuItem>
      )}

      {!isSeller(user.type) && (
        <AccountMenuHeading title={"Bid on BidlyAuction"} className={"mt-5"} />
      )}

      {!isSeller(user.type) && (
        <AccountMenuItem
          single
          title={`Dashboard`}
          onToggle={() => {
            handleToggle(8);
            navigate("/bidder-dashboard");
          }}
          active={clicked === 8}
          key={8}
        />
      )}

      {!isSeller(user.type) && (
        <AccountMenuItem
          single={true}
          title={"Identity Verification"}
          onToggle={() => {
            handleToggle(1);
            ctx.showProgress(true);
            StatusRequest()
              .then((data) => {
                ctx.showProgress(false);
                if (
                  data.documentStatus == "VERIFIED" ||
                  data.documentStatus == "SUBMITTED"
                ) {
                  navigate("/verify-identity");
                } else {
                  navigate("/verify-identity-welcome");
                }
              })
              .catch((error) => {
                ctx.showProgress(false);
                navigate("/verify-identity-welcome");
              });
          }}
          active={clicked === 1 || identityActive}
          key={1}
        />
      )}

      {!isSeller(user.type) && (
        <AccountMenuItem
          single
          title={"Add Credit Card"}
          onToggle={() => {
            handleToggle(2);
            navigate("/add-card-info");
          }}
          active={clicked === 2 || addCardActive}
          key={2}
        />
      )}

      {!isSeller(user.type) && (
        <AccountMenuItem
          single
          title={"Watchlist"}
          onToggle={() => {
            handleToggle(3);
            navigate("/wish-list");
          }}
          active={clicked === 3 || watchlistActive}
          key={3}
        />
      )}

      {!isSeller(user.type) && (
        <AccountMenuItem
          title={"My Bids"}
          onToggle={() => handleToggle(4)}
          active={clicked === 4 || myBidsActive}
          key={4}
        >
          <ul className="innerpanel-links">
            <li>
              <Link
                className={subMenu === "open-bids" ? "active" : ""}
                to="/open-bids"
              >
                Open Lots
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "bids-won" ? "active" : ""}
                to="/bids-won"
              >
                Lots Won
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "bids-not-won" ? "active" : ""}
                to="/bids-not-won"
              >
                Lots Not Won
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "open-offers" ? "active" : ""}
                to="/open-offers"
              >
                Open Offers
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "offers-won" ? "active" : ""}
                to="/offers-won"
              >
                Offers Won
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "offers-not-won" ? "active" : ""}
                to="/offers-not-won"
              >
                Offers Not Won
              </Link>
            </li>
          </ul>
        </AccountMenuItem>
      )}

      {!isSeller(user.type) && (
        <AccountMenuItem
          title={"Buy Now"}
          onToggle={() => handleToggle(9)}
          active={clicked === 9 || myBuyNowActive}
          key={9}
        >
          <ul className="innerpanel-links">
            <li>
              <Link
                className={subMenu === "item-cart" ? "active" : ""}
                to="/item-bought-cart"
              >
                Generate Invoice & Pay Buyer Fee
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "item-checkout" ? "active" : ""}
                to="/item-bought-checkout"
              >
                Make Payment & Pickup Item
              </Link>
            </li>
            <li>
              <Link
                className={subMenu === "item-bought" ? "active" : ""}
                to="/item-bought"
              >
                Item(s) Bought
              </Link>
            </li>
          </ul>
        </AccountMenuItem>
      )}

      <AccountMenuItem
        title={"Messages"}
        onToggle={() => handleToggle(5)}
        active={clicked === 5 || messagesActive}
        className={isSeller(user.type) && "mt-0"}
        key={5}
      >
        <ul className="innerpanel-links">
          {false && (
            <li>
              <Link
                className={subMenu === "new-message" ? "active" : ""}
                to="/new-message"
              >
                New Message
              </Link>
            </li>
          )}
          <li>
            <Link className={subMenu === "inbox" ? "active" : ""} to="/inbox">
              Inbox
            </Link>
          </li>
          <li>
            <Link className={subMenu === "outbox" ? "active" : ""} to="/outbox">
              Outbox
            </Link>
          </li>
        </ul>
      </AccountMenuItem>

      <AccountMenuItem
        title={"Account Update"}
        onToggle={() => handleToggle(0)}
        active={clicked === 0 || accountInfoActive}
        key={0}
      >
        <ul className="innerpanel-links">
          <li>
            <Link
              to="/account-info"
              className={subMenu === "info" ? "active" : ""}
            >
              Account information
            </Link>
          </li>
          <li>
            <Link
              to="/add-address-info"
              className={subMenu === "address" ? "active" : ""}
            >
              {isSeller(user.type) && "Agency Address"}
              {!isSeller(user.type) && "Addresses"}
            </Link>
          </li>
          <li>
            <Link
              to="/add-phone-info"
              className={subMenu === "phoneNumber" ? "active" : ""}
            >
              {isSeller(user.type) && "Agency Phone Number"}
              {!isSeller(user.type) && "Phone Number"}
            </Link>
          </li>

          {false && isSeller(user.type) && (
            <li>
              <Link
                to="/seller-bank-info"
                className={subMenu === "bankInfo" ? "active" : ""}
              >
                Seller Bank Account
              </Link>
            </li>
          )}

          {isSeller(user.type) && (
            <li>
              <Link
                to="/seller-terms-info"
                className={subMenu === "sellerTerms" ? "active" : ""}
              >
                Seller Terms and Conditions
              </Link>
            </li>
          )}

          <li>
            <Link
              to="/reset-password-pu"
              className={subMenu === "resetPassword" ? "active" : ""}
            >
              Reset Password
            </Link>
          </li>
        </ul>
      </AccountMenuItem>
      {isSeller(user.type) && (
        <AccountMenuItem
          single={true}
          title={"Bidder Questions"}
          onToggle={() => {
            navigate("/bidder-questions");
            handleToggle(10);
          }}
          active={clicked === 10 || questionActive}
        ></AccountMenuItem>
      )}
    </ul>
  );
};

export default AccountMenu;
