import React from "react";
import { Fragment } from "react";
import gteIcon from "../../assets/images/gte-grey.svg";
import classes from "./Breadcrumb.module.css";

const Breadcrumb = ({ items = [] }) => {
  return (
    <div className={classes.breadcrumb}>
      {items &&
        items.map((item, i) => {
          return (
            <Fragment key={i}>
              {item}
              <span className={classes.caret}>
                <img src={gteIcon} />
              </span>
            </Fragment>
          );
        })}
    </div>
  );
};

export default Breadcrumb;
