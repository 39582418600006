import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import classes from "./Listing.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import LotsTable from "../../components/table/LotsTable";
import {
  formatUSD,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import {
  chooseAuctionWinner,
  fetchMyItem,
  rejectAuctionWinner,
} from "../../services/AuctionItemService";
import {
  SELLER_AUCTION_ITEM_DECISION_URL,
  SELLER_AUCTION_ITEM_OFFER_LIST_URL,
} from "../../Constants";
import ConfirmModal from "../../components/UI/ConfirmModal";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";

const SellerDecision = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [itemDetail, setItemDetail] = useState({});
  const [bidderList, setBidderList] = useState();

  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [winnerId, setWinnerId] = useState();
  const [showRejectConfirmBox, setShowRejectConfirmBox] = useState(false);

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();

  const fetchData = async (itemId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchMyItem(
        SELLER_AUCTION_ITEM_DECISION_URL.replace("{itemId}", itemId)
      );
      console.log("item", rs);
      setItemDetail(rs);

      if (rs.type === "SEALED AUCTION" || rs.type === "ONLINE AUCTION") {
        setBidderList([
          {
            bidderId: rs.highestBid.bidderId,
            name: rs.highestBid.name,
            email: rs.highestBid.email,
            amount: rs.highestBid.amount,
            phoneNumber: rs.highestBid.phoneNumber,
            submittedOn: rs.highestBid.submittedOn,
          },
        ]);
      } else if (rs.type === "MAKE AN OFFER") {
        setBidderList(rs.offers?.content);
        setTotal(rs.offers?.totalElements);
        setTotalPages(rs.offers?.totalPages);
      }

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const fetchItemOffers = async (itemId) => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "?" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const rs = await fetchMyItem(
        SELLER_AUCTION_ITEM_OFFER_LIST_URL.replace("{itemId}", itemId) +
          pagingParams
      );

      if (itemDetail.type === "MAKE AN OFFER") {
        setBidderList(rs?.content);
        setTotal(rs?.totalElements);
        setTotalPages(rs?.totalPages);
      }

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const chooseWinner = async (winningId) => {
    ctx.showProgress(true);
    chooseAuctionWinner(itemDetail?.itemId, winnerId)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/my-listing");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  const rejectWinners = async () => {
    ctx.showProgress(true);
    rejectAuctionWinner(itemDetail?.itemId)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/my-listing");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  useEffect(() => {
    if (params.item) fetchData(params.item);
  }, [params.item]);

  useEffect(() => {
    console.log("bidder list updated", bidderList);
  }, [bidderList]);

  useEffect(() => {
    if (pageSize && currentPage + 1 && itemDetail?.itemId)
      fetchItemOffers(itemDetail.itemId);
  }, [pageSize, currentPage]);

  const tableHeader = (
    <tr>
      <th>Bidder</th>
      <th>Submitted On</th>
      <th>Amount</th>
      <th>Action</th>
    </tr>
  );

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to accept this ${
            itemDetail?.type === "MAKE AN OFFER" ? "offer" : "bid"
          }?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            chooseWinner(winnerId);
          }}
        />
      )}

      {showRejectConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to reject ${
            itemDetail?.type === "MAKE AN OFFER" ? "offers" : "highest bid"
          }?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowRejectConfirmBox(false);
            rejectWinners();
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myItemsActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Item Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col-auto">
                  <div className="d-lg-none d-block mb-3">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.type?.toLowerCase()}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div
                      className={classes.imgContainer}
                      style={{ background: `url(${itemDetail.defaultImage})` }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-lg-block d-none">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.type?.toLowerCase()}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <h4>{itemDetail.title}</h4>

                  <div
                    className="instructions-desc"
                    dangerouslySetInnerHTML={{ __html: itemDetail.description }}
                  />

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Start Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.startedOn)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>End Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.endingIn)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Starting Price</div>
                      <div className={classes.value}>
                        {formatUSD(itemDetail.startingPrice)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Reserve Price</div>
                      <div className={classes.value}>
                        {formatUSD(itemDetail.reservePrice)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <ButtonsPanel>
                  {(itemDetail.type === "SEALED AUCTION" ||
                    itemDetail.type === "ONLINE AUCTION") && (
                    <a
                      href="#"
                      className={`btn btn-secondary ${buttonPanelClasses.item}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowRejectConfirmBox(true);
                      }}
                    >
                      REJECT HIGHEST BID
                    </a>
                  )}

                  {itemDetail.type === "MAKE AN OFFER" && (
                    <a
                      href="#"
                      className={`btn btn-secondary ${buttonPanelClasses.item}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowRejectConfirmBox(true);
                      }}
                    >
                      REJECT OFFERS
                    </a>
                  )}
                </ButtonsPanel>
              </div>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <LotsTable
                    tableHeader={tableHeader}
                    noRecordTableBody={
                      <>
                        <tr>
                          <td colSpan={4} className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      </>
                    }
                    tableBody={
                      bidderList && Object.keys(bidderList).length > 0
                        ? bidderList.map((bid, i) => {
                            return (
                              <SellerDecision.BiddersBodyRow
                                key={i}
                                bidId={bid.bidderId}
                                name={bid.name}
                                email={bid.email}
                                phone={bid.phoneNumber}
                                amount={bid.amount}
                                currency={itemDetail.currency}
                                submittedOn={formattedUTCtoUSCentral(
                                  bid.submittedOn
                                )}
                                acceptClicked={(winningId) => {
                                  setWinnerId(winningId);
                                  setShowConfirmBox(true);
                                }}
                              />
                            );
                          })
                        : undefined
                    }
                    noRecordMobileBody={
                      <>
                        <div className={tableClasses.blockPanel}>
                          <div
                            className={`justify-content-center ${tableClasses.block}`}
                          >
                            <div className={tableClasses.blockText}>
                              <div className={tableClasses.item}>
                                <div className={tableClasses.productLabel}>
                                  No Records Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    mobileBody={
                      bidderList && Object.keys(bidderList).length > 0
                        ? bidderList.map((bid, i) => {
                            return (
                              <SellerDecision.BiddersMobileBodyRow
                                key={i}
                                bidId={bid.bidderId}
                                name={bid.name}
                                email={bid.email}
                                phone={bid.phoneNumber}
                                amount={bid.amount}
                                currency={itemDetail.currency}
                                submittedOn={formattedUTCtoUSCentral(
                                  bid.submittedOn
                                )}
                                acceptClicked={(winningId) => {
                                  setWinnerId(winningId);
                                  setShowConfirmBox(true);
                                }}
                              />
                            );
                          })
                        : undefined
                    }
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage + 1}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

SellerDecision.BiddersBodyRow = ({
  bidId,
  name,
  email,
  phone,
  amount,
  currency,
  submittedOn,
  acceptClicked = (bidId) => {},
}) => (
  <>
    <tr>
      <td>
        <span className={tableClasses.productLabel}>{name}</span>
        <span className={tableClasses.lot}>{email}</span>
        {phone && <span className={tableClasses.lot}>{phone}</span>}
      </td>
      <td>{submittedOn}</td>
      <td>{formatUSD(amount)}</td>
      <td>
        <a
          href="#"
          className="btn btn-block py-1 btn-primary-dark-w"
          onClick={(e) => {
            e.preventDefault();
            acceptClicked(bidId);
          }}
        >
          ACCEPT
        </a>
      </td>
    </tr>
  </>
);

SellerDecision.BiddersMobileBodyRow = ({
  bidId,
  name,
  email,
  phone,
  amount,
  currency,
  submittedOn,
  acceptClicked = (bidId) => {},
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}></div>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.productLabel}>{name}</div>
            <div className={tableClasses.lot}>{email}</div>
            {phone && <div className={tableClasses.lot}>{phone}</div>}
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Submitted On</div>
            <div className={tableClasses.value}>{submittedOn}</div>
          </div>
        </div>
      </div>
      <div className={`${tableClasses.block} ${tableClasses.bordered}`}>
        <div className={tableClasses.selection}></div>

        <div className={tableClasses.totalRow}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Amount</div>
            <div className={tableClasses.value}>{formatUSD(amount)}</div>
          </div>
          <div className={tableClasses.buttons}>
            <a
              href="#"
              className="btn btn-block py-1 btn-primary-dark-w"
              onClick={(e) => {
                e.preventDefault();
                acceptClicked(bidId);
              }}
            >
              ACCEPT
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerDecision;
