import React from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./CategoryPanel.module.css";

const CategoryPanel = ({ categoryId, title, categories }) => {
  const navigate = useNavigate();
  return (
    <>
      {categories && (
        <>
          <h1
            className={classes.heading}
            id={title?.replace(/\s+/g, "-")?.toLowerCase()}
          >
            {title}
          </h1>
          <div className="row mt-5">
            {categories.map((cat, i) => {
              return (
                <div key={i} className="col-md-4 mb-5">
                  <a
                    href="#"
                    className={classes.link}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/search-result", {
                        state: { filter: "?sub_category=" + cat.id },
                      });
                    }}
                  >
                    {cat.name} <span> ({cat.count})</span>
                  </a>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default CategoryPanel;
