import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../redux/auth-context";
import "../../layout/sidebar/Sidebar.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import classes from "./Search.module.css";
import formClasses from "../../components/form/Form.module.css";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchFilter from "./SearchFiltter";

const SearchFilterSidebar = ({
  categories,
  country,
  states,
  cities,
  conditions,
  checkedConditions,
  salesType,
  checkedSalesType,
  accountType,
  checkedAccountType,
  currency,
  startingPrice,
  seller,
  applyFilter,
  selectedCategory,
  getFilteredCategories,
  selectSubCategory,
  onFilterChange,
  onCategoryChange,
  bidlyFilter,
  make,
  model,
  ...props
}) => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const clickAndCloseSidebar = (clicked = () => {}) => {
    clicked();
    ctx.searchFilterSidebarOpen(false);
  };


  return (
    <Menu
      isOpen={ctx.isSearchFilterSidebarOpen}
      onClose={() => ctx.searchFilterSidebarOpen(false)}
    >
      <SearchFilter
        categories={categories}
        country={country}
        states={states}
        cities={cities}
        conditions={conditions}
        salesType={salesType}
        checkedSalesType={checkedSalesType}
        accountType={accountType}
        checkedAccountType={checkedAccountType}
        currency={currency}
        startingPrice={startingPrice}
        seller={seller}
        getFilteredCategories={getFilteredCategories}
        selectedCategory={selectedCategory}
        selectSubCategory={selectSubCategory}
        onFilterChange={onFilterChange}
        onCategoryChange={onCategoryChange}
        bidlyFilter={bidlyFilter}
        make={make}
        model={model}
      />
    </Menu>
  );
};

export default SearchFilterSidebar;
