import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CreateListingMenu from "../../../components/AccountMenu/CreateListingMenu";
import {
  ALL_CONDITION_LIBRARY_URL,
  AUCTION_ITEM_SAVE_ADDITIONAL_URL,
  AUCTION_ITEM_SAVE_DATES_URL,
  AUCTION_ITEM_SAVE_INSTRUCTIONS_URL,
  AUCTION_ITEM_SAVE_LOCATION_URL,
  AUCTION_ITEM_SAVE_MEDIA_URL,
  AUCTION_ITEM_SAVE_PAYMENT_URL,
  AUCTION_ITEM_SAVE_PRICING_URL,
  AUCTION_ITEM_SAVE_REVIEW_URL,
  CATEGORY_LIBRARY_URL,
  COUNTRIES_LIBRARY_URL,
  COUNTRY_STATE_LIBRARY_URL,
  genericErrorMsg,
  STATE_CITY_LIBRARY_URL,
  TIMEZONE_NAME,
} from "../../../Constants";
import Layout from "../../../layout/Layout";
import { useAuthContext } from "../../../redux/auth-context";
import {
  saveMediaServerRequest,
  saveTabServerRequest,
} from "../../../services/AuctionItemService";
import { LibraryService } from "../../../services/LibraryService";
import {
  clearTabsErrors,
  deflateErrors,
  deflateTableErrors,
  isBadRequest,
  isJSON,
  isStopFlowConditionMet,
  localToUSCentralToUTC,
  markTabActiveStatus,
  prepareWizardTabs,
  setTabErrors,
  tabSaved,
  tabValidationFailed,
  wizardValidationFailed,
} from "../../../services/Utilities";
import { actionTypes } from "../../_redux/wizardRedux";
import AdditionalDetail from "./AdditionalDetail";
import ListingDates from "./ListingDates";
import ListingIntroduction from "./ListingIntroduction";
import ListingMedia from "./ListingMedia";
import ListingPaymentMethod from "./ListingPaymentMethod";
import ListingReview from "./ListingReview";
import LocationDetail from "./LocationDetail";
import PricingDetail from "./PricingDetail";
import {
  fetchBidlyBankData,
  fetchSellerAuctionConfig,
  fetchSellerBankData,
} from "../../../services/UserService";

const ListingWizard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useAuthContext();

  const libraryObj = {
    allCatSubCatList: [],
    categories: [],
    subCategories: [],
    conditions: [],
    countries: [],
    states: [],
    cities: [],
    sellerBankAccount: {},
    bidlyBankAccount: {},
  };

  const tabFunctions = {
    saveTab: (step, data, draft = false) => {
      if (step === wizardObj.tabs.instructions.step) {
        saveInstructionsInfo(data, draft);
      } else if (step === wizardObj.tabs.additional.step) {
        saveAdditionalInfo(data, draft);
      } else if (step === wizardObj.tabs.location.step) {
        saveLocationInfo(data, draft);
      } else if (step === wizardObj.tabs.media.step) {
        saveMedia(data, draft);
      } else if (step === wizardObj.tabs.pricing.step) {
        savePricingInfo(data, draft);
      } else if (step === wizardObj.tabs.payment.step) {
        savePaymentInfo(data, draft);
      } else if (step === wizardObj.tabs.dates.step) {
        saveDatesInfo(data, draft);
      } else if (step === wizardObj.tabs.review.step) {
        saveReview(draft);
      }
    },
    onTabToggle: (step, tabActive) => {
      const tempState = { ...wizardState };
      dispatch({
        type: actionTypes.UpdateListingWizard,
        payload: {
          wizardState: markTabActiveStatus(tempState, step, tabActive),
        },
      });
    },
    closeWizard: () => {
      navigate("/my-listing");
    },
  };

  const wizardFunctions = {
    tabs: {
      instructions: {
        filterSubCategories: async (categoryId) => {
          // fetch sub categories filtered with category id
          // update library state

          let subCatList = [];

          wizardLibraryState?.allCatSubCatList
            ?.filter((cat) => cat.id === categoryId)
            ?.map((item) => {
              item.subList?.map((s) => {
                subCatList.push({
                  categoryId: item.id,
                  key: s.id,
                  name: s.key,
                });
              });
            });

          setWizardLibraryState((state) => {
            const tempState = { ...state };
            tempState.subCategories = subCatList;
            return tempState;
          });

          return subCatList;
        },
        ...tabFunctions,
      },
      additional: {
        fetchSellerTerms: async () => {
          // fetch seller terms

          console.log("seller auction config fetch started");

          const auctionConfigRs = await fetchSellerAuctionConfig();

          return auctionConfigRs?.sellerTerms;
        },
        ...tabFunctions,
      },
      location: {
        filterStates: (countryCode, auctionType) => {
          if (!countryCode) return;

          ctx.showProgress(true);
          LibraryService(
            COUNTRY_STATE_LIBRARY_URL.replace("{countryCode}", countryCode)
          )
            .then((data) => {
              ctx.showProgress(false);

              console.log("filtered states", data);

              let states = [];
              data.map((s) => {
                states.push({
                  id: s.id,
                  name: s.key,
                  countryCode: s.value,
                });
              });

              // if (auctionType !== "BUY_NOW") {
              //   states = [...states.filter((s) => s.id == 55 || s.id == 16)];
              // }

              setWizardLibraryState((state) => {
                const tempState = { ...state };
                tempState.states = states;
                return tempState;
              });
            })
            .catch((e) => {
              ctx.showProgress(false);
              if (isBadRequest(e.message)) navigate("/error");
            });
        },
        filterCities: (stateId) => {
          ctx.showProgress(true);

          LibraryService(STATE_CITY_LIBRARY_URL.replace("{stateId}", stateId))
            .then((data) => {
              ctx.showProgress(false);
              let cities = [];
              data.map((s) => {
                cities.push({
                  id: s.id,
                  name: s.key,
                  stateId: s.idTwo,
                  lat: s.value,
                  lng: s.valueTwo,
                });
              });

              setWizardLibraryState((state) => {
                const tempState = { ...state };
                tempState.cities = cities;
                return tempState;
              });
            })
            .catch((e) => {
              ctx.showProgress(false);
              if (isBadRequest(e.message)) navigate("/error");
            });
        },
        ...tabFunctions,
      },
      media: { ...tabFunctions },
      pricing: { ...tabFunctions },
      payment: { ...tabFunctions },
      dates: { ...tabFunctions },
      review: { ...tabFunctions },
    },
  };

  const tabProps = {
    completed: false,
    active: false,
    tabDisable: true,
    data: {},
    serverErrors: {},
    tableErrors: {},
  };

  const wizardObj = {
    status: {
      currentStep: 1,
      completedSteps: 0,
      itemId: null,
    },
    tabs: {
      instructions: {
        step: 1,
        ...tabProps,
      },
      additional: {
        step: 2,
        ...tabProps,
      },
      location: {
        step: 3,
        ...tabProps,
      },
      media: {
        step: 4,
        ...tabProps,
      },
      pricing: {
        step: 5,
        ...tabProps,
      },
      payment: {
        step: 6,
        ...tabProps,
      },
      dates: {
        step: 7,
        ...tabProps,
      },
      review: {
        step: 8,
        ...tabProps,
      },
    },
  };

  const { wizardState } = useSelector(({ wizard }) => ({
    wizardState: wizard.listingWizardState,
  }));

  const wizardReferenceData = useLocation().state;
  const populateReferenceData = (refData) => {
    const tempWizardState = {
      ...wizardObj,
      ...wizardState,
    };
    console.log("inside populateReferenceData temp", wizardState);

    if (refData?.wizardStatus) {
      tempWizardState.status.itemId = refData?.wizardStatus?.itemId;
      tempWizardState.status.completedSteps =
        refData?.wizardStatus?.completedTab;
      tempWizardState.status.currentStep = refData?.wizardStatus?.activeTab;

      console.log("navigate from ", refData?.wizardStatus?.navigateFrom);
      if (
        refData?.wizardStatus?.navigateFrom == "relist" ||
        refData?.wizardStatus?.navigateFrom == "similar"
      ) {
        console.log("im inside");
        tempWizardState.status.currentStep = 1;
      }
    }

    tempWizardState.tabs.instructions.data = {
      ...refData?.instructions,
    };

    tempWizardState.tabs.additional.data = {
      ...refData?.additional,
    };

    tempWizardState.tabs.location.data = {
      ...refData?.location,
    };

    tempWizardState.tabs.media.data = {
      ...refData?.media,
    };

    tempWizardState.tabs.pricing.data = {
      ...refData?.pricing,
    };

    tempWizardState.tabs.payment.data = {
      ...refData?.payment,
    };
    tempWizardState.tabs.dates.data = {
      ...refData?.dates,
    };

    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: {
        wizardState: prepareWizardTabs({ ...tempWizardState, ...wizardState }),
      },
    });

    if (tempWizardState?.status?.itemId) {
      console.log("item id", tempWizardState?.status?.itemId);
      fetchReviewDataFromServer(tempWizardState?.status?.itemId);
    }

    // if (tempWizardState?.status?.currentStep > 1) {
    //   scrollTo(tempWizardState?.status?.currentStep, () => {});
    // }

    console.log("inside populateReferenceData", tempWizardState);
  };

  const [wizardLibraryState, setWizardLibraryState] = useState(libraryObj);

  const instructionsLibraries = async () => {
    let allCatSubCatList = wizardLibraryState?.allCatSubCatList ?? [];
    if (
      !wizardLibraryState?.allCatSubCatList?.length ||
      !wizardLibraryState?.categories?.length
    ) {
      try {
        console.log("categories fetch started");

        const catRs = await LibraryService(CATEGORY_LIBRARY_URL);
        allCatSubCatList = catRs;

        let catList = [];
        catRs?.map((item) => {
          catList.push({
            key: item.id,
            name: item.key,
            type: item.value,
          });
        });

        setWizardLibraryState((state) => {
          const tempState = { ...state };
          tempState.allCatSubCatList = catRs ?? [];
          tempState.categories = catList;
          return tempState;
        });
        console.log("categories fetch completed");
      } catch (e) {
        if (isBadRequest(e.message)) navigate("/error");
      }
    }

    try {
      console.log("sub categories fetch started");
      if (wizardState?.tabs?.instructions?.data?.category) {
        let subCatList = [];
        allCatSubCatList
          ?.filter(
            (cat) => cat.id === wizardState?.tabs?.instructions?.data?.category
          )
          ?.map((item) => {
            item.subList?.map((s) => {
              subCatList.push({
                categoryId: item.id,
                key: s.id,
                name: s.key,
              });
            });
          });

        setWizardLibraryState((state) => {
          const tempState = { ...state };
          tempState.subCategories = subCatList;
          return tempState;
        });
      }
      console.log("sub categories fetch completed");
    } catch (e) {
      if (isBadRequest(e.message)) navigate("/error");
    }
  };

  const additionalLibraries = async () => {
    if (!wizardLibraryState?.conditions?.length) {
      try {
        console.log("conditions fetch started");

        const conditionRs = await LibraryService(ALL_CONDITION_LIBRARY_URL);

        let conditionList = [];
        conditionRs?.map((item) => {
          conditionList.push({
            id: item.id,
            name: item.value,
          });
        });

        setWizardLibraryState((state) => {
          const tempState = { ...state };
          tempState.conditions = conditionList;
          return tempState;
        });
        console.log("conditions fetch completed");
      } catch (e) {
        if (isBadRequest(e.message)) navigate("/error");
      }
    }
  };

  const locationLibraries = async () => {
    if (!wizardLibraryState?.countries?.length) {
      try {
        console.log("countries fetch started");

        const countryRs = await LibraryService(COUNTRIES_LIBRARY_URL);

        let countries = [];
        countryRs?.map((c) => {
          countries.push({
            code: c.key,
            name: c.value,
          });
        });

        console.log(countries);

        setWizardLibraryState((state) => {
          const tempState = { ...state };
          tempState.countries = countries;
          return tempState;
        });
        console.log("countries fetch completed");
      } catch (e) {
        if (isBadRequest(e.message)) navigate("/error");
      }
    }

    try {
      console.log("states fetch started");
      if (wizardState?.tabs?.location?.data?.country) {
        const stateRs = await LibraryService(
          COUNTRY_STATE_LIBRARY_URL.replace(
            "{countryCode}",
            wizardState?.tabs?.location?.data?.country
          )
        );

        let states = [];
        stateRs.map((s) => {
          states.push({
            id: s.id,
            name: s.key,
            countryCode: s.value,
          });
        });

        console.log("loaded states", states);

        setWizardLibraryState((state) => {
          const tempState = { ...state };
          tempState.states = states;
          return tempState;
        });
      }
      console.log("states fetch completed");
    } catch (e) {
      if (isBadRequest(e.message)) navigate("/error");
    }

    try {
      console.log("cities fetch started");
      if (wizardState?.tabs?.location?.data?.state) {
        const cityRs = await LibraryService(
          STATE_CITY_LIBRARY_URL.replace(
            "{stateId}",
            wizardState?.tabs?.location?.data?.state
          )
        );

        let cities = [];
        cityRs.map((c) => {
          cities.push({
            id: c.id,
            name: c.key,
            stateId: c.idTwo,
            lat: c.value,
            lng: c.valueTwo,
          });
        });

        console.log("loaded cities", cities);

        setWizardLibraryState((state) => {
          const tempState = { ...state };
          tempState.cities = cities;
          return tempState;
        });
      }
      console.log("cities fetch completed");
    } catch (e) {
      if (isBadRequest(e.message)) navigate("/error");
    }
  };

  const paymentLibraries = async () => {
    try {
      console.log("bidly bank info fetch started");

      const bidlyBankRs = await fetchBidlyBankData();

      console.log(bidlyBankRs);

      setWizardLibraryState((state) => {
        const tempState = { ...state };
        tempState.bidlyBankAccount = bidlyBankRs;
        return tempState;
      });
      console.log("bidly bank info fetch completed");
    } catch (e) {
      // if (isBadRequest(e.message)) navigate("/error");
    }

    try {
      console.log("seller bank info fetch started");

      const sellerBankRs = await fetchSellerBankData();

      console.log(sellerBankRs);

      setWizardLibraryState((state) => {
        const tempState = { ...state };
        tempState.sellerBankAccount = sellerBankRs ?? undefined;
        return tempState;
      });
      console.log("seller bank info fetch completed");
    } catch (e) {
      //if (isBadRequest(e.message)) navigate("/error");
    }
  };

  const loadLibraries = async () => {
    // service call to fetch libraries based on completed steps & active step
    ctx.showProgress(true);
    console.log("inside loadLibraries", wizardState);

    // instructionsLibraries();

    if (
      wizardState?.status?.completedSteps + 1 >=
      wizardState?.tabs?.instructions?.step
    ) {
      await instructionsLibraries();
    }

    if (
      wizardState?.status?.completedSteps + 1 >=
      wizardState?.tabs?.additional?.step
    ) {
      await additionalLibraries();
    }

    if (
      wizardState?.status?.completedSteps + 1 >=
      wizardState?.tabs?.location?.step
    ) {
      await locationLibraries();
    }

    if (
      wizardState?.status?.completedSteps + 1 >=
      wizardState?.tabs?.payment?.step
    ) {
      await paymentLibraries();
    }

    ctx.showProgress(false);
  };

  useEffect(() => {
    // ctx.showProgress(true);

    const tempWizardState = prepareWizardTabs({
      ...wizardObj,
      ...wizardState,
    });
    console.log("init wizard state", tempWizardState);

    console.log("wizard state", wizardState);

    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: { ...clearTabsErrors(tempWizardState) } },
    });

    // if (wizardState?.status?.currentStep > 1) {
    //   scrollTo(wizardState?.status?.currentStep, () => {});
    // }

    //loadLibraries();

    console.log("use effect start");

    if (wizardState?.status?.itemId) {
      console.log("wizard state before fetch review", wizardState);
      fetchReviewDataFromServer(wizardState?.status?.itemId);
    }

    return () => {
      dispatch({
        type: actionTypes.UpdateListingWizard,
        payload: { wizardState: { ...clearTabsErrors(wizardState) } },
      });
    };
  }, []);

  useEffect(() => {
    if (wizardReferenceData && Object.values(wizardReferenceData)?.length > 0) {
      populateReferenceData(wizardReferenceData);
      // loadLibraries();
      console.log("inside wizardReferenceData useEffect");
    }
  }, [wizardReferenceData]);

  useEffect(() => {
    loadLibraries();
    console.log("completed step changed", wizardState?.status?.completedSteps);
  }, [wizardState?.status?.completedSteps]);

  const fetchReviewDataFromServer = async (itemId) => {
    // try {
    //   const res = await fetchListingDataServerRequest(itemId);
    //   const tempState = { ...wizardObj, ...wizardState };
    //   tempState.tabs.review.data = {
    //     ...tempState?.tabs?.review?.data,
    //     ...res,
    //     status: tempState.status,
    //   };
    //   dispatch({
    //     type: actionTypes.UpdateListingWizard,
    //     payload: { wizardState: { ...tempState } },
    //   });
    // } catch (e) {
    //   console.log("exception", e);
    //   ctx.showProgress(false);
    //   if (!isJSON(e.message)) {
    //     navigate("/error");
    //     return;
    //   }
    // }
  };

  const validationFailed = (e, actionStep) => {
    if (isStopFlowConditionMet(e.message)) return;

    if (!isJSON(e.message)) {
      navigate("/error");
      return;
    }

    const json = JSON.parse(e.message);
    if (json.status !== "BAD_REQUEST") {
      // navigate("/error");
      return;
    }

    let errors = {};
    if (json.data.status === "VALIDATION_FAILED") {
      errors = {
        ...deflateErrors(json.data.errors),
        message: genericErrorMsg,
      };

      dispatch({
        type: actionTypes.UpdateListingWizard,
        payload: {
          wizardState: setTabErrors({ ...wizardState }, actionStep, errors),
        },
      });

      tabValidationFailed(actionStep);
    } else if (json.data.status === "WIZARD_VALIDATION_FAILED") {
      errors = {
        ...deflateErrors(json.data.errors),
        message: genericErrorMsg,
      };

      wizardValidationFailed(actionStep, json.data.tab, () => {
        dispatch({
          type: actionTypes.UpdateListingWizard,
          payload: {
            wizardState: setTabErrors(
              { ...wizardState },
              json.data.tab,
              errors
            ),
          },
        });
      });
    } else if (json.data.status === "BUSSINESS_RULE_FAILED") {
      errors = { message: json.data.message };
      wizardValidationFailed(actionStep, json.data.tab, () => {
        dispatch({
          type: actionTypes.UpdateListingWizard,
          payload: {
            wizardState: setTabErrors(
              { ...wizardState },
              json.data.tab,
              errors
            ),
          },
        });
      });
    } else if (json.data.status === "NOT_FOUND") {
      errors = { message: json.data.message };

      dispatch({
        type: actionTypes.UpdateListingWizard,
        payload: {
          wizardState: setTabErrors({ ...wizardState }, actionStep, errors),
        },
      });

      tabValidationFailed(actionStep);
    }
  };

  const saveInstructionsInfo = (data, draft) => {
    // server call
    ctx.showProgress(true);

    const tempState = { ...wizardState };
    tempState.tabs.instructions.serverErrors = {};
    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });

    const requestBody = { ...data, itemId: wizardState.status.itemId };

    console.log("values submitted and starting server call", requestBody);

    saveTabServerRequest(AUCTION_ITEM_SAVE_INSTRUCTIONS_URL, requestBody)
      .then(async (res) => {
        await fetchReviewDataFromServer(res.itemId);

        tabSaved(() => {
          const tempState = { ...wizardState };
          tempState.status.currentStep = res.activeTab;
          tempState.status.completedSteps = res.completedTab;
          tempState.status.itemId = res.itemId;
          tempState.tabs.instructions.data = {
            ...tempState.tabs.instructions.data,
            ...data,
          };

          dispatch({
            type: actionTypes.UpdateListingWizard,
            payload: { wizardState: prepareWizardTabs(tempState) },
          });

          console.log("saving wizard state", prepareWizardTabs(tempState));

          console.log("additional tab saved and moved to next tab");
          ctx.showProgress(false);

          if (draft) navigate("/my-listing");
        });
      })
      .catch((e) => {
        validationFailed(e, wizardState.tabs.instructions.step);
        ctx.showProgress(false);
      });

    console.log("application info save", "save");
  };

  const saveAdditionalInfo = (data, draft) => {
    console.log("is draft", draft);

    // server call
    ctx.showProgress(true);

    console.log("wizard state", wizardState);
    console.log("data before sending", data);

    const tempState = { ...wizardState };
    tempState.tabs.additional.serverErrors = {};
    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });

    const requestBody = { ...data, itemId: wizardState.status.itemId };

    console.log("values submitted and starting server call", requestBody);

    saveTabServerRequest(AUCTION_ITEM_SAVE_ADDITIONAL_URL, requestBody)
      .then(async (res) => {
        await fetchReviewDataFromServer(res.itemId);

        tabSaved(() => {
          const tempState = { ...wizardState };
          tempState.status.currentStep = res.activeTab;
          tempState.status.completedSteps = res.completedTab;
          tempState.status.itemId = res.itemId;
          tempState.tabs.additional.data = {
            ...tempState.tabs.additional.data,
            ...data,
          };

          dispatch({
            type: actionTypes.UpdateListingWizard,
            payload: { wizardState: prepareWizardTabs(tempState) },
          });

          console.log("additional tab saved and moved to next tab");
          ctx.showProgress(false);

          if (draft) navigate("/my-listing");
        });
      })
      .catch((e) => {
        validationFailed(e, wizardState.tabs.additional.step);
        ctx.showProgress(false);
      });
  };

  const saveLocationInfo = (data, draft) => {
    // server call
    ctx.showProgress(true);

    console.log("wizard state", wizardState);
    console.log("data before sending", data);

    const tempState = { ...wizardState };
    tempState.tabs.location.serverErrors = {};
    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });

    const requestBody = { ...data, itemId: wizardState.status.itemId };

    console.log("values submitted and starting server call", requestBody);

    saveTabServerRequest(AUCTION_ITEM_SAVE_LOCATION_URL, requestBody)
      .then(async (res) => {
        await fetchReviewDataFromServer(res.itemId);

        tabSaved(() => {
          const tempState = { ...wizardState };
          tempState.status.currentStep = res.activeTab;
          tempState.status.completedSteps = res.completedTab;
          tempState.status.itemId = res.itemId;
          tempState.tabs.location.data = {
            ...tempState.tabs.location.data,
            ...data,
          };

          dispatch({
            type: actionTypes.UpdateListingWizard,
            payload: { wizardState: prepareWizardTabs(tempState) },
          });

          console.log("location tab saved and moved to next tab");
          ctx.showProgress(false);

          if (draft) navigate("/my-listing");
        });
      })
      .catch((e) => {
        validationFailed(e, wizardState.tabs.location.step);
        ctx.showProgress(false);
      });
  };

  const saveMedia = (data, draft) => {
    // server call
    ctx.showProgress(true);

    console.log("wizard state", wizardState);
    console.log("data before sending", data);

    const tempState = { ...wizardState };
    tempState.tabs.pricing.serverErrors = {};
    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });

    const requestBody = { ...data, itemId: wizardState.status.itemId };

    console.log("values submitted and starting server call", requestBody);

    saveMediaServerRequest(AUCTION_ITEM_SAVE_MEDIA_URL, requestBody)
      .then(async (res) => {
        await fetchReviewDataFromServer(res.itemId);

        tabSaved(() => {
          const tempState = { ...wizardState };
          tempState.status.currentStep = res?.status?.activeTab;
          tempState.status.completedSteps = res?.status?.completedTab;
          tempState.status.itemId = res?.status?.itemId;
          tempState.tabs.media.data = {
            ...tempState.tabs.media.data,
            ...data,
            documents: [],
            youtubeURLs: [],
            galleryImages: [],
            uploadedDocuments: res.documents,
            uploadedGalleryImages: res.galleryImages,
            uploadedYoutubeURLs: res.youtubeURLs,
          };

          dispatch({
            type: actionTypes.UpdateListingWizard,
            payload: { wizardState: prepareWizardTabs(tempState) },
          });

          console.log("media tab saved and moved to next tab");
          ctx.showProgress(false);

          if (draft) navigate("/my-listing");
        });
      })
      .catch((e) => {
        validationFailed(e, wizardState.tabs.pricing.step);
        ctx.showProgress(false);
      });
  };

  const savePricingInfo = (data, draft) => {
    // server call
    ctx.showProgress(true);

    console.log("wizard state", wizardState);
    console.log("data before sending", data);

    const tempState = { ...wizardState };
    tempState.tabs.pricing.serverErrors = {};
    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });

    const requestBody = { ...data, itemId: wizardState.status.itemId };

    console.log("values submitted and starting server call", requestBody);

    saveTabServerRequest(AUCTION_ITEM_SAVE_PRICING_URL, requestBody)
      .then(async (res) => {
        await fetchReviewDataFromServer(res.itemId);

        tabSaved(() => {
          const tempState = { ...wizardState };
          tempState.status.currentStep = res.activeTab;
          tempState.status.completedSteps = res.completedTab;
          tempState.status.itemId = res.itemId;
          tempState.tabs.pricing.data = {
            ...tempState.tabs.pricing.data,
            ...data,
          };

          dispatch({
            type: actionTypes.UpdateListingWizard,
            payload: { wizardState: prepareWizardTabs(tempState) },
          });

          console.log("pricing tab saved and moved to next tab");
          ctx.showProgress(false);

          if (draft) navigate("/my-listing");
        });
      })
      .catch((e) => {
        validationFailed(e, wizardState.tabs.pricing.step);
        ctx.showProgress(false);
      });
  };

  const savePaymentInfo = (data, draft) => {
    // server call
    ctx.showProgress(true);

    console.log("wizard state", wizardState);
    console.log("data before sending", data);

    const tempState = { ...wizardState };
    tempState.tabs.payment.serverErrors = {};
    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });

    const requestBody = { ...data, itemId: wizardState.status.itemId };

    console.log("values submitted and starting server call", requestBody);

    saveTabServerRequest(AUCTION_ITEM_SAVE_PAYMENT_URL, requestBody)
      .then(async (res) => {
        await fetchReviewDataFromServer(res.itemId);

        tabSaved(() => {
          const tempState = { ...wizardState };
          tempState.status.currentStep = res.activeTab;
          tempState.status.completedSteps = res.completedTab;
          tempState.status.itemId = res.itemId;
          tempState.tabs.payment.data = {
            ...tempState.tabs.payment.data,
            ...data,
          };

          dispatch({
            type: actionTypes.UpdateListingWizard,
            payload: { wizardState: prepareWizardTabs(tempState) },
          });

          console.log("payment tab saved and moved to next tab");
          ctx.showProgress(false);

          if (draft) navigate("/my-listing");
        });
      })
      .catch((e) => {
        validationFailed(e, wizardState.tabs.payment.step);
        ctx.showProgress(false);
      });
  };

  const saveDatesInfo = (data, draft) => {
    // server call
    ctx.showProgress(true);

    console.log("wizard state", wizardState);
    console.log("data before sending", data);

    const tempState = { ...wizardState };
    tempState.tabs.dates.serverErrors = {};
    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });

    let updatedStartDate = undefined;
    if (data.startDate) {
      updatedStartDate = localToUSCentralToUTC(data.startDate);
    }

    let updatedEndDate = undefined;
    if (data.endDate) {
      updatedEndDate = localToUSCentralToUTC(data.endDate);
    }

    const requestBody = {
      ...data,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      itemId: wizardState.status.itemId,
    };

    console.log("values submitted and starting server call", requestBody);

    saveTabServerRequest(AUCTION_ITEM_SAVE_DATES_URL, requestBody)
      .then(async (res) => {
        await fetchReviewDataFromServer(res.itemId);

        tabSaved(() => {
          const tempState = { ...wizardState };
          tempState.status.currentStep = res.activeTab;
          tempState.status.completedSteps = res.completedTab;
          tempState.status.itemId = res.itemId;
          tempState.tabs.dates.data = {
            ...tempState.tabs.dates.data,
            ...data,
          };

          dispatch({
            type: actionTypes.UpdateListingWizard,
            payload: { wizardState: prepareWizardTabs(tempState) },
          });

          console.log("dates tab saved and moved to next tab");
          ctx.showProgress(false);

          if (draft) navigate("/my-listing");
        });
      })
      .catch((e) => {
        validationFailed(e, wizardState.tabs.dates.step);
        ctx.showProgress(false);
      });
  };

  const saveReview = (draft) => {
    // server call
    ctx.showProgress(true);

    console.log("wizard state", wizardState);

    const tempState = { ...wizardState };
    tempState.tabs.review.serverErrors = {};
    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });

    const requestBody = { itemId: wizardState.status.itemId, draft: draft };

    console.log("values submitted and starting server call", requestBody);

    saveTabServerRequest(AUCTION_ITEM_SAVE_REVIEW_URL, requestBody)
      .then(async (res) => {
        const tempState = { ...wizardState };
        tempState.status.currentStep = res.activeTab;
        tempState.status.completedSteps = res.completedTab;
        tempState.status.itemId = res.itemId;

        dispatch({
          type: actionTypes.UpdateListingWizard,
          payload: { wizardState: prepareWizardTabs(tempState) },
        });

        console.log("review tab saved and moved to next tab");
        ctx.showProgress(false);

        navigate("/my-listing");
      })
      .catch((e) => {
        validationFailed(e, wizardState.tabs.review.step);
        ctx.showProgress(false);
      });
  };

  const tabClicked = (tab) => {
    const tempState = { ...wizardState };
    tempState.status.currentStep = tab;

    dispatch({
      type: actionTypes.UpdateListingWizard,
      payload: { wizardState: prepareWizardTabs(tempState) },
    });
  };

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <CreateListingMenu
              itemClicked={tabClicked}
              wizardState={{ ...wizardState }}
            />
          </div>

          <div className="offset-lg-1 col-lg-8 col-md-8">
            {wizardState?.tabs?.instructions?.step ===
              wizardState?.status?.currentStep && (
              <ListingIntroduction
                {...wizardState?.tabs?.instructions}
                {...wizardLibraryState}
                {...wizardFunctions.tabs.instructions}
              />
            )}

            {wizardState?.tabs?.additional?.step ===
              wizardState?.status?.currentStep && (
              <AdditionalDetail
                {...wizardState?.tabs?.additional}
                {...wizardLibraryState}
                {...wizardFunctions.tabs.additional}
                isVehicleParam={
                  wizardState?.tabs?.instructions?.data?.isVehicle
                }
              />
            )}

            {wizardState?.tabs?.location?.step ===
              wizardState?.status?.currentStep && (
              <LocationDetail
                {...wizardState?.tabs?.location}
                {...wizardLibraryState}
                {...wizardFunctions.tabs.location}
                auctionTypeParam={
                  wizardState?.tabs?.instructions?.data?.saleType
                }
              />
            )}

            {wizardState?.tabs?.media?.step ===
              wizardState?.status?.currentStep && (
              <ListingMedia
                {...wizardState?.tabs?.media}
                {...wizardLibraryState}
                {...wizardFunctions.tabs.media}
              />
            )}

            {wizardState?.tabs?.pricing?.step ===
              wizardState?.status?.currentStep && (
              <PricingDetail
                {...wizardState?.tabs?.pricing}
                {...wizardLibraryState}
                {...wizardFunctions.tabs.pricing}
                auctionTypeParam={
                  wizardState?.tabs?.instructions?.data?.saleType
                }
              />
            )}

            {wizardState?.tabs?.payment?.step ===
              wizardState?.status?.currentStep && (
              <ListingPaymentMethod
                {...wizardState?.tabs?.payment}
                {...wizardLibraryState}
                {...wizardFunctions.tabs.payment}
                disableCCPaymentParam={
                  wizardState?.tabs?.pricing?.data?.startingPrice >= 5000
                }
              />
            )}

            {wizardState?.tabs?.dates?.step ===
              wizardState?.status?.currentStep && (
              <ListingDates
                {...wizardState?.tabs?.dates}
                {...wizardLibraryState}
                {...wizardFunctions.tabs.dates}
              />
            )}

            {wizardState?.tabs?.review?.step ===
              wizardState?.status?.currentStep && (
              <ListingReview
                {...wizardState?.tabs?.review}
                {...wizardLibraryState}
                {...wizardFunctions.tabs.review}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListingWizard;
