import Category from "../../assets/images/advance-search/ad_category_icon.svg";
import Date from "../../assets/images/advance-search/ad_date_icon.svg";
import Keyword from "../../assets/images/advance-search/ad_Icon.svg";
import Listing from "../../assets/images/advance-search/ad_listing.svg";
import Price from "../../assets/images/advance-search/ad_price_icon.svg";
import Seller from "../../assets/images/advance-search/ad_seller_icon.svg";
import Sort from "../../assets/images/advance-search/ad_sorting_icon.svg";
import Location from "../../assets/images/advance-search/ad_location.svg";
import Zip from "../../assets/images/advance-search/ad_zip.svg";
import classes from "./Search.module.css";

const getIcon = (icon) => {

  switch (icon) {
    case "category":
      return Category;
    case "date":
      return Date;
    case "listing":
      return Listing;
    case "price":
      return Price;
    case "keyword":
      return Keyword;
    case "seller":
      return Seller;
    case "sort":
      return Sort;
    case "location":
      return Location;
    case "zip":
      return Zip;
    default:
      break;
  }
};

const ASLabel = ({ label, icon }) => {
  return (
    <>
      <img className={`${classes.labelIcon} my-auto`} src={getIcon(icon)} />{" "}
      <label className={`${classes.asLabel} ml-2 mb-0`}>{label}</label>
    </>
  );
};

export default ASLabel;
