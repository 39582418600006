import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import PlaceholderHeading from "../credit-card/PlaceholderHeading";
import { useAuthContext } from "../../redux/auth-context";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BIDDER_ALL_QUESTION, DELETE_QUESTION } from "../../Constants";
import { allQuestion } from "../../services/AuctionItemService";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import DropDownButton from "../../components/button/DropDownButton";
import DropDownItem from "../../components/button/DropDownItem";

const AllQuestions = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [itemList, setItemList] = useState([]);

  const { miscData } = useSelector(
    ({ auth }) => ({
      miscData: auth.miscData,
    }),
    shallowEqual
  );

  const fetchMyItems = (status) => {
    ctx.showProgress(true);
    allQuestion(`${BIDDER_ALL_QUESTION}${status}`)
      .then((items) => {
        setItemList(items);
        ctx.showProgress(false);
      })
      .catch((err) => {
        ctx.showProgress(false);
      });
  };

  const deleteQuestion = (questionId) => {
    ctx.showProgress(true);
    allQuestion(`${DELETE_QUESTION}${questionId}`)
      .then((items) => {
        fetchMyItems("EMPTY");
        ctx.showProgress(false);
      })
      .catch((err) => {
        ctx.showProgress(false);
      });
  };

  useEffect(() => {
    fetchMyItems("EMPTY");
  }, []);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu questionActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <PlaceholderHeading title="Bidder Questions"></PlaceholderHeading>
              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>
              <div className="row">
                <div className="col-12">
                  <ButtonsPanel minWidth={`230px`}>
                    <DropDownButton
                      styles={{ minWidth: "200px" }}
                      onItemClicked={(v) => {
                        console.log(v);
                        fetchMyItems(v);
                      }}
                    >
                      <DropDownItem label="New" value="EMPTY" />
                      <DropDownItem label="Deleted" value="DELETED" />
                      <DropDownItem label="Responded" value="ANSWERED" />
                    </DropDownButton>
                  </ButtonsPanel>
                </div>
              </div>
              {itemList && itemList.length > 0 && (
                <div className="row">
                  <div className="col-12 mt-3">
                    <div className="table-responsive">
                      <table className={`table`}>
                        <thead style={{ fontWeight: "bold" }}>
                          <tr>
                            <td>
                              <div className={`text-left`}>Picture</div>
                            </td>
                            <td>
                              <div className={`text-left`}>Title</div>
                            </td>
                            <td>
                              <div className={`text-left`}>Lot Number</div>
                            </td>
                            <td>
                              <div className={`text-left`}>Question</div>
                            </td>
                            <td>
                              <div className={`text-center`}>Action</div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {itemList.map((item) => {
                            return (
                              <tr>
                                <td>
                                  <img
                                    className="img-fluid"
                                    src={item.imageLink}
                                    alt="Image Description"
                                    style={{
                                      borderRadius: "10px",
                                      height: "70px",
                                    }}
                                  />
                                </td>
                                <td>
                                  <div className={`text-left`}>
                                    {item.itemName}
                                  </div>
                                </td>
                                <td>
                                  <div className={`text-left`}>
                                    {item.lotNumber}
                                  </div>
                                </td>
                                <td>
                                  <div className={`text-left`}>
                                    {item.qestion}
                                  </div>
                                </td>
                                <td>
                                  <div className={`text-center`}>
                                    {item.status == "EMPTY" && (
                                      <button
                                        onClick={()=>navigate("/question-details/", { state: item })}
                                        className="btn btn-block py-1 btn-primary-dark-w"
                                      >
                                        Respond
                                      </button>
                                    )}
                                    {item.status == "EMPTY"  && (
                                      <Link
                                        onClick={() =>
                                          deleteQuestion(item.questionId)
                                        }
                                        to="#"
                                        className="btn btn-block py-1 btn-secondary"
                                      >
                                        Delete
                                      </Link>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AllQuestions;
