import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import PlaceholderHeading from "../credit-card/PlaceholderHeading";
import { useAuthContext } from "../../redux/auth-context";
import ListingTable from "../../components/table/ListingTable";
import tableClasses from "../../components/table/ListingTable.module.css";
import {
  formatUSD,
  formattedUTCDatetoUSCentral,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import { actionTypes } from "../_redux/wizardRedux";
import { actionTypes as authActionTypes } from "../_redux/authRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import DropDownButton from "../../components/button/DropDownButton";
import DropDownItem from "../../components/button/DropDownItem";
import {
  SELLER_AUCTION_EDIT_ITEM_FETCH_URL,
  SELLER_AUCTION_ITEM_LIST_URL,
  SELLER_AUCTION_SEND_INVOICE_URL,
  SELLER_AUCTION_SIMILAR_ITEM_URL,
} from "../../Constants";
import {
  deleteItem,
  endAuctionEarly,
  fetchMyItem,
  fetchMyListings,
  listSimilaryMyItem,
  paymentConfirmation,
} from "../../services/AuctionItemService";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import ConfirmModal from "../../components/UI/ConfirmModal";
import AlertModal from "../../components/UI/AlertModal";

const AuctionItemList = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [showInvoiceAlertBox, setShowInvoiceAlertBox] = useState(false);
  const [showDeleteConfirmBox, setShowDeleteConfirmBox] = useState(false);
  const [showCloseAuctionConfirmBox, setShowCloseAuctionConfirmBox] =
    useState(false);
  const [itemId, setItemId] = useState();

  const [itemList, setItemList] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();

  const { miscData } = useSelector(
    ({ auth }) => ({
      miscData: auth.miscData,
    }),
    shallowEqual
  );

  const fetchMyItems = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "&" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const statusParam = "?status=" + (miscData?.listingStatus ?? "ACTIVE");

      const rs = await fetchMyListings(
        SELLER_AUCTION_ITEM_LIST_URL + statusParam + pagingParams
      );
      console.log("items", rs);

      const content = rs?.content;
      const items = [];
      content?.map((c) => {
        let m = {
          ...c,
          startedOn: c.startedOn
            ? formattedUTCtoUSCentral(c.startedOn)
            : c.startedOn,
          endingIn: c.endingIn
            ? formattedUTCtoUSCentral(c.endingIn)
            : c.endingIn,
          createdOn: c.createdOn
            ? formattedUTCDatetoUSCentral(c.createdOn)
            : c.createdOn,
          paymentDate: c.paymentDate
            ? formattedUTCDatetoUSCentral(c.paymentDate)
            : c.paymentDate,
          paymentAcknowledgeDate: c.paymentAcknowledgeDate
            ? formattedUTCDatetoUSCentral(c.paymentAcknowledgeDate)
            : c.paymentAcknowledgeDate,
          pickupDate: c.pickupDate
            ? formattedUTCDatetoUSCentral(c.pickupDate)
            : c.pickupDate,
        };

        items.push(m);
      });

      setItemList(items);

      setTotal(rs.totalElements);
      setTotalPages(rs.totalPages);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const removeItem = async (itemId) => {
    ctx.showProgress(true);
    deleteItem(itemId)
      .then((rs) => {
        ctx.showProgress(false);

        fetchMyItems();

        navigate("/my-listing");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  const closeAuction = async (itemId) => {
    ctx.showProgress(true);
    endAuctionEarly(itemId)
      .then((rs) => {
        ctx.showProgress(false);

        fetchMyItems();

        navigate("/my-listing");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  const prepareDataForItemWizard = (data, from) => {
    dispatch({
      type: actionTypes.ResetListingWizard,
      payload: { wizardState: {} },
    });

    const payload = {
      instructions: {
        saleType: data?.saleType,
        category: data?.category?.id,
        subCategory: data?.subCategory?.id,
        title: data?.title,
        description: data?.description,
        descriptionStyled: data?.descriptionStyled,
        isVehicle: data?.category?.valueTwo == "VEHICLE" ? "yes" : "no",
        autoExtension: data?.autoExtend ? "yes" : "no",
      },
      additional: {
        customId: data?.customId,
        department: data?.department,
        condition: data?.condition?.id,
        inspectionPersonName: data?.inspectionPersonName,
        inspectionPersonContact: data?.inspectionPersonContact,
        inspectionInformation: data?.inspectionInformation,
        inspectionInformationStyled: data?.inspectionInformationStyled,
        removalInformation: data?.removalInformation,
        removalInformationStyled: data?.removalInformationStyled,
        specialInstructions: data?.specialInstructions,
        specialInstructionsStyled: data?.specialInstructionsStyled,
        additionalInformation: data?.additionalInformation,
        additionalInformationStyled: data?.additionalInformationStyled,
        sellerTerms: data?.sellerTerms,
        sellerTermsStyled: data?.sellerTermsStyled,
        vehicleStarts: data?.vehicleStarts,
        vehicleDriveable: data?.vehicleDriveable,
        vehicleTransferrable: data?.vehicleTransferrable,
        vehicleMiles: data?.vehicleMiles,
        vin: data?.vin,
        vehicleMake: data?.vehicleMake,
        vehicleYear: data?.vehicleYear,
        vehicleModel: data?.vehicleModel,
      },
      location: {
        country: data?.country?.key,
        state: data?.state?.id,
        city: data?.city?.id,
        shipping: data?.shipping,
        pickupLocation: data?.pickupLocation,
        pickupLocationLat: data?.pickupLocationLat,
        pickupLocationLong: data?.pickupLocationLong,
        pickupLocationPublic:
          data?.pickupInformationPublic == null ||
          data?.pickupInformationPublic == undefined ||
          data?.pickupInformationPublic
            ? "yes"
            : "no",
      },
      media: {
        documents: [],
        youtubeURLs: [],
        galleryImages: [],
        uploadedDocuments: data?.documents,
        uploadedGalleryImages: data?.galleryImages,
        uploadedYoutubeURLs: data?.youtubeURLs,
      },
      pricing: {
        startingPrice: data?.startingPrice,
        reservePrice: data?.reservePrice,
        addTaxAmount: data?.addTaxAmount ? "yes" : "no",
        taxAmount: data?.taxAmount,
      },
      payment: {
        paymentInstructions: data?.paymentInstructions,
        paymentInstructionsStyled: data?.paymentInstructionsStyled,
        paymentMethods: data?.paymentMethods,
        bankSource: data?.bankSource,
      },
      dates: {
        startDate: formattedUTCtoUSCentral(data?.startDate),
        endDate: formattedUTCtoUSCentral(data?.endDate),
        days: data?.days,
      },
      review: {},
      wizardStatus: { ...data?.wizardStatus, navigateFrom: from },
    };

    console.log("sending data to wizard", payload);

    navigate("/listing-new", { state: { ...payload } });
  };

  const editItem = async (itemId) => {
    ctx.showProgress(true);
    fetchMyItem(SELLER_AUCTION_EDIT_ITEM_FETCH_URL.replace("{itemId}", itemId))
      .then((res) => {
        ctx.showProgress(false);
        prepareDataForItemWizard(res, "edit");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e)) return;
        navigate("/error");
      });
  };

  const relistItem = async (itemId) => {
    listSimilaryMyItem(
      SELLER_AUCTION_SIMILAR_ITEM_URL.replace("{itemId}", itemId)
    )
      .then((res) => {
        ctx.showProgress(false);
        prepareDataForItemWizard(res, "relist");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e)) return;
        navigate("/error");
      });
  };
  const listSimilarItem = async (itemId) => {
    ctx.showProgress(true);
    listSimilaryMyItem(
      SELLER_AUCTION_SIMILAR_ITEM_URL.replace("{itemId}", itemId)
    )
      .then((res) => {
        ctx.showProgress(false);
        prepareDataForItemWizard(res, "similar");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e)) return;
        navigate("/error");
      });
  };

  const sendInvoice = async (itemId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchMyItem(
        SELLER_AUCTION_SEND_INVOICE_URL.replace("{itemId}", itemId)
      );

      console.log("item", rs);
      ctx.showProgress(false);

      if (!rs?.certificateGenerated) {
        setShowInvoiceAlertBox(true);
        return;
      }

      navigate(`/listing-invoice/${itemId}`);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchMyItems();
  }, []);

  useEffect(() => {
    fetchMyItems();
  }, [miscData]);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchMyItems();
  }, [pageSize, currentPage]);

  return (
    <>
      {showDeleteConfirmBox && (
        <ConfirmModal
          title=""
          message="Are you sure you want to remove this item?"
          cancelClicked={(e) => {
            e.preventDefault();
            setShowDeleteConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowDeleteConfirmBox(false);
            removeItem(itemId);
          }}
        />
      )}

      {showCloseAuctionConfirmBox && (
        <ConfirmModal
          title=""
          message="Closing auction early will result in the item being marked as UNSOLD. Are you sure you want to close the auction before the scheduled end time?"
          cancelClicked={(e) => {
            e.preventDefault();
            setShowCloseAuctionConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowCloseAuctionConfirmBox(false);
            closeAuction(itemId);
          }}
        />
      )}

      {showInvoiceAlertBox && (
        <AlertModal
          type="info"
          message={`Please wait while system generates the invoice!`}
          okClicked={(e) => {
            e.preventDefault();
            setShowInvoiceAlertBox(false);
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myItemsActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <PlaceholderHeading title="My Listings">
                <button
                  className="btn-block btn btn-outline-primary px-4"
                  type="button"
                  onClick={async (e) => {
                    e.preventDefault();
                    dispatch({
                      type: actionTypes.ResetListingWizard,
                      payload: { wizardState: {} },
                    });

                    navigate("/listing-new");
                  }}
                >
                  Add New Item
                </button>
              </PlaceholderHeading>
              <div className="mt-3 mb-2">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row">
                <div className="col-12">
                  <ButtonsPanel minWidth={`230px`}>
                    <DropDownButton
                      styles={{ minWidth: "200px" }}
                      onItemClicked={(v) => {
                        console.log(v);

                        dispatch({
                          type: authActionTypes.UpdateMiscData,
                          payload: {
                            listingStatus: v,
                          },
                        });
                      }}
                      selectedValue={miscData?.listingStatus}
                    >
                      <DropDownItem label="Active" value="ACTIVE" />
                      <DropDownItem label="Draft" value="DRAFT" />
                      <DropDownItem label="Scheduled" value="SCHEDULED" />
                      <DropDownItem label="Time Ended" value="TIMED_OUT" />
                      <DropDownItem
                        label="Generate Invoice"
                        value="FINALIZING_WINNER"
                      />
                      <DropDownItem
                        label="Waiting For Bidder to Pay"
                        value="INVOICE_GENERATED"
                      />
                      <DropDownItem
                        label="Confirm Payment"
                        value="WAITING_TO_RECEIVE_PAYMENT"
                      />
                      <DropDownItem
                        label="Confirm Item Pickup"
                        value="WAITING_FOR_ITEM_PICKUP"
                      />
                      <DropDownItem label="Sold" value="SUCCESSFUL_ENDED" />
                      <DropDownItem label="Unsold" value="UNSUCCESSFUL_ENDED" />
                    </DropDownButton>
                  </ButtonsPanel>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mt-3">
                  <ListingTable
                    tableBody={itemList?.map((item, i) => {
                      return (
                        <AuctionItemList.TableBodyRow
                          key={i}
                          itemId={item.itemId}
                          auctionType={item.type}
                          image={item.defaultImage}
                          title={item.title}
                          lot={item.lot}
                          listingDate={item.createdOn}
                          startDate={item.startedOn}
                          endDate={item.endingIn}
                          startingPrice={item.startingPrice}
                          currentPrice={item.price}
                          reserveMet={item.reserveMet}
                          reservePrice={item.reservePrice}
                          currency={item.currency}
                          bids={item.bids}
                          watching={item.watching}
                          status={item.status}
                          bidlyPaymentConfirmation={
                            item.bidlyPaymentConfirmation
                          }
                          paymentDate={item.paymentDate}
                          paymentAcknowledgmentDate={
                            item.paymentAcknowledgeDate
                          }
                          pickupDate={item.pickupDate}
                          winningAmount={item.winningAmount}
                          winner={item.winner}
                          paymentConfirmClicked={(itemId) => {
                            setItemId(itemId);
                            navigate("/listing-confirm-payment/" + itemId);
                          }}
                          listSimilarClicked={(itemId) => {
                            listSimilarItem(itemId);
                          }}
                          closeAuction={(itemId) => {
                            setItemId(itemId);
                            setShowCloseAuctionConfirmBox(true);
                          }}
                          relistClicked={(itemId) => {
                            relistItem(itemId);
                          }}
                          editItemClicked={(itemId) => {
                            editItem(itemId);
                          }}
                          viewBiddersClicked={(itemId) => {
                            navigate("/listing-bidders/" + itemId);
                          }}
                          deleteClicked={(itemId) => {
                            setItemId(itemId);
                            setShowDeleteConfirmBox(true);
                          }}
                          sendInvoice={(itemId) => {
                            setItemId(itemId);
                            sendInvoice(itemId);
                          }}
                        />
                      );
                    })}
                    mobileBody={itemList?.map((item, i) => {
                      return (
                        <AuctionItemList.MobileBodyRow
                          key={i}
                          itemId={item.itemId}
                          auctionType={item.type}
                          image={item.defaultImage}
                          title={item.title}
                          lot={item.lot}
                          listingDate={item.createdOn}
                          startDate={item.startedOn}
                          endDate={item.endingIn}
                          startingPrice={item.startingPrice}
                          currentPrice={item.price}
                          reserveMet={item.reserveMet}
                          reservePrice={item.reservePrice}
                          currency={item.currency}
                          bids={item.bids}
                          watching={item.watching}
                          status={item.status}
                          paymentDate={item.paymentDate}
                          bidlyPaymentConfirmation={
                            item.bidlyPaymentConfirmation
                          }
                          paymentAcknowledgmentDate={
                            item.paymentAcknowledgeDate
                          }
                          pickupDate={item.pickupDate}
                          winningAmount={item.winningAmount}
                          winner={item.winner}
                          paymentConfirmClicked={(itemId) => {
                            setItemId(itemId);
                            navigate("/listing-confirm-payment/" + itemId);
                          }}
                          listSimilarClicked={(itemId) => {
                            listSimilarItem(itemId);
                          }}
                          closeAuction={(itemId) => {
                            setItemId(itemId);
                            setShowCloseAuctionConfirmBox(true);
                          }}
                          relistClicked={(itemId) => {
                            relistItem(itemId);
                          }}
                          editItemClicked={(itemId) => {
                            editItem(itemId);
                          }}
                          viewBiddersClicked={(itemId) => {
                            navigate("/listing-bidders/" + itemId);
                          }}
                          deleteClicked={(itemId) => {
                            setItemId(itemId);
                            setShowDeleteConfirmBox(true);
                          }}
                          sendInvoice={(itemId) => {
                            setItemId(itemId);
                            sendInvoice(itemId);
                          }}
                        />
                      );
                    })}
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage + 1}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

AuctionItemList.TableBodyRow = ({
  itemId,
  auctionType,
  image,
  title,
  lot,
  listingDate,
  startDate,
  endDate,
  startingPrice,
  currentPrice,
  reservePrice,
  reserveMet,
  currency,
  bids,
  watching,
  status,
  bidlyPaymentConfirmation = false,
  paymentDate,
  paymentAcknowledgmentDate,
  pickupDate,
  winningAmount,
  winner,
  paymentConfirmClicked = (itemId) => {},
  listSimilarClicked = (itemId) => {},
  closeAuction = (itemId) => {},
  relistClicked = (item) => {},
  editItemClicked = (itemId) => {},
  viewBiddersClicked = (itemId) => {},
  deleteClicked = (itemId) => {},
  sendInvoice = (itemId) => {},
}) => {
  return (
    <>
      <tr>
        <td>
          {image && (
            <div
              className={tableClasses.imgContainer}
              style={{ backgroundImage: `url(${image})` }}
            />
          )}
        </td>

        <td>
          <div className={`${tableClasses.label}`}>
            <span className="text-uppercase font-weight-bold">
              {(status === "ACTIVE" ||
                status === "DRAFT" ||
                status === "SCHEDULED") &&
                status}
              {status === "TIMED_OUT" && "TIME ENDED"}
              {status === "FINALIZING_WINNER" && "SEND INVOICE"}
              {status === "INVOICE_GENERATED" && (
                <span className="text-danger">WAITING FOR PAYMENT</span>
              )}
              {status === "WAITING_TO_RECEIVE_PAYMENT" && "CONFIRM PAYMENT"}
              {status === "WAITING_FOR_ITEM_PICKUP" && "CONFIRM PICKUP"}
              {status === "SUCCESSFUL_ENDED" && "SOLD"}
              {status === "UNSUCCESSFUL_ENDED" && "UNSOLD"}
            </span>{" "}
            |{" "}
            <span className="text-capitalize">
              {auctionType.replaceAll("_", " ").toLowerCase()}
            </span>
          </div>
          <div className={tableClasses.heading}>
            {status === "ACTIVE" && (
              <Link to={`/auction/${itemId}`}>{title}</Link>
            )}
            {status !== "ACTIVE" && title}
          </div>
          <div className="mt-2">
            <span className={tableClasses.label}>Listing ID: </span>
            <span className={tableClasses.value}> {lot}</span>
          </div>
          <div>
            <span className={tableClasses.label}>Dated: </span>
            <span className={tableClasses.value}> {listingDate}</span>
          </div>
        </td>
        <td>
          <div className={tableClasses.label}>Start Date</div>
          <div className={tableClasses.value}>{startDate}</div>
          <div className={tableClasses.label}>Ending Date</div>
          <div className={tableClasses.value}>{endDate}</div>
        </td>
        <td>
          {(status === "WAITING_FOR_ITEM_PICKUP" ||
            status === "SUCCESSFUL_ENDED") && (
            <>
              <div className={tableClasses.label}>Winning Price</div>
              <div className={tableClasses.value}>
                {formatUSD(winningAmount)}
              </div>

              <div className={tableClasses.label}>Winner</div>
              <div className={tableClasses.value}>{winner}</div>
            </>
          )}

          {status !== "WAITING_FOR_ITEM_PICKUP" &&
            status !== "SUCCESSFUL_ENDED" && (
              <>
                <div className={tableClasses.label}>
                  {auctionType === "BUY NOW" && "Price"}
                  {auctionType !== "BUY NOW" && "Starting Price"}
                </div>
                <div className={tableClasses.value}>
                  {formatUSD(startingPrice)}
                </div>
              </>
            )}

          {status !== "WAITING_FOR_ITEM_PICKUP" &&
            status !== "SUCCESSFUL_ENDED" &&
            status !== "DRAFT" &&
            status !== "SCHEDULED" &&
            (auctionType === "ONLINE AUCTION" ||
              auctionType === "SEALED AUCTION" ||
              auctionType === "MAKE AN OFFER") && (
              <>
                {currentPrice && (
                  <>
                    <div className={tableClasses.label}>
                      {(auctionType === "ONLINE AUCTION" ||
                        auctionType === "SEALED AUCTION") &&
                        "Highest Bid"}
                      {auctionType === "MAKE AN OFFER" && "Highest Offer"}
                    </div>
                    <div className={tableClasses.value}>
                      {formatUSD(currentPrice)}
                    </div>
                  </>
                )}
              </>
            )}
        </td>

        <td>
          {status === "WAITING_FOR_ITEM_PICKUP" && (
            <>
              <div className={tableClasses.label}>Payment Date</div>
              <div className={tableClasses.value}>{paymentDate}</div>
              <div className={tableClasses.label}>
                Payment Acknowledgment Date
              </div>
              <div className={tableClasses.value}>
                {paymentAcknowledgmentDate}
              </div>
            </>
          )}

          {status === "SUCCESSFUL_ENDED" && (
            <>
              <div className={tableClasses.label}>Payment Date</div>
              <div className={tableClasses.value}>{paymentDate}</div>
              <div className={tableClasses.label}>Pickup Date</div>
              <div className={tableClasses.value}>{pickupDate}</div>
            </>
          )}

          {auctionType === "ONLINE AUCTION" &&
            (status === "ACTIVE" ||
              status === "TIMED_OUT" ||
              status === "FINALIZING_WINNER" ||
              status === "INVOICE_GENERATED" ||
              status === "WAITING_TO_RECEIVE_PAYMENT") && (
              <>
                {reserveMet && (
                  <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                    Reserve is Met
                  </div>
                )}
              </>
            )}

          {status !== "WAITING_FOR_ITEM_PICKUP" &&
            status !== "SUCCESSFUL_ENDED" &&
            (auctionType === "ONLINE AUCTION" ||
              auctionType === "SEALED AUCTION" ||
              auctionType === "MAKE AN OFFER") &&
            reservePrice && (
              <>
                <div className={`${tableClasses.label} mt-2`}>
                  Reserve Price
                </div>
                <div className={tableClasses.value}>
                  {formatUSD(reservePrice)}
                </div>
              </>
            )}
        </td>

        {status !== "DRAFT" && status !== "SCHEDULED" && (
          <>
            <td>
              {(auctionType === "ONLINE AUCTION" ||
                auctionType === "SEALED AUCTION") && (
                <div className={tableClasses.label}>Bids</div>
              )}
              {auctionType === "MAKE AN OFFER" && (
                <div className={tableClasses.label}>Offers</div>
              )}
              {(auctionType === "ONLINE AUCTION" ||
                auctionType === "SEALED AUCTION" ||
                auctionType === "MAKE AN OFFER") && (
                <div className={tableClasses.value}>{bids}</div>
              )}

              {(status === "ACTIVE" || status === "TIMED_OUT") && (
                <>
                  <div className={tableClasses.label}>Watching</div>
                  <div className={tableClasses.value}>{watching}</div>
                </>
              )}
            </td>
          </>
        )}

        <td>
          {(status === "DRAFT" || status === "SCHEDULED") && (
            <>
              <a
                href="#"
                className="btn btn-block py-1 btn-primary-dark-w"
                onClick={(e) => {
                  e.preventDefault();
                  editItemClicked(itemId);
                }}
              >
                EDIT
              </a>

              <a
                href="#"
                className="btn btn-block py-1 btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  deleteClicked(itemId);
                }}
              >
                REMOVE
              </a>
            </>
          )}

          {status === "ACTIVE" && (
            <>
              <a
                href="#"
                className="btn btn-block py-1 btn-primary-dark-w"
                onClick={(e) => {
                  e.preventDefault();
                  listSimilarClicked(itemId);
                }}
              >
                LIST SIMILAR
              </a>

              {(auctionType === "ONLINE AUCTION" ||
                auctionType === "MAKE AN OFFER") && (
                <a
                  href="#"
                  className="btn btn-block py-1 btn-secondary-dark-w"
                  onClick={(e) => {
                    e.preventDefault();
                    viewBiddersClicked(itemId);
                  }}
                >
                  {auctionType === "ONLINE AUCTION" && "VIEW BIDS"}
                  {auctionType === "MAKE AN OFFER" && "VIEW OFFERS"}
                </a>
              )}

              <a
                href="#"
                className="btn btn-block py-1 btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  closeAuction(itemId);
                }}
              >
                CLOSE AUCTION
              </a>
            </>
          )}

          {status === "TIMED_OUT" && (
            <>
              <Link
                to={`/listing-award/${itemId}`}
                className="btn btn-block py-1 btn-primary-dark-w"
              >
                ACKNOWLEDGE RESULTS
              </Link>
            </>
          )}

          {status === "FINALIZING_WINNER" && (
            <>
              <a
                href="#"
                className="btn btn-block py-1 btn-primary-dark-w"
                onClick={(e) => {
                  e.preventDefault();
                  sendInvoice(itemId);
                }}
              >
                GENERATE INVOICE
              </a>
            </>
          )}

          {status === "INVOICE_GENERATED" && (
            <>
              <Link
                to={`/listing-payment-await/${itemId}`}
                className="btn btn-block py-1 btn-primary-dark-w"
              >
                {auctionType !== "BUY NOW" && "CONTACT BIDDER"}
                {auctionType === "BUY NOW" && "CONTACT BUYER"}
              </Link>
            </>
          )}

          {status === "WAITING_TO_RECEIVE_PAYMENT" && (
            <>
              {bidlyPaymentConfirmation && (
                <span
                  className={`badge badge-warning ${tableClasses["badge-confirmation"]}`}
                >
                  Waiting for BidlyAuction to Confirm Payment
                </span>
              )}
              {!bidlyPaymentConfirmation && (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    paymentConfirmClicked(itemId);
                  }}
                  className="btn btn-block py-1 btn-primary-dark-w"
                >
                  CONFIRM PAYMENT
                </a>
              )}
            </>
          )}

          {status === "WAITING_FOR_ITEM_PICKUP" && (
            <>
              <Link
                to={"/listing-pickup-confirm/" + itemId}
                className="btn btn-block py-1 btn-primary-dark-w"
              >
                CONFIRM PICKUP
              </Link>
            </>
          )}

          {status === "SUCCESSFUL_ENDED" && (
            <>
              <a
                href="#"
                className="btn btn-block py-1 btn-primary-dark-w"
                onClick={(e) => {
                  e.preventDefault();
                  listSimilarClicked(itemId);
                }}
              >
                LIST SIMILAR
              </a>
            </>
          )}

          {status === "UNSUCCESSFUL_ENDED" && (
            <>
              <a
                href="#"
                className="btn btn-block py-1 btn-primary-dark-w"
                onClick={(e) => {
                  e.preventDefault();
                  relistClicked(itemId);
                }}
              >
                RELIST
              </a>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

AuctionItemList.MobileBodyRow = ({
  itemId,
  auctionType,
  image,
  title,
  lot,
  listingDate,
  startDate,
  endDate,
  startingPrice,
  currentPrice,
  reservePrice,
  reserveMet,
  currency,
  bids,
  watching,
  status,
  bidlyPaymentConfirmation = false,
  paymentDate,
  paymentAcknowledgmentDate,
  pickupDate,
  winningAmount,
  winner,
  paymentConfirmClicked = (itemId) => {},
  listSimilarClicked = (itemId) => {},
  closeAuction = (itemId) => {},
  relistClicked = (item) => {},
  editItemClicked = (itemId) => {},
  viewBiddersClicked = (itemId) => {},
  deleteClicked = (itemId) => {},
  sendInvoice = (itemId) => {},
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={`${tableClasses.block} ${tableClasses.buttons}`}>
        <div className={tableClasses.buttonRow}>
          {(status === "DRAFT" || status === "SCHEDULED") && (
            <div className={tableClasses.value}>
              <a
                href="#"
                className={tableClasses.link}
                onClick={(e) => {
                  e.preventDefault();
                  editItemClicked(itemId);
                }}
              >
                EDIT
              </a>

              <a
                href="#"
                className={tableClasses.link}
                onClick={(e) => {
                  e.preventDefault();
                  deleteClicked(itemId);
                }}
              >
                REMOVE
              </a>
            </div>
          )}

          {status === "ACTIVE" && (
            <>
              <div className={tableClasses.value}>
                <a
                  href="#"
                  className={tableClasses.link}
                  onClick={(e) => {
                    e.preventDefault();
                    listSimilarClicked(itemId);
                  }}
                >
                  LIST SIMILAR
                </a>
              </div>

              {(auctionType === "ONLINE AUCTION" ||
                auctionType === "MAKE AN OFFER") && (
                <div className={tableClasses.value}>
                  <a
                    href="#"
                    className={tableClasses.link}
                    onClick={(e) => {
                      e.preventDefault();
                      viewBiddersClicked(itemId);
                    }}
                  >
                    {auctionType === "ONLINE AUCTION" && "VIEW BIDS"}
                    {auctionType === "MAKE AN OFFER" && "VIEW OFFERS"}
                  </a>
                </div>
              )}

              <div className={tableClasses.value}>
                <a
                  href="#"
                  className={tableClasses.link}
                  onClick={(e) => {
                    e.preventDefault();
                    closeAuction(itemId);
                  }}
                >
                  CLOSE AUCTION
                </a>
              </div>
            </>
          )}

          {status === "TIMED_OUT" && (
            <div className={tableClasses.value}>
              <Link
                to={`/listing-award/${itemId}`}
                className={tableClasses.link}
              >
                ACKNOWLEDGE RESULTS
              </Link>
            </div>
          )}

          {status === "FINALIZING_WINNER" && (
            <div className={tableClasses.value}>
              <a
                href="#"
                className={tableClasses.link}
                onClick={(e) => {
                  e.preventDefault();
                  sendInvoice(itemId);
                }}
              >
                GENERATE INVOICE
              </a>
            </div>
          )}

          {status === "INVOICE_GENERATED" && (
            <div className={tableClasses.value}>
              <Link
                to={`/listing-payment-await/${itemId}`}
                className={tableClasses.link}
              >
                {auctionType !== "BUY NOW" && "CONTACT BIDDER"}
                {auctionType === "BUY NOW" && "CONTACT BUYER"}
              </Link>
            </div>
          )}

          {status === "WAITING_TO_RECEIVE_PAYMENT" && (
            <div className={tableClasses.value}>
              {!bidlyPaymentConfirmation && (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    paymentConfirmClicked(itemId);
                  }}
                  className={tableClasses.link}
                >
                  CONFIRM PAYMENT
                </a>
              )}
            </div>
          )}

          {status === "WAITING_FOR_ITEM_PICKUP" && (
            <div className={tableClasses.value}>
              <Link
                to={"/listing-pickup-confirm/" + itemId}
                className={tableClasses.link}
              >
                CONFIRM PICKUP
              </Link>
            </div>
          )}

          {status === "SUCCESSFUL_ENDED" && (
            <div className={tableClasses.value}>
              <a
                href="#"
                className={tableClasses.link}
                onClick={(e) => {
                  e.preventDefault();
                  listSimilarClicked(itemId);
                }}
              >
                LIST SIMILAR
              </a>
            </div>
          )}

          {status === "UNSUCCESSFUL_ENDED" && (
            <div className={tableClasses.value}>
              <a
                href="#"
                className={tableClasses.link}
                onClick={(e) => {
                  e.preventDefault();
                  relistClicked(itemId);
                }}
              >
                RELIST
              </a>
            </div>
          )}
        </div>
      </div>

      <div className={tableClasses.block}>
        <div className={tableClasses.blockText}>
          {status === "WAITING_TO_RECEIVE_PAYMENT" &&
            bidlyPaymentConfirmation && (
              <span
                className={`badge badge-warning ${tableClasses["badge-confirmation"]}`}
              >
                Waiting for BidlyAuction to Confirm Payment
              </span>
            )}

          <div className={tableClasses.item}>
            <div className={`${tableClasses.label} `}>
              <span className="text-uppercase font-weight-bold">
                {(status === "ACTIVE" ||
                  status === "DRAFT" ||
                  status === "SCHEDULED") &&
                  status}
                {status === "TIMED_OUT" && "TIME ENDED"}
                {status === "FINALIZING_WINNER" && "SEND INVOICE"}
                {status === "INVOICE_GENERATED" && (
                  <span className="text-danger">WAITING FOR PAYMENT</span>
                )}
                {status === "WAITING_TO_RECEIVE_PAYMENT" && "CONFIRM PAYMENT"}
                {status === "WAITING_FOR_ITEM_PICKUP" && "CONFIRM ITEM PICKUP"}
                {status === "SUCCESSFUL_ENDED" && "SOLD"}
                {status === "UNSUCCESSFUL_ENDED" && "UNSOLD"}
              </span>{" "}
              |{" "}
              <span className="text-capitalize">
                {auctionType.replaceAll("_", " ").toLowerCase()}
              </span>
            </div>
            <div className={tableClasses.heading}>{title}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing ID</div>
            <div className={tableClasses.value}>{lot}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Listing Date</div>
            <div className={tableClasses.value}>{listingDate}</div>
          </div>
          {startDate && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Start Date</div>
              <div className={tableClasses.value}>{startDate}</div>
            </div>
          )}

          {endDate && (
            <div className={tableClasses.item}>
              <div className={tableClasses.label}>Ending Date</div>
              <div className={tableClasses.value}>{endDate}</div>
            </div>
          )}

          {(status === "WAITING_FOR_ITEM_PICKUP" ||
            status === "SUCCESSFUL_ENDED") && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Winning Price</div>
                <div className={tableClasses.value}>
                  {formatUSD(winningAmount)}
                </div>
              </div>

              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Winner</div>
                <div className={tableClasses.value}>{winner}</div>
              </div>
            </>
          )}

          {status !== "WAITING_FOR_ITEM_PICKUP" &&
            status !== "SUCCESSFUL_ENDED" &&
            startingPrice && (
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>
                  {auctionType === "BUY NOW" && "Price"}
                  {auctionType !== "BUY NOW" && "Starting Price"}
                </div>
                <div className={tableClasses.value}>
                  {formatUSD(startingPrice)}
                </div>
              </div>
            )}

          {status !== "WAITING_FOR_ITEM_PICKUP" &&
            status !== "SUCCESSFUL_ENDED" &&
            status !== "DRAFT" &&
            status !== "SCHEDULED" &&
            (auctionType === "ONLINE AUCTION" ||
              auctionType === "SEALED AUCTION" ||
              auctionType === "MAKE AN OFFER") && (
              <>
                {currentPrice ? (
                  <div className={tableClasses.item}>
                    <div className={tableClasses.label}>
                      {(auctionType === "ONLINE AUCTION" ||
                        auctionType === "SEALED AUCTION") &&
                        "Highest Bid"}
                      {auctionType === "MAKE AN OFFER" && "Highest Offer"}
                    </div>
                    <div className={tableClasses.value}>
                      {formatUSD(currentPrice)}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}

          {status === "WAITING_FOR_ITEM_PICKUP" && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Payment Date</div>
                <div className={tableClasses.value}>{paymentDate}</div>
              </div>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>
                  Payment Acknowledgment Date
                </div>
                <div className={tableClasses.value}>
                  {paymentAcknowledgmentDate}
                </div>
              </div>
            </>
          )}

          {status === "SUCCESSFUL_ENDED" && (
            <>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Payment Date</div>
                <div className={tableClasses.value}>{paymentDate}</div>
              </div>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Pickup Date</div>
                <div className={tableClasses.value}>{pickupDate}</div>
              </div>
            </>
          )}

          {status !== "WAITING_FOR_ITEM_PICKUP" &&
            status !== "SUCCESSFUL_ENDED" &&
            (auctionType === "ONLINE AUCTION" ||
              auctionType === "SEALED AUCTION" ||
              auctionType === "MAKE AN OFFER") &&
            reservePrice && (
              <>
                <div className={tableClasses.item}>
                  <div className={tableClasses.label}>Reserve Price</div>
                  <div className={tableClasses.value}>
                    <div className="row">
                      <div className="col">{formatUSD(reservePrice)}</div>

                      {auctionType === "ONLINE AUCTION" &&
                        (status === "ACTIVE" ||
                          status === "TIMED_OUT" ||
                          status === "FINALIZING_WINNER" ||
                          status === "INVOICE_GENERATED" ||
                          status === "WAITING_TO_RECEIVE_PAYMENT") && (
                          <div className="col-auto">
                            {reserveMet && (
                              <div className="badge bg-danger px-2 py-1 font-size-14 d-block">
                                Reserve is Met
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </>
            )}

          {status !== "DRAFT" && status !== "SCHEDULED" && (
            <>
              <div className={tableClasses.item}>
                {(auctionType === "ONLINE AUCTION" ||
                  auctionType === "SEALED AUCTION") && (
                  <div className={tableClasses.label}>Bids</div>
                )}
                {auctionType === "MAKE AN OFFER" && (
                  <div className={tableClasses.label}>Offers</div>
                )}
                <div className={tableClasses.value}>{bids}</div>
              </div>

              {(status === "ACTIVE" || status === "TIMED_OUT") && (
                <div className={tableClasses.item}>
                  <div className={tableClasses.label}>Watching</div>
                  <div className={tableClasses.value}>{watching}</div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionItemList;
