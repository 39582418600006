import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import plus from "../../assets/images/plus.svg";
import LinkButton from "../../components/button/LinkButton";
import { UserWishList, RemoveFromWishList } from "../../services/UserService";
import { isJSON, isStopFlowConditionMet } from "../../services/Utilities";
import AddressCardsPanel from "./AddressCardsPanel";
import AddressCard from "./AddressCard";
import Alert from "../../components/UI/Alert";
import WishListItem from "./WishListItem";
import { useAuthContext } from "../../redux/auth-context";
import { shallowEqual, useSelector } from "react-redux";
import ConfirmModal from "../../components/UI/ConfirmModal";
import { PaginationComponent, Pagination } from "../search/PaginationComponent";

const WishList = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const { isAuthorized, viewExpired } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      viewExpired: auth.viewExpired,
    }),
    shallowEqual
  );
  const params = useLocation().state ?? {};

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [wishListItems, setWishListItems] = useState([]);
  const [seller, setSeller] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    ctx.showProgress(true);
    setCurrentPage(1);
    searchUp();
  }, []);

  const buildUrl = (currentPage) => {
    let appendUrl = "?";

    let start = currentPage - 1;

    let paginatedString = "&start=" + start * pageSize + "&end=" + pageSize;

    appendUrl += paginatedString;

    appendUrl = appendUrl.replaceAll("??", "?");

    return appendUrl;
  };

  const searchWatchlistItems = (filterParams) => {
    ctx.showProgress(true);
    UserWishList()
      .then((resp) => {
        console.log(resp);
        setTotal(resp.totalItems);
        setWishListItems(resp.searchList);
        ctx.showProgress(false);
      })
      .catch((error) => {
        console.log(error);
        ctx.showProgress(false);
      });
  };

  const watchListHadler = (auction) => {
    if (isAuthorized) {
      ctx.showProgress(true);

      RemoveFromWishList(auction.watchId).then((resp) => {
        console.log(resp);
        setTotal(resp.totalItems);
        setWishListItems(resp.searchList);
        ctx.showProgress(false);
      }).catch((error) => {
        console.log(error);
        ctx.showProgress(false);
      })

    }
  }

  const searchUp = async (currentPage = 1) => {
    let params = buildUrl(currentPage);
    searchWatchlistItems(params);
  };

  const handlePageChange = (newCurrentPage) => {
    setCurrentPage(newCurrentPage);
    searchUp(newCurrentPage);
  };

  return (
    <>
    {showDeleteConfirm && (
      <ConfirmModal
        title="Remove Item from Wishlist"
        message="Are you sure want to remove this item from wishlist?"
        cancelClicked={(e) => {
          e.preventDefault();
          setShowDeleteConfirm(false);
        }}
        confirmClicked={(e) => {
          e.preventDefault();
          setShowDeleteConfirm(false);
        }}
      />
    )}
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <AccountMenu watchlistActive={true} />
          </div>
          <div className="offset-lg-1 col-lg-7 col-md-7">

            <h2 className="h3 font-weight-bold">Watchlist</h2>

            <div className="mb-1">
              <BHotrizontalProgress progress={33} />
            </div>

            <div className="tab-pane fade pt-2 show active"
              id="pills-three-example1"
              role="tabpanel"
              aria-labelledby="pills-three-example1-tab"
              data-target-group="groups">
              {wishListItems && wishListItems.length > 0 ? (
                  <ul className="d-block list-unstyled products-group prodcut-list-view">
                    {wishListItems.map((eachItem) => {
                      return (
                        <WishListItem
                          watchListHadler={watchListHadler}
                          key={eachItem.itemId}
                          data={eachItem}
                        />
                      );
                    })}
                  </ul>
                ) : (
                <h3 className="font-size-20 mt-10 text-center">
                  No Result found
                </h3>
              )}
            </div>
            <div className="d-block d-md-flex flex-center-between mb-3">
                <Pagination
                  currentPage={currentPage}
                  total={total}
                  pageSize={pageSize}
                />
                <PaginationComponent
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={total}
                  pageSize={pageSize}
                  onPageChange={(page) => handlePageChange(page)}
                />
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
};

export default WishList;
