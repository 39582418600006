import React from "react";
import unreadIcon from "../../assets/images/green-dot.svg";
import optionsIcon from "../../assets/images/dots-more.svg";
import classes from "./NotificationBar.module.css";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { useAuthContext } from "../../redux/auth-context";

const MessageNotification = ({ data, messageClicked = (data) => {} }) => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const proceedToView = (messageId) => {
    ctx.showProgress(true);

    navigate("/view-message", {
      state: { messageId: messageId },
    });
  };

  return (
    <>
      <div
        className={`row ${classes.bar} ${classes.messageNotificationRow}`}
        onClick={(e) => {
          e.preventDefault();
          proceedToView(data.messageId);

          messageClicked(data);
        }}
      >
        <div className="col-auto">
          <div className={`d-flex ${classes.icons} `}>
            <img src={unreadIcon} className={classes.readIcon} />
          </div>
        </div>
        <div className="col pl-0">
          <p className={classes.subject}>{data.subject}</p>
          <p className={classes.sender}>
            From: {data.sender.name}
            {data.sender.username && (
              <>
                {" <"} {data.sender.username} {">"}
              </>
            )}
          </p>
          <p>
            Received{" "}
            <span className="secondary-500">
              <ReactTimeAgo
                date={new Date(data.createdOn)}
                locale="en-US"
                timeStyle="round"
              />
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
export default MessageNotification;
