import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate, Link } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import * as Yup from "yup";
import LinkButton from "../../components/button/LinkButton";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Form, Formik } from "formik";
import SearchSelect from "../../components/form/SearchSelect";
import { LibraryService } from "../../services/LibraryService";
import { useAuthContext } from "../../redux/auth-context";
import { PhoneTypes } from "../../components/common/PhoneTypes";
import {
  deflateErrors,
  isJSON,
  validateUSPhoneNumber,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import { UpdatePhoneNumbersRequest } from "../../services/UserService";
import Alert from "../../components/UI/Alert";

const AddPhone = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();
  const [addressList, setAddressList] = useState();

  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);

  useEffect(() => {
    ctx.showProgress(true);

    ctx.showProgress(false);
  }, []);

  const schema = Yup.object().shape({
    phoneType: Yup.string().required("Phone type is required"),
    phoneNumber: Yup.string()
      .max(32, "Phone number must be maximum of 32 letters or numbers")
      .required("Phone number is required"),
    alternatePhoneType: Yup.string(),
    alternatePhoneNumber: Yup.string().max(
      32,
      "Phone number must be maximum of 32 letters or numbers"
    ),
  });

  const initialValue = {
    phoneType: "",
    phoneNumber: "",
    alternatePhoneType: "",
    alternatePhoneNumber: "",
  };

  const updatePhoneNumber = (values) => {
    let payload = {
      phoneType: values.phoneType,
      phoneNumber: values.phoneNumber,
      alternatePhoneType: values.alternatePhoneType,
      alternatePhoneNumber: values.alternatePhoneNumber,
    };

    ctx.showProgress(true);

    UpdatePhoneNumbersRequest(payload)
      .then((data) => {
        console.log(data);
        ctx.showProgress(false);
        setUpdatedSuccessfully(true);
        navigate("/add-phone-info", {
          state: { phoneAddStatus: true },
        });
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        console.log(json);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          } else if (json.data.status === "NOT_FOUND") {
            setServerErrors(json.data.message);
          }
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <AccountMenu accountInfoActive={true} subMenu={"phoneNumber"} />
          </div>
          <div className="offset-lg-1 col-lg-7 col-md-7">
            <div className="row">
              <div className="col-md-12">
                <h2 className="h3 font-weight-bold purple-500">Phone Number</h2>
              </div>
              <div className="col-md-12 mb-4">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="col-md-12">
                <p className="pg-2 instructions-desc">
                  If you have multiple phone numbers, please be sure to
                  designate an active phone number. You will receive all calls
                  to this number.
                </p>
              </div>

              <div className="col-md-6">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    console.log(values);
                    updatePhoneNumber(values);
                  }}
                >
                  {({
                    handleSubmit,
                    isSubmitting,
                    errors,
                    validateForm,
                    setFieldValue,
                  }) => (
                    <form id="passportInfoForm">
                      {updatedSuccessfully && (
                        <Alert
                          type="success"
                          children="Phone numbers updated successfully."
                        />
                      )}
                      {serverErrors && (
                        <Alert
                          type="error"
                          children="Please update your account information first."
                        />
                      )}
                      <div className="js-form-message mb-3">
                        <label className="form-label">
                          What is phone type?
                          <span className="color-red"> *</span>
                        </label>
                        <SearchSelect
                          placeholder="Select phone type"
                          name="phoneType"
                          options={PhoneTypes}
                          onChange={(e) => {
                            setFieldValue("phoneType", e.value);
                          }}
                        />
                      </div>

                      <div className="js-form-message mb-3">
                        <Field
                          className="form-control"
                          type="text"
                          name="phoneNumber"
                          required="true"
                          component={ZInputComponent}
                          placeholder="Enter phone number"
                          label="Primary Phone Number"
                          validate={validateUSPhoneNumber}
                        />
                      </div>

                      <div className="js-form-message mb-3">
                        <label className="form-label">
                          What is alternate phone type?
                        </label>
                        <SearchSelect
                          placeholder="Select alternate phone type"
                          name="alternatePhoneType"
                          options={PhoneTypes}
                          onChange={(e) => {
                            setFieldValue("alternatePhoneType", e.value);
                          }}
                        />
                      </div>
                      <div className="js-form-message mb-3">
                        <Field
                          className="form-control"
                          type="text"
                          name="alternatePhoneNumber"
                          component={ZInputComponent}
                          placeholder="Enter alternate phone number"
                          label="Alternate Phone Number"
                          validate={validateUSPhoneNumber}
                        />
                      </div>

                      <div className="row">
                        <div className="d-inline p-3 col-md-6">
                          <button
                            className="btn-block btn btn-outline-primary px-5 text-transform-upper"
                            type="button"
                            onClick={async (e) => {
                              e.preventDefault();
                              navigate("/add-phone-info");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="d-inline p-3 col-md-6">
                          <Link
                            className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                            type="submit"
                            onClick={async (e) => {
                              e.preventDefault();
                              let res = await validateForm();
                              console.log(res);
                              if (res && Object.values(res)?.length > 0) {
                              }
                              handleSubmit(e);
                            }}
                          >
                            save
                          </Link>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default AddPhone;
