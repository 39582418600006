import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import classes from "./Listing.module.css";
import tableClasses from "../../components/table/LotsTable.module.css";
import LotsTable from "../../components/table/LotsTable";
import {
  formatUSD,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import { fetchMyItem } from "../../services/AuctionItemService";
import {
  SELLER_AUCTION_ITEM_BIDS_LIST_URL,
  SELLER_AUCTION_ITEM_DETAIL_URL,
  SELLER_AUCTION_ITEM_OFFER_LIST_URL,
} from "../../Constants";

const ViewBidders = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [itemDetail, setItemDetail] = useState({});
  const [bidderList, setBidderList] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();

  const fetchData = async (itemId) => {
    try {
      const rs = await fetchMyItem(
        SELLER_AUCTION_ITEM_DETAIL_URL.replace("{itemId}", itemId)
      );
      console.log("item", rs);
      setItemDetail(rs);

      return rs;
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const fetchItemBidders = async (itemId, URL) => {
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "?" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const rs = await fetchMyItem(
        URL.replace("{itemId}", itemId) + pagingParams
      );

      setBidderList(rs?.content);
      setTotal(rs?.totalElements);
      setTotalPages(rs?.totalPages);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const prepareData = async (itemId) => {
    ctx.showProgress(true);
    const detail = await fetchData(itemId);

    let URL = SELLER_AUCTION_ITEM_BIDS_LIST_URL;
    if (detail?.saleType === "MAKE_AN_OFFER") {
      URL = SELLER_AUCTION_ITEM_OFFER_LIST_URL;
    }

    await fetchItemBidders(detail.itemId, URL);

    ctx.showProgress(false);
  };

  useEffect(() => {
    if (params.item) {
      prepareData(params.item);
    }
  }, [params.item]);

  useEffect(() => {
    if (pageSize && currentPage + 1 && itemDetail?.itemId) {
      let URL = SELLER_AUCTION_ITEM_BIDS_LIST_URL;
      if (itemDetail.saleType === "MAKE_AN_OFFER") {
        URL = SELLER_AUCTION_ITEM_OFFER_LIST_URL;
      }

      ctx.showProgress(true);

      fetchItemBidders(itemDetail.itemId, URL).then(() => {
        ctx.showProgress(false);
      });
    }
  }, [pageSize, currentPage]);

  const tableHeader = (
    <tr>
      <th>Bidder</th>
      <th>Submitted On</th>
      <th>Amount</th>
    </tr>
  );

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myItemsActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Item Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col-auto">
                  <div className="d-lg-none d-block mb-3">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.saleType
                            ?.toLowerCase()
                            ?.replaceAll("_", " ")}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div
                      className={classes.imgContainer}
                      style={{ background: `url(${itemDetail.defaultImage})` }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-lg-block d-none">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.saleType
                            ?.toLowerCase()
                            ?.replaceAll("_", " ")}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <h4>{itemDetail.title}</h4>

                  <div
                    className="instructions-desc"
                    dangerouslySetInnerHTML={{ __html: itemDetail.description }}
                  />

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Start Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.startDate)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>End Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.endDate)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Starting Price</div>
                      <div className={classes.value}>
                        {formatUSD(itemDetail.startingPrice)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <LotsTable
                    tableHeader={tableHeader}
                    noRecordTableBody={
                      <>
                        <tr>
                          <td colSpan={3} className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      </>
                    }
                    tableBody={
                      bidderList && Object.keys(bidderList).length > 0
                        ? bidderList.map((bid, i) => {
                            return (
                              <ViewBidders.BiddersBodyRow
                                key={i}
                                bidId={bid.bidderId}
                                name={bid.name}
                                email={bid.email}
                                phone={bid.phoneNumber}
                                amount={bid.amount}
                                currency={itemDetail.currency}
                                submittedOn={formattedUTCtoUSCentral(
                                  bid.submittedOn
                                )}
                              />
                            );
                          })
                        : undefined
                    }
                    noRecordMobileBody={
                      <>
                        <div className={tableClasses.blockPanel}>
                          <div
                            className={`justify-content-center ${tableClasses.block}`}
                          >
                            <div className={tableClasses.blockText}>
                              <div className={tableClasses.item}>
                                <div className={tableClasses.productLabel}>
                                  No Records Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    mobileBody={
                      bidderList && Object.keys(bidderList).length > 0
                        ? bidderList.map((bid, i) => {
                            return (
                              <ViewBidders.BiddersMobileBodyRow
                                key={i}
                                bidId={bid.bidderId}
                                name={bid.name}
                                email={bid.email}
                                phone={bid.phoneNumber}
                                amount={bid.amount}
                                currency={itemDetail.currency}
                                submittedOn={formattedUTCtoUSCentral(
                                  bid.submittedOn
                                )}
                              />
                            );
                          })
                        : undefined
                    }
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage + 1}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

ViewBidders.BiddersBodyRow = ({
  bidId,
  name,
  email,
  phone,
  amount,
  currency,
  submittedOn,
}) => (
  <>
    <tr>
      <td>
        <span className={tableClasses.productLabel}>{name}</span>
        <span className={tableClasses.lot}>{email}</span>
        {phone && <span className={tableClasses.lot}>{phone}</span>}
      </td>
      <td>{submittedOn}</td>
      <td>{formatUSD(amount)}</td>
    </tr>
  </>
);

ViewBidders.BiddersMobileBodyRow = ({
  bidId,
  name,
  email,
  phone,
  amount,
  currency,
  submittedOn,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}></div>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.productLabel}>{name}</div>
            <div className={tableClasses.lot}>{email}</div>
            {phone && <div className={tableClasses.lot}>{phone}</div>}
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Submitted On</div>
            <div className={tableClasses.value}>{submittedOn}</div>
          </div>
        </div>
      </div>
      <div className={`${tableClasses.block} ${tableClasses.bordered}`}>
        <div className={tableClasses.selection}></div>

        <div className={tableClasses.totalRow}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Amount</div>
            <div className={tableClasses.value}>{formatUSD(amount)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBidders;
