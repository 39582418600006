import React from "react";
import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "./Form.module.css";
const ZMultiCheckBox = ({
  items,
  name,
  checkedValue,
  handleOnChange,
  title,
  scroll = true,
  className = "",
  titleCase = true,
}) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${name}-content`}
          id={`${name}-header`}
        >
          <Typography>
            <span className={`text-blue-25 font-size-18 ${className}`}>
              {title}
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={scroll ? `${classes.zScrollbar}` : ``}>
            <div className={scroll ? `${classes.forceOverflow}` : ""}>
              {items.map(({ value, label, checked }, index) => {
                return (
                  <div
                    key={index}
                    className="custom-control custom-checkbox mt-1 text-blue-25"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`${name}-${index}`}
                      name={name}
                      value={value}
                      checked={checkedValue?.includes(value)}
                      onChange={(e) => {
                        handleOnChange(index, name, e.target);
                      }}
                    />
                    <label
                      htmlFor={`${name}-${index}`}
                      className="custom-control-label font-size-16"
                    >
                      {titleCase
                        ? label.replace(/_/g, " ").toProperCase()
                        : label}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ZMultiCheckBox;
