import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import ZRadio from "../../components/form/ZRadio";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import ZMultiCheckBox from "../../components/form/ZMultiCheckBox";
import classes from "./Search.module.css";
import formClasses from "../../components/form/Form.module.css";
import SearchMinMax from "./SearchMinMax";

const SearchFilter = ({
  categories,
  country,
  states,
  cities,
  conditions,
  checkedConditions,
  salesType,
  checkedSalesType,
  accountType,
  checkedAccountType,
  currency,
  startingPrice,
  seller,
  applyFilter,
  selectedCategory,
  getFilteredCategories,
  selectSubCategory,
  onFilterChange,
  onCategoryChange,
  bidlyFilter,
  make,
  model,
  ...props
}) => {
  const [maxPrice, setMaxPrice] = useState();
  const [minPrice, setMinPrice] = useState();

  const initialValue = {};

  const handleMinChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const handleMinMax = () => {
    onFilterChange(false, "sprice", { minPrice: minPrice, maxPrice: maxPrice });
  };

  return (
    <div className="mb-8">
      <Formik
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValue}
        onSubmit={(values) => {
          values.startingPrice = minPrice;
          values.endPrice = maxPrice;
          applyFilter(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, resetForm }) => (
          <>
            <Form>
              <div className="col-md-12 px-0 mb-6">
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="all-category-content"
                      id="all-category-header"
                    >
                      <Typography>
                        <span className="text-blue-25">
                          Show All Categories
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={formClasses.zScrollbar}>
                        <ul
                          id="sidebarNav1"
                          className="list-unstyled dropdown-list mb-0"
                        >
                          {getFilteredCategories()?.map((category) => {
                            return (
                              <li key={category.value}>
                                <Link
                                  onClick={() =>
                                    onCategoryChange(
                                      `category=${category.value}`
                                    )
                                  }
                                  className="dropdown-item px-0"
                                >
                                  <span className="text-blue-25 font-size-16 font-weight-normal">
                                    {category.label} ({category.count})
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  {selectedCategory && selectedCategory.value > 0 && (
                    <Accordion expanded={true}>
                      <AccordionSummary
                        aria-controls="selected-category-content"
                        id="selected-category-header"
                      >
                        <Typography>
                          <span>
                            <Link className="dropdown-current active text-blue-25 font-weight-bold">
                              {selectedCategory?.label}
                            </Link>
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul
                          id="sidebarNav1"
                          className="list-unstyled dropdown-list"
                        >
                          {selectedCategory?.subCategories?.map((subCat) => {
                            return (
                              <li key={subCat.id}>
                                <Link
                                  onClick={() =>
                                    onCategoryChange(
                                      `sub_category=${subCat.id}`
                                    )
                                  }
                                  className="dropdown-item px-2"
                                >
                                  <span
                                    className={`text-blue-25 font-size-16 ${
                                      subCat.id == selectSubCategory
                                        ? "font-weight-bold"
                                        : "font-weight-normal"
                                    }`}
                                  >
                                    {subCat.key}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <h3
                  className={`${classes.filterHeading} mb-0 pb-2 font-size-18`}
                >
                  Filter
                </h3>
              </div>
              {country && country.length > 0 && (
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZMultiCheckBox
                    scroll={false}
                    title={"Region"}
                    checkedValue={["usa"]}
                    handleOnChange={onFilterChange}
                    className={classes.filterTitle}
                    items={country}
                    name={"region"}
                  />
                </div>
              )}
              {states && states.length > 0 && (
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZMultiCheckBox
                    className={classes.filterTitle}
                    title={"State"}
                    checkedValue={bidlyFilter?.state}
                    handleOnChange={onFilterChange}
                    items={states}
                    name={"state"}
                  />
                </div>
              )}
              {cities && cities.length > 0 && (
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZMultiCheckBox
                    className={classes.filterTitle}
                    title={"City"}
                    checkedValue={bidlyFilter?.city}
                    handleOnChange={onFilterChange}
                    items={cities}
                    name={"city"}
                  />
                </div>
              )}
              {currency && currency.length > 0 && (
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZMultiCheckBox
                    titleCase={false}
                    className={classes.filterTitle}
                    scroll={false}
                    title={"Currency"}
                    items={currency}
                    name={"currency"}
                    checkedValue={bidlyFilter?.currency}
                    handleOnChange={onFilterChange}
                  />
                </div>
              )}
              <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                <SearchMinMax
                  scroll={false}
                  items={startingPrice}
                  className={classes.filterTitle}
                  name={"price"}
                  title={"Price"}
                  minHandler={handleMinChange}
                  maxHandler={handleMaxChange}
                  minMaxChange={handleMinMax}
                  handleOnChange={onFilterChange}
                />
              </div>
              {salesType && salesType.length > 0 && (
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZRadio
                    className={classes.filterTitle}
                    scroll={false}
                    title={"Sales type"}
                    items={salesType}
                    name={"salesType"}
                    handleOnChange={onFilterChange}
                  />
                </div>
              )}
              {conditions && conditions.length > 0 && (
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZMultiCheckBox
                    className={classes.filterTitle}
                    scroll={false}
                    title={"Condition"}
                    items={conditions}
                    name={"conditions"}
                    checkedValue={bidlyFilter?.condition}
                    handleOnChange={onFilterChange}
                  />
                </div>
              )}

              {/* {accountType && accountType.length > 0 && ( */}
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZRadio
                    className={classes.filterTitle}
                    scroll={false}
                    title={"Account Type"}
                    items={accountType}
                    name={"accountType"}
                    handleOnChange={onFilterChange}
                  />
                </div>
              {/* )} */}
              {seller && seller.length > 0 && (
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZMultiCheckBox
                    className={classes.filterTitle}
                    title={"Seller"}
                    items={seller}
                    name={"seller"}
                    checkedValue={bidlyFilter?.seller}
                    handleOnChange={onFilterChange}
                  />
                </div>
              )}
              {selectedCategory?.value == 5 &&
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZMultiCheckBox
                    className={classes.filterTitle}
                    title={"Make"}
                    items={make}
                    name={"make"}
                    checkedValue={bidlyFilter?.make}
                    handleOnChange={onFilterChange}
                  />
                </div>  
              }
               {selectedCategory?.value == 5 &&
                <div className="col-md-12 mt-2 zfilter border-bottom pb-2">
                  <ZMultiCheckBox
                    className={classes.filterTitle}
                    title={"Model"}
                    items={model}
                    name={"model"}
                    checkedValue={bidlyFilter?.model}
                    handleOnChange={onFilterChange}
                  />
                </div>  
              }
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
export default SearchFilter;
