import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../../components/UI/HorizontalProgress";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../../components/UI/ZInputComponent";
import * as Yup from "yup";
import Label from "../../../components/UI/Label";
import PlaceholderHeading from "../../credit-card/PlaceholderHeading";
import { scrollTo } from "../../../services/Utilities";
import { wizardGenericErrorMsg } from "../../../Constants";
import { Select } from "../../../components/Select";
import ErrorMessage from "../../../components/UI/ErrorMessage";
import ReactQuill from "react-quill";
import DualErrorMessage from "../../../components/UI/DualErrorMessage";
import { useRef } from "react";
import WizardButtons from "./WizardButtons";
import ZCheckBoxComponent from "../../../components/form/ZCheckBoxComponent";

const ListingIntroduction = (props) => {
  const { step, data, serverErrors, tabDisable } = props;
  const { filterSubCategories, saveTab, closeWizard } = props;

  const { categories, subCategories } = props;

  const draftSubmitRef = useRef();
  const [isDraft, setIsDraft] = useState(false);

  const tabInitValues = {
    saleType: "",
    category: "",
    subCategory: "",
    subCategoryList: [],
    title: "",
    description: "",
    descriptionStyled: "",
    autoExtension: "no",
  };

  const schema = Yup.object().shape({
    saleType: Yup.string().required("Sale type is required"),
    category: Yup.string().required("Category is required"),
    subCategory: Yup.string().when(
      "subCategoryList",
      (subCategoryList, schema) => {
        if (subCategoryList && Object.keys(subCategoryList)?.length > 0) {
          return schema.required("Sub category is required");
        }

        return schema;
      }
    ),
    title: Yup.string()
      .required("Listing title is required")
      .max(512, "Maximum 512 characters allowed"),
    description: Yup.string().required("Listing description is required"),
    descriptionStyled: Yup.string().required("Listing description is required"),
    autoExtension: Yup.string().optional(),
  });

  const [tabData, setTabData] = useState(tabInitValues);
  const [tabErrors, setTabErrors] = useState(serverErrors);

  const [itemDescription, setItemDescription] = useState();

  const populateTabData = (inputData) => {
    if (inputData && Object.values(inputData)?.length > 0) {
      setTabData((state) => {
        const tempState = { ...state, ...inputData };
        setItemDescription(tempState?.descriptionStyled);
        return tempState;
      });
    }
  };

  useEffect(() => {
    populateTabData(data);
  }, []);

  useEffect(() => {
    populateTabData(data);
  }, [data]);

  useEffect(() => {
    setTabErrors(serverErrors);
  }, [serverErrors]);

  return (
    <>
      <PlaceholderHeading title="Select a category for your item">
        <button
          className="btn-block btn btn-outline-primary px-4"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            closeWizard();
          }}
        >
          Close
        </button>
      </PlaceholderHeading>
      <div className="mt-3 mb-2">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mt-5 instructions-desc">
            Items can only be in one category. Choose the best category where
            bidders would most likely look for your item. Make sure to scroll
            all the way through the list. If you don't see a category for your
            item, please <Link to="/contact-us">Contact us</Link>.
          </p>

          <div className="row" id="pageError">
            <div className="col-md-12">
              {tabErrors && tabErrors.message && (
                <div className="mb-4">
                  <ErrorMessage position="top">
                    {tabErrors.message}
                  </ErrorMessage>
                </div>
              )}
            </div>
          </div>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={tabData}
            validationSchema={schema}
            onSubmit={(values) => {
              console.log("submitted values", values, isDraft);

              console.log(
                "category after filter",
                categories?.filter((c) => c.key == values.category)
              );

              const isVehicle =
                categories?.filter((c) => c.key == values.category)[0]?.type ===
                "VEHICLE"
                  ? "yes"
                  : "no";

              saveTab(
                step,
                {
                  ...tabData,
                  ...values,
                  isVehicle: isVehicle,
                  autoExtend: values.autoExtension === "yes",
                },
                isDraft
              );
            }}
          >
            {({
              handleSubmit,
              errors,
              values,
              setFieldValue,
              validateForm,
            }) => (
              <form>
                <div className="row">
                  <div className="col-lg-7 col-12">
                    <div className="js-form-message mb-4">
                      <Select
                        name="saleType"
                        className="form-control"
                        label="Item Sale Type"
                        highlightedLabel
                        mandatory={true}
                        defaultTouch
                        serverError={serverErrors && serverErrors.saleType}
                        onChange={(e) => {
                          setFieldValue("saleType", e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Item Sale Type
                        </option>

                        <option value={"ONLINE_AUCTION"}>Online Auction</option>
                        <option value={"SEALED_AUCTION"}>Sealed Auction</option>
                        <option value={"MAKE_AN_OFFER"}>Make an Offer</option>
                        <option value={"BUY_NOW"}>Buy Now</option>
                      </Select>
                    </div>

                    <div className="js-form-message mb-4">
                      <Select
                        name="category"
                        className="form-control"
                        label="Category"
                        highlightedLabel
                        mandatory={true}
                        defaultTouch
                        serverError={serverErrors && serverErrors.category}
                        onChange={async (e) => {
                          console.log(e.target.value);
                          setFieldValue("category", e.target.value);
                          setFieldValue("subCategory", "");
                          const subCatList = await filterSubCategories(
                            e.target.value
                          );
                          setFieldValue("subCategoryList", subCatList);
                          console.log("subcategories fetched", subCatList);
                        }}
                      >
                        <option value="" disabled>
                          Select Category
                        </option>

                        {categories &&
                          categories.length > 0 &&
                          categories.map((cat) => {
                            return (
                              <option key={cat.key} value={cat.key}>
                                {cat.name}
                              </option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7 col-12">
                    {subCategories &&
                      Object.keys(subCategories)?.length > 0 && (
                        <div className="js-form-message mb-4">
                          <Select
                            name="subCategory"
                            className="form-control"
                            label="Sub Category"
                            highlightedLabel
                            mandatory={true}
                            defaultTouch
                            serverError={
                              serverErrors && serverErrors.subCategory
                            }
                            onChange={(e) => {
                              setFieldValue("subCategory", e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Sub Category
                            </option>

                            {subCategories.map((subCat) => {
                              return (
                                <option key={subCat.key} value={subCat.key}>
                                  {subCat.name}
                                </option>
                              );
                            })}
                          </Select>
                        </div>
                      )}
                  </div>
                </div>

                {values?.saleType !== "BUY_NOW" && (
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Auto Extend Bidding
                        </Label>
                        <Field
                          className="form-control"
                          name="autoExtension"
                          fieldName="autoExtension"
                          items={[
                            {
                              value: "yes",
                              label: "Auto Extend",
                            },
                          ]}
                          component={ZCheckBoxComponent}
                          handleOnChange={(index, fieldName, checkedItems) => {
                            setFieldValue(
                              "autoExtension",
                              checkedItems?.includes("yes") ? "yes" : "no"
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-7 col-12">
                    <div className="js-form-message mb-4">
                      <Field
                        className="form-control"
                        name="title"
                        required
                        highlightedLabel
                        component={ZInputComponent}
                        placeholder="Enter Listing Title"
                        label="Listing Title"
                        serverError={serverErrors && serverErrors.title}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="js-form-message mb-4">
                      <Label hightlighted required>
                        Listing Description
                      </Label>
                      <p className="instructions-desc">
                        This should include condition of your item(s), specifics
                        of your item(s), (hours, dimensions, included pieces,
                        ex...), and faults with the item including damage and
                        missing parts.
                      </p>

                      <ReactQuill
                        theme="snow"
                        value={itemDescription}
                        onChange={(m) => {
                          setItemDescription(m);

                          const ele = document.createElement("div");
                          ele.innerHTML = m;

                          setFieldValue("description", ele.textContent);
                          setFieldValue("descriptionStyled", m);
                        }}
                        placeholder="Enter Listing Description here"
                        rows
                      />

                      <DualErrorMessage
                        fieldName="description"
                        serverError={serverErrors && serverErrors.description}
                      />
                    </div>
                  </div>
                </div>

                <div className="js-form-message mb-4 row">
                  <div className="col-12">
                    <WizardButtons>
                      <button
                        className="btn btn-primary-dark-w px-5"
                        type="submit"
                        onClick={async (e) => {
                          e.preventDefault();
                          setIsDraft(false);
                          setTabErrors({});
                          let res = await validateForm();
                          console.log("errors", res);
                          if (res && Object.values(res)?.length > 0) {
                            scrollTo("pageError");
                            setTabErrors({ message: wizardGenericErrorMsg });
                          }
                          handleSubmit(e);
                        }}
                      >
                        Save and Continue
                      </button>

                      <button
                        ref={draftSubmitRef}
                        className="btn btn-outline-primary px-5"
                        type="button"
                        onClick={async (e) => {
                          e.preventDefault();
                          setIsDraft(true);
                          setTabErrors({});
                          let res = await validateForm();
                          if (res && Object.values(res)?.length > 0) {
                            scrollTo("pageError");
                            setTabErrors({ message: wizardGenericErrorMsg });
                          }
                          handleSubmit(e);
                        }}
                      >
                        Save Draft
                      </button>
                    </WizardButtons>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ListingIntroduction;
