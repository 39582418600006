import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  deflateErrors,
  isBadRequest,
  isJSON,
  validatePassword,
  validateUsername,
  Regex,
} from "../../services/Utilities";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./ScheduleDemo.module.css";
import smallMap from "../../assets/images/small-map.svg";
import smallClock from "../../assets/images/small-clock-grey.svg";
import smallCamera from "../../assets/images/small-camera.svg";
import calendarIcon from "../../assets/images/calender-grey.svg";
import moment from "moment";
import LinkButton from "../../components/button/LinkButton";
import { ScheduleADemoRequest } from "../../services/ScheduleDemoService";
import Alert from "../../components/UI/Alert";
import { isSeller, validateUSPhoneNumber } from "../../services/Utilities";
import { shallowEqual, useSelector } from "react-redux";
import { PhoneTypes } from "../../components/common/PhoneTypes";
import { Select } from "../../components/Select";

const DemoDetails = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  console.log("location.state :: " + location.state);

  const [guests, setGuests] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const [timeSlotTakenError, setTimeSlotTakenErrors] = useState();
  const [guestFound, setGuestFound] = useState();
  const [guestAdded, setGuestAdded] = useState();
  const [guestRequired, setGuestRequired] = useState();
  const [remarks, setRemarks] = useState();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Name must be minimum of 5 characters")
      .max(128, "Name must be maximum of 128 characters")
      .required("Name is required"),

    email: Yup.string()
      .max(128, "Email must be maximum of 128 characters")
      .email("Not a valid email")
      .required("Email is required"),

    phoneType: Yup.string().required("Phone type is required"),

    phoneNumber: Yup.string()
      .max(32, "Phone number must be maximum of 32 characters or numbers")
      .required("Phone number is required"),
  });

  const initialValue = {
    name: "",
    email: "",
    remarks: "",
    phoneType: "",
    phoneNumber: "",
    timeForMeeting: location?.state?.selectedTimeSlot,
    dayForMeeting: location?.state?.dayForMeeting,
    durationOfMeeting: location?.state?.timeDuration,
  };

  const handleGuest = (values) => {
    ctx.showProgress(true);
    setGuestAdded(false);
    setGuestFound(false);
    setGuestRequired(false);

    let payload = {
      name: values.name,
      email: values.email,
      phoneType: values.phoneType,
      phoneNumber: values.phoneNumber,
    };

    // Find the first object that matches a condition
    const found = guests.find((obj) => {
      return obj.email === values.email;
    });

    if (found) {
      setGuestFound(true);
    } else {
      guests.push(payload);
      setGuestAdded(true);
    }

    console.log(guests);
    ctx.showProgress(false);
  };

  const handleDemoDay = (values) => {
    setGuestAdded(false);
    setGuestFound(false);
    setGuestRequired(false);
    if (guests.length === 0) {
      setGuestRequired(true);
      return;
    }

    ctx.showProgress(true);
    let payload = {
      timeForMeeting: location.state.selectedTimeSlot,
      dayForMeeting: moment(location.state.dayForMeeting).format("MM/DD/YYYY"),
      durationOfMeeting: location.state.timeDuration,
      guests: guests,
      remarks: remarks,
    };

    console.log(payload);

    ScheduleADemoRequest(payload)
      .then((resp) => {
        console.log(resp);
        ctx.showProgress(false);
        navigate("/demo-success");
      })
      .catch((error) => {
        console.log(error);
        ctx.showProgress(false);
        const json = JSON.parse(error.message);
        console.log(json);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }

          if (json.data.status === "NOT_FOUND") {
            setTimeSlotTakenErrors(json.data.message);
          }
        }
      });

    ctx.showProgress(false);
  };

  return (
    <>
      {/* {!isSeller(user.type) && (
      <Navigate to="/not-authorized" />
    )} */}

      <Layout>
        <div className="row mb-18 mr-1 ml-1">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 mt-7">
                <div className="row">
                  <div className="d-inline col-7">
                    <h2 className="pg-5 font-weight-bold purple-500">
                      Enter Details for a demo
                    </h2>
                  </div>
                  <div className="d-inline col-5 text-right">
                    <h2
                      className=""
                      style={{ color: "#AFB0B5", fontSize: "24px" }}
                    >
                      Step: <b className="color-2">4/4</b>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="offset-lg-3 col-md-6 mt-3">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validationSchema={schema}
                  onSubmit={(values, { resetForm }) => {
                    handleGuest(values);
                    resetForm({ values: "" });
                  }}
                >
                  {({ handleSubmit, errors, setFieldValue, resetForm }) => (
                    <form id="advanceSearchForm">
                      <div className="mb-2 mt-4">
                        <p className={`${classes.sellerTimeLink} text-center`}>
                          {location?.state?.timeDuration} Minute Meeting
                        </p>
                        <p className="mb-3">
                          <img src={smallClock} alt="..." className="mr-3" />{" "}
                          {location?.state?.timeDuration} min
                        </p>
                        <p className="mb-3">
                          <img src={smallCamera} alt="..." className="mr-3" />{" "}
                          Web conferencing details provided upon confirmation
                        </p>
                        <p className="mb-3">
                          <img src={calendarIcon} alt="..." className="mr-3" />
                          {location?.state?.selectedTimeSlot +
                            " - " +
                            moment(location?.state?.selectedTimeSlot, "hh:mm a")
                              .add(location?.state?.timeDuration, "minutes")
                              .format("hh:mm a")}
                          ,{" "}
                          {moment(location?.state?.dayForMeeting).format("dddd")},{" "}
                          {moment(location?.state?.dayForMeeting).format(
                            "MMMM D, YYYY"
                          )}
                        </p>
                        <p className="mb-3">
                          <img src={smallMap} alt="..." className="mr-3" />{" "}
                          USA, Central Time
                        </p>

                        <hr className={`${classes.sellerLine}`}></hr>
                        <p className={`${classes.heading2} mb-2`}>
                          Enter Details
                        </p>
                      </div>
                      <div className="mb-2">
                        <div
                          className={`d-col justify-content-md-center justify-content-start align-items-center custom-scroll-self`}
                        >
                          {timeSlotTakenError && (
                            <Alert type="error" children={timeSlotTakenError} />
                          )}
                          {guestFound && (
                            <Alert
                              type="error"
                              children="Guest with provided email is already added."
                            />
                          )}
                          {guestAdded && (
                            <Alert
                              type="success"
                              children="Guest added successfully."
                            />
                          )}
                          {guestRequired && (
                            <Alert
                              type="error"
                              children="Please add guest details."
                            />
                          )}
                          <div className="row">
                            <div className="col-md-12">
                              <div className="js-form-message mb-4">
                                <Field
                                  className="form-control"
                                  type="text"
                                  name="name"
                                  required="true"
                                  component={ZInputComponent}
                                  placeholder="Enter name"
                                  label="Name"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="js-form-message mb-4">
                                <Field
                                  className="form-control"
                                  type="text"
                                  name="email"
                                  required="true"
                                  component={ZInputComponent}
                                  placeholder="Enter email"
                                  label="Email"
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="js-form-message mb-4">
                                <Select
                                  name="phoneType"
                                  className="form-control"
                                  label="Phone type"
                                  mandatory={true}
                                  defaultTouch
                                  serverError={serverErrors && serverErrors.city}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setFieldValue("phoneType", e.target.value);
                                  }}
                                >
                                  <option value="" disabled>Select phone type</option>
                                  {PhoneTypes.map((ptype) => {
                                    return (
                                      <option key={ptype.value} value={ptype.value}>
                                        {ptype.label}
                                      </option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="js-form-message mb-4">
                                <Field
                                  className="form-control"
                                  type="text"
                                  name="phoneNumber"
                                  required="true"
                                  component={ZInputComponent}
                                  placeholder="Enter phone number"
                                  label="Phone Number"
                                  validate={validateUSPhoneNumber}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <Link
                                className="btn-block btn btn-secondary-dark-w px-5 mb-4"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  handleSubmit(e);
                                }}
                              >
                                Add Guests
                              </Link>
                            </div>

                            <div className="col-md-12">
                              <div className="js-form-message mb-2">
                                <label className={`form-label`}>
                                  Please share anything that will help prepare
                                  for our meeting
                                </label>
                                <textarea
                                  className="form-control"
                                  type="textarea"
                                  placeholder="Enter message"
                                  name="remarks"
                                  onChange={(e) => {
                                    setRemarks(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-0 mb-2">
                        <div className="row">
                          <div className="d-inline p-3 col-md-6">
                            <Link
                              className="btn-block btn btn-outline-primary px-5 text-transform-upper"
                              type="button"
                              onClick={async (e) => {
                                e.preventDefault();
                                navigate("/demo-time", {
                                  state: {
                                    timeDuration: location.state.timeDuration,
                                  },
                                });
                              }}
                            >
                              back
                            </Link>
                          </div>
                          <div className="d-inline p-3 col-md-6">
                            <Link
                              className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                              type="submit"
                              onClick={async (e) => {
                                e.preventDefault();
                                handleDemoDay(e.target.values);
                              }}
                            >
                              Schedule
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </Layout>
    </>
  );
};

export default DemoDetails;
