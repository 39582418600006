import { useRef } from "react";

const AccountMenuHeading = ({ title, className }) => {
  return (
    <li className={`accordion_item ${className}`}>
      <h5 className="button item_heading">{title}</h5>
    </li>
  );
};

export default AccountMenuHeading;
