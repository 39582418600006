import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import StepHeading from "../credit-card/StepHeading";
import classes from "./MyBids.module.css";
import CheckoutTable from "../../components/table/CheckoutTable";
import importantIcon from "../../assets/images/important-grey.svg";
import { useState } from "react";
import { useEffect } from "react";
import CheckedoutItems from "./CheckedoutItems";
import { useAuthContext } from "../../redux/auth-context";
import {
  fetchAccountInfo,
  fetchCashierChequeAccountInfo,
} from "../../services/MyBidsService";
import { isStopFlowConditionMet } from "../../services/Utilities";

const CashierCheque = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [selected, setSelected] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const params = useLocation()?.state;
  const [auctionItem, setAuctionItem] = useState();
  const [invoiceAccount, setInvoiceAccount] = useState();
  const [navigationFrom, setNavigationFrom] = useState();

  useEffect(() => {
    console.log(params?.selected);
    setSelected((prev) => {
      setTotalAmount(
        params?.selected.reduce((total, cur) => total + cur.total, 0)
      );

      setAuctionItem([...params?.selected].shift()?.auction);

      return params?.selected;
    });

    setNavigationFrom(params?.from);
  }, [params]);

  useEffect(() => {
    if (auctionItem) {
      ctx.showProgress(true);
      fetchCashierChequeAccountInfo(auctionItem.itemId)
        .then((data) => {
          ctx.showProgress(false);
          setInvoiceAccount(data);
        })
        .catch((e) => {
          ctx.showProgress(false);
          if (isStopFlowConditionMet(e.message)) return;
          navigate("/error");
        });
    }
  }, [auctionItem]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
              <CheckedoutItems selected={selected} totalAmount={totalAmount} />

              <h2 className="h4 font-weight-bold">
                Cashier Cheque Payment Information
              </h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="row mb-5 align-items-center">
                <div className="col-auto">
                  <img src={importantIcon} />
                </div>
                <div className="col">
                  <p className="font-size-18 font-weight-bold mb-0">
                    Important
                  </p>
                </div>
              </div>

              <div className="mb-5">
                <ul className={classes.instructionsList}>
                  <li>
                    Include the Invoice Number ({invoiceAccount?.invoiceNo}) in
                    the cashier cheque memo.
                  </li>

                  <li>
                    Payment Must be received within 5 days of auction closing or
                    Sale will be canceled and your account held in default.
                  </li>

                  <li>
                    Contact seller to make pickup arrangements After you receive
                    payment confirmation email.
                  </li>
                </ul>
              </div>

              <p className={classes.instructions}>
                Please use the information below to make cashier cheque.
              </p>

              <div className={`${classes.bankInfo} mb-5`}>
                <div className={classes.bankInfoRow}>
                  <div className={classes.label}>Pay to the order of</div>
                  <div className={classes.value}>
                    {invoiceAccount?.beneficiary}
                  </div>
                </div>
                <div className={classes.bankInfoRow}>
                  <div className={classes.label}>Physical Address</div>
                  <div className={classes.value}>
                    {invoiceAccount?.physicalAddress}
                  </div>
                </div>
              </div>

              <div className="mb-5 d-block d-sm-flex gap-10">
                <Link
                  className="btn btn-outline-primary px-5 d-block d-sm-inline-block mb-2 mb-sm-0"
                  to={navigationFrom?.url}
                >
                  RETURN TO {navigationFrom?.type === "bids" ? "MY BIDS" : ""}
                  {navigationFrom?.type === "offers" ? "MY OFFERS" : ""}
                  {navigationFrom?.type === "buy_now" ? "BUY NOW" : ""}
                </Link>
                <Link
                  className="btn btn-primary-dark-w px-5 d-block d-sm-inline-block"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/bid-cashier-cheque-upload", {
                      state: {
                        selected: selected,
                        from: navigationFrom,
                      },
                    });
                  }}
                >
                  UPLOAD CASHIER CHEQUE IMAGE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CashierCheque;
