import React from "react";
import classes from "./WizardButtons.module.css";

const WizardButtons = ({ children, direction = "left" }) => {
  return (
    <div
      className={`${classes.buttonsPanel} ${
        direction === "right" ? classes.right : ""
      }`}
    >
      {children}
    </div>
  );
};

export default WizardButtons;
