import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../redux/auth-context";

const SetupForm = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    ctx.showProgress(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_ADD_CARD_RETURN_URL,
      },
    });

    ctx.showProgress(false);

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
    wallets: {
      googlePay: "never",
      applePay: "never",
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />

      <div className="js-form-message mb-4 mt-4 row">
        <div className="col-md-6 mb-3 mb-md-0">
          <button
            className="btn-block btn btn-outline-primary px-5"
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              navigate("/add-card-billing");
            }}
          >
            Back
          </button>
        </div>
        <div className="col-md-6">
          <button
            className="btn-block btn btn-primary-dark-w px-5"
            disabled={!stripe}
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>

      {errorMessage && (
        <div style={{ display: "block" }} className="invalid-feedback mt-3">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

export default SetupForm;
