import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  deflateErrors,
  isBadRequest,
  isJSON,
  validatePassword, validateUsername,
  Regex,
} from "../../services/Utilities";
import {
  EmailCheckServerRequest,
  SignupServerRequest,
} from "../../services/SignUpService";
import { PasswordGroup } from "../../components/PasswordGroup";
import BiddingInfo from "./BiddingInfo";
import classes from "./RegisterModule.css";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";

const Register = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [showBiddingInfoModel, setShowBiddingInfoModel] = useState(true);
  const [serverErrors, setServerErrors] = useState();

  const schema = Yup.object().shape({
    userName: Yup.string()
      .min(5, "Username must be minimum of 5 characters or numbers")
      .max(15, "Username must be maximum of 15 characters or numbers")
      .required("Username is required"),
    firstName: Yup.string().required("First name is required")
      .min(3, "First name must be minimum of 3 characters")
      .max(40, "First name must be maximum of 40 characters")
      .matches(Regex.stringWithSpace, "First name must contain characters and space only"),
    lastName: Yup.string().required("Last name is required")
      .min(3, "Last name must be minimum of 3 characters")
      .max(20, "Last name must be maximum of 20 characters")
      .matches(Regex.stringWithSpace, "Last name must contain characters and space only"),
    email: Yup.string().max(60, "Email must be maximum of 60 characters")
      .email("Not a valid email")
      .required("Email is required"),
    confirmEmail: Yup.string()
      .max(60, "Confirm email must be maximum of 60 characters")
      .email("Not a valid email")
      .required("Confirm email is required")
      .oneOf([Yup.ref("email"), null], "Confirm email must match with email"),
    password: Yup.string()
      .min(8, "Please read our password policy. ")
      .max(20, "Password must be maximum of 20 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Confirm password must match with password"),
  });

  const initialValue = {
    userName: "",
    email: "",
    confirmEmail: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    accountType: location.state.accountType,
  };

  const createAccount = (values) => {
    ctx.showProgress(true);
    const accountInfo = {};
    accountInfo.userName = values.userName;
    accountInfo.email = values.email;
    accountInfo.confirmEmail = values.confirmEmail;
    accountInfo.firstName = values.firstName;
    accountInfo.lastName = values.lastName;
    accountInfo.password = values.password;
    accountInfo.confirmPassword = values.confirmPassword;
    accountInfo.accountType = values.accountType;

    const checkUser = {};
    checkUser.email = values.email;
    checkUser.userName = values.userName;
    EmailCheckServerRequest(checkUser)
      .then((data) => {
        ctx.showProgress(false);
        console.log(data);
        if (data.responseType === "REGISTERED" || data.responseType === "REGISTERED_AS_BIDDER") {
          setServerErrors({ userName: data.message });
        } else if (data.responseType === "NOT_REGISTERED") {
          navigate("/personal-info", { state: accountInfo });
        } else {
          navigate("/error");
        }
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }
        const json = JSON.parse(e.message);

        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "NOT_FOUND") {
            navigate("/personal-info", { state: accountInfo });
          }

          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }
        }
      });
  };

  const passwordTooltip = (
    <div className="tooltip-info">
      <h5>Password Policy</h5>
      <p className="mr-10">
        Password must be atleast 8 characters and must contain an upper-case
        character, a lower-case character, a numeric character, and a special
        character.
      </p>
    </div>
  );

  return (
    <Layout>
      <div className="row mr-1 ml-1">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="row">
            <BiddingInfo />
            <div className="col-md-12 mt-5">
              <div className="row">
                <div className="d-inline col-7">
                  <h2 className="pg-5 font-weight-bold purple-500">
                    Create Account
                  </h2>
                </div>
                <div className="d-inline col-5 text-right">
                  <h2
                    className=""
                    style={{ color: "#AFB0B5", fontSize: "24px" }}
                  >
                    Step: <b className="color-2">1/2</b>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <BHotrizontalProgress progress={33} />
              <p className="mt-4 mr-10">
                Registration is quick and easy! enter your information once and
                gain access to all auction services marketplace. If you are
                already register you can use the login credentials to bid on any
                item.
              </p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="d-inline col-6">
                  <h4 className="ca-subheading">1. Account Information</h4>
                </div>
                <div className="d-inline col-6">
                  <h5 className="pg-1 font-weight-bold text-right mb-0">
                    <span className="color-red">*</span> Mandantory Fields
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={schema}
                onSubmit={(values) => {
                  createAccount(values);
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  validateForm,
                  setFieldValue,
                }) => (
                  <form id="passportInfoForm">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="userName"
                            required="true"
                            component={ZInputComponent}
                            placeholder="Enter username"
                            label="Username"
                            validate={validateUsername}
                            serverError={serverErrors && serverErrors.userName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p className="mt-4">
                          Username must be minimum of 5 letters or numbers and
                          may include the underscore symbol (_). Must contain at
                          least one letter. May not contain an email address.
                        </p>
                      </div>
                    </div>
                    {/* first row */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="firstName"
                            required="true"
                            component={ZInputComponent}
                            placeholder="Enter first name"
                            label="First Name"
                            serverError={serverErrors && serverErrors.firstName}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="lastName"
                            required="true"
                            component={ZInputComponent}
                            placeholder="Enter last name"
                            label="Last Name"
                            serverError={serverErrors && serverErrors.lastName}
                          />
                        </div>
                      </div>
                    </div>
                    {/* first row end */}

                    {/* 2 row */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="email"
                            required="true"
                            component={ZInputComponent}
                            placeholder="Enter email address"
                            label="Email Address"
                            serverError={serverErrors && serverErrors.email}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="confirmEmail"
                            required="true"
                            component={ZInputComponent}
                            placeholder="Enter confirm email address"
                            label="Confirm Email Address"
                            serverError={
                              serverErrors && serverErrors.confirmEmail
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* sec row end */}

                    {/* 3 row */}

                    <div className="row">
                      <div className="col-md-12">
                        {/* <ReactTooltip
                            id="passwordTip"
                            place="top"
                            className="tooltip-theme"
                          >
                            {passwordTooltip}
                        </ReactTooltip> */}
                        {passwordTooltip}
                      </div>
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <label className="form-label">
                            Password<span className="color-red"> * </span>
                          </label>
                          <Field
                            name="password"
                            component={PasswordGroup}
                            serverError={serverErrors && serverErrors.password}
                            placeholder="Password"
                            validate={validatePassword}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <label className="form-label">
                            Confirm Password
                            <span className="color-red"> *</span>
                          </label>
                          <Field
                            name="confirmPassword"
                            component={PasswordGroup}
                            serverError={serverErrors && serverErrors.password}
                            placeholder="Re-enter password"
                            validate={validatePassword}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 3 row end */}

                    <div className="row mb-3">
                      <div className="col-md-9">
                        <p>
                          Already have an account?{" "}
                          <Link to="/login">Sign in Now</Link>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <Link
                          className="btn-block btn btn-primary-dark-w px-5"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            let res = await validateForm();
                            // debugger;
                            if (res && Object.values(res)?.length > 0) {
                            }
                            handleSubmit(e);
                          }}
                        >
                          Next
                        </Link>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Layout>
  );
};

export default Register;
