import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import classes from "./Static.module.css";
import LinkButton from "../../components/button/LinkButton";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useAuthContext } from "../../redux/auth-context";
import { Field, Formik } from "formik";
import { Select } from "../../components/Select";
import ZInputComponent from "../../components/UI/ZInputComponent";
import ASLabel from "../search/ASLabel";
import * as Yup from "yup";
import {
  ContactUsService,
  VerifyIdentityRequest,
} from "../../services/UserService";
import { Alert } from "@mui/material";
import { useRef } from "react";

const ContactUs = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const formikRef = useRef();

  const [purpose, setPurpose] = useState([
    { label: "Buying on BidlyAuction", value: "buying_bidly" },
    { label: "Selling on BidlyAuction", value: "selling_bidly" },
    { label: "Feedback", value: "feedback" },
    { label: "Contact Sales", value: "contact_sales" },
    { label: "Inquiry", value: "inquiry" },
  ]);
  const initialValue = {};
  const [response, setResponse] = useState();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const schema = Yup.object().shape({
    purpose: Yup.string().required("Purpose is required"),
    first_name: Yup.string()
      .required("Last Name is required")
      .min(4, "Please enter valid first name with 4 minimum charater")
      .max(64, "Please enter valid first name with 64 maximum charater"),
    last_name: Yup.string()
      .required("Last Name is required")
      .min(4, "Please enter valid last name with 4 minimum charater")
      .max(64, "Please enter valid last name with 64 maximum charater"),
    company_name: Yup.string()
      .required("Company Name is required")
      .min(4, "Please enter valid company name with 4 minimum charater")
      .max(64, "Please enter valid company name with 64 maximum charater"),
    email: Yup.string()
      .required("Email Address is required")
      .min(4, "Please enter valid email")
      .max(64, "Please enter valid email"),
    phone_number: Yup.string()
      .required("Phone Number is required")
      .min(9, "Please enter valid phone number 9 minimum charater")
      .max(15, "Please enter valid phone number 15 maximum charater"),
    message: Yup.string(),
  });

  const handleContactUs = (values) => {
    console.log(values);

    let payload = {
      purpose: values.purpose,
      firstName: values.first_name,
      lastName: values.last_name,
      companyName: values.company_name,
      email: values.email,
      phone: values.phone_number,
      message: values.message,
    };

    ctx.showProgress(true);

    ContactUsService(payload)
      .then((res) => {
        setResponse(res);
        formikRef.current?.resetForm();
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
      });
  };

  return (
    <Layout>
      <>
        <div className="mb-5">
          <div className={`bg-img-hero ${classes.bannerImgContact}`}>
            <div className="container min-height-300 overflow-hidden d-flex justify-content-md-start justify-content-start align-items-center">
              <div className={` ${classes.bannerTextContainer} col-md-7 mt-5`}>
                <h2 className={classes.heading}>CONTACT US</h2>
                <p className={classes.para}>
                  Have a question? We'll be happy to help. Most questions can be
                  answered by visiting our help page. If you still have a
                  question on using BidlyAuction, please contact us using one of
                  the methods below.
                </p>

                {/* <div className="mb-4">
                  <LinkButton
                    type="secondary2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/become-seller");
                    }}
                  >
                    Send us a message
                  </LinkButton>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div className="mb-7">
            <div className="min-height-320 overflow-hidden">
              <div className="row">
                <div className="col-md-8">
                  <div className={classes.contactUsFrmContainer}>
                    <h4 className={classes.heading2}>
                      We’re happy to answer any questions you may have. We look
                      forward to connecting with you!
                    </h4>
                    {response && response.status == "SUCCESS" && (
                      <div className="offset-lg-2 col-md-8 mb-1 text-center">
                        <label>
                          <Alert severity="success">
                            We have received your request, we will contact you
                            soon
                          </Alert>
                        </label>
                      </div>
                    )}
                    {response && response.status != "SUCCESS" && (
                      <div className="offset-lg-2 col-md-8 mb-1 text-center">
                        <label>
                          <Alert severity="error">Please try again</Alert>
                        </label>
                      </div>
                    )}
                    <Formik
                      innerRef={formikRef}
                      validateOnChange={false}
                      validateOnBlur={false}
                      enableReinitialize={true}
                      initialValues={initialValue}
                      onSubmit={(values) => {
                        handleContactUs(values);
                      }}
                      validationSchema={schema}
                    >
                      {({ handleSubmit, errors, setFieldValue, resetForm }) => (
                        <form id="contactForm">
                          <div className="row">
                            <div className="col-md-12 mt-2">
                              <Select
                                placeholder="Please Select"
                                name="purpose"
                                onChange={(e) => {
                                  setFieldValue("purpose", e.target.value);
                                }}
                              >
                                {purpose.map((item) => {
                                  return (
                                    <option key={item.value} value={item.value}>
                                      {item.label}
                                    </option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 mt-2">
                              <label>First Name</label>
                              <Field
                                type="text"
                                name="first_name"
                                component={ZInputComponent}
                                placeholder="First Name"
                              />
                            </div>
                            <div className="col-md-6 mt-2">
                              <label>Last Name</label>
                              <Field
                                type="text"
                                name="last_name"
                                component={ZInputComponent}
                                placeholder="Last Name"
                              />
                            </div>
                            <div className="col-md-12 mt-2">
                              <label>Company Name</label>
                              <Field
                                type="text"
                                name="company_name"
                                component={ZInputComponent}
                                placeholder="Company Name"
                              />
                            </div>
                            <div className="col-md-12 mt-2">
                              <label>Email Address</label>
                              <Field
                                type="email"
                                name="email"
                                component={ZInputComponent}
                                placeholder="Email Address"
                              />
                            </div>
                            <div className="col-md-12 mt-2">
                              <label>Phone Number</label>
                              <Field
                                type="text"
                                name="phone_number"
                                component={ZInputComponent}
                                placeholder="Phone Number"
                              />
                            </div>
                            <div className="col-md-12 mt-2">
                              <label>Message</label>
                              <Field
                                type="textarea"
                                name="message"
                                component={ZInputComponent}
                                placeholder="Message"
                              />
                            </div>
                            <div className="col-md-12 mt-5 text-left">
                              <button
                                className="btn btn-primary-dark-w px-5"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  handleSubmit(e);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <div className={classes.stepsCard}>
                    <div className="p-3 text-left">
                      <h4 className={classes.heading2}>Reach Us at:</h4>
                      <p className={`${classes.para} mt-0`}>
                        2747 Belvidere RD <br />
                        Waukegan, IL 60085
                        <br />
                        <strong>Phone:</strong>
                        224-509-0244
                        <br />
                        <strong>Hours:</strong> 8am to 7pm ET (Mon-Fri) <br />
                        <strong>General Enquiries:</strong>{" "}
                        sales@bidlyauction.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default ContactUs;
