import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useRef } from "react";
import { useLayoutEffect } from "react";

am4core.useTheme(am4themes_animated);

const LastDaysSaleBarChart = ({ inputData = [] }) => {
  const chart = useRef(null);

  const prepareData = (input) => {
    let data = [];

    for (const [key, value] of Object.entries(input)) {
      console.log(key, value);

      data.push({
        date: new Date(key),
        name: "name" + key,
        value: value,
      });
    }

    return data;
  };

  useLayoutEffect(() => {
    let x = am4core.create("chartdiv", am4charts.XYChart);

    if (inputData && Object.keys(inputData)?.length > 0) {
      x.paddingRight = 20;

      x.data = prepareData(inputData);

      x.numberFormatter.numberFormat = "'$'#";

      let dateAxis = x.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;

      let series = x.series.push(new am4charts.ColumnSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";
      series.tooltipText = "{valueY.value}";
      x.cursor = new am4charts.XYCursor();

      chart.current = x;
    }

    return () => {
      x.dispose();
    };
  }, [inputData]);

  return <div id="chartdiv" style={{ width: "100%", height: "400px" }} />;
};

export default LastDaysSaleBarChart;
