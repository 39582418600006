import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate, Link } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu"
import Layout from "../../layout/Layout";
import * as Yup from "yup";
import LinkButton from "../../components/button/LinkButton";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Form, Formik } from "formik";
import SearchSelect from "../../components/form/SearchSelect";
import { LibraryService } from "../../services/LibraryService";
import { useAuthContext } from "../../redux/auth-context";
import { PhoneTypes } from "../../components/common/PhoneTypes";
import { deflateErrors, isJSON, validateUSPhoneNumber, isStopFlowConditionMet } from "../../services/Utilities";
import { ResetPasswordRequest } from "../../services/UserService";
import Alert from "../../components/UI/Alert";
import { PasswordGroup } from "../../components/PasswordGroup";
import { validatePassword } from "../../services/Utilities";

const ResetPasswordPU = () => {
    const ctx = useAuthContext();
    let navigate = useNavigate();
    const [addressList, setAddressList] = useState();

    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [serverErrors, setServerErrors] = useState();
    const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);

    useEffect(() => {
        ctx.showProgress(true);

        ctx.showProgress(false);
    }, []);

    const schema = Yup.object().shape({
        currentPassword: Yup.string().required("Current Password is required"),
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    });
    
    let initialValue = {
        currentPassword: "",
        password: "",
        confirmPassword: "",
    };

    const updatePassowrd = (values) => {
        let payload = {
            currentPassword: values.currentPassword,
            password: values.password,
            confirmPassword: values.confirmPassword,
        };
    
        ctx.showProgress(true);
    
        ResetPasswordRequest(payload)
        .then((data) => {
            console.log(data);
            ctx.showProgress(false);
            setUpdatedSuccessfully(true);
            setServerErrors();
        })
        .catch((e) => {
            ctx.showProgress(false);
            setUpdatedSuccessfully(false);
            if (!isJSON(e.message)) {
                navigate("/error");
                return;
            }
    
            if (isStopFlowConditionMet(e.message)) return;
    
            const json = JSON.parse(e.message);
            console.log(json);
            if (json.status === "BAD_REQUEST") {
                if (json.data.status === "VALIDATION_FAILED") {
                    setServerErrors(deflateErrors(json.data.errors));
                }
            }
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        });

    }
    return (<Layout>
        <div className="container mt-5 mb-5">
            <div className="row">
                <div className="col-lg-3 col-md-3">
                    <AccountMenu accountInfoActive={true} />
                </div>
                <div className="offset-lg-1 col-lg-7 col-md-7">
                    <div className="row">

                        <div className="col-md-12">
                            <h2 className="h3 font-weight-bold purple-500">
                            Reset Password
                            </h2>
                        </div>
                        <div className="col-md-12 mb-4">
                            <BHotrizontalProgress progress={33} />
                        </div>

                        <div className="col-md-12">
                            <p className="pg-2 instructions-desc">
                            Create new password but it must not be any of your previous password. Password must contains:<br/>
                            At least <b>8 characters and must contain an upper-case
                            character, a lower-case character, a numeric character, and a special
                            character.</b>
                            </p>
                        </div>

                        <div className="col-md-6">
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={false}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={schema}
                                onSubmit={(values) => {
                                    console.log(values);
                                    updatePassowrd(values);
                                }}
                            >
                                {({
                                handleSubmit,
                                isSubmitting,
                                errors,
                                validateForm,
                                setFieldValue,
                                resetForm
                                }) => (
                                <form id="passportInfoForm">
                                    {updatedSuccessfully && (
                                        <Alert type="success" children="Password updated successfully." />
                                    )}
                                    <div className="js-form-message mb-3">
                                        <label className="form-label">
                                            Current Password<span className="color-red"> *</span>
                                        </label>
                                        <Field
                                            name="currentPassword"
                                            component={PasswordGroup}
                                            placeholder="Enter current password"
                                            label="Current Password"
                                            validate={validatePassword}
                                            serverError={serverErrors && serverErrors.currentPassword}
                                        />
                                    </div>

                                    <div className="js-form-message mb-3">
                                        <label className="form-label">
                                            New Password<span className="color-red"> *</span>
                                        </label>
                                        <Field
                                            name="password"
                                            component={PasswordGroup}
                                            placeholder="Enter new password"
                                            label="New Password"
                                            validate={validatePassword}
                                        />
                                    </div>

                                    <div className="js-form-message mb-3">
                                        <label className="form-label">
                                            Confirm New Password<span className="color-red"> *</span>
                                        </label>
                                        <Field
                                            name="confirmPassword"
                                            component={PasswordGroup}
                                            placeholder="Re enter new password"
                                            label="New Password"
                                            validate={validatePassword}
                                            serverError={serverErrors && serverErrors.confirmPassword}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="d-inline p-3 col-md-6">

                                            <button
                                                className="btn-block btn btn-outline-primary px-5 text-transform-upper"
                                                type="button"
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    navigate("/reset-password-pu");
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="d-inline p-3 col-md-6">
                                            <Link
                                            className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                                            type="submit"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                let res = await validateForm();
                                                console.log(res);
                                                if (res && Object.values(res)?.length > 0) {
                                                }
                                                handleSubmit(e);
                                                setTimeout(()=>{
                                                    resetForm()
                                                }, 2000);
                                            }}
                                            >
                                            save
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                                )}
                            </Formik>
                            
                        </div>

                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    </Layout>
    );
};

export default ResetPasswordPU;
