import { Field, Form, Formik, useField } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../redux/auth-context";
import * as Yup from "yup";
import classes from "./Auction.module.css";
import { BidNow, StatusRequest } from "../../services/UserService";
import { Alert } from "@mui/material";
import {
  deleteAddWatchedItem,
  userAddWatchedItem,
} from "../../services/AuctionService";
import { shallowEqual, useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { isSeller } from "../../services/Utilities";

const AuctionBid = ({ isAuthorized, item, reFetchDetail }) => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const location = useLocation();

  const { userType } = useSelector(
    ({ auth }) => ({
      userType: auth.user,
    }),
    shallowEqual
  );

  const {viewExpired, user } = useSelector(
    ({ auth }) => ({
      viewExpired: auth.viewExpired,
      user: auth.user,
    }),
    shallowEqual
  );


  const [accountStatusData, setAccountStatusData] = useState();
  const [enableBid, setEnableBid] = useState(false);
  const [bidRequest, setBidRequest] = useState();
  const [bidResponse, setBidResponse] = useState();

  let btnTitle = "Bid Now";
  let confirmTitle = "Agree & Confirm Your Bid";
  let cancelTitle = "Cancel this Bid";

  switch (item.saleType) {
    case "MAKE_AN_OFFER":
      btnTitle = "Make An Offer";
      confirmTitle = "Agree & Confirm Your Offer";
      cancelTitle = "Cancel this Offer";
      break;
    default:
      btnTitle = "Bid Now";
      break;
  }

  useEffect(() => {
    if (isAuthorized) {
      ctx.showProgress(true);
      StatusRequest()
        .then((data) => {
          setAccountStatusData(data);
          ctx.showProgress(false);
        })
        .catch((error) => {
          ctx.showProgress(false);
          navigate("/error");
        });
    }
  }, [isAuthorized]);

  const BidInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">{item.currency}</span>
          </div>
          <input
            id="bidF"
            step={1}
            min={item.saleType == "ONLINE_AUCTION" ? props.min : props.min}
            max={item.saleType == "ONLINE_AUCTION" ? props.max : 9999999999}
            type="number"
            className="form-control text-area"
            disabled={props.disable}
            {...field}
            {...props}
          />
        </div>
        {meta.touched && meta.error ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
      </>
    );
  };

  const onBid = (values) => {
    setBidRequest({
      bidAmount: values.bid,
      autoBid:
        item.saleType == "ONLINE_AUCTION"
          ? values.auto_bid
            ? values.auto_bid
            : false
          : false,
      emailWhenOutBid: values.email ? values.email : false,
      itemId: item.itemId,
    });
  };

  const bidNow = () => {
    ctx.showProgress(true);
    BidNow(bidRequest)
      .then((data) => {
        setBidResponse(data);
        if (data?.status == "success") {
          setEnableBid(false);
          setBidRequest(null);
          reFetchDetail();
        }
        ctx.showProgress(false);
      })
      .catch((error) => {
        ctx.showProgress(false);
        navigate("/error");
      });
  };

  const watchListHadler = () => {
    if (isAuthorized) {
      ctx.showProgress(true);
      if (!item.watchListed) {
        userAddWatchedItem(item.itemId)
          .then((data) => {
            item.watchListed = true;
            ctx.showProgress(false);
          })
          .catch((error) => {
            ctx.showProgress(false);
          });
      } else {
        deleteAddWatchedItem(item.itemId)
          .then((data) => {
            item.watchListed = false;
            ctx.showProgress(false);
          })
          .catch((error) => {
            console.log(error);
            ctx.showProgress(false);
          });
      }
    } else {
      navigate("/login", { state: { location } });
    }
  };

  return enableBid ? (
    <>
      <div className="offset-lg-2 col-md-8 mb-1">
        <Formik
          initialValues={{
            bid: "",
            auto_bid: true,
            email: true,
          }}
          validationSchema={
            item.saleType == "ONLINE_AUCTION"
              ? Yup.object({
                  bid: Yup.number()
                    .integer("Please enter a whole number without decimals.")
                    .min(
                      item.currentMaxBid + item.bidIncrement,
                      `You can not bid less than ${
                        item.currentMaxBid + item.bidIncrement
                      }$`
                    )
                    // .max(
                    //   accountStatusData?.creditLimit,
                    //   `Your account is currently on probation. You can not bid more than ${accountStatusData?.creditLimit}$ amount.`
                    // )
                    .required("Bid Amount is Required"),
                })
              : Yup.object({
                  bid: Yup.number()
                    .integer("Please enter a whole number without decimals.")
                    .min(1, `Amount cannot be less than 1$`)
                    .required("Amount is Required"),
                })
          }
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
          onSubmit={(values) => {
            onBid(values);
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            errors,
            validateForm,
            setFieldValue,
          }) => (
            <Form>
              <div className="row">
                <div className="col-md-12 mb-2">
                  <BidInput
                    disable={bidRequest ? "disabled" : ""}
                    name="bid"
                    min={item.currentMaxBid + item.bidIncrement}
                    max={accountStatusData?.creditLimit}
                    rows="6"
                    placeholder={
                      item.saleType == "MAKE_AN_OFFER"
                        ? "Offer Amount"
                        : item.saleType == "MAKE_AN_OFFER"
                        ? "Amount"
                        : "Bid Amount"
                    }
                  />
                </div>
                {item.saleType == "ONLINE_AUCTION" && (
                  <>
                    <div className="col-md-6">
                      <Field
                        type="checkbox"
                        name="auto_bid"
                        className={"mr-2"}
                      />
                      <label className={classes.infoTableLabel}>
                        Auto BidS{" "}
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Auto Bid lets you get the lowest possible without having to follow the auction in real time"
                        >
                          <i
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <Tooltip id="my-tooltip" />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <Field type="checkbox" name="email" className={"mr-2"} />
                      <label className={classes.infoTableLabel}>
                        Email if outbid{" "}
                        <a
                          data-tooltip-id="my-tooltip-two"
                          data-tooltip-content="You will be notified via email when you have been outbid"
                        >
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                        </a>
                        <Tooltip id="my-tooltip-two" />
                      </label>
                    </div>
                  </>
                )}
                {!bidRequest && (
                  <>
                    <div className="col-md-12 mt-2">
                      <button
                        className="btn btn-block btn-primary-dark-w"
                        type="submit"
                        onClick={async (e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                      >
                        {btnTitle}
                      </button>
                    </div>
                    <div className="col-md-12 mt-2">
                      <button
                        type="button"
                        onClick={() => {
                          watchListHadler();
                        }}
                        className="btn btn-block btn-outline-primary mt-2"
                      >
                        {item.watchListed ? "Remove from Watch" : "Watch"}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {bidResponse?.status == "error" && (
        <div className="offset-lg-2 col-md-8 mb-1 text-center">
          <label className={classes.infoTableLabelRed}>
            <Alert severity="error">{bidResponse.message}</Alert>
          </label>
        </div>
      )}
      {bidRequest && (
        <>
          <div className="offset-lg-2 col-md-8 mb-1">
            <ul>
              <li className={classes.auctionLocationLabel}>
                By clicking on Agree & Confirm your Bid you agree to following:
              </li>
            </ul>

            <ol>
              <li className={classes.auctionLocationLabel}>
                You have done your due diligence on the item. All Items are sold
                AS IS WHERE IS.
              </li>
              <li className={classes.auctionLocationLabel}>
                Bids cannot be cancelled, withdrawn or rescinded.
              </li>
              <li className={classes.auctionLocationLabel}>
                You have sufficient funds available to cover the buyers fee on
                your credit card.
              </li>
              <li className={classes.auctionLocationLabel}>
                The selling agency has the right to reject the final bid for any
                reason.
              </li>
            </ol>
          </div>
          <div className="offset-lg-2 col-md-8 mb-1">
            <button
              type="button"
              onClick={() => bidNow()}
              className="btn btn-block btn-primary-dark-w mt-2"
            >
              {confirmTitle}
            </button>
          </div>
          <div className="offset-lg-2 col-md-8 mb-1 text-center">
            <Link
              className={classes.auctionLocationGreen}
              onClick={() => {
                setEnableBid(false);
                setBidRequest(null);
              }}
            >
              {cancelTitle}
            </Link>
          </div>
        </>
      )}
    </>
  ) : !isAuthorized ? (
    <div className="offset-lg-2 col-md-8 mb-1">
      <button
        type="button"
        onClick={() => navigate("/login", { state: { location } })}
        className="btn btn-block btn-primary-dark-w mt-2"
      >
        Sign in to Place Bid
      </button>
    </div>
  ) : accountStatusData?.documentStatus == "EMPTY" || accountStatusData?.documentStatus == "SUBMITTED" &&
   !isSeller(user.type)? (
    <div className="offset-lg-2 col-md-8 mb-1">
      <button
        type="button"
        onClick={() => !isSeller(user.type) ?  navigate("/verify-identity-welcome") : navigate("/apply-for-bidder-account")}
        className="btn btn-block btn-primary-dark-w mt-2"
      >
        Verify Identity to Bid
      </button>
    </div>
  ) : accountStatusData?.paymentStatus == "f" ? (
    <>
      <div className="offset-lg-2 col-md-8 mb-1">
        <button
          type="button"
          onClick={() => {
            !isSeller(user.type) ?  navigate("/add-card-info") : navigate("/apply-for-bidder-account")
          }}
          className="btn btn-block btn-primary-dark-w mt-2"
        >
          Add Credit Card to Bid
        </button>
      </div>
    </>
  ) : (
    <>
      {bidResponse?.status == "success" && (
        <div className="offset-lg-2 col-md-8 mb-1 text-center">
          <label className={classes.infoTableLabelGreen}>
            <Alert severity="success">{bidResponse.message}</Alert>
          </label>
        </div>
      )}
      <div className="offset-lg-2 col-md-8 mb-1">
        <button
          type="button"
          onClick={() => {
            setEnableBid(true);
            setTimeout(() => {
              document.querySelector("#bidF").focus();
            }, 1000);
          }}
          className="btn btn-block btn-primary-dark-w mt-2"
        >
          {btnTitle}
        </button>
      </div>
      <div className="offset-lg-2 col-md-8 mb-1">
        <button
          type="button"
          onClick={() => {
            watchListHadler();
          }}
          className="btn btn-block btn-outline-primary mt-2"
        >
          {item.watchListed ? "Remove from Watch" : "Add to Watchlist"}
        </button>
      </div>
    </>
  );
};
export default AuctionBid;
