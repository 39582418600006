import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./Listing.module.css";
import {
  formatUSD,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import {
  chooseAuctionWinner,
  fetchMyItem,
  sendAuctionInvoice,
} from "../../services/AuctionItemService";
import { SELLER_AUCTION_SEND_INVOICE_URL } from "../../Constants";
import ConfirmModal from "../../components/UI/ConfirmModal";
import ButtonsPanel from "../my-bids/ButtonsPanel";
import buttonPanelClasses from "../my-bids/MyBids.module.css";

const ListingInvoice = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [itemDetail, setItemDetail] = useState({});

  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const fetchData = async (itemId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchMyItem(
        SELLER_AUCTION_SEND_INVOICE_URL.replace("{itemId}", itemId)
      );
      console.log("item", rs);
      setItemDetail(rs);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const sendInvoice = async () => {
    ctx.showProgress(true);
    sendAuctionInvoice(itemDetail?.itemId)
      .then((rs) => {
        ctx.showProgress(false);
        navigate("/my-listing");
      })
      .catch((e) => {
        console.log(e);
        ctx.showProgress(false);
        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  };

  useEffect(() => {
    if (params.item) fetchData(params.item);
  }, [params.item]);

  const tableHeader = (
    <tr>
      <th>Bidder</th>
      <th>Submitted On</th>
      <th>Amount</th>
      <th>Action</th>
    </tr>
  );

  return (
    <>
      {showConfirmBox && (
        <ConfirmModal
          title=""
          message={`Are you sure want to send this invoice?`}
          cancelClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowConfirmBox(false);
            sendInvoice();
          }}
        />
      )}

      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myItemsActive />
            </div>

            <div className="col-lg-9 col-md-8">
              <h2 className="h4 font-weight-bold">Item Details</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className={`row ${classes.topRow}`}>
                <div className="col-auto">
                  <div className="d-lg-none d-block mb-3">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.type?.toLowerCase()}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div
                      className={classes.imgContainer}
                      style={{ background: `url(${itemDetail.defaultImage})` }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-lg-block d-none">
                    <div className="row">
                      <div className="col-auto">
                        <span className={`${classes.label} text-capitalize`}>
                          {itemDetail?.type?.toLowerCase()}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className={classes.label}>
                          Lot # {itemDetail.lot}
                        </span>
                      </div>
                    </div>
                  </div>

                  <h4>{itemDetail.title}</h4>

                  <div
                    className="instructions-desc"
                    dangerouslySetInnerHTML={{ __html: itemDetail.description }}
                  />

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Start Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.startedOn)}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>End Date</div>
                      <div className={classes.value}>
                        {formattedUTCtoUSCentral(itemDetail.endingIn)}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>Buyer Name</div>
                      <div className={classes.value}>
                        {itemDetail.highestBid?.name}
                      </div>
                    </div>
                    <div className="col-auto my-2">
                      <div className={classes.label}>Buyer Email</div>
                      <div className={classes.value}>
                        {itemDetail.highestBid?.email}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto my-2">
                      <div className={classes.label}>
                        {itemDetail?.type !== "BUY NOW" && "Starting Price"}
                        {itemDetail?.type === "BUY NOW" && "Price"}
                      </div>
                      <div className={classes.value}>
                        {formatUSD(itemDetail.startingPrice)}
                      </div>
                    </div>
                    {itemDetail?.type !== "BUY NOW" && (
                      <div className="col-auto my-2">
                        <div className={classes.label}>Reserve Price</div>
                        <div className={classes.value}>
                          {formatUSD(itemDetail.reservePrice)}
                        </div>
                      </div>
                    )}

                    {itemDetail?.type !== "BUY NOW" && (
                      <div className="col-auto my-2">
                        <div className={classes.label}>
                          {itemDetail?.type === "MAKE AN OFFER" &&
                            "Winning Offer"}
                          {(itemDetail?.type === "ONLINE AUCTION" ||
                            itemDetail?.type === "SEALED AUCTION") &&
                            "Higest Bid"}
                        </div>
                        <div className={classes.value}>
                          {formatUSD(itemDetail.highestBid?.amount)}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-5">
                    <ButtonsPanel>
                      <a
                        download={true}
                        href={itemDetail.certificate}
                        className={`btn btn-primary-dark-w ${buttonPanelClasses.item}`}
                      >
                        DOWNLOAD INVOICE
                      </a>
                      <a
                        href="#"
                        className={`btn btn-secondary ${buttonPanelClasses.item}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowConfirmBox(true);
                        }}
                      >
                        SEND INVOICE to BIDDER
                      </a>
                    </ButtonsPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ListingInvoice;
