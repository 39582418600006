import { useEffect } from "react";
import { scrollToPosition } from "../services/Utilities";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./sidebar/Sidebar";
import UserSidebar from "./sidebar/UserSidebar";
const Layout = (props) => {
  useEffect(() => {
    scrollToPosition(0, () => {});
  }, []);

  const grey = props?.grey ? { background: "#f3f3f3" } : {};

  return (
    <>
      {window.screen.width < 1200 && <Sidebar />}
      {window.screen.width < 1200 && <UserSidebar />}
      <Header {...props} />
      <main id="content" role="main" style={grey}>
        {props.children}
      </main>
      <Footer {...props} />
    </>
  );
};

export default Layout;
