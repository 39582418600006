import React from "react";
import classes from "./ListingTable.module.css";

const ListingTable = ({ tableBody, mobileBody }) => {
  return (
    <div className={classes.main}>
      <div className={classes.tableContainer}>
        <div className="table-responsive">
          <table className={`${classes.table} table`}>
            {tableBody && Object.keys(tableBody)?.length > 0 && (
              <tbody>{tableBody}</tbody>
            )}
            {!tableBody ||
              (Object.keys(tableBody)?.length == 0 && (
                <tbody>
                  <tr>
                    <td>
                      <div className={`${classes.nrHeading} text-center`}>
                        No Record Found
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </div>

      <div className={classes.smallTableContainer}>
        <div className={classes.panel}>
          {mobileBody}

          {!mobileBody ||
            (Object.keys(mobileBody)?.length === 0 && (
              <table className={`${classes.table} table table-bordered`}>
                <tbody>
                  <tr>
                    <td>
                      <div className={`${classes.nrHeading} text-center`}>
                        No Record Found
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ListingTable;
