import React from "react";
import classes from "./InstructionMultibox.module.css";

const InstructionMultibox = ({ children }) => {
  return <div className={classes.container}>{children}</div>;
};

InstructionMultibox.header = ({ heading, description }) => {
  return (
    <div className={`${classes.row} ${classes.heading}`}>
      <p>{heading}</p>
      <p>{description}</p>
    </div>
  );
};
InstructionMultibox.row = ({ children }) => {
  return (
    <div className={classes.row}>
      <p>{children}</p>
    </div>
  );
};

export default InstructionMultibox;
