import React from "react";
import CheckoutTable from "../../components/table/CheckoutTable";
import tableClasses from "../../components/table/CheckoutTable.module.css";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { formatUSD } from "../../services/Utilities";
import StepHeading from "../credit-card/StepHeading";

const CheckedoutItems = ({ selected, totalAmount }) => {
  const tableHeader = (
    <>
      <tr>
        <th>
          <input type="checkbox" disabled />
        </th>
        <th>Asset Description</th>
        <th>Auction End</th>
        <th>Sold Amount</th>
        <th>Buyer’s Premium</th>
        <th>Estimated Tax</th>
        <th>Total Due</th>
      </tr>
    </>
  );

  const mobileHeader = (
    <>
      <div className={tableClasses.selection}>
        <input type="checkbox" disabled />
      </div>

      <div className={tableClasses.label}>Item Description</div>
    </>
  );

  return (
    <>
      <StepHeading
        title="Payment Review"
        secondInfo={{
          title: "Total Amount Due:",
          info: "USD " + formatUSD(totalAmount),
        }}
      />

      <div className="mb-5">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col mb-5">
          <CheckoutTable
            tableHeader={tableHeader}
            mobileHeader={mobileHeader}
            tableBody={selected?.map((bid, i) => {
              return (
                <CheckedoutItems.TableBodyRow
                  key={i}
                  label={bid.auction.title}
                  auctionEnd={bid.auction.endedOn}
                  buyerFee={bid.buyerPremium}
                  lot={bid.auction.customId}
                  soldAmount={bid.soldAmount}
                  tax={bid.taxAmount}
                  total={bid.total}
                  currency={bid.auction.currency}
                />
              );
            })}
            mobileBody={selected.map((bid, i) => {
              return (
                <CheckedoutItems.MobileBodyRow
                  key={i}
                  label={bid.auction.title}
                  auctionEnd={bid.auction.endedOn}
                  buyerFee={bid.buyerPremium}
                  lot={bid.auction.customId}
                  soldAmount={bid.soldAmount}
                  tax={bid.taxAmount}
                  total={bid.total}
                  currency={bid.auction.currency}
                />
              );
            })}
            tableFooter={
              <CheckedoutItems.TableFooterRow
                currency="USD"
                amount={totalAmount}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

CheckedoutItems.TableBodyRow = ({
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
}) => {
  return (
    <>
      <tr>
        <td>
          <input type="checkbox" checked disabled />
        </td>
        <td>
          <span className={tableClasses.productLabel}>{label}</span>
          <span className={tableClasses.lot}>LOT # {lot}</span>
        </td>
        <td>{auctionEnd}</td>
        <td>{formatUSD(soldAmount)}</td>
        <td>{formatUSD(buyerFee)}</td>
        <td>{formatUSD(tax)}</td>
        <td>{formatUSD(total)}</td>
      </tr>
    </>
  );
};

CheckedoutItems.MobileBodyRow = ({
  label,
  lot,
  auctionEnd,
  soldAmount,
  buyerFee,
  tax,
  total,
  currency,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}>
          <input type="checkbox" checked disabled />
        </div>

        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.productLabel}>{label}</div>
            <div className={tableClasses.lot}>LOT # {lot}</div>
          </div>

          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Auction End</div>
            <div className={tableClasses.value}>{auctionEnd}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Sold Amount</div>
            <div className={tableClasses.value}>{formatUSD(soldAmount)}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Buyer’s Premium</div>
            <div className={tableClasses.value}>{formatUSD(buyerFee)}</div>
          </div>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>Estimated Tax</div>
            <div className={tableClasses.value}>{formatUSD(tax)}</div>
          </div>
        </div>
      </div>
      <div className={`${tableClasses.block} ${tableClasses.total}`}>
        <div className={tableClasses.selection}>&nbsp;&nbsp;&nbsp;</div>

        <div className={tableClasses.totalRow}>
          <div className={tableClasses.label}>Total Due</div>
          <div className={tableClasses.value}>{formatUSD(total)}</div>
        </div>
      </div>
    </div>
  );
};

CheckedoutItems.TableFooterRow = ({ currency, amount }) => {
  return (
    <>
      <tr>
        <th></th>
        <th colSpan={5}>Total Amount Due</th>
        <th>{formatUSD(amount)}</th>
      </tr>
    </>
  );
};

export default CheckedoutItems;
