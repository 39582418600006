import React from "react";
import classes from "./dashboard.module.css";

const ListItem = ({ label, value }) => {
  return (
    <div className={classes.listItem}>
      <span className={classes.label}>{label}</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};

export default ListItem;
