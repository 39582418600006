import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import plus from "../../assets/images/plus.svg";
import LinkButton from "../../components/button/LinkButton";
import { FetchAddressData } from "../../services/UserService";
import {
  isJSON,
  isSeller,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import AddressCardsPanel from "./AddressCardsPanel";
import AddressCard from "./AddressCard";
import Alert from "../../components/UI/Alert";
import { shallowEqual, useSelector } from "react-redux";

const AddAddressInfo = () => {
  const navigate = useNavigate();
  const params = useLocation().state ?? {};

  const [addressList, setAddressList] = useState();
  const [addressAddStatus, setAddressAddStatus] = useState();

  useEffect(() => {
    setAddressAddStatus(params.addressAddStatus);
  }, [params]);

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    FetchAddressData()
      .then((resp) => {
        setAddressList(resp);
      })
      .catch((e) => {
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        navigate("/error");
      });
  }, []);

  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <AccountMenu accountInfoActive={true} subMenu={"address"} />
          </div>
          <div className="offset-lg-1 col-lg-7 col-md-7">
            <div className="row">
              <div className="col-md-12">
                <h2 className="h3 font-weight-bold">Addresses</h2>
              </div>
              <div className="col-md-12 mb-4">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="col-md-12">
                {addressAddStatus && (
                  <Alert>
                    Success! Your address information has been saved.
                  </Alert>
                )}
              </div>

              <div className="col-md-12">
                <p className="pg-2 instructions-desc">
                  You may add as many addresses as you like. If you have
                  multiple addresses please be sure to designate a contact
                  address. You will receive correspondence at this address.
                </p>
              </div>

              <div className="col-md-12">
                {!isSeller(user.type) && (
                  <LinkButton to="/add-address" className="mt-5">
                    UPDATE ADDRESSES
                  </LinkButton>
                )}

                {isSeller(user.type) && (
                  <LinkButton to="/add-agency-address" className="mt-5">
                    UPDATE ADDRESSES
                  </LinkButton>
                )}
              </div>

              <div className="col-md-12 mt-4 mb-10">
                {addressList && (
                  <AddressCardsPanel>
                    {addressList.map((item, i) => {
                      let addressLine =
                        item.addressLineOne +
                        " " +
                        item.addressLineTwo +
                        ", " +
                        item.city +
                        " " +
                        item.postalCode +
                        " " +
                        item.state +
                        " " +
                        item.country;

                      if (isSeller(user.type)) {
                        addressLine =
                          item.city +
                          " " +
                          item.postalCode +
                          " " +
                          item.state +
                          " " +
                          item.country;
                      }

                      return (
                        <AddressCard
                          key={i}
                          isRemove={false}
                          identified={item.cardInfoId}
                          title={"Primary Address"}
                          applicantName={item.name}
                          address={addressLine}
                          onRemove={() => {
                            console.log("clicked on remove button");
                          }}
                        />
                      );
                    })}
                  </AddressCardsPanel>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default AddAddressInfo;
