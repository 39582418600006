import React from "react";
import Layout from "../../layout/Layout";
import classes from "./ScheduleDemo.module.css";
import LinkButton from "../../components/button/LinkButton";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { isSeller } from "../../services/Utilities";

const BecomeSeller = () => {
  const navigate = useNavigate();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <Layout>
      
        <div className="mb-5">
          <div className={`bg-img-hero ${classes.bannerImg}`}>
            <div className="container min-height-300 overflow-hidden d-flex justify-content-md-start justify-content-start align-items-center">
              <div className={` ${classes.bannerTextContainer} col-md-7 mt-5`}>
                <h2 className={classes.heading}>Intelligent, Swift & Secure</h2>
                <p className={classes.para}>
                  Discover all the tools you require as a seller<br></br> 
                  to enhance your online selling journey!
                </p>

                {(!isAuthorized || !isSeller(user.type)) && (
                  <div className="mb-4">
                    {/* <LinkButton
                      to="/demo-duration"
                      type="primary"
                      className="mb-2"
                    >
                      SCHEDULE A DEMO
                    </LinkButton>{" "} */}
                    <LinkButton
                      className="mb-2"
                      type="secondary2"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/reprentative-info", {
                          state: { accountType: "SELLER" },
                        });
                      }}
                    >
                      APPLY FOR SELLER ACCOUNT
                    </LinkButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
        <div className="mb-7">
          <div className="min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
            <div className={classes.textContainer}>
              <h2 className={classes.heading}>
              Introducing Our Seller's<br></br> Platform!
              </h2>
              <p className={classes.para}>
                Step into our seller's platform, designed to cater to both newcomers 
                to e-commerce and seasoned sellers aiming to grow<br></br> their enterprises.
                We provide the essential tools and comprehensive support necessary for your triumph.
              </p>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="row">
            <div className="offset-lg-2 col-md-4">
              <div className={classes.virtualGathering}>
              <h4 className={classes.heading2}>Seller's Virtual Gathering</h4>
              <h2 className={classes.heading}>
                Find Inspiration, <br></br>Gain Knowledge, Excel 
              </h2>
              <p className={classes.para}>
                Reserve your spot for a virtual meeting and acquire insights on propelling 
                your business forward on our platform. Our team of experts will share invaluable 
                guidance and proven strategies to enhance your listings, boost sales, and elevate 
                your customers' satisfaction.
              </p>
              <div className={`${classes.buttons} d-none d-md-inline-flex`}>
                <LinkButton to="/demo-duration" type="primary">
                  SCHEDULE A DEMO
                </LinkButton>
              </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`bg-img-hero ${classes.demoMeetingImg}`}></div>
            </div>
          </div>
        </div>

        <div className="mb-7">
          <div className="min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
            <div className={classes.textContainer}>
              <h2 className={classes.heading}>
              Becoming a<br></br> Seller Made Simple
              </h2>
              <p className={`${classes.para} mt-3 mb-0`}>
              Follow these straightforward steps to become a seller on BidlyAuction.
              </p>
            </div>
          </div>
        </div>

        <div className="mb-7">
          <div className="min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
            <div className="row">
              <div className="col-md-4 mb-2">
                <div className={classes.stepsCard}>
                  <div className="p-3">
                    <h2 className={classes.stepNo}>1</h2>
                    <h4 className={classes.heading2}>Sell Everything!</h4>
                    <h2 className={classes.heading}>
                    List Any Item, Regardless of Condition 
                    </h2>
                    <p className={classes.para}>
                    Easily create your auction listing by adding item details and images. 
                    Utilize our analytics tools to gain insights into real market value estimates.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-2">
                <div className={classes.stepsCard}>
                  <div className="p-3">
                    <h2 className={classes.stepNo}>2</h2>
                    <h4 className={classes.heading2}>Track Items!</h4>
                    <h2 className={classes.heading}>Easy Item Management</h2>
                    <p className={classes.para}>
                      Our user-friendly platform allows you to effortlessly
                      monitor your products and inventory as they make their way
                      through the bidding process.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-2">
                <div className={classes.stepsCard}>
                  <div className="p-3">
                    <h2 className={classes.stepNo}>3</h2>
                    <h4 className={classes.heading2}>Safety Assured!</h4>
                    <h2 className={classes.heading}>
                    Effortless and Swift Payments
                    </h2>
                    <p className={classes.para}>
                    Rest assured, handling disputes is not your concern. Once the transaction is complete, 
                    we'll seamlessly transfer the proceeds directly to your bank account.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-7">
          <div className="min-height-320 overflow-hidden d-flex justify-content-md-center justify-content-start align-items-center">
            <div className={classes.learnMoreContainer}>
              <div className={`${classes.textContainer} mt-10`}>
                <h2 className={classes.heading}>Want to Learn More?</h2>
                <p className={classes.para}>
                  In case you require additional assistance or tailored
                  services, we offer a variety of options that can be customized
                  to <br></br> your requirements - be it comprehensive or
                  minimal. Reach out to us now to explore our Managed Services,
                  which <br></br> include a complete range of expert solutions
                  for a hassle-free experience.
                </p>
                <div className={`${classes.buttons} d-md-inline-flex`}>
                  <LinkButton to="/contact-us" type="primary">
                    CONTACT US
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BecomeSeller;
