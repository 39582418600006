import React from "react";
import unreadIcon from "../../assets/images/green-dot.svg";
import optionsIcon from "../../assets/images/dots-more.svg";
import importantIcon from "../../assets/images/exclaim.svg";
import classes from "./NotificationBar.module.css";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { useAuthContext } from "../../redux/auth-context";
import { readNotification } from "../../services/NotificationService";
import { useDispatch } from "react-redux";
import { actionTypes as authActionTypes } from "../../pages/_redux/authRedux";
import { newDateObjectUTC, newDateObjectUsCentral } from "../../services/Utilities";

const GeneralNotification = ({ data, onNotificationRead = (id) => {} }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useAuthContext();

  const proceedTo = async (notification) => {
    if (notification.notificationType !== "DISMISSIVE") ctx.showProgress(true);

    await readNotification(notification?.notificationId);
    onNotificationRead(notification?.notificationId);

    let listing = "DRAFT";
    if (notification.notificationType === "ITEM_SCHEDULED") {
      listing = "SCHEDULED";
    } else if (notification.notificationType === "ITEM_MOVED_TO_LIVE") {
      listing = "ACTIVE";
    } else if (notification.notificationType === "ITEM_RESERVE_MET") {
      listing = "ACTIVE";
    } else if (notification.notificationType === "ITEM_TIMED_OUT") {
      listing = "TIMED_OUT";
    } else if (
      notification.notificationType === "ITEM_TIMED_OUT_UNSUCCESSFUL"
    ) {
      listing = "UNSUCCESSFUL_ENDED";
    } else if (notification.notificationType === "ITEM_INVOICE_GENERATED") {
      listing = "FINALIZING_WINNER";
    } else if (notification.notificationType === "ITEM_PAYMENT_DONE") {
      listing = "WAITING_TO_RECEIVE_PAYMENT";
    } else if (
      notification.notificationType === "ITEM_PAYMENT_RECEIVED_CONFIRM"
    ) {
      ctx.showProgress(false);

      if (notification.item.type === "MAKE AN OFFER") navigate("/offers-won");
      else if (notification.item.type === "BUY NOW")
        navigate("/item-bought-checkout");
      else navigate("/bids-won");
      return;
    } else if (notification.notificationType === "ITEM_PICKUP_CONFIRMED") {
      ctx.showProgress(false);

      if (notification.item.type === "MAKE AN OFFER") navigate("/offers-won");
      else if (notification.item.type === "BUY NOW") navigate("/item-bought");
      else navigate("/bids-won");
      return;
    } else if (
      notification.notificationType === "ITEM_BUY_NOW_INVOICE_GENERATED"
    ) {
      navigate("/item-bought-checkout");
      return;
    } else if (notification.notificationType === "DISMISSIVE") {
      return;
    }

    dispatch({
      type: authActionTypes.UpdateMiscData,
      payload: {
        listingStatus: listing,
      },
    });

    ctx.showProgress(false);
    navigate("/my-listing");
  };

  return (
    <>
      <div className={`row ${classes.bar}`}>
        <div className="col-auto">
          <div className={`d-flex ${classes.icons} `}>
            {false && <img src={unreadIcon} />}
            <img src={importantIcon} />
          </div>
        </div>
        <div className="col pl-0">
          <p className="purple-500">
            {data.notificationType === "ITEM_SCHEDULED" && (
              <>Listing is scheduled to go live</>
            )}
            {data.notificationType === "ITEM_MOVED_TO_LIVE" && (
              <>Listing is live</>
            )}
            {data.notificationType === "ITEM_RESERVE_MET" && (
              <>Reserve price met</>
            )}
            {data.notificationType === "ITEM_TIMED_OUT" && (
              <>Auction time has ended</>
            )}
            {data.notificationType === "ITEM_TIMED_OUT_UNSUCCESSFUL" && (
              <>Auction time has ended with no bids</>
            )}
            {data.notificationType === "ITEM_INVOICE_GENERATED" && (
              <>Auction invoice generated</>
            )}
            {data.notificationType === "ITEM_BUY_NOW_INVOICE_GENERATED" && (
              <>Item invoice generated</>
            )}
            {data.notificationType === "ITEM_PAYMENT_DONE" && (
              <>Auction payment is completed</>
            )}
            {data.notificationType === "ITEM_PAYMENT_RECEIVED_CONFIRM" && (
              <>Auction payment received. Proceed to item pickup.</>
            )}
            {data.notificationType === "ITEM_PICKUP_CONFIRMED" && (
              <>The seller has confirmed that you have received the item.</>
            )}
            {data.notificationType === "DISMISSIVE" && (
              <>{data.notification}.</>
            )}
          </p>
          <p>
            {data.item.title} - Lot # {data.item.customId}
          </p>
          <p>
            <span className="secondary-500">
              <ReactTimeAgo
                date={new Date(newDateObjectUTC(data.createdOn ? data.createdOn?.replaceAll("T", " ") + "GMT" : new Date()))}
                timeStyle="round"
              />
            </span>
          </p>

          <Link
            className="btn px-4 py-2 my-3 btn-primary-dark-w"
            onClick={(e) => {
              e.preventDefault();
              proceedTo(data);
            }}
          >
            {data.notificationType !== "DISMISSIVE" ? "View" : "Read"}
          </Link>
        </div>
        {false && (
          <div className="col-auto pl-0">
            <Link>
              <img src={optionsIcon} />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
export default GeneralNotification;
