import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import icon from "../../assets/images/up-down-arrow.svg";
import classes from "./DropDownButton.module.css";

const DropDownButton = ({
  className,
  children,
  onItemClicked = (v) => {},
  styles,
  selectedValue,
}) => {
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [label, setLabel] = useState();
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState();

  const [inputItems, setInputItems] = useState([]);

  useEffect(() => {
    if (selected) setLabel(selected);
    else if (selectedValue && inputItems?.length > 0) {
      setLabel(
        inputItems.filter((elem) => elem.props.value === selectedValue)?.shift()
          ?.props?.label
      );
    } else if (inputItems?.length > 0) setLabel(inputItems[0].props.label);

    if (inputItems?.length > 0) {
      let elements = [];
      inputItems.forEach((element) => {
        elements.push({
          ...element,
          props: {
            ...element.props,
            onClick: (l, v) => {
              setSelected(l);
              setLabel(l);
              onItemClicked(v);
            },
          },
        });
      });

      setItems(elements);
    }
  }, [inputItems]);

  useEffect(() => {
    if (children?.length > 0) {
      setInputItems(children);
    }
  }, [children]);

  const documentClickEventHandler = (e) => {
    if (!menuRef?.current?.contains(e.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", documentClickEventHandler);

    return () => {
      window.removeEventListener("click", documentClickEventHandler);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      className={`${className} d-inline-flex justify-content-space-between btn px-3 py-2 btn-secondary-dark-w ${classes.button}`}
      style={styles}
      onClick={(e) => {
        e.preventDefault();
        setShowMenu((show) => !show);
      }}
    >
      <span>{label}</span>
      <img src={icon} />
      <div className={`${classes.dropdown} ${showMenu ? classes.show : ""}`}>
        <ul>
          {items.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropDownButton;
