import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Breadcrumb.module.css";

const BreadcrumbItem = ({ title, to }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${classes.item} ${!to && classes.noPointer}`}
      onClick={() => {
        if (to) {
          navigate(to);
        }
      }}
    >
      {title}
    </div>
  );
};

export default BreadcrumbItem;
