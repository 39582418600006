import { Form, Formik, useField } from "formik";
import { Link } from "react-router-dom";
import TitleUnderline from "../../components/common/TitleUnderline";
import classes from "./Auction.module.css";
import * as Yup from "yup";

const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <textarea className="form-control text-area" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};

const Question = ({ onClose, onQuestionPosted }) => {
  return (
    <>
      <div className="col-md-12 mb-4">
        <h2 className={classes.title}>Ask this seller a question</h2>
        <TitleUnderline />
        <p className="mt-2 my-0">
          Please note that all questions are public.{" "}
          <strong>Do not include personal information</strong> you wouldn't want
          everyone to know.
        </p>
        <p className="mt-2 my-0">
          Please keep your question specific to the actual item or details of
          the auction. Users abusing the questions & answer section will be
          banned from using BidlyAuction. Harassment and abuse will not be tolerated.
        </p>
      </div>
      <div className="col-md-6 mb-4">
        <Formik
          initialValues={{
            question: "",
          }}
          validationSchema={Yup.object({
            question: Yup.string()
              .max(512, "Must be 512 characters or less")
              .required("Required"),
          })}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
          onSubmit={(values) => {onQuestionPosted(values)}}
        >
          {({
            handleSubmit,
            isSubmitting,
            errors,
            validateForm,
            setFieldValue,
          }) => (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <MyTextArea
                    name="question"
                    rows="6"
                    placeholder="Ask a question"
                  />
                </div>
                <div className="col-md-6 mt-2">
                  <button
                    className="btn btn-outline-primary px-5"
                    type="reset"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Close
                  </button>
                </div>
                <div className="col-md-6 mt-2">
                  <button
                    className="btn btn-primary-dark-w px-5"
                    type="submit"
                    onClick={async (e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    Submit My Question
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default Question;
