import React from "react";
import classes from "./dashboard.module.css";
import { Link } from "react-router-dom";

const LabelValue = ({ label, value, url }) => {
  return (
    <>
      <div className={classes.countsDiv}>
        <div className={classes.value}>
          <Link to={url}>{value}</Link>
        </div>
        <div className={classes.label}>{label}</div>
      </div>
    </>
  );
};

export default LabelValue;
