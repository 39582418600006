import React from "react";

const Label = ({ children, hightlighted = false, required = false }) => {
  return (
    <label className={`form-label ${hightlighted ? "highlighted" : ""}`}>
      {children} {required && <span className="color-red">*</span>}
    </label>
  );
};

export default Label;
