import React from "react";
import classes from "./CheckoutTable.module.css";

const CheckoutTable = ({
  tableHeader,
  mobileHeader,
  tableBody,
  mobileBody,
  tableFooter,
}) => {
  return (
    <div className={classes.main}>
      <div className={classes.tableContainer}>
        <div className="table-responsive">
          <table className="table">
            <thead>{tableHeader}</thead>
            <tbody>{tableBody}</tbody>
            <tfoot>{tableFooter}</tfoot>
          </table>
        </div>
      </div>

      <div className={classes.smallTableContainer}>
        <div className={classes.header}>{mobileHeader}</div>

        <div className={classes.panel}>{mobileBody}</div>
      </div>
    </div>
  );
};

export default CheckoutTable;
