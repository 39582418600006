import React from "react";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";

const BidlyLiveChat = () => {
  const handleLiveChatNewEvent = (event) => {
    console.log("LiveChatWidget.onNewEvent", event);
  };

  return (
    <>
      <LiveChatWidget
        license="15841512"
        visibility="minimized"
        onNewEvent={handleLiveChatNewEvent}
      />
    </>
  );
};

export default BidlyLiveChat;
