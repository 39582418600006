import React from "react";
import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "../../components/form/Form.module.css";
import formClasses from "./Search.module.css";
import { Field } from "formik";
import SearchMinMaxInput from "./SearchMinMaxInput";
import { Link } from "react-router-dom";
const SearchMinMax = ({
  items,
  name,
  handleOnChange,
  title,
  scroll = true,
  className = "",
  minHandler,
  maxHandler,
  minMaxChange,
  ...props
}) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${name}-content`}
          id={`${name}-header`}
        >
          <Typography>
            <span className={`text-blue-25 font-size-18 ${className}`}>
              {title}
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row px-3">
            <div className="col-md-4 pl-0 pr-0">
              <Field
                props={props}
                type="text"
                name="min"
                className={formClasses.formControl}
                component={SearchMinMaxInput}
                handleChange={minHandler}
                placeholder="Min"
              />
            </div>
            <div className="col-md-1 align-self-center">{"-"}</div>
            <div className="col-md-4 pl-0 pr-0">
              <Field
                type="text"
                name="max"
                handleChange={maxHandler}
                className={formClasses.formControl}
                component={SearchMinMaxInput}
                placeholder="Max"
                props={props}
              />
            </div>
            <div className={`col-md-1 align-self-center text-center py-2 ${formClasses.goTitle}`}><Link onClick={()=>minMaxChange()} className={formClasses.goTitle}>{"Go"}</Link></div>
            <div className="col-md-12 mt-2 px-0">
              {items.map(({ value, label }, index) => {
                return (
                  <div
                    key={index}
                    className="custom-control custom-radio mt-1 text-blue-25"
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      id={`${name}-${index}`}
                      name={name}
                      value={value}
                      onChange={(e) => handleOnChange(value, name, e.target)}
                    />
                    <label
                      htmlFor={`${name}-${index}`}
                      className="custom-control-label font-size-16"
                    >
                      {label.replace(/_/g, " ").toProperCase()}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SearchMinMax;
