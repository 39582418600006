import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import classes from "./ScheduleDemo.module.css";
import smallMap from "../../assets/images/small-map.svg";
import { BookedSlots } from "../../services/ScheduleDemoService";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment-timezone";
import { TIMEZONE_NAME } from "../../Constants";
import { StartTwoTone } from "@mui/icons-material";
import Alert from "../../components/UI/Alert";

const DemoTime = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const centralTime = moment().tz(TIMEZONE_NAME);

  const [timeSlots30Min, setTimeSlots30Min] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [currentTime, setCurrentTime] = useState(centralTime.format("hh:mm a"));

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    ctx.showProgress(true);
    const timeInterval = setInterval(
      () =>
        setCurrentTime(
          //moment(new Date()).tz("America/Chicago").format("hh:mm a")
          centralTime.format("hh:mm a")
        ),
      30000
    );

    var openTime30 = moment().tz(TIMEZONE_NAME);
    var openTime60 = openTime30;

    console.log("centralTime :: currentTime ::", centralTime.format("hh:mm a"), currentTime)

    if(centralTime.hour() < 9){
      openTime30 = openTime30.hours(9).minutes(0).format("hh:mm a");
      openTime60 = openTime60.hours(9).minutes(0).format("hh:mm a");
    }else if(centralTime.minute() < 30 && location.state.timeDuration == 30){
      openTime30 = openTime30.minutes(30).format("hh:mm a");
    }else{
      openTime30 = openTime30.hours(centralTime.hour()+1).minutes(0).format("hh:mm a");
      openTime60 = openTime60.hours(centralTime.hour()+1).minutes(0).format("hh:mm a");
    }

    console.log("Current Time :: opentTime30 - openTime60 ::: ", currentTime, openTime30, openTime60);

    //Data
    let x = {
      slotInterval30: 30,
      slotInterval60: 60,
      slotOpenTime30: openTime30,
      slotOpenTime60: openTime60,
      openTime: openTime30,
      closeTime: "05:00 PM",
    };

    // setSelectedTimeSlot("09:00 am");

    //Format the time
    let startTime = location.state.timeDuration == 30 ? moment(x.slotOpenTime30, "hh:mm a") : moment(x.slotOpenTime60, "hh:mm a");

    //Format the end time and the next day to it
    // let endTime = moment(x.closeTime, "hh:mm a").add(1, 'days');
    let endTime = moment(x.closeTime, "hh:mm a");

    //Times
    let allTimes = [];
    var index = 100;

    const demoDay = moment(location.state.dayForMeeting).tz(TIMEZONE_NAME);
    const nowDay = moment(
      new Date(
        centralTime.format("YYYY") +
          "-" +
          centralTime.format("MM") +
          "-" +
          centralTime.format("DD")
      )
    ).tz(TIMEZONE_NAME);

    console.log("demo day", demoDay);
    console.log("now day", nowDay);

    console.log("is same day?", nowDay.dayOfYear() == demoDay.dayOfYear());

    const nowTime = moment(currentTime, "hh:mm a");
    //Loop over the times - only pushes time with 30 minutes interval
    if (location.state.timeDuration == 30) {
      while (startTime < endTime) {
        if (nowDay.dayOfYear() == demoDay.dayOfYear()) {
          if (nowTime < startTime) {
            //Push times
            allTimes.push({ value: startTime.format("hh:mm a"), key: index++ });
          }
        } else {
          //Push times
          allTimes.push({ value: startTime.format("hh:mm a"), key: index++ });
        }

        //Add interval of 30 minutes
        startTime.add(x.slotInterval30, "minutes");

        // index += 1;
      }
    } else {
      while (startTime < endTime) {
        if (nowDay.dayOfYear() == demoDay.dayOfYear()) {
          if (nowTime < startTime) {
            //Push times
            allTimes.push({ value: startTime.format("hh:mm a"), key: index++ });
          }
        } else {
          //Push times
          allTimes.push({ value: startTime.format("hh:mm a"), key: index++ });
        }
        //Add interval of 60 minutes
        startTime.add(x.slotInterval60, "minutes");
        // index += 1;
      }
    }

    const demoRequest = {};
    demoRequest.dayOfMeeting = moment(location.state.dayForMeeting).format(
      "MM/DD/YYYY"
    );
    demoRequest.durationOfMeeting = location.state.timeDuration;

    let bookedSlots = [];
    BookedSlots(demoRequest)
      .then((resp) => {
        console.log(resp);
        ctx.showProgress(false);

        let filteredSlots = [];
        filteredSlots = allTimes.filter((el) => {
          return !resp.find((item) => {
            return el.value === item.slot;
          });
        });
        console.log(filteredSlots);
        setTimeSlots30Min(filteredSlots);
        setSelectedTimeSlot(filteredSlots[0].value);
      })
      .catch((error) => {
        console.log(error);
        ctx.showProgress(false);
      });

    return () => {
      if (timeInterval) clearInterval(timeInterval);
    };
  }, []);

  const schema = Yup.object().shape({
    selectedTimeSlot: Yup.string(),
  });

  const initialValue = {
    selectedTimeSlot: selectedTimeSlot,
    dayForMeeting: location.state.dayForMeeting,
    durationOfMeeting: location.state.timeDuration,
  };

  const handleDemoTime = (values) => {
    ctx.showProgress(false);

    let payload = {
      selectedTimeSlot: values.selectedTimeSlot,
      dayForMeeting: values.dayForMeeting,
      durationOfMeeting: values.durationOfMeeting,
    };
    console.log(payload);
    navigate("/demo-details", {
      state: {
        timeDuration: values.durationOfMeeting,
        dayForMeeting: values.dayForMeeting,
        selectedTimeSlot: selectedTimeSlot,
      },
    });
  };

  return (
    <>
      {/* {!isSeller(user.type) && (
      <Navigate to="/not-authorized" />
    )} */}

      <Layout>
        <div className="row mb-18 mr-1 ml-1">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 mt-7">
                <div className="row">
                  <div className="d-inline col-7">
                    <h2 className="pg-5 font-weight-bold purple-500">
                      Select a time slot for a demo
                    </h2>
                  </div>
                  <div className="d-inline col-5 text-right">
                    <h2
                      className=""
                      style={{ color: "#AFB0B5", fontSize: "24px" }}
                    >
                      Step: <b className="color-2">3/4</b>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="offset-lg-3 col-md-6 mt-3">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={initialValue}
                  onSubmit={(values) => {
                    handleDemoTime(values);
                  }}
                >
                  {({ handleSubmit, errors, setFieldValue, resetForm }) => (
                    <form id="advanceSearchForm">
                      <div className="mb-2">
                        <p className={`${classes.sellerName} mt-3 mb-2`}>
                          {moment(location.state.dayForMeeting).format("dddd")}
                        </p>
                        <p className={`${classes.sellerTimeLink} text-center`}>
                          {moment(location.state.dayForMeeting).format(
                            "MMMM D, YYYY"
                          )}
                        </p>
                        <p className="mb-3 text-center">
                          <img src={smallMap} alt="..." className="mr-3" />
                          USA, Central Time{" "}
                          <span className="text-uppercase">
                            ({currentTime})
                          </span>
                        </p>

                        <hr className={`${classes.sellerLine}`}></hr>
                        <p
                          className={`${classes.sellerTimeLink} text-center mb-2`}
                        >
                          Select a Time
                        </p>
                        <p className="mb-3 text-center">
                          Duration: {location.state.timeDuration} min
                        </p>
                      </div>
                      <div className="mb-2">
                        <div
                          className={` ${classes.timeSlotContainer} d-col justify-content-md-center justify-content-start align-items-center custom-scroll-self`}
                        >
                          <div role="group" aria-labelledby="my-radio-group">
                            {timeSlots30Min?.length > 0 &&
                              timeSlots30Min.map((timeSlot) => {
                                if (timeSlot.value === selectedTimeSlot) {
                                  return (
                                    <div
                                      key={timeSlot.key}
                                      className={`${classes.timeSlot} ${classes.selectedSlot} text-transform-upper`}
                                      id={timeSlot.key}
                                    >
                                      <h2
                                        className={classes.timeText}
                                        id={"slot" + timeSlot.key}
                                      >
                                        {timeSlot.value}
                                      </h2>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className={`${classes.timeSlot} text-transform-upper`}
                                      id={timeSlot.key}
                                      key={timeSlot.key}
                                      onClick={() => {
                                        setSelectedTimeSlot(timeSlot.value);
                                        console.log(
                                          "selected time slote :: " +
                                            selectedTimeSlot
                                        );
                                      }}
                                    >
                                      <h2
                                        className={classes.timeText}
                                        id={"slot" + timeSlot.key}
                                      >
                                        {timeSlot.value}
                                      </h2>
                                    </div>
                                  );
                                }
                              })}

                            {timeSlots30Min?.length === 0 && (
                              <Alert type="error">
                                There are no available timeslots for today.
                                Please select a different day.
                              </Alert>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="row">
                          <div className="d-inline p-3 col-md-6">
                            <Link
                              className="btn-block btn btn-outline-primary px-5 text-transform-upper"
                              type="button"
                              onClick={async (e) => {
                                e.preventDefault();
                                navigate("/demo-date", {
                                  state: {
                                    timeDuration: location.state.timeDuration,
                                  },
                                });
                              }}
                            >
                              back
                            </Link>
                          </div>
                          <div className="d-inline p-3 col-md-6">
                            {timeSlots30Min?.length > 0 && (
                              <Link
                                className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                                type="submit"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  handleSubmit(e);
                                }}
                              >
                                next
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </Layout>
    </>
  );
};

export default DemoTime;
