import React from "react";
import classes from "./dashboard.module.css";

const DetailedLink = ({ label, onClick, border = true }) => {
  const borderStyle = border ? {} : { border: "none" };

  return (
    <div
      style={borderStyle}
      className={classes.detailLink}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {label}
    </div>
  );
};

export default DetailedLink;
