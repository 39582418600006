import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import successIcon from "../../assets/images/regsuccess.svg";
import "../registration/RegisterModule.css";
import Layout from "../../layout/Layout";
import classes from "../search/Search.module.css";

const ScheduleDemoSuccess = () => {
  return (
    <Layout>
      <div className="row mt-10 mb-20">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <section className="section-onsale-product p-2">
            <div className="text-center mb-4">
              <img
                src={successIcon}
                className="notification-logo img-responsive"
                alt="envelop img"
              />
            </div>
            <h2 className="pg-4 text-center purple-500">
              Thank you for your interest in demo with BidlyAuction.
            </h2>
            <h3
              className={`nav-classic ${classes.searchSection} ${classes.asSearchTitle} mb-0 pb-2 font-size-25 section-title text-center`}
            ></h3>
            <p className="pg-2 text-center mt-5">
              Our representative will get in touch with you soon to confirm your
              demo with BidlyAuction. Video conference details will be shared with you
              on provided email address.
            </p>
          </section>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Layout>
  );
};

export default ScheduleDemoSuccess;
