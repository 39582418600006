import React from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";

const VerifyIdentityInstruction = () => {
  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-lg-3">
                <AccountMenu identityActive={true} />
              </div>
              <div className="offset-lg-1 col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <h2 class="h2 font-weight-bold">Please verify your ID</h2>
                  </div>
                  <div className="col-md-12">
                    <BHotrizontalProgress progress={33} />
                  </div>
                  <div className="col-md-12">
                    <p className="mt-5 mb-5 instructions-desc">
                      We require all bidders to have a valid US Driver License
                      ID to place a bid.
                    </p>
                    <p className="banner-bg rounded py-3 px-3 instructions-desc">
                      <h4 class="h4 font-weight-bold">
                        How do I Verify my ID?
                      </h4>
                      We make the process very simple. You can just upload a
                      picture of the front of your Driver License or state
                      issued ID. Click the "Start Verification" button below to
                      get started.
                    </p>
                  </div>
                  <div className="col-md-3">
                    <Link
                      className="btn-add-cart btn-add-cart__wide btn-primary transition-3d-hover"
                      to="/verify-identity"
                    >
                      START VERIFICATION
                    </Link>
                  </div>
                  <div className="col-md-12 mt-5">
                    <p className="mb-0 instructions-desc">
                      Having trouble or don't have a Driver License?{" "}
                    </p>
                    <p>
                      <Link to="/contact-us">Send us a message</Link>
                    </p>
                  </div>
                  {/* <div className="col-md-12">
                    <p className="instructions-desc">
                      Your privacy is important. We will not sell or use the
                      information on your id for marketing purpose outside of
                      BidlyAuction. <Link to="/privacy-policy">See our privacy policy.</Link>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifyIdentityInstruction;
