import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import envelop from "../../assets/images/envelop.svg";
import style from "./RegisterModule.css";
import Layout from "../../layout/Layout";
import classes from "../search/Search.module.css";

const UserNotified = () => {
  return (
    <Layout>
      <div className="container mt-1">
        <div className="row mt-10 mb-10">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <section className="section-onsale-product p-2">
              <div className="text-center mb-4">
                <img
                  src={envelop}
                  className="notification-logo img-responsive"
                  alt="envelop img"
                />
              </div>
              <h2 className="pg-4 font-weight-bold text-center purple-500">
                Please check your email!
              </h2>
              <h3
                className={`nav-classic ${classes.searchSection} ${classes.asSearchTitle} mb-0 pb-2 font-size-25 section-title text-center`}
              ></h3>
              <p className="pg-1 text-center mt-5 font-weight-bold">
                One last step before you are ready to find your deals.
              </p>
              <p className="text-center pg-1">
                A verification email has been sent to your provided email
                address. Please click the link in the email to automatically
                verify your email address and continue to the next step.
              </p>
              <p className="text-center pg-1">
                (If you did not receive this email after 5 minutes, please check
                your spam folder. If you still haven't received it please
                <Link to="/contact-us" className="link-v1"> Contact Us</Link>)
              </p>
            </section>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </Layout>
  );
};

export default UserNotified;
