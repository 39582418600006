import React from "react";
import classes from "./dashboard.module.css";

const DetailLinkDashboardCard = ({ card, children, height }) => {
  const cardHeightStyle = height ? { minHeight: height + "px" } : {};

  return (
    <div className={classes.detailedLinkDashboardCard} style={cardHeightStyle}>
      {card}
      {children}
    </div>
  );
};

export default DetailLinkDashboardCard;
