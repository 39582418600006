import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import classes from "../seller-listing/Listing.module.css";
import { useAuthContext } from "../../redux/auth-context";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Map from "../auction/Map";
import {
  formatUSD,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import Layout from "../../layout/Layout";
import { fetchBidderItemPickupDetails } from "../../services/AuctionService";
import ButtonsPanel from "./ButtonsPanel";
import buttonPanelClasses from "./MyBids.module.css";

const ItemPickupDetails = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [itemDetail, setItemDetail] = useState({});
  const [navigationFrom, setNavigationFrom] = useState();

  const fetchData = async (itemId) => {
    ctx.showProgress(true);
    try {
      const rs = await fetchBidderItemPickupDetails(itemId);
      console.log("item", rs);
      setItemDetail(rs);
      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    if (params.item) fetchData(params.item);
  }, [params.item]);

  useEffect(() => {
    if (location?.state) {
      setNavigationFrom(
        location?.state?.from === "item-checkout"
          ? "/item-bought-checkout"
          : "/" + location?.state?.from
      );
    }
  }, [location]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              {location?.state?.from === "item-checkout" && (
                <AccountMenu
                  myBuyNowActive={true}
                  subMenu={location?.state?.from}
                />
              )}

              {location?.state?.from !== "item-checkout" && (
                <AccountMenu
                  myBidsActive={true}
                  subMenu={location?.state?.from}
                />
              )}
            </div>

            {itemDetail && (
              <div className="col-lg-9 col-md-8">
                <h2 className="h4 font-weight-bold">Item Details</h2>

                <div className="mb-5">
                  <BHotrizontalProgress progress={33} />
                </div>

                <div className={`row ${classes.topRow}`}>
                  <div className="col-auto">
                    <div className="d-lg-none d-block mb-3">
                      <div className="row">
                        <div className="col-auto">
                          <span className={`${classes.label} text-capitalize`}>
                            {itemDetail?.saleType
                              ?.toLowerCase()
                              ?.replaceAll("_", " ")}
                          </span>
                        </div>
                        <div className="col-auto">
                          <span className={classes.label}>
                            Lot # {itemDetail.lot}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div
                        className={classes.imgContainer}
                        style={{
                          background: `url(${itemDetail.defaultImage})`,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-lg-block d-none">
                      <div className="row">
                        <div className="col-auto">
                          <span className={`${classes.label} text-capitalize`}>
                            {itemDetail?.saleType
                              ?.toLowerCase()
                              ?.replaceAll("_", " ")}
                          </span>
                        </div>
                        <div className="col-auto">
                          <span className={classes.label}>
                            Lot # {itemDetail.lot}
                          </span>
                        </div>
                      </div>
                    </div>

                    <h4>{itemDetail.title}</h4>

                    <div
                      className="instructions-desc"
                      dangerouslySetInnerHTML={{
                        __html: itemDetail.description,
                      }}
                    />

                    <div className="row">
                      <div className="col-auto my-2">
                        <div className={classes.label}>Start Date</div>
                        <div className={classes.value}>
                          {formattedUTCtoUSCentral(itemDetail.startDate)}
                        </div>
                      </div>
                      <div className="col-auto my-2">
                        <div className={classes.label}>End Date</div>
                        <div className={classes.value}>
                          {formattedUTCtoUSCentral(itemDetail.endDate)}
                        </div>
                      </div>
                      <div className="col-auto my-2">
                        <div className={classes.label}>Price</div>
                        <div className={classes.value}>
                          {formatUSD(itemDetail?.winningBid?.amount)}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-auto my-2">
                        <div className={classes.label}>Pickup Location</div>
                        <div className={classes.value}>
                          {itemDetail?.pickupLocation}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col my-2">
                        <Map
                          item={{
                            pickUpLat: itemDetail.pickupLocationLat,
                            pickUpLong: itemDetail.pickupLocationLong,
                            citiName:
                              itemDetail?.city?.value +
                              ", " +
                              itemDetail?.state?.value +
                              ", " +
                              itemDetail?.state?.valueTwo,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <ButtonsPanel>
                    <Link
                      to={navigationFrom}
                      className={`btn btn-secondary-dark-w ${buttonPanelClasses.item}`}
                    >
                      CANCEL
                    </Link>
                    <a
                      href="#"
                      className={`btn btn-primary-dark-w ${buttonPanelClasses.item}`}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/new-message/", {
                          state: {
                            subject:
                              "Lot# " +
                              itemDetail.lot +
                              ", " +
                              itemDetail.title,
                            to: itemDetail.sellerId,
                          },
                        });
                      }}
                    >
                      CONTACT SELLER
                    </a>
                  </ButtonsPanel>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ItemPickupDetails;
