import React from "react";
import classes from "./TitleUnderline.module.css";

const TitleUnderline = (props) => {
  const { type } = props;
  let progressColor =
    type === "secondary" ? classes.progressSecondary : classes.progress;
  return (
    <div className={classes.bar}>
      <div className={progressColor}></div>
    </div>
  );
};

export default TitleUnderline;
