const ViewOption = ({ onClick, viewOption, active}) => {
  return (
    <li className="nav-item">
      <a
        className={`nav-link ${active}`}
        id="pills-one-example1-tab"
        data-toggle="pill"
        href="#"
        role="tab"
        aria-controls="pills-one-example1"
        aria-selected="false"
        onClick={()=>onClick(viewOption)}
      >
        <div className="d-md-flex justify-content-md-center align-items-md-center">
          {viewOption != "list" ? (
            <i className="fa fa-th"></i>
          ) : (
            <i className="fa fa-list"></i>
          )}
        </div>
      </a>
    </li>
  );
};

export default ViewOption;
