import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import inboxNotificationIcon from "../assets/images/inboxNotificationIcon.svg";
import TopBarMyAccountMenu from "./TopBarMyAccountMenu";
import NotificationBar from "./notification/NotificationBar";
import MessageNotificationBar from "./notification/MessageNotificationBar";

const TopBar = () => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
    }),
    shallowEqual
  );

  return (
    <>
      <div className="u-header-topbar py-2 d-none d-xl-block">
        <div className="container">
          <div className="d-flex align-items-center">
            <div className="topbar-left">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                  <Link className="u-header-topbar__nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                  <Link className="u-header-topbar__nav-link" to="/help-and-support">
                    Help & Support
                  </Link>
                </li>
                <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                  <Link className="u-header-topbar__nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
                <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                  <Link className="u-header-topbar__nav-link" to="/become-seller">
                    Sell
                  </Link>
                </li>
                <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                  <Link className="u-header-topbar__nav-link" to="/buy">
                    Buy
                  </Link>
                </li>
              </ul>
            </div>

            <div className="topbar-right ml-auto">
              <ul className="list-inline mb-0">
                {!isAuthorized && (
                  <>
                    <li className="list-inline-item mr-0 u-header-topbar__nav-item">
                      <Link
                        className="btn px-4 py-1 btn-secondary-dark-w"
                        to="/account-type"
                      >
                        Register
                      </Link>
                    </li>

                    <li className="list-inline-item mr-0 u-header-topbar__nav-item">
                      <Link
                        className="btn px-4 py-1 btn-primary-dark-w"
                        to="/login"
                      >
                        Sign in
                      </Link>
                    </li>
                  </>
                )}

                {isAuthorized && (
                  <>
                    <TopBarMyAccountMenu />

                    <li className="list-inline-item mr-0 u-header-topbar__nav-item">
                      {window.screen.width >= 1200 && (
                        <MessageNotificationBar />
                      )}
                    </li>

                    <li className="list-inline-item mr-0 u-header-topbar__nav-item">
                      {window.screen.width >= 1200 && <NotificationBar />}
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {isAuthorized && (
        <div className="u-header-topbar py-2 d-none d-md-block d-xl-none">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="topbar-right ml-auto">
                <ul className="list-inline mb-0">
                  <TopBarMyAccountMenu />

                  <li className="list-inline-item mr-0 u-header-topbar__nav-item">
                    {window.screen.width >= 768 &&
                      window.screen.width <= 1199 && <MessageNotificationBar />}
                  </li>

                  <li className="list-inline-item mr-0 u-header-topbar__nav-item">
                    {window.screen.width >= 768 &&
                      window.screen.width <= 1199 && <NotificationBar />}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopBar;
