import React from "react";
import AlertCommon from "../errors/AlertCommon";
import successIcon from "../../assets/images/success-icon.svg";
import Layout from "../../layout/Layout";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

const CardPaymentStatusMessage = () => {
  const params = useLocation()?.state ?? {};

  useEffect(() => {
    console.log(params);
  }, [params]);

  const successDescription =
    "This confirms that we’ve just received your online payment. You can download Payment Receipt for future reference. Thank you for bidding on BidlyAuction.";
  const successHeading = "Thank your for your payment!";

  const errorDescription =
    "Your payment could not be completed. Please try again later.";
  const errorHeading = "Payment Unsuccessful";

  return (
    <>
      <Layout>
        <div className="container mt-10 mb-10">
          <div className="row">
            <div className="offset-xl-3 col-xl-6">
              <AlertCommon
                description={
                  params?.status === "success"
                    ? successDescription
                    : errorDescription
                }
                heading={
                  params?.status === "success" ? successHeading : errorHeading
                }
                image={params?.status === "success" ? successIcon : successIcon}
                hideButton={true}
              />

              <div className="d-flex gap-10 justify-content-center flex-column-reverse flex-sm-row">
                {params?.status === "success" && (
                  <>
                    <Link
                      to={params?.from?.url}
                      className="btn btn-secondary px-5"
                    >
                      Back to {params?.from?.type === "bids" ? "My Bids" : ""}
                      {params?.from?.type === "offers" ? "My Offers" : ""}
                      {params?.from?.type === "buy_now"
                        ? "My Bought Items"
                        : ""}
                    </Link>
                    <a
                      className="btn btn-primary-dark-w px-5"
                      href={params?.receiptURL}
                      target="_blank"
                      download={true}
                    >
                      Download Payment Receipt
                    </a>
                  </>
                )}

                {params?.status !== "success" && (
                  <Link
                    to={params?.from?.url}
                    className="btn btn-primary-dark-w px-5"
                  >
                    Try Again
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CardPaymentStatusMessage;
