import React from "react";
import { Link } from "react-router-dom";

const DropDownItem = ({ label, value, onClick }) => {
  return (
    <>
      <Link
        onClick={(e) => {
          e.preventDefault();
          if (onClick) onClick(label, value);
        }}
      >
        {label}
      </Link>
    </>
  );
};

export default DropDownItem;
