import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../redux/auth-context";
import { isSeller } from "../../services/Utilities";
import "./Sidebar.css";

const UserSidebar = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
      user: auth.user,
    }),
    shallowEqual
  );

  const linkClick = (clicked = () => {}) => {
    clicked();
    ctx.userSidebarOpen(false);
  };

  useEffect(() => {
    setMenuItems(menuItemData);
  }, []);

  let menuItemData = [
    {
      title: "My Account",
      show: true,
      onClick: () => {
        linkClick(() => navigate("/account-info"));
      },
    },
    {
      title: "My Bids",
      show: true,
      onClick: () => {
        linkClick(() => navigate("/open-bids"));
      },
    },
    {
      title: "Buy Now",
      show: true,
      onClick: () => {
        linkClick(() => navigate("/item-bought-cart"));
      },
    },
    {
      title: "Watchlist",
      show: true,
      onClick: () => {
        linkClick(() => navigate("/wish-list"));
      },
    },
    {
      title: "Messages",
      show: true,
      onClick: () => {
        linkClick(() => navigate("/inbox"));
      },
    },
    {
      title: "My Listings",
      show: isSeller(user?.type),
      onClick: () => {
        linkClick(() => navigate("/my-listing"));
      },
    },
    {
      bold: true,
      show: true,
      title: "SIGN OUT",
      onClick: () => {
        linkClick(() => navigate("/logout"));
      },
    },
  ];

  return (
    <Menu
      isOpen={ctx.isUserSidebarOpen}
      onClose={() => ctx.userSidebarOpen(false)}
    >
      <ul className="sidebar-nav-profile">
        <li>
          <a
            href="#"
            className="link-toggle"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {user.name}
          </a>
        </li>
      </ul>
      <ul className="sidebar-nav">
        {menuItems?.map((item, index) => {
          if (item.show) {
            const itemValue = (
              <li key={index}>
                <a
                  href="#"
                  className={`${item?.bold ? "font-weight-bold" : ""} ${
                    item?.subMenu ? "link-toggle" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (item?.onClick) item?.onClick();
                  }}
                >
                  {item.title}
                </a>
              </li>
            );

            return itemValue;
          }
        })}
      </ul>
    </Menu>
  );
};

export default UserSidebar;
