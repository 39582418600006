import Select from "react-select";
import Icon from "../../assets/images/select_icon.svg";

const SearchSelect = ({
  name,
  placeholder,
  options,
  onChange,
  defaultValue,
}) => {
  return (
    <Select
      placeholder={placeholder}
      name={name}
      components={{
        DropdownIndicator: () => <img className="mr-3" src={Icon} />,
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (styles) => ({
          ...styles,
          border: "1px solid #339966",
          borderRadius: "6px",
          minHeight: "45px",
        }),
      }}
      defaultTouch
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
    />
  );
};
export default SearchSelect;
