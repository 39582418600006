import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../../components/UI/HorizontalProgress";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Label from "../../../components/UI/Label";
import PlaceholderHeading from "../../credit-card/PlaceholderHeading";
import { scrollTo, youtubeVideoThumbnail } from "../../../services/Utilities";
import { wizardGenericErrorMsg } from "../../../Constants";
import ErrorMessage from "../../../components/UI/ErrorMessage";
import GalleryUploader, {
  GalleryItems,
} from "../../../components/uploader/GalleryUploader";
import YoutubeVideoFetcher, {
  VideoItems,
} from "../../../components/uploader/YoutubeVideoFetcher";
import FileUploader, {
  FileItems,
} from "../../../components/uploader/FileUploader";
import DualErrorMessage from "../../../components/UI/DualErrorMessage";
import { useRef } from "react";
import WizardButtons from "./WizardButtons";

const ListingMedia = (props) => {
  const { step, data, serverErrors, tabDisable } = props;
  const { saveTab, closeWizard } = props;

  const draftSubmitRef = useRef();
  const [isDraft, setIsDraft] = useState(false);

  const [initialImages, setInitialImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const [initialDocuments, setInitialDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [initialVideos, setInitialVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);

  const [removeUploadedImages, setRemoveUploadedImages] = useState([]);
  const [removeUploadedVideoURLs, setRemoveUploadedVideoURLs] = useState([]);
  const [removeUploadedDocuments, setRemoveUploadedDocuments] = useState([]);

  const tabInitValues = {
    documents: [],
    youtubeURLs: [],
    galleryImages: [],
    uploadedDocuments: [],
    uploadedGalleryImages: [],
    uploadedYoutubeURLs: [],
    removeUploadedDocuments: [],
    removeUploadedGalleryImages: [],
    removeUploadedYoutubeURLs: [],
  };

  const schema = Yup.object().shape({
    documents: Yup.array().optional(),
    youtubeURLs: Yup.array().optional(),
    galleryImages: Yup.array().when(
      "uploadedGalleryImages",
      (uploadedGalleryImages, schema) => {
        if (
          !uploadedGalleryImages ||
          Object.keys(uploadedGalleryImages)?.length == 0 ||
          uploadedGalleryImages?.filter(
            (g) => !removeUploadedImages?.includes(g.id)
          )?.length === 0
        ) {
          return schema
            .required("Media images are required")
            .min(1, "Media images are required");
        }

        return schema;
      }
    ),

    // galleryImages: Yup.array().when("uploadedGalleryImages", {
    //   is: "yes",
    //   then: Yup.array()
    //     .required("Media images are required")
    //     .min(1, "Media images are required"),
    // }),
  });

  const [tabData, setTabData] = useState(tabInitValues);
  const [tabErrors, setTabErrors] = useState(serverErrors);

  const populateTabData = (inputData) => {
    if (inputData && Object.values(inputData)?.length > 0) {
      setTabData((state) => {
        const tempState = {
          ...state,
          ...inputData,
          uploadedGalleryImages: inputData?.uploadedGalleryImages?.map((g) => {
            return { ...g, data_url: g.value, uploaded: true };
          }),
          uploadedYoutubeURLs: inputData?.uploadedYoutubeURLs?.map((g) => {
            return {
              ...g,
              uploaded: true,
              url: g.value,
              thumb: youtubeVideoThumbnail(g.value),
            };
          }),
          uploadedDocuments: inputData?.uploadedDocuments?.map((g) => {
            return { ...g, uploaded: true };
          }),
        };

        setSelectedImages(tempState.uploadedGalleryImages || []);
        setInitialImages(tempState.uploadedGalleryImages || []);

        setSelectedDocuments(tempState.uploadedDocuments || []);
        setInitialDocuments(tempState.uploadedDocuments || []);

        setSelectedVideos(tempState.uploadedYoutubeURLs || []);
        setInitialVideos(tempState.uploadedYoutubeURLs || []);

        console.log("data in media tab", tempState);

        return tempState;
      });
    }
  };

  useEffect(() => {
    populateTabData(data);
  }, []);

  useEffect(() => {
    populateTabData(data);
  }, [data]);

  useEffect(() => {
    setTabErrors(serverErrors);
  }, [serverErrors]);

  return (
    <>
      <PlaceholderHeading title="Add Media">
        <button
          disabled={tabDisable}
          className="btn-block btn btn-outline-primary px-4"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            closeWizard();
          }}
        >
          Close
        </button>
      </PlaceholderHeading>
      <div className="mt-3 mb-2">
        <BHotrizontalProgress progress={33} />
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mt-5 instructions-desc">
            Add pictures, videos, and documents for your item
          </p>

          <div className="row" id="pageError">
            <div className="col-md-12">
              {tabErrors && tabErrors.message && (
                <div className="mb-4">
                  <ErrorMessage position="top">
                    {tabErrors.message}
                  </ErrorMessage>
                </div>
              )}
            </div>
          </div>

          <fieldset disabled={tabDisable}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={tabData}
              validationSchema={schema}
              onSubmit={(values) => {
                console.log("submitted values", values);

                // temporary solution: will be fixed in next release

                saveTab(
                  step,
                  {
                    ...tabData,
                    ...values,
                    // galleryImages: values.galleryImages.filter(
                    //   (m) => !m.uploaded
                    // ),
                    removeImages: values.removeUploadedGalleryImages,
                    removeYoutubeUrls: values.removeUploadedYoutubeURLs,
                    removeDocuments: values.removeUploadedDocuments,
                  },
                  isDraft
                );
              }}
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                validateForm,
              }) => (
                <form id="passportInfoForm">
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Images
                        </Label>
                        <p className="mt-2 mb-2">
                          Upload images of your item from your computer or
                          device. This first image will be your main image.
                        </p>

                        <div className="mt-2 mb-2">
                          <GalleryUploader
                            label={"Add Images"}
                            errorEvent={(errors) => {
                              // setReceiptError(errors);
                            }}
                            fileSelectedEvent={(event, file) => {
                              // setReceipt(file.data_url.split(",")[1]);
                              event.proceed(file);
                              // event.cancel();
                            }}
                            showUploaded={false}
                            initialImages={initialImages}
                            listChanged={(list) => {
                              console.log("list changed", list);
                              setSelectedImages(list);
                              let changedList = [];
                              list?.map((i) => {
                                if (!i.uploaded) changedList.push({ ...i });
                              });

                              setFieldValue("galleryImages", changedList);
                            }}
                          />

                          <DualErrorMessage
                            fieldName="galleryImages"
                            serverError={
                              serverErrors && serverErrors.galleryImages
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {selectedImages &&
                    Object.keys(selectedImages)?.length > 0 && (
                      <div className="row">
                        <div className="col-12 mb-4">
                          <GalleryItems
                            items={selectedImages}
                            removeClicked={(index, meta) => {
                              console.log("gallery image removed", index, meta);

                              let rem = [...selectedImages];
                              rem.splice(index, 1);
                              setSelectedImages([...rem]);
                              setInitialImages([...rem]);

                              if (meta.uploaded) {
                                setFieldValue("removeUploadedGalleryImages", [
                                  ...removeUploadedImages,
                                  meta.id,
                                ]);

                                setRemoveUploadedImages((prev) => [
                                  ...prev,
                                  meta.id,
                                ]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}

                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted>YouTube Videos</Label>
                        <p className="mt-2 mb-2">
                          Link to a YouTube video of your item. If you don't
                          have a YouTube account, we can upload it to our BidlyAuction
                          account. Please contact us to arrange this.
                        </p>

                        <YoutubeVideoFetcher
                          showList={false}
                          initialList={initialVideos}
                          listChanged={(list) => {
                            console.log("list changed", list);
                            setSelectedVideos(list);
                            let changedList = [];
                            list?.map((i) => {
                              if (!i.uploaded) changedList.push({ ...i });
                            });
                            setFieldValue("youtubeURLs", changedList);
                          }}
                        />

                        <DualErrorMessage
                          fieldName="youtubeURLs"
                          serverError={serverErrors && serverErrors.youtubeURLs}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 mb-4">
                      <VideoItems
                        items={selectedVideos}
                        removeClicked={(index, meta) => {
                          console.log("video url removed", index, meta);

                          let rem = [...selectedVideos];
                          rem.splice(index, 1);
                          setSelectedVideos([...rem]);
                          setInitialVideos([...rem]);

                          if (meta.uploaded) {
                            setFieldValue("removeUploadedYoutubeURLs", [
                              ...removeUploadedVideoURLs,
                              meta.id,
                            ]);

                            setRemoveUploadedVideoURLs((prev) => [
                              ...prev,
                              meta.id,
                            ]);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="js-form-message mb-4">
                        <Label hightlighted>Add Documents</Label>
                        <p className="mt-2 mb-2">
                          Upload any documentation on your item you think would
                          help a bidder make a decision on your item. **PDF
                          ONLY**
                        </p>

                        <div className="mt-2 mb-2">
                          <FileUploader
                            errorEvent={(errors) => {}}
                            showUploaded={false}
                            initialFiles={initialDocuments}
                            listChanged={(list) => {
                              console.log("list changed", list);
                              setSelectedDocuments(list);
                              let changedList = [];
                              list?.map((i) => {
                                if (!i.uploaded) changedList.push({ ...i });
                              });
                              setFieldValue("documents", changedList);
                            }}
                          />

                          <DualErrorMessage
                            fieldName="documents"
                            serverError={serverErrors && serverErrors.documents}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 mb-4">
                      <FileItems
                        items={selectedDocuments}
                        removeClicked={(index, meta) => {
                          console.log("file removed", index, meta);

                          let rem = [...selectedDocuments];
                          rem.splice(index, 1);
                          setSelectedDocuments([...rem]);
                          setInitialDocuments([...rem]);

                          if (meta.uploaded) {
                            setFieldValue("removeUploadedDocuments", [
                              ...removeUploadedDocuments,
                              meta.id,
                            ]);

                            setRemoveUploadedDocuments((prev) => [
                              ...prev,
                              meta.id,
                            ]);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="js-form-message mb-4 row">
                    <div className="col-12">
                      <WizardButtons>
                        <button
                          className="btn btn-primary-dark-w px-5"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(false);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save and Continue
                        </button>

                        <button
                          ref={draftSubmitRef}
                          className="btn btn-outline-primary px-5"
                          type="button"
                          onClick={async (e) => {
                            e.preventDefault();
                            setIsDraft(true);
                            setTabErrors({});
                            let res = await validateForm();
                            if (res && Object.values(res)?.length > 0) {
                              scrollTo("pageError");
                              setTabErrors({ message: wizardGenericErrorMsg });
                            }
                            handleSubmit(e);
                          }}
                        >
                          Save Draft
                        </button>
                      </WizardButtons>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default ListingMedia;
