import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function SellerTermsAndConditions({ show, handleClose = () => {} }) {
  return (
    <>
      <Link className="link-v1" onClick={handleClose}>
        user agreement.{" "}
      </Link>
      <Modal
        size="xl"
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h4 className="mb-0">
            TERMS AND CONDITIONS FOR SALES ON BIDLY AUCTION
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 text-left ct-u-padding0">
                <p>
                  <b>
                    THE INFORMATION CONTAINED HEREIN SETS FORTH YOUR RIGHTS AND
                    OBLIGATIONS WITH RESPECT TO THE BID(S) CONTEMPLATED FOR
                    HEREBY. THESE TERMS REQUIRE THE USE OF ARBITRATION (ON AN
                    INDIVIDUAL BASIS ONLY; I.E., CASE CONSOLIDATIONS AND
                    CLASS-ACTIONS ARE NOT PERMITTED) IN ORDER TO RESOLVE
                    DISPUTES. PLEASE REVIEW THIS DOCUMENT IN ITS ENTIRETY BEFORE
                    ACCEPTING ANY BID HEREUNDER IN ORDER TO CONFIRM YOUR
                    ACCEPTANCE HEREOF. YOU MAY NOT SELL OR OFFER PRODUCTS OR
                    SERVICES FROM THIS WEBSITE UNLESS AND UNTIL YOU: (A) AGREE
                    TO THESE TERMS AND CONDITIONS IN THEIR ENTIRETY; (B) ARE AT
                    LEAST 18 YEARS OLD; (C) HAVE THE LEGAL AUTHORITY TO BIND THE
                    ORGANIZATION THAT YOU REPRESENT, IF ANY, TO THESE TERMS AND
                    CONDITIONS; AND (D) ARE NOT PROHIBITED FROM ACCESSING OR
                    USING THIS WEBSITE OR ANY OF THIS WEBSITE'S CONTENTS, GOODS,
                    OR SERVICES BY ANY APPLICABLE LAW, RULE, OR REGULATION.
                  </b>
                </p>
                <ul style={{ listStyle: "decimal", marginLeft: "3%" }}>
                  <li>
                    <p>
                      <b>Applicability of Terms and Conditions. </b>
                      These terms and conditions (these "<u>Terms</u>") shall
                      apply to your marketing or selling of products and related
                      services through bidlyauction.com (the "<u>Site</u>").
                      These Terms are subject to change at any time without
                      prior written notice by <b>BIDLY AUCTION INC.</b>{" "}
                      (referred to herein as either “Company,” “we,” “us,” or
                      “our”). The most recent version of these Terms shall be
                      posted for your review at any time on the Site. Please
                      review these Terms in their entirety prior to engaging in
                      any transaction on the Site. Your continued use of the
                      Site after any posting of updated Terms (which shall be
                      dated as of their most recent update) shall constitute
                      your acceptance of and agreement to any changes therein
                      made.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Online Bids. </b>
                      When placing a product on our Site, you are effectively
                      offering to sell whatever products and services you list.
                      The Sellers reserve the right to accept or reject any bid
                      at their discretion. The Seller will only accept or reject
                      an order in its entirety. Should the Seller elect to
                      accept an offer, the successful bidder will receive a
                      confirming email. Notwithstanding, the Seller reserves the
                      right to cancel any order once accepted by the Seller (as
                      evidenced by a confirming email) at any time in their sole
                      discretion. Additionally, the Seller has the option of
                      canceling an order (in its entirety only) at any time
                      before our having sent the successful bidder the
                      confirming email referenced herein. Seller reserves the
                      right to cancel any sale if Seller deems Bidder` unable to
                      pay for any products.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Bidding Terms. </b>
                      If a bid is submitted within the final two minutes of an
                      auction, the auction will extend for an additional two
                      minutes from the time of that bid. This extension will
                      continue until a two-minute period passes with no new
                      bids. The winning bidder will be the one with the highest
                      offer when the auction concludes, regardless of whether
                      this bid exceeds the minimum bid increment or not.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Payment Terms. </b>
                      Bidder will be responsible for the prices stated at the
                      time of the transaction (which occurs when Seller accepts
                      the bid), as well as any: (i) sales, use, excise, and
                      related taxes; and (ii) shipping and handling charges.
                      Payment may only be made with a valid credit, debit card,
                      wire transfer, or per the payment terms listed on the
                      auction lot items.
                    </p>
                    <p>
                      We strictly enforce a policy against bidders who fail to
                      fulfill their bid commitments. Should a seller confirm
                      that they've made all reasonable efforts to reach out to a
                      bidder without a response, we will proceed to terminate
                      the bidder's participation on our platform. This action
                      will occur 10 days following the issuance of a non-payment
                      notification from us. During this period, the bidder's
                      account will be inactivated. If the bidder settles their
                      payment within this timeframe, their account will be
                      restored but noted with a violation. Should such a payment
                      delinquency recur, the bidder will face permanent
                      exclusion.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Shipping Information. </b>
                      Bidder will be responsible for all associated shipping &
                      handling charges. Seller retains the right and title to
                      the products sold to Bidder until Seller is paid in full
                      for the products. Bidder shall obtain the right and title
                      to the products upon payment to Seller of the purchase
                      price and any taxes, or other charges and receipt of the
                      item. At the time of purchase, the risk of loss,
                      including, but not limited to the risk of loss, theft,
                      damage or destruction of the products, transfers to Bidder
                      F.O.B.
                    </p>
                    <p>
                      If an item will be designated for a physical pick up from
                      the location of Seller or Bidly Auction, the Bidder shall
                      be required to pick up the item within 7 days from payment
                      of the item. If paid items remain in possession of the
                      Seller or Bidly Auction then the purchased items shall be
                      considered abandoned.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Privacy Policy and Website Terms of Use. </b>
                      Please review our Privacy Policy and Website Terms of Use,
                      which can be found at Bidly Auction’s website. The Privacy
                      Policy governs our processing of all personal information
                      that we may collect from any person through the use of our
                      Site. The Website Terms of Use governs your use of our
                      Site in general.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Intellectual Property. </b>
                      The content on this Website, such as texts, documents,
                      products, logos, graphics, images, auctions, and services,
                      is exclusively owned and copyrighted by Bidly Auction
                      and/or its Third Party Providers. No part of this site's
                      content is permitted to be copied, reproduced,
                      distributed, republished, downloaded, displayed, posted,
                      or transmitted in any form or by any means, including but
                      not limited to, electronic, mechanical, photocopying,
                      recording, or otherwise, without the explicit written
                      consent of Bidly Auction or the relevant Third Party
                      Provider. Additionally, no materials from this Website may
                      be "mirrored" on any other server or website without the
                      express written permission of Bidly Auction. Registration
                      on this site requires the provision of your actual name
                      and a valid email address.
                    </p>
                    <p>
                      Sellers reserves the right to discontinue deliveries of
                      any products, the manufacture, sale or use of which would,
                      in Seller’s opinion, infringe upon any U.S. patent, trade
                      mark or design now or hereinafter issued, registered, or
                      existing and under which Seller is not licensed.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        Representations & Warranties (R&Ws); Disclaimers;
                        Limitations on Liability.{" "}
                      </b>
                      <ol type="a">
                        <li>
                          <b>Seller’s R&Ws. </b>You represent and warrant to us
                          as follows: (i) that you have the right to enter any
                          transaction contemplated for hereby without violating
                          these Terms, any applicable law, rule, or regulation,
                          and/or any agreement with, or rights of, any third
                          party; (ii) that you will sell the goods and services
                          provided hereunder exactly as authorized and never in
                          any way that would violate any applicable law or third
                          party right of any kind; and (iii) that you are
                          marketing or selling the goods or services solely for
                          yourself and not a third party.
                        </li>
                        <li>
                          <b>
                            (b) Manufacturer’s Warranty and Company’s
                            Disclaimers. We do not manufacture (or direct the
                            manufacture of) any of the goods or services offered
                            on our Site in any way. The availability on our Site
                            of goods and services does not constitute an
                            affiliation with or endorsement of any of the goods
                            or services or their manufacturer. As such, subject
                            to applicable law, the Sellers are providing the
                            goods and services to the Bidder “as is” without
                            express or implied warranties of any kind (including
                            without limitation any: (i) warranty of
                            merchantability; (ii) warranty of fitness for a
                            particular purpose; (iii) warranty of title; or (iv)
                            warranty against infringement of intellectual
                            property rights of a third party, whether express or
                            implied by law, course of dealing, course of
                            performance, usage or trade, or otherwise). They may
                            however be covered by the manufacturer’s warranty as
                            detailed in any manufacturer’s website. The Bidder
                            can avail yourself of any of the manufacturer’s
                            warranties by following the instructions provided in
                            their warranty agreements. You acknowledge and agree
                            that under no circumstances shall we be liable for
                            any breach of the manufacturer’s warranty claims
                            and/or for any loss or damages that may arise out of
                            the manufacturer’s failure to honor its warranty
                            obligations to you.
                          </b>
                        </li>
                        <li>
                          <b>
                            (c) Liability Cap. UNDER NO CIRCUMSTANCES WILL THE
                            COMPANY’S OBLIGATION OR LIABILITY UNDER THIS
                            AGREEMENT EXCEED THE SELLING PRICE YOU RECEIVE ON
                            THE SITE FOR ANY GOODS OR SERVICES. ADDITIONALLY,
                            UNDER NO CIRCUMSTANCES WILL WE BE LIABLE FOR ANY
                            LOSS OF USE, DATA, BUSINESS, GOODWILL, REPUTATION,
                            OR REVENUE, AND/OR ANY CONSEQUENTIAL, INCIDENTAL,
                            SPECIAL, OR PUNITIVE DAMAGES OR OTHER DIRECT OR
                            INDIRECT LOSSES OF ANY KIND.
                          </b>
                        </li>
                      </ol>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Third Party Beneficiaries. </b>
                      These Terms are for your sole benefit and nothing herein,
                      express or implied, is intended to or shall confer upon
                      any other person or entity any legal or equitable right,
                      benefit, or remedy of any nature whatsoever under or by
                      reason of these Terms.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Force Majeure. </b>
                      Company shall not be held responsible for delays or
                      non-performance caused by activities or factors beyond its
                      reasonable control, including without limitation, war,
                      weather, strikes, floods, lockouts, fires, pandemics,
                      epidemics, local disease outbreaks, public health
                      emergencies, acts of God, terrorism, and/or delivery,
                      vendor, supplier, or other third party delays,
                      non-performance, or failures of any kind.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Assignment. </b>
                      Company may assign or otherwise transfer any or all of its
                      rights or obligations hereunder, in whole or in part, to
                      any third party in its sole discretion. You may not assign
                      any of your rights or delegate any of your duties
                      hereunder at any time without our prior written consent in
                      each instance, and any attempt to do so shall be null and
                      void.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Partial Invalidity</b>
                      In the event that any part or portion of these Terms is
                      deemed to be invalid, illegal, or unenforceable, the
                      remaining provisions shall continue in full force and
                      effect.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Governing Law/Binding Arbitration. </b>
                      <ol type="a">
                        <li>
                          <b>Governing Law. </b>These Terms shall be governed by
                          the laws of the State of Illinois without regard to
                          its conflict of laws principles.
                        </li>
                        <li>
                          <b>
                            (b) Binding Arbitration. Subject to the subsection
                            below and all applicable laws, you are agreeing to
                            give up: (i) your right to litigate any claims that
                            may arise hereunder in court or before a jury; and
                            (ii) your right to consolidate any claim and/or
                            participate in any class-action claim that may arise
                            hereunder in any manner or forum. Instead, any
                            claim, dispute, or controversy of any kind or nature
                            arising hereunder which cannot be amicably resolved
                            by us shall be solely and finally settled by
                            arbitration administered by the American Arbitration
                            Association in accordance with its commercial
                            arbitration rules. Judgment on the award rendered by
                            the arbitrator(s) may be entered in any court having
                            jurisdiction thereof. The arbitration shall take
                            place before a panel of arbitrators sitting in
                            Illinois, DuPage County. The language of the
                            arbitration shall be English. The arbitrators will
                            be bound to adjudicate all disputes in accordance
                            with the laws of the State of Illinois. The decision
                            of the arbitrator(s) shall be in writing with
                            written findings of fact and shall be final and
                            binding on the parties. Company shall bear all of
                            its own costs, as well as your reasonable outside
                            attorneys’ fees, actually incurred in connection
                            with any such arbitration proceedings; provided,
                            however, that if we are the prevailing party, we
                            shall be entitled to reimbursement for those amounts
                            that were expended on your behalf. With respect to
                            any arbitration hereunder, as stated above, you
                            hereby expressly waive any right to consolidate any
                            claim and/or participate in any class-action claim
                            of any kind or nature. This Section 13 provides your
                            sole recourse for the settlement of any disputes
                            arising out of, in connection with, or related to
                            these Terms. If any provision of this clause is
                            found unenforceable, such unenforceable provision
                            will be removed and the remaining terms will be
                            enforced.
                          </b>
                        </li>
                      </ol>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>No Waivers. </b>
                      Our failure to enforce any of our rights hereunder will
                      not constitute a waiver of our right to make such
                      enforcement in the future, subject to applicable law.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Notices. </b>
                      We may provide notices hereunder to you by: (i) email;
                      (ii) regular mail; or (iii) posting them on the Site. You
                      shall be responsible for ensuring that you have provided
                      us with your current email and mailing addresses. You can
                      contact us at any time by any of the following means: (i)
                      personal delivery, overnight courier, or registered or
                      certified mail to: Bidly Auction – 2747 Belvidere Rd,
                      Waukegan, IL 60085.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Entire Agreement. </b>
                      These Seller’s Terms and Conditions of Sale are the sole
                      and exclusive statement of the parties’ understanding and
                      agreement with respect to the transactions contemplated by
                      this sale, notwithstanding any other terms that might be
                      contained in any purchase order from Bidder or submitted
                      to Seller. These Terms and Conditions of Sale constitute
                      the entire agreement between the parties regarding the
                      subject matter hereof and can only be modified or changed
                      in writing and signed by authorized representatives of
                      both parties.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              className="btn-add-cart btn-add-cart__wide btn-primary transition-3d-hover fl"
              style={{ width: 100 }}
              variant="default"
              onClick={handleClose}
            >
              CLOSE
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default SellerTermsAndConditions;
