import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import icon from "../../assets/images/collapsable-up.svg";
import classes from "./LotsTable.module.css";

const LotsTable = ({
  label,
  tableHeader,
  tableBody,
  noRecordTableBody,
  mobileHeader,
  mobileBody,
  noRecordMobileBody,
}) => {
  const [show, setShow] = useState(true);

  return (
    <div className={classes.main}>
      {label && (
        <div
          className={`${classes.header} ${show ? classes.close : ""}`}
          onClick={(e) => {
            e.preventDefault();
            setShow((show) => !show);
          }}
        >
          <span>{label}</span>
          <span>
            <img src={icon} />
          </span>
        </div>
      )}
      {show && (
        <>
          <div className={classes.tableContainer}>
            <div className="table-responsive">
              <table className="table">
                <thead>{tableHeader}</thead>
                <tbody>{tableBody ?? noRecordTableBody}</tbody>
              </table>
            </div>
          </div>

          <div className={classes.smallTableContainer}>
            {mobileHeader && (
              <div className={classes.header}>{mobileHeader}</div>
            )}

            <div className={classes.panel}>
              {mobileBody ?? noRecordMobileBody}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LotsTable;
