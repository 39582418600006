import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import Reaptcha from "reaptcha";
import { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { sendVerificationEmail } from "../../services/ForgotPasswordService";
import { deflateErrors, isBadRequest, isJSON } from "../../services/Utilities";
import ErrorMessage from "../../components/UI/ErrorMessage";
import { useAuthContext } from "../../redux/auth-context";
import forgotpsw from  "../../assets/images/forgotpsw.svg";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import  style from "../login/LoginModule.css";
import classes from "../search/Search.module.css";

const ForgotPasswordCaptcha = () => {
  const [serverErrors, setServerErrors] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [captchaToken, setCaptchaToken] = useState();
  const [captchaError, setCaptchaError] = useState("");

  const ctx = useAuthContext();

  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Not a valid email")
      .required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha("register");
      setCaptchaToken(token);
      console.log(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  return (
      <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="offset-lg-4 offset-md-4 col-lg-4 col-md-4 col-sm-12">
            <section className="section-onsale-product ">
              <div className="text-center mb-4">
                <img src={forgotpsw} className="notification-logo img-responsive" alt="forgotpsw img"/>
              </div>
              <h2 className="pg-3 font-weight-bold text-center purple-500">Forgot Your Password?</h2>
              <h3 className={`nav-classic ${classes.searchSection} ${classes.asSearchTitle} mb-0 pb-2 font-size-25 section-title text-center`}></h3>
              <div className="col-md-12">
                  <p className="pg-3 text-center mt-5">Enter the email address associated with your BidlyAuction account.</p>
              </div>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  setMessage(null);
                  debugger;
                  ctx.showProgress(true);

                  setCaptchaError("");
                  sendVerificationEmail(values)
                    .then((res) => {
                      ctx.showProgress(false);
                      setServerErrors(res.errors ?? {});
                      // setMessage(res.message ?? "Email sent");
                      navigate("/verify-email", {
                        state: {
                          from: "forgotpassword",
                          message: res.message ?? "Email sent",
                          data: {
                            email: values.email,
                          },
                        },
                      });
                      setSubmitting(false);
                      resetForm();
                    })
                    .catch((e) => {
                      console.log(e);
                      ctx.showProgress(false);
                      if (!isJSON(e.message)) {
                        navigate("/error");
                        return;
                      }
                      debugger;
                      const json = JSON.parse(e.message);

                      if (json.status === "BAD_REQUEST") {
                        if (json.data.status === "NOT_FOUND") {
                          setServerErrors({ email: json.data.message });
                          console.log(json.data.message);
                        }
                      }
                      setSubmitting(false);
                    });
                }}
              >
                {({ handleSubmit }) => (
                    <form>
                    <div className="js-form-message mb-4">
                      <Field
                        className="form-control"
                        type="text"
                        name="email"
                        component={ZInputComponent}
                        placeholder="Enter Email Address"
                        label="Email Address"
                        serverError={serverErrors && serverErrors.email}
                      />
                    </div>
                    <div className="js-form-message mb-4">
                          {captchaError && (
                            <ErrorMessage>{captchaError}</ErrorMessage>
                          )}
                    </div>

                    <div className="row">
                      <div className="d-inline p-3 col-md-6">
                        <Link to="/login" className="btn-block btn btn-outline-primary px-5 text-transform-upper">
                          Back to Login
                        </Link>
                      </div>
                      <div className="d-inline p-3 col-md-6">
                        <Link className="btn-block btn btn-primary-dark-w px-5 text-transform-upper" type="submit"
                          disabled={!executeRecaptcha}
                          onClick={async (e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                        >
                          {executeRecaptcha ? "Submit" : "Please Wait..."}
                        </Link>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <p className="pg-1 text-center mt-4">
                          If you no longer use the email address associated with <br></br> 
                          your BidlyAuction account, you may contact <Link  to="/contact-us" className="link-v1">Customer Service</Link><br></br>
                          for help restoring access to your account.
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
          </section>
      </div>
    </div >
    </div>
    </Layout>
  );
};

export default ForgotPasswordCaptcha;
