import React, { useState, useEffect } from "react";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import { isBadRequest } from "../../services/Utilities";
import { verifyLink } from "../../services/ForgotPasswordService";

const VerifyLinkFP = () => {
  const navigate = useNavigate();
  const [linkVerified, setLinkVerified] = useState(false);
  const [searchParams] = useSearchParams();

  const link = searchParams.get("emailParam");

  useEffect(() => {
    setLinkVerified(false);
    verifyLink({ link: link })
      .then((data) => {
        if (data.status === "EXPIRED") {
          navigate("/expired");
        } else if (data.status === "VALID") {
          setLinkVerified(true);
          navigate("/reset-password", {state:{email: data.email}});
        }
      })
      .catch((e) => {
        if (isBadRequest(e.message)) navigate("/error");
      });

    console.log("on link verification");
  }, [link]);

  useEffect(() => {
    console.log("linkVerified", linkVerified);
  }, [linkVerified]);

  return (
    <>
      {!linkVerified && <div>Verifing ...</div>}
    </>
  );
};

export default VerifyLinkFP;
