import React from "react";
import classes from "./dashboard.module.css";

const ListingInfoBlock = ({ label, value }) => {
  return (
    <div className={classes.listingInfoBlock}>
      <span className={classes.label}>{label}</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};

export default ListingInfoBlock;
