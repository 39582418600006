import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { BIDS_OPEN_URL, OFFERS_OPEN_URL } from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { fetchMyBids } from "../../services/MyBidsService";
import {
  formatUSD,
  formattedUTCtoUSCentral,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import tableClasses from "../../components/table/LotsTable.module.css";
import ButtonsPanel from "./ButtonsPanel";
import DropDownItem from "../../components/button/DropDownItem";
import DropDownButton from "../../components/button/DropDownButton";
import classes from "./MyBids.module.css";
import LotsTable from "../../components/table/LotsTable";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";

const OpenOffers = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [bids, setBids] = useState([]);

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();

  const fetchOpenOffers = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "?" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const rs = await fetchMyBids(OFFERS_OPEN_URL + pagingParams);
      console.log("offers", rs);

      const content = rs?.content;
      const items = [];
      content?.map((c) => {
        let m = {
          ...c,
        };

        m.auction.endedOn = m.auction.endedOn
          ? formattedUTCtoUSCentral(m.auction.endedOn)
          : m.auction.endedOn;

        items.push(m);
      });

      setBids(items);

      setTotal(rs.totalElements);
      setTotalPages(rs.totalPages);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchOpenOffers();
  }, []);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchOpenOffers();
  }, [pageSize, currentPage]);

  const bidsTableHeader = (
    <tr>
      <th>Asset Description</th>
      <th>Auction End Time</th>
      <th>My Offer</th>
      <th>Auction Status</th>
    </tr>
  );

  const bidsMobileHeader = (
    <>
      <div className={tableClasses.selection}></div>
      <div className={tableClasses.label}>Item Description</div>
    </>
  );

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu myBidsActive={true} subMenu="open-offers" />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <h2 className="h4 font-weight-bold">My Bids - Open Offers</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <ButtonsPanel>
                <DropDownButton
                  className={classes.item}
                  onItemClicked={(v) => {
                    // setSelectedInvoices([]);
                    // if (v === "ALL") {
                    //   setWonBids(allWonBids);
                    // } else {
                    //   const items = allWonBids.filter((item) =>
                    //     item.auction.paymentMethods.includes(v)
                    //   );
                    //   setWonBids(items);
                    // }

                    setCurrentPage(0);
                    setPageSize(v);
                  }}
                >
                  <DropDownItem label="Show 30 Records" value="30" />
                  <DropDownItem label="Show 15 Records" value="15" />
                  <DropDownItem label="Show 7 Records" value="7" />
                </DropDownButton>
              </ButtonsPanel>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <LotsTable
                    label="Open Lots"
                    mobileHeader={bidsMobileHeader}
                    tableHeader={bidsTableHeader}
                    noRecordTableBody={
                      <>
                        <tr>
                          <td colSpan={4} className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      </>
                    }
                    tableBody={
                      bids && Object.keys(bids).length > 0
                        ? bids.map((bid, i) => {
                            return (
                              <OpenOffers.BidsBodyRow
                                key={i}
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                lot={bid.auction.customId}
                                auctionEnd={bid.auction.endedOn}
                                amount={bid.bidAmount}
                                currency={bid.auction.currency}
                                auctionStatus={bid.auction.status}
                              />
                            );
                          })
                        : undefined
                    }
                    noRecordMobileBody={
                      <>
                        <div className={tableClasses.blockPanel}>
                          <div
                            className={`justify-content-center ${tableClasses.block}`}
                          >
                            <div className={tableClasses.blockText}>
                              <div className={tableClasses.item}>
                                <div className={tableClasses.productLabel}>
                                  No Records Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    mobileBody={
                      bids && Object.keys(bids).length > 0
                        ? bids.map((bid, i) => {
                            return (
                              <OpenOffers.BidsMobileBodyRow
                                key={i}
                                itemId={bid.auction.itemId}
                                label={bid.auction.title}
                                lot={bid.auction.customId}
                                auctionEnd={bid.auction.endedOn}
                                amount={bid.bidAmount}
                                currency={bid.auction.currency}
                                auctionStatus={bid.auction.status}
                              />
                            );
                          })
                        : undefined
                    }
                  />
                </div>
              </div>

              {totalPages > 1 && (
                <div className="d-block d-md-flex flex-center-between mb-3">
                  <Pagination
                    currentPage={currentPage}
                    total={total}
                    pageSize={pageSize}
                  />
                  <PaginationComponent
                    className="pagination-bar"
                    currentPage={currentPage + 1}
                    totalCount={total}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                      setCurrentPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

OpenOffers.BidsBodyRow = ({
  itemId,
  label,
  lot,
  auctionEnd,
  amount,
  currency,
  auctionStatus,
}) => (
  <>
    <tr>
      <td>
        <span className={tableClasses.productLabel}>
          <Link
            to={`${
              auctionStatus === "ACTIVE"
                ? "/auction/" + itemId
                : "/auction-item/" + itemId
            }`}
          >
            {label}
          </Link>
        </span>
        <span className={tableClasses.lot}>
          <Link
            to={`${
              auctionStatus === "ACTIVE"
                ? "/auction/" + itemId
                : "/auction-item/" + itemId
            }`}
          >
            LOT # {lot}
          </Link>
        </span>
      </td>
      <td>{auctionEnd}</td>
      <td>{formatUSD(amount)}</td>
      <td>
        {auctionStatus === "ACTIVE" && (
          <span className={`${tableClasses.badge} badge bg-success d-block`}>
            Live
          </span>
        )}

        {auctionStatus !== "ACTIVE" && (
          <span className={`${tableClasses.badge} badge bg-danger d-block`}>
            Time Ended
          </span>
        )}
      </td>
    </tr>
  </>
);

OpenOffers.BidsMobileBodyRow = ({
  itemId,
  label,
  lot,
  auctionEnd,
  amount,
  currency,
  auctionStatus,
}) => {
  return (
    <div className={tableClasses.blockPanel}>
      <div className={tableClasses.block}>
        <div className={tableClasses.selection}></div>
        <div className={tableClasses.blockText}>
          <div className={tableClasses.item}>
            <div className={tableClasses.productLabel}>
              <Link
                to={`${
                  auctionStatus === "ACTIVE"
                    ? "/auction/" + itemId
                    : "/auction-item/" + itemId
                }`}
              >
                {label}
              </Link>
            </div>
            <div className={tableClasses.lot}>
              <Link
                to={`${
                  auctionStatus === "ACTIVE"
                    ? "/auction/" + itemId
                    : "/auction-item/" + itemId
                }`}
              >
                LOT # {lot}
              </Link>
            </div>
          </div>

          <div className={`${tableClasses.block} pb-0`}>
            <div className={tableClasses.totalRow}>
              <div className={tableClasses.item}>
                <div className={tableClasses.label}>Auction End Time</div>
                <div className={tableClasses.value}>{auctionEnd}</div>
              </div>
              <div className={tableClasses.buttons}>
                {auctionStatus === "ACTIVE" && (
                  <span className={`${tableClasses.badge} badge bg-success`}>
                    Live
                  </span>
                )}

                {auctionStatus !== "ACTIVE" && (
                  <span className={`${tableClasses.badge} badge bg-danger`}>
                    Time Ended
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${tableClasses.block} ${tableClasses.bordered}`}>
        <div className={tableClasses.selection}></div>

        <div className={tableClasses.totalRow}>
          <div className={tableClasses.item}>
            <div className={tableClasses.label}>My Offer</div>
            <div className={tableClasses.value}>{formatUSD(amount)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenOffers;
