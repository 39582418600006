import { SERVICE_URL, FORGOT_PASSWORD_SEND_EMAIL_URL, FORGOT_PASSWORD_VERIFY_LINK_URL, FORGOT_PASSWORD_SET_PASSWOED_URL } from "../Constants";
import { simpleFetch } from "./fetch";

export async function sendVerificationEmail(values) {
  const response = await simpleFetch(
    `${FORGOT_PASSWORD_SEND_EMAIL_URL}`,
    {
      method: "POST",
      body: JSON.stringify({ email: values.email ?? "", userName: values.email ?? "" }),
    }
  );

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}
export async function verifyLink(link) {
  const response = await simpleFetch(
    `${FORGOT_PASSWORD_VERIFY_LINK_URL}`,
    {
      method: "POST",
      body: JSON.stringify(link),
    }
  );

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}
export async function verifyPin(data) {
  const response = await simpleFetch(
    `${SERVICE_URL}/user/forgot-password/verify-pin`,
    {
      method: "POST",
      body: JSON.stringify({ email: data.email, pinCode: data.otp }),
    }
  );

  let errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}
export async function ResetPasswordRequest(data) {
  const response = await simpleFetch(
    `${FORGOT_PASSWORD_SET_PASSWOED_URL}`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
}
