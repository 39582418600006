import { useState } from "react";
import { useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CATEGORY_MENU_URL,
  CITY_ITEM_COUNT_LIBRARY_URL,
  COUNTRY_ITEM_COUNT_LIBRARY_URL,
  STATE_ITEM_COUNT_LIBRARY_URL,
} from "../../Constants";
import { useAuthContext } from "../../redux/auth-context";
import { LibraryService } from "../../services/LibraryService";
import "./Sidebar.css";

const Sidebar = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);
  const [showMenuItems, setShowMenuItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [menuBack, setMenuBack] = useState();

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
    }),
    shallowEqual
  );

  const linkClick = (clicked = () => {}) => {
    clicked();
    ctx.sidebarOpen(false);
  };

  const prepareCategoriesMenuItems = () => {
    let subMenu = [];
    ctx.menuData?.categories?.map((val) => {
      let subMenu2 = [];

      val?.subList?.map((subCat, index) => {
        if (index <= 4) {
          subMenu2.push({
            id: subCat.id,
            title: subCat.key + " (" + subCat.valueNumber + ")",
            parent: subCat.idTwo,
            onClick: () => {
              linkClick(() =>
                navigate("/search-result", {
                  state: { filter: "?sub_category=" + subCat.id },
                })
              );
            },
          });
        }
      });

      subMenu2.push({
        id: val.id + "-view-all",
        title: "View All " + val.key + " (" + val.valueNumber + ")",
        parent: val.id,
        onClick: () => {
          linkClick(() =>
            navigate("/search-result", {
              state: { filter: "?category=" + val.id },
            })
          );
        },
      });

      subMenu.push({
        id: val.id,
        title: val.key + " (" + val.valueNumber + ")",
        parent: "categoryMenu",
        subMenu: [...subMenu2],
      });
    });

    const catMenuIdx = menuItemData.findIndex(
      (val) => val.id == "categoryMenu"
    );

    menuItemData[catMenuIdx].subMenu = subMenu;
  };

  const prepareLocationMenuItems = () => {
    let countryList = ctx.menuData.countries;
    let stateList = ctx.menuData.states;
    let cityList = ctx.menuData.cities;

    countryList.map((country) => {
      const states = [];
      stateList.map((state) => {
        states.push({
          ...state,
          cities: cityList.filter((city) => city.state == state.id),
        });
      });

      country.states = states.filter((state) => state.country == country.key);
    });

    const countrySubMenu = [];
    countryList.map((country) => {
      const stateSubMenu = [];
      country.states.map((state) => {
        const citySubMenu = [];
        state.cities.map((city) => {
          citySubMenu.push({
            id: city.id,
            title: city.name,
            parent: city.state,
            onClick: () => {
              linkClick(() =>
                navigate("/search-result", {
                  state: {
                    filter: "?city=" + city.id + "&state=" + city.state,
                  },
                })
              );
            },
          });
        });

        citySubMenu.push({
          id: state.id + "-all",
          title: "View All " + state.name,
          parent: state.id,
          onClick: () => {
            linkClick(() =>
              navigate("/search-result", {
                state: {
                  filter: "?state=" + state.id,
                },
              })
            );
          },
        });

        stateSubMenu.push({
          id: state.id,
          title: state.name,
          parent: state.country,
          subMenu: citySubMenu,
        });
      });

      countrySubMenu.push({
        id: country.key,
        title: country.name,
        parent: "locationMenu",
        subMenu: stateSubMenu,
      });
    });

    const catMenuIdx = menuItemData.findIndex(
      (val) => val.id == "locationMenu"
    );

    menuItemData[catMenuIdx].subMenu = countrySubMenu;
  };

  useEffect(() => {
    if (
      ctx.menuData?.categories &&
      ctx.menuData?.countries &&
      ctx.menuData?.states &&
      ctx.menuData?.cities &&
      Object.keys(ctx.menuData?.categories).length > 0 &&
      Object.keys(ctx.menuData?.countries).length > 0 &&
      Object.keys(ctx.menuData?.states).length > 0 &&
      Object.keys(ctx.menuData?.cities).length > 0
    ) {
      prepareCategoriesMenuItems();
      prepareLocationMenuItems();
      setShowMenuItems([...menuItemData]);
      setMenuItems([...menuItemData]);
    }
  }, [ctx.menuData]);

  let menuItemData = [
    {
      bold: true,
      title: "SIGN IN",
      onClick: () => {
        linkClick(() => navigate("/login"));
      },
      unauthorized: true,
    },
    {
      bold: true,
      title: "SIGN UP",
      onClick: () => {
        linkClick(() => navigate("/account-type"));
      },
      unauthorized: true,
    },
    {
      id: "categoryMenu",
      title: "Shop by Category",
      subMenu: [],
    },
    {
      id: "locationMenu",
      title: "Location",
      subMenu: [],
    },
    {
      title: "Popular Auctions",
      onClick: () => {
        linkClick(() =>
          navigate("/search-result", {
            state: { filter: "?badge=popular_auctions" },
          })
        );
      },
    },
    {
      title: "Closing Soon",
      onClick: () => {
        linkClick(() =>
          navigate("/search-result", {
            state: { filter: "?badge=ending_soon" },
          })
        );
      },
    },
    {
      title: "New Listings",
      onClick: () => {
        linkClick(() =>
          navigate("/search-result", {
            state: { filter: "?badge=new_auctions" },
          })
        );
      },
    },
    {
      title: "View All",
      onClick: () => {
        linkClick(() => navigate("/categories"));
      },
    },
    {
      title: "Sell",
      onClick: () => {
        linkClick(() => navigate("/become-seller"));
      },
    },
    {
      title: "Buy",
      onClick: () => {
        linkClick(() => navigate("/buy"));
      },
    },
    {
      title: "Help & Support",
      onClick: () => {
        linkClick(() => navigate("/help-and-support"));
      },
    },
    {
      title: "About Us",
      onClick: () => {
        linkClick(() => navigate("/about-us"));
      },
    },
  ];

  return (
    <Menu isOpen={ctx.isSidebarOpen} onClose={() => ctx.sidebarOpen(false)}>
      {menuBack && (
        <ul className="sidebar-nav-back">
          <li>
            <a
              href="#"
              className="link-toggle"
              onClick={(e) => {
                e.preventDefault();

                setSelected((prev) => {
                  const stack = [...prev];
                  const item = stack?.pop();

                  if (item?.parent) {
                    // const tempData = [...menuItems];
                    let tempData = [...menuItems];
                    stack?.forEach((i) => {
                      tempData = tempData
                        ?.filter((filter) => filter.id == i.id)
                        ?.shift()?.subMenu;
                    });

                    setShowMenuItems(tempData);

                    setMenuBack([...stack]?.pop());
                  } else {
                    setMenuBack(null);
                    setShowMenuItems([...menuItems]);
                  }

                  return stack;
                });
              }}
            >
              {menuBack.title}
            </a>
          </li>
        </ul>
      )}
      <ul className="sidebar-nav">
        {showMenuItems?.map((item, index) => {
          const itemValue = (
            <li key={index}>
              <a
                href="#"
                className={`${item?.bold ? "font-weight-bold" : ""} ${
                  item?.subMenu ? "link-toggle" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  if (item?.onClick) item?.onClick();

                  if (item?.subMenu?.length > 0) {
                    setSelected((prev) => {
                      let stack = [...prev];
                      stack.push({
                        id: item.id,
                        title: item.title,
                        parent: item.parent,
                      });

                      let tempData = [...menuItems];
                      stack?.forEach((i) => {
                        tempData = tempData
                          ?.filter((filter) => filter.id == i.id)
                          ?.shift()?.subMenu;
                      });

                      setShowMenuItems(tempData);
                      setMenuBack([...stack]?.pop());

                      return stack;
                    });
                  }
                }}
              >
                {item.title}
              </a>
            </li>
          );

          return item?.unauthorized ? !isAuthorized && itemValue : itemValue;
        })}
      </ul>
    </Menu>
  );
};

export default Sidebar;
