import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  deflateErrors,
  isBadRequest,
  isJSON,
  validatePassword,
  validateUsername,
  Regex,
} from "../../services/Utilities";
import { EmailCheckServerRequest } from "../../services/SignUpService";
import { PasswordGroup } from "../../components/PasswordGroup";
import classes from "../registration/RegisterModule.css";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import Alert from "../../components/UI/Alert";
import ZRadioComponent from "../../components/form/ZRadioComponent";
import DualErrorMessage from "../../components/UI/DualErrorMessage";

const SellerAccountType = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [regesteredAsBidderError, setRegesteredAsBidderError] = useState();
  const [serverErrors, setServerErrors] = useState();

  const schema = Yup.object().shape({
    sellerType: Yup.string()
      .required("Type is required"),
  });

  const initialValue = {
    accountType: location.state.accountType,
    sellerType: ""
  };

  const setSellerAccountType = (values) => {
    ctx.showProgress(true);
    const sellerAccType = {};
    sellerAccType.sellerType = values.sellerType;
    sellerAccType.accountType = values.accountType;

    if (values.sellerType === "") {
      setServerErrors({ sellerType: "Type is required" });
      ctx.showProgress(false);
      return;
    }

    navigate("/reprentative-info", { state: sellerAccType });
    ctx.showProgress(false);
  };


  return (
    <Layout>
      <div className="row mr-1 ml-1">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="row">
                <div className="d-inline col-7">
                  <h2 className="pg-5 font-weight-bold purple-500">
                    Apply for a seller account
                  </h2>
                </div>
                <div className="d-inline col-5 text-right">
                  <h2
                    className=""
                    style={{ color: "#AFB0B5", fontSize: "24px" }}
                  >
                    Step: <b className="color-2">1/3</b>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <BHotrizontalProgress progress={33} />
            </div>
            {/* <div className="col-md-12">
              <div className="row mt-4">
                <div className="d-inline col-6">
                  <h4 className="ca-subheading">1. Agency Representative</h4>
                </div>
                <div className="d-inline col-6">
                  <h5 className="pg-1 font-weight-bold text-right mb-0">
                    <span className="color-red">*</span> Mandantory Fields
                  </h5>
                </div>
              </div>
            </div> */}
            <div className="col-md-12">
              <p className="mt-2 ml-3">
                BidlyAuction is an online market place for everyone. You are welcome to sell, whether you are an individual <br></br>
                or an agency looking to sell online.
              </p>
            </div>
            <div className="col-md-12">
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={schema}
                onSubmit={(values) => {
                  setSellerAccountType(values);
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  validateForm,
                  setFieldValue,
                }) => (
                  <form id="sellerAccountType">
                    {regesteredAsBidderError && (
                      <Alert
                        type="error"
                        children={regesteredAsBidderError}
                      />
                    )}
                    {/* first row */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4  ml-3">
                          <Field
                              className="form-control"
                              name="sellerType"
                              radioName="sellerType"
                              items={[
                                {
                                  value: "PUBLIC",
                                  label: "I am an individual",
                                },
                                {
                                  value: "AGENCY",
                                  label: "I am representing an agency",
                                },
                              ]}
                              component={ZRadioComponent}
                              handleOnChange={(value) => {
                                setFieldValue("sellerType", value);
                              }}
                            />
                            <DualErrorMessage
                              fieldName="sellerType"
                              serverError={
                                serverErrors && serverErrors.sellerType
                              }
                            />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                        </div>
                      </div>
                    </div>
                    {/* first row end */}


                    <div className="row mb-3">
                      <div className="col-md-6">
                      </div>
                      <div className="col-md-6">
                        <Link
                          className="btn-block btn btn-primary-dark-w px-5 w-50 float-right"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            let res = await validateForm();
                            // debugger;
                            if (res && Object.values(res)?.length > 0) {
                            }
                            handleSubmit(e);
                          }}
                        >
                          Next
                        </Link>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Layout>
  );
};

export default SellerAccountType;
