import React, { useEffect, useState } from "react";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import LinkButton from "../../components/button/LinkButton";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import Layout from "../../layout/Layout";
import Alert from "../../components/UI/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import CreditCard from "../../components/UI/CreditCard";
import CreditCardsPanel from "./CreditCardsPanel";
import {
  deleteCustomerBillingCreditCards,
  fetchCustomerBillingCreditCards,
} from "../../services/PaymentService";
import { isJSON, isStopFlowConditionMet } from "../../services/Utilities";
import { useAuthContext } from "../../redux/auth-context";
import ConfirmModal from "../../components/UI/ConfirmModal";

const AddCardInfo = () => {
  const ctx = useAuthContext();
  const params = useLocation().state ?? {};
  const navigate = useNavigate();

  const [cardsList, setCardsList] = useState();
  const [cardAddStatus, setCardAddStatus] = useState();

  const [deleteCardId, setDeleteCardId] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    setCardAddStatus(params.cardAddStatus);
  }, [params]);

  const fetchCreditCards = () => {
    ctx.showProgress(true);
    fetchCustomerBillingCreditCards()
      .then((resp) => {
        setCardsList(resp);
        ctx.showProgress(false);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        navigate("/error");
      });
  };

  const removeCreditCard = async (id) => {
    ctx.showProgress(true);
    console.log("clicked on remove button", id);
    try {
      await deleteCustomerBillingCreditCards(id);

      const resp = await fetchCustomerBillingCreditCards();
      setCardsList(resp);

      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);

      if (isStopFlowConditionMet(e.message)) return;

      navigate("/error");
    }
  };

  useEffect(() => {
    fetchCreditCards();
  }, []);

  return (
    <>
      {showDeleteConfirm && (
        <ConfirmModal
          title="Delete Credit Card Information"
          message="Are you sure want to remove credit card information?"
          cancelClicked={(e) => {
            e.preventDefault();
            setShowDeleteConfirm(false);
          }}
          confirmClicked={(e) => {
            e.preventDefault();
            setShowDeleteConfirm(false);
            removeCreditCard(deleteCardId);
          }}
        />
      )}
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu addCardActive={true} />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              {cardAddStatus && cardAddStatus === "succeeded" && (
                <Alert>
                  Success! Your credit card information has been saved.
                </Alert>
              )}

              {cardAddStatus && cardAddStatus === "processing" && (
                <Alert type="info">
                  Processing payment details. We'll update you when processing
                  is complete.
                </Alert>
              )}

              {cardAddStatus && cardAddStatus === "requires_payment_method" && (
                <Alert type="error">
                  Failed to process payment details. Please try another payment
                  method.
                </Alert>
              )}

              <h2 className="h4 font-weight-bold">Add Credit Card</h2>

              <div className="mb-5">
                <BHotrizontalProgress progress={33} />
              </div>

              <p className="mt-5 instructions-desc">
                Valid USA Credit/Debit Card is needed to place bid. Your card
                will be used for the buyer's fee only.
              </p>

              {(!cardsList || cardsList?.length == 0) && (
                <LinkButton to="/add-card-billing" className="mt-5">
                  ADD NEW CARD
                </LinkButton>
              )}

              <div className="mt-5 mb-10">
                {cardsList && (
                  <CreditCardsPanel>
                    {cardsList.map((item, i) => {
                      return (
                        <CreditCard
                          key={i}
                          identifier={item.cardInfoId}
                          isActive={item.status === "ACTIVE"}
                          cardNumber={item.maskedCardNumber}
                          validity={item.validity}
                          onRemove={(id) => {
                            setDeleteCardId(id);
                            setShowDeleteConfirm(true);
                          }}
                        />
                      );
                    })}
                  </CreditCardsPanel>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddCardInfo;
