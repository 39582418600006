import React from "react";
import { Link } from "react-router-dom";
import LinkButton from "../../components/button/LinkButton";
import classes from "./Search.module.css";
import clock from "../../assets/images/search-clock.svg";

const SearchActionItemList = ({ data, sellers, onFilterChange, watchListHadler, ...props }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  return (
    <li className="product-item remove-divider mx-1">
      <div className="product-item__outer w-100">
        <div className="product-item__inner remove-prodcut-hover py-4 row">
          <div className="product-item__header col-3 align-self-center">
            <div className="mb-2">
              <Link to={`/auction/${data.itemId}`}>
                <img
                  className="img-fluid"
                  src={data.defaultImage}
                  alt="Image Description"
                  style={{ borderRadius: "10px" }}
                />
              </Link>
            </div>
          </div>
          <div className="pl-2 product-item__body col-6">
            <div className="pr-lg-0">
              <div
                className={`${classes.searchItemEndLabel} d-flex flex-center mb-2`}
              >
                {data.auctionType.toProperCase()} | LOT # : {data.lotNo}
              </div>
              <div className="mb-2">
                <Link
                  to={`/auction/${data.itemId}`}
                  className={`${classes.searchItemTitle}`}
                >
                  {data.title.toProperCase()}
                </Link>
              </div>
              <div className="mb-2">
                <a
                  href="#"
                  className={`${classes.searchItemCityState}`}
                >
                  {data.city.toProperCase()} {data.state.toProperCase()}{" "}
                  {", USA"}
                </a>
              </div>
              <div className="mb-2">
                {sellers
                  .filter((seller) => seller.value == data.sellerId)
                  .map((seller) => {
                    return (
                      <>
                        <Link onClick={() =>
                            onFilterChange(false, "seller", {
                              checked: true,
                              value: seller.value,
                            })
                          } className={`${classes.searchItemCityName}`}>
                          {seller.label}
                        </Link>
                      </>
                    );
                  })}
              </div>
              {data.auctionType == "ONLINE AUCTION" && (
                <div className="mb-2">
                  <div className={`${classes.searchItemBids}`}>
                    Bid :{" "}
                    <span className={`${classes.searchItemBidsValue}`}>
                      {data.bids} 
                    </span>{" "}
                    | Current Price{" "}
                    <span className={`${classes.searchItemBidsValue}`}>
                      ${data.maxBid > 0? data.maxBid : data.startingPrice}
                    </span>
                  </div>
                </div>
              )}

              {data.auctionType == "BUY NOW" && (
                <div className="mb-2">
                  <div className={`${classes.searchItemBids}`}>
                    Price{" "}
                    <span className={`${classes.searchItemBidsValue}`}>
                      ${data.maxBid > 0? data.maxBid : data.startingPrice}
                    </span>
                  </div>
                </div>
              )}
              <div className="mb-2">
                <div
                  className={`${classes.searchItemEndLabel} d-flex flex-center`}
                >
                  Ends in:{" "}
                  <img
                    className={`${classes.clockIcon} my-auto mx-1`}
                    src={clock}
                  />{" "}
                  {data.endsDaysAndHour}
                </div>
              </div>
            </div>
          </div>
          <div className="product-item__footer col-3 d-md-block my-auto">
            <div className="mb-3 d-flex justify-content-center">
              <div className="prodcut-add-cart">
                <LinkButton  to={`/auction/${data.itemId}`} className="mt-5">
                  {(data.auctionType == "ONLINE AUCTION" ||  data.auctionType == "SEALED AUCTION") && "Bid Now"}
                  {data.auctionType == "MAKE AN OFFER" && "Make an Offer" }
                  {data.auctionType == "BUY NOW" && "Buy Now" }
                </LinkButton>
              </div>
            </div>
            <div className="flex-horizontal-center justify-content-center justify-content-wd-center flex-wrap">
              <button onClick={()=>{watchListHadler(data)}}
                className="text-gray-6 font-size-16 mx-wd-3" style={{border: 'none', background: 'none'}}
              >
                <i className={ data.inWishList ? `yith-wcwl-icon fa fa-heart mr-1 font-size-16`: `ec ec-favorites mr-1 font-size-16`}></i> Watch
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default SearchActionItemList;
