import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import { PhoneTypes } from "../../components/common/PhoneTypes";
import SearchSelect from "../../components/form/SearchSelect";
import {
  deflateErrors,
  isJSON,
  validateUSPhoneNumber,
} from "../../services/Utilities";
import { Card } from "react-bootstrap";
import { SellerSignupServerRequest } from "../../services/SignUpService";
import Layout from "../../layout/Layout";
import classes from "../registration/RegisterModule.css";
import {
  MUNCIPALITY_POPULATION_LIBRARY_URL,
  SELLER_LIST_CREATION_PLAN_LIBRARY_URL,
  CITIES_LIBRARY_URL,
  COUNTRIES_LIBRARY_URL,
  ALL_STATES_LIBRARY_URL,
  STATE_CITIES_LIBRARY_URL,
} from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import { useAuthContext } from "../../redux/auth-context";
import ZMultiCheckBox from "../../components/form/ZMultiCheckBox";
import { Select } from "../../components/Select";
import SellerTermsAndConditions from "../registration/SellerTermsAndConditions";

const AgencyOtherInfo = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state ?? {};

  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [muncipalityPopulations, setMuncipalityPopulations] = useState();
  const [listCreationPlans, setListCreationPlans] = useState();

  const [showSellerTerms, setShowSellerTerms] = useState(false);

  const schema = Yup.object().shape({
    agencyName: Yup.string()
      .max(60, "Agency name must be maximum of 60 characters"),
      // .required("Agency name is required"),
    street: Yup.string()
      .max(128, "Street address must be maximum of 128 characters")
      .required("Street address is required"),
    suiteOrFloor: Yup.string().max(
      128,
      "(PO Box, Suite, Floor, etc) must be maximum of 128 characters"
    ),
    country: Yup.string().required("Please select Country"),
    state: Yup.string().required("Please select State"),
    city: Yup.string().required("Please select City"),
    postalCode: Yup.string()
      .max(32, "Zip Code must be maximum of 32 characters or numbers")
      .required("Zip Code is required"),
    phoneType: Yup.string().required("Phone type is required"),
    phoneNumber: Yup.string()
      .max(32, "Phone number must be maximum of 32 characters or numbers")
      .required("Phone number is required"),
    listCreationPlan: Yup.string(),
    // .required("Please select your first listing"),
    muncipalityPopulation: Yup.string(),
    userAgreement: Yup.string().required(
      "Please agree to the Terms & Conditions"
    ),
  });

  const initialValue = {
    country: "",
    city: "",
    state: "",
    agencyName: "",
    street: "",
    suiteOrFloor: "",
    postalCode: "",
    phoneNumber: "",
    listCreationPlan: "",
    userAgreement: "",
    phoneType: "",
    muncipalityPopulation: "",
  };

  const myFunc = async () => {
    ctx.showProgress(true);
    LibraryService(COUNTRIES_LIBRARY_URL)
      .then((res) => {
        let myCountires = [];
        res.map((item) => {
          myCountires.push({ key: item.key, name: item.value });
        });
        setCountries(myCountires);
      })
      .catch((error) => {
        console.log(error);
      });

    const statesRs = await LibraryService(ALL_STATES_LIBRARY_URL);
    let stateList = [];
    statesRs?.map((item) => {
      stateList.push({
        id: item.id,
        name: item.key,
        country: item.value,
      });
    });

    setStates(stateList);

    const citiesRs = await LibraryService(CITIES_LIBRARY_URL);
    let cityList = [];
    citiesRs?.map((item) => {
      cityList.push({
        id: item.id,
        name: item.key,
        state: item.value,
      });
    });

    setCities(cityList);

    LibraryService(MUNCIPALITY_POPULATION_LIBRARY_URL)
      .then((res) => {
        let populations = [];
        res.map((item) => {
          populations.push({ key: item.key, name: item.value });
        });
        setMuncipalityPopulations(populations);
      })
      .catch((error) => {
        console.log(error);
      });

    LibraryService(SELLER_LIST_CREATION_PLAN_LIBRARY_URL)
      .then((res) => {
        let myListCreationPlan = [];
        res.map((item) => {
          myListCreationPlan.push({ key: item.key, name: item.value });
        });
        setListCreationPlans(myListCreationPlan);
      })
      .catch((error) => {
        console.log(error);
      });

    ctx.showProgress(false);
  };

  useEffect(() => {
    myFunc();
  }, []);

  const findCitiesByState = async (value) => {
    ctx.showProgress(true);

    const citiesURL = `${STATE_CITIES_LIBRARY_URL}?state=${value}`;

    LibraryService(citiesURL)
      .then((res) => {
        let myCities = [];
        res.map((item) => {
          myCities.push({ value: item.id, label: item.key });
        });
        setCities(myCities);
      })
      .catch((error) => {
        console.log(error);
      });

    ctx.showProgress(false);
  };

  const createAccount = (values) => {
    ctx.showProgress(true);
    const accountInfo = params;
    const otherInfo = {};
    otherInfo.agencyName = values.agencyName;
    otherInfo.street = values.street;
    otherInfo.suiteOrFloor = values.suiteOrFloor;
    otherInfo.postalCode = values.postalCode;
    otherInfo.phoneNumber = values.phoneNumber;
    otherInfo.listCreationPlan = values.listCreationPlan;
    otherInfo.phoneType = values.phoneType;
    otherInfo.muncipalityPopulation = values.muncipalityPopulation;
    otherInfo.country = values.country;
    otherInfo.city = values.city;
    otherInfo.state = values.state;
    otherInfo.userAgreement = values.userAgreement;

    accountInfo.otherInfo = otherInfo;

    console.log(accountInfo);
    SellerSignupServerRequest(accountInfo)
      .then((data) => {
        console.log(data);
        setTimeout(() => {
          ctx.showProgress(false);
          navigate("/user-notified");
        }, 2000);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }
        const json = JSON.parse(e.message);
        console.log(json);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  return (
    <>
      {showSellerTerms && (
        <SellerTermsAndConditions
          show={showSellerTerms}
          handleClose={() => {
            setShowSellerTerms(false);
          }}
        />
      )}
      <Layout>
        <div className="row mr-0 ml-0">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 mt-5">
                <div className="row">
                  <div className="d-inline col-7">
                    <h2 className="pg-5 font-weight-bold purple-500">
                      Apply for a seller account
                    </h2>
                  </div>
                  <div className="d-inline col-5 text-right">
                    <h2
                      className=""
                      style={{ color: "#AFB0B5", fontSize: "24px" }}
                    >
                      Step: <b className="color-2">3/3</b>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <BHotrizontalProgress progress={33} />
              </div>
              <div className="col-md-12">
                <div className="row mt-4">
                  <div className="d-inline col-6">
                    <h4 className="ca-subheading">3. Address</h4>
                  </div>
                  <div className="d-inline col-6">
                    <h5 className="pg-1 font-weight-bold text-right">
                      <span className="color-red">* </span>Mandantory Fields
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    console.log(values);
                    createAccount(values);
                  }}
                >
                  {({
                    handleSubmit,
                    isSubmitting,
                    errors,
                    touched,
                    validateForm,
                    setFieldValue,
                  }) => (
                    <form id="agencyOtherInfo">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <Field
                              className="form-control"
                              type="text"
                              name="street"
                              required="true"
                              component={ZInputComponent}
                              placeholder="Enter street address"
                              label="Street Address"
                              serverError={serverErrors && serverErrors.street}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <Field
                              className="form-control"
                              type="text"
                              name="suiteOrFloor"
                              component={ZInputComponent}
                              placeholder="Enter (PO Box, Suite, Floor, etc)"
                              label="(PO Box, Suite, Floor, etc) (Optional)"
                              serverError={
                                serverErrors && serverErrors.suiteOrFloor
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* first row */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <Select
                              name="country"
                              className="form-control"
                              label="Country"
                              mandatory={true}
                              defaultTouch
                              serverError={serverErrors && serverErrors.country}
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("country", e.target.value);
                                setSelectedCountry(e?.target?.value);
                                setSelectedState(undefined);
                              }}
                            >
                              <option value="" disabled>
                                Select Country
                              </option>

                              {countries &&
                                countries.length > 0 &&
                                countries.map((country) => {
                                  return (
                                    <option
                                      key={country.key}
                                      value={country.key}
                                    >
                                      {country.name}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <Select
                              name="state"
                              className="form-control"
                              label="State"
                              mandatory={true}
                              defaultTouch
                              serverError={serverErrors && serverErrors.state}
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("state", e.target.value);
                                setSelectedState(e?.target?.value);
                              }}
                            >
                              <option value="" disabled>
                                Select State
                              </option>

                              {selectedCountry &&
                                states &&
                                states.length > 0 &&
                                states
                                  .filter(
                                    (item) => item.country == selectedCountry
                                  )
                                  .map((state) => {
                                    return (
                                      <option key={state.id} value={state.id}>
                                        {state.name}
                                      </option>
                                    );
                                  })}
                            </Select>
                          </div>
                        </div>
                      </div>
                      {/* first row end */}

                      {/* 2 row */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <Select
                              name="city"
                              className="form-control"
                              label="City"
                              mandatory={true}
                              defaultTouch
                              serverError={serverErrors && serverErrors.city}
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("city", e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Select City
                              </option>

                              {selectedState &&
                                cities &&
                                cities.length > 0 &&
                                cities
                                  .filter((item) => item.state == selectedState)
                                  .map((city) => {
                                    return (
                                      <option key={city.id} value={city.id}>
                                        {city.name}
                                      </option>
                                    );
                                  })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <Field
                              className="form-control"
                              type="text"
                              name="postalCode"
                              required="true"
                              component={ZInputComponent}
                              placeholder="Enter zip code"
                              label="Zip Code"
                              serverError={
                                serverErrors && serverErrors.postalCode
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* 2 row end */}

                      {/* 4 row */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <label className="form-label">
                              What is phone type?
                              <span className="color-red"> *</span>
                            </label>
                            <SearchSelect
                              placeholder="Select phone type"
                              name="phoneType"
                              options={PhoneTypes}
                              onChange={(e) => {
                                setFieldValue("phoneType", e.value);
                              }}
                            />
                             {errors["phoneType"] && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors["phoneType"]}
                          </div>
                        )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <Field
                              className="form-control"
                              type="text"
                              name="phoneNumber"
                              required="true"
                              component={ZInputComponent}
                              placeholder="Enter phone number"
                              label="Phone Number"
                              validate={validateUSPhoneNumber}
                            />
                          </div>
                        </div>
                      </div>
                      {/* 4 row end */}

                      {params && params.sellerType === "AGENCY" && (
                        <>
                          <div className="row mt-3">
                            <div className="d-inline col-6">
                              <h4 className="ca-subheading">
                                3. About Your Agency
                              </h4>
                            </div>
                            <div className="d-inline col-6">
                              <h5 className="pg-1 font-weight-bold text-right">
                                <span className="color-red">* </span>Mandantory
                                Fields
                              </h5>
                            </div>
                          </div>

                          {/* row start */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="js-form-message mb-4">
                                <Field
                                  className="form-control"
                                  type="text"
                                  name="agencyName"
                                  required="true"
                                  component={ZInputComponent}
                                  placeholder="Enter agency name"
                                  label="Name of your Agency"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="js-form-message mb-4">
                                <Select
                                  name="listCreationPlan"
                                  className="form-control"
                                  label="When will you be creating your first listing"
                                  mandatory={true}
                                  defaultTouch
                                  serverError={
                                    serverErrors && serverErrors.listCreationPlan
                                  }
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setFieldValue(
                                      "listCreationPlan",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="" disabled>
                                    -- Choose an option --
                                  </option>

                                  {listCreationPlans &&
                                    listCreationPlans.length > 0 &&
                                    listCreationPlans.map((plan) => {
                                      return (
                                        <option key={plan.key} value={plan.key}>
                                          {plan.name}
                                        </option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                          {/* row end */}
                        </>
                      )}        

                      {/* row start */}
                      {/* <div className="row">
                        <div className="col-md-6">
                          <div className="js-form-message mb-4">
                            <Select
                              name="muncipalityPopulation"
                              className="form-control"
                              label="Municipality population"
                              mandatory={false}
                              defaultTouch
                              serverError={
                                serverErrors &&
                                serverErrors.muncipalityPopulation
                              }
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue(
                                  "muncipalityPopulation",
                                  e.target.value
                                );
                              }}
                            >
                              <option value="" disabled>
                                -- Choose an option --
                              </option>

                              {muncipalityPopulations &&
                                muncipalityPopulations.length > 0 &&
                                muncipalityPopulations.map((population) => {
                                  return (
                                    <option
                                      key={population.key}
                                      value={population.key}
                                    >
                                      {population.name}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div> */}
                      {/* row end */}

                      <div className="row">
                        <div className="col-md-12 pb-3">
                          <Card style={{ width: "100%" }}>
                            <Card.Body style={{ backgroundColor: "#FBF9EE" }}>
                              <p className="pg-2 m-0 text-center">
                                All seller accounts require approval to sell. It
                                usually takes 02 business days to approve a
                                seller account. You will be notified, via email,
                                about your account status.
                              </p>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                      <div className="row mb-8">
                        <div className="d-inline p-2 col-md-8">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="userAgreement"
                              name="userAgreement"
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("userAgreement", e.target.value);
                              }}
                            />
                            <label className="form-check-label">
                              I agree to BidlyAuction{" "}
                              <Link
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowSellerTerms(true);
                                }}
                              >
                                Terms & Conditions
                              </Link>
                            </label>
                          </div>
                          {(touched.userAgreement || errors.userAgreement) && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.userAgreement}
                            </div>
                          )}

                          {serverErrors && serverErrors.userAgreement && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {serverErrors.userAgreement}
                            </div>
                          )}
                        </div>
                        <div className="d-inline p-2 col-md-4">
                          <Link
                            className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                            type="submit"
                            onClick={async (e) => {
                              e.preventDefault();
                              let res = await validateForm();
                              console.log(res);
                              if (res && Object.values(res)?.length > 0) {
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }
                              handleSubmit(e);
                            }}
                          >
                            Register Now!
                          </Link>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </Layout>
    </>
  );
};

export default AgencyOtherInfo;
