import authenticatedFetch from "./fetch";

export const fetchGetData = async (url) => {
  const response = await authenticatedFetch(`${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return await response.json();
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};

export const postUserMessage = async (url, requestBody) => {
  const response = await authenticatedFetch(`${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response) return;

  const errorMessage = { status: "ERROR" };
  if (response.status !== 200) {
    throw new Error(JSON.stringify({ ...errorMessage }));
  }

  try {
    return response;
  } catch (e) {
    throw new Error(JSON.stringify({ ...errorMessage, data: e }));
  }
};
