import React from "react";
import Carousel from "better-react-carousel";

const BidlyCarousel = ({
  cols = 5,
  gap = 30,
  showDots = true,
  hideArrow = false,
  items = [],
  mobileBreakpoint,
  responsiveLayout,
}) => {
  const dots = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "10px" : "0.5rem",
        width: isActive ? "30px" : "0.5rem",
        background: isActive ? "#339966" : "#8E8F94",
        border: 0,
        borderRadius: isActive ? "10px" : "50%",
        marginTop: "30px",
      }}
    ></span>
  );

  return (
    <>
      <Carousel
        cols={cols}
        rows={1}
        gap={gap}
        loop
        showDots={showDots}
        dot={dots}
        scrollSnap
        hideArrow={hideArrow}
        mobileBreakpoint={mobileBreakpoint}
        responsiveLayout={responsiveLayout}
      >
        {items &&
          items.map((item, i) => {
            return <Carousel.Item key={i}>{item}</Carousel.Item>;
          })}
      </Carousel>
    </>
  );
};

export default BidlyCarousel;
