import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import classes from "./GalleryUploader.module.css";
import ErrorMessage from "../UI/ErrorMessage";
import addIcon from "../../assets/images/add-img-icon.svg";
import removeIcon from "../../assets/images/remove-red-icon.svg";

const GalleryUploader = ({
  label = "Add Media",
  initialImages,
  fileSelectedEvent = (file) => {},
  showErrors = false,
  errorEvent = (errors) => {},
  showUploaded = true,
  listChanged = (list) => {},
}) => {
  const [image, setImage] = useState([]);
  const [shownList, setShownList] = useState([]);
  const maxNumber = 10;

  useEffect(() => {
    setShownList(initialImages);
  }, [initialImages]);

  useEffect(() => {
    listChanged(shownList);
  }, [shownList]);

  const uploadEvent = {
    proceed: (file) => {
      setShownList((prev) => [...prev, { ...file, uploaded: false }]);
      setImage([]);
    },
    cancel: () => {
      setImage([]);
    },
  };

  const onChange = (uploadedImage) => {
    setImage(uploadedImage);
    fileSelectedEvent(uploadEvent, uploadedImage[0]);
  };

  return (
    <>
      <ImageUploading
        multiple
        maxNumber={maxNumber}
        value={image}
        onChange={onChange}
        onError={(errors) => {
          if (errorEvent) errorEvent(errors);
        }}
        dataURLKey="data_url"
        acceptType={["png", "jpg", "jpeg"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          onImageRemoveAll,
          dragProps,
          isDragging,
          errors,
        }) => (
          <>
            <div className="upload__image-wrapper">
              <div
                onClick={onImageUpload}
                {...dragProps}
                className={`w-100 py-5 px-4 font-size-15 text-lh-19 font-size-15-md text-center d-flex align-items-center justify-content-center gap-10 ${
                  classes.dragPanel
                } ${isDragging ? classes.dropActive : undefined}`}
              >
                <img src={addIcon} /> {label}
              </div>
            </div>

            {showUploaded && (
              <div className="mt-4 mb-4">
                <GalleryItems
                  items={shownList}
                  removeClicked={(index, meta) => {
                    let rem = [...shownList];
                    rem.splice(index, 1);
                    setShownList([...rem]);
                  }}
                />
              </div>
            )}

            {showErrors && errors && (
              <ErrorMessage>
                {errors.maxNumber && (
                  <span className="block">
                    Number of selected images exceed maximum number
                  </span>
                )}
                {errors.acceptType && (
                  <span className="block">
                    Your selected file type is not allowed
                  </span>
                )}
                {errors.maxFileSize && (
                  <span className="block">
                    Selected file size exceed maximum file size
                  </span>
                )}
                {errors.resolution && (
                  <span className="block">
                    Selected file is not match your desired resolution
                  </span>
                )}
              </ErrorMessage>
            )}
          </>
        )}
      </ImageUploading>
    </>
  );
};

export const GalleryItems = ({
  items,
  removeClicked = (index, meta) => {},
}) => {
  return (
    <div className={classes.uploadedRow}>
      {items?.map((uImage, index) => {
        return (
          <GalleryTile
            key={index}
            index={index}
            meta={uImage}
            image={uImage.data_url}
            removeClicked={removeClicked}
          />
        );
      })}
    </div>
  );
};

const GalleryTile = ({
  index,
  meta,
  image,
  removeClicked = (index, meta) => {},
}) => {
  return (
    <div
      className={classes.tile}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <img
        src={removeIcon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          removeClicked(index, meta);
        }}
      />
    </div>
  );
};

export default GalleryUploader;
