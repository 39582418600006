import React from "react";
import classes from "./SmallBanner.module.css";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

const SmallBanner = () => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.token != null,
    }),
    shallowEqual
  );

  return (
    <>
      <div className={classes.banner}>
        <div className={classes.sectionText}>
          <div className={classes.description}>
            Turn your inventory into cash
          </div>
          <div className={classes.heading}>Sell On BidlyAuction Now !</div>
        </div>
        <div className={classes.sectionButton}>
          <Link className=" btn px-5 btn-light-w" to="/account-type">
            GET STARTED
          </Link>
        </div>
      </div>
    </>
  );
};

export default SmallBanner;
