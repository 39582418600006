import React from "react";
import { Link } from "react-router-dom";

const LinkButton = (props) => {
  const { type, block, className } = props;

  let buttonClass = "btn-primary-dark-w";
  if (type == "secondary") buttonClass = "btn-secondary-dark-w";
  if (type == "secondary2") buttonClass = "btn-secondary";

  return (
    <Link
      {...props}
      className={` btn px-5 ${buttonClass} ${className}
      ${block ? "btn-block" : ""}`}
    >
      {props.children}
    </Link>
  );
};

export default LinkButton;
