import classes from "./LoadingModal.module.css";
import Card from "./Card";

const LoadingModal = (props) => {
  return (
    <div className="loading-cart">
      <div className={classes.backdroploading} />
      <Card className={classes.modal}>
        <div className={`${classes.content} text-center`}>
          {props.waitImg && <img src={props.waitImg} alt="Loading" />}
          {props.waitText && <h5>{props.waitText}</h5>}
        </div>
      </Card>
    </div>
  );
};

/*
LoadingModal.defaultProps = {
  waitText: "Please wait ...",
};*/

export default LoadingModal;
