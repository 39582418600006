import { Field, Form, Formik, useField } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../redux/auth-context";
import * as Yup from "yup";
import classes from "./Auction.module.css";
import { BidNow, StatusRequest } from "../../services/UserService";
import { Alert } from "@mui/material";
import {
  deleteAddWatchedItem,
  userAddWatchedItem,
} from "../../services/AuctionService";

const BuyNow = ({ isAuthorized, item, reFetchDetail }) => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const location = useLocation();

  const [accountStatusData, setAccountStatusData] = useState();
  const [enableBid, setEnableBid] = useState(false);
  const [bidRequest, setBidRequest] = useState();
  const [bidResponse, setBidResponse] = useState();

  useEffect(() => {
    if (isAuthorized) {
      ctx.showProgress(true);
      StatusRequest()
        .then((data) => {
          setAccountStatusData(data);
          ctx.showProgress(false);
        })
        .catch((error) => {
          ctx.showProgress(false);
          navigate("/error");
        });
    }
  }, [isAuthorized]);

  const BidInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">{item.currency}</span>
          </div>
          <input
            step={1}
            min={props.min}
            max={props.max}
            type="number"
            className="form-control text-area"
            disabled={props.disable}
            {...field}
            {...props}
          />
        </div>
        {meta.touched && meta.error ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
      </>
    );
  };

  const bidNow = () => {
    ctx.showProgress(true);
    BidNow({
      bidAmount: item.currentMaxBid,
      autoBid: false,
      emailWhenOutBid: false,
      itemId: item.itemId,
    })
      .then((data) => {
        setBidResponse(data);
        ctx.showProgress(false);
        if (data?.status == "success") {
          navigate("/item-bought-cart");
        }
      })
      .catch((error) => {
        ctx.showProgress(false);
        navigate("/error");
      });
  };

  const watchListHadler = () => {
    if (isAuthorized) {
      ctx.showProgress(true);
      if (!item.watchListed) {
        userAddWatchedItem(item.itemId)
          .then((data) => {
            item.watchListed = true;
            ctx.showProgress(false);
          })
          .catch((error) => {
            ctx.showProgress(false);
          });
      } else {
        deleteAddWatchedItem(item.itemId)
          .then((data) => {
            item.watchListed = false;
            ctx.showProgress(false);
          })
          .catch((error) => {
            console.log(error);
            ctx.showProgress(false);
          });
      }
    } else {
      navigate("/login", { state: { location } });
    }
  };

  return enableBid ? (
    <>
      {bidResponse?.status == "error" && (
        <div className="offset-lg-2 col-md-8 mb-1 text-center">
          <label className={classes.infoTableLabelRed}>
            <Alert severity="error">{bidResponse.message}</Alert>
          </label>
        </div>
      )}
      {bidRequest && (
        <>
          <div className="offset-lg-2 col-md-8 mb-1">
            <ul>
              <li className={classes.auctionLocationLabel}>
                By clicking on Buy Now you agree to following:
              </li>
            </ul>

            <ol>
              <li className={classes.auctionLocationLabel}>
                You have done your due diligence on the item. All Items are sold
                AS IS WHERE IS.
              </li>
              <li className={classes.auctionLocationLabel}>
                You have sufficient funds available to cover the buyers fee on
                your credit card.
              </li>
            </ol>
          </div>
          <div className="offset-lg-2 col-md-8 mb-1">
            <button
              type="button"
              onClick={() => bidNow()}
              className="btn btn-block btn-primary-dark-w mt-2"
            >
              Agree & Confirm Buy Now
            </button>
          </div>
          <div className="offset-lg-2 col-md-8 mb-1 text-center">
            <Link
              className={classes.auctionLocationGreen}
              onClick={() => {
                setEnableBid(false);
                setBidRequest(null);
              }}
            >
              Cancel Buy Now
            </Link>
          </div>
        </>
      )}
    </>
  ) : !isAuthorized ? (
    <div className="offset-lg-2 col-md-8 mb-1">
      <button
        type="button"
        onClick={() => navigate("/login", { state: { location } })}
        className="btn btn-block btn-primary-dark-w mt-2"
      >
        Sign in to Buy Now
      </button>
    </div>
  ) : accountStatusData?.documentStatus == "EMPTY" || accountStatusData?.documentStatus == "SUBMITTED"? (
    <div className="offset-lg-2 col-md-8 mb-1">
      <button
        type="button"
        onClick={() => navigate("/verify-identity-welcome")}
        className="btn btn-block btn-primary-dark-w mt-2"
      >
        Verify Identity to Buy Now
      </button>
    </div>
  ) : accountStatusData?.paymentStatus == "f" ? (
    <>
      <div className="offset-lg-2 col-md-8 mb-1">
        <button
          type="button"
          onClick={() => {
            navigate("/add-card-info");
          }}
          className="btn btn-block btn-primary-dark-w mt-2"
        >
          Add Credit Card to Buy Now
        </button>
      </div>
    </>
  ) : (
    <>
      {bidResponse?.status == "success" && (
        <div className="offset-lg-2 col-md-8 mb-1 text-center">
          <label className={classes.infoTableLabelGreen}>
            <Alert severity="success">{bidResponse.message}</Alert>
          </label>
        </div>
      )}
      <div className="offset-lg-2 col-md-8 mb-1">
        <button
          type="button"
          onClick={() => {
            setEnableBid(true);
            setBidRequest(true);
          }}
          className="btn btn-block btn-primary-dark-w mt-2"
        >
          Buy Now
        </button>
      </div>
      <div className="offset-lg-2 col-md-8 mb-1">
        <button
          type="button"
          onClick={() => {
            watchListHadler();
          }}
          className="btn btn-block btn-outline-primary mt-2"
        >
          {item.watchListed ? "Remove from Watch" : "Add to Watchlist"}
        </button>
      </div>
    </>
  );
};
export default BuyNow;
