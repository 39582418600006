import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import { BIDS_OPEN_URL, OUTBOX_URL } from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { fetchMyBids } from "../../services/MyBidsService";
import { isStopFlowConditionMet } from "../../services/Utilities";
import { Pagination, PaginationComponent } from "../search/PaginationComponent";
import MessagesTable from "./MessagesTable";
import classes from "./messages.module.css";
import { fetchGetData } from "../../services/MessageService";

const Outbox = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();

  const [messages, setMessages] = useState([]);

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState();

  const fetchOutbox = async () => {
    ctx.showProgress(true);
    try {
      const pagingParams =
        currentPage + 1 && pageSize
          ? "?" + ["page=" + currentPage, "size=" + pageSize].join("&")
          : "";

      const rs = await fetchGetData(OUTBOX_URL + pagingParams);
      const msgList = [];
      rs?.content?.map((m) => {
        msgList.push({ ...m, name: m.receiver.name, read: true });
      });

      setMessages(msgList);

      setTotal(rs.totalElements);
      setTotalPages(rs.totalPages);

      ctx.showProgress(false);
    } catch (e) {
      console.log(e);
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchOutbox();
  }, []);

  useEffect(() => {
    if (pageSize && currentPage + 1) fetchOutbox();
  }, [pageSize, currentPage]);

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu messagesActive={true} subMenu="outbox" />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <div className="row">
                <div className="col mb-5">
                  <div className="px-0 mb-3">
                    <div className={classes.heading}>
                      Outbox <span>({messages?.length})</span>
                    </div>

                    <div>
                      {totalPages > 1 && (
                        <div>
                          <Pagination
                            currentPage={currentPage}
                            total={total}
                            pageSize={pageSize}
                          />
                          <PaginationComponent
                            showNumbers={false}
                            className="pagination-bar"
                            currentPage={currentPage + 1}
                            totalCount={total}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                              setCurrentPage(page - 1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <MessagesTable
                    messages={messages}
                    onClick={(messageId) => {
                      navigate("/view-message", {
                        state: { messageId: messageId, cameFrom: "outbox" },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Outbox;
