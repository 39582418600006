import React, { useEffect, useState, useContext } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import ZInputComponent from "../../components/UI/ZInputComponent";
import * as Yup from "yup";
import { PhoneTypes } from "../../components/common/PhoneTypes";
import TermsAndConditions from "./TermsAndConditions";
import SearchSelect from "../../components/form/SearchSelect";
import {
  deflateErrors,
  isJSON,
  validateUSPhoneNumber,
} from "../../services/Utilities";
import { Card } from "react-bootstrap";
import { SignupServerRequest } from "../../services/SignUpService";
import Layout from "../../layout/Layout";
import classes from "./RegisterModule.css";
import {
  CATEGORY_LIBRARY_URL,
  CITIES_LIBRARY_URL,
  COUNTRIES_LIBRARY_URL,
  ALL_STATES_LIBRARY_URL,
  STATE_CITIES_LIBRARY_URL,
} from "../../Constants";
import { LibraryService } from "../../services/LibraryService";
import { useAuthContext } from "../../redux/auth-context";
import ZMultiCheckBox from "../../components/form/ZMultiCheckBox";
import { Select } from "../../components/Select";

const PersonalInformation = () => {
  const ctx = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state ?? {};

  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const [marketingConsent, setMarketingConsent] = useState();
  const [emailPrefrences, setEmailPrefrences] = useState([]);
  const [checkedEmailPrefrences, setCheckedEmailPrefrences] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [showList, setShowList] = useState("t");

  const schema = Yup.object().shape({
    companyName: Yup.string().max(
      60,
      "Company name must be maximum of 60 characters"
    ),
    addressLineOne: Yup.string()
      .max(128, "Address must be maximum of 128 characters")
      .required("Address line 1 is required"),
    addressLineTwo: Yup.string().max(
      128,
      "Address must be maximum of 128 characters"
    ),
    country: Yup.string().required("Please select Country"),
    state: Yup.string().required("Please select State"),
    city: Yup.string().required("Please select City"),
    postalCode: Yup.string()
      .max(32, "Zip Code must be maximum of 32 characters or numbers")
      .required("Zip Code is required"),
    phoneType: Yup.string().required("Phone type is required"),
    phoneNumber: Yup.string()
      .max(32, "Phone number must be maximum of 32 characters or numbers")
      .required("Phone number is required"),
    alternatePhoneType: Yup.string(),
    alternatePhoneNumber: Yup.string().max(
      32,
      "Phone number must be maximum of 32 characters or numbers"
    ),
    marketingConsent: Yup.string().required("Marketing consent is required"),
    emailPrefrences: Yup.array(),
    userAgreement: Yup.string(),
  });

  const initialValue = {
    country: "",
    city: "",
    state: "",
    companyName: "",
    addressLineOne: "",
    addressLineTwo: "",
    postalCode: "",
    phoneNumber: "",
    emailPrefrences: [],
    userAgreement: "",
    phoneType: "",
    alternatePhoneType: "",
  };

  const myFunc = async () => {
    ctx.showProgress(true);
    LibraryService(COUNTRIES_LIBRARY_URL)
      .then((res) => {
        let myCountires = [];
        res.map((item) => {
          myCountires.push({ key: item.key, name: item.value });
        });
        setCountries(myCountires);
      })
      .catch((error) => {
        console.log(error);
      });

    const statesRs = await LibraryService(ALL_STATES_LIBRARY_URL);
    let stateList = [];
    statesRs?.map((item) => {
      stateList.push({
        id: item.id,
        name: item.key,
        country: item.value,
      });
    });

    setStates(stateList);

    const citiesRs = await LibraryService(CITIES_LIBRARY_URL);
    let cityList = [];
    citiesRs?.map((item) => {
      cityList.push({
        id: item.id,
        name: item.key,
        state: item.value,
      });
    });

    setCities(cityList);

    LibraryService(CATEGORY_LIBRARY_URL)
      .then((res) => {
        let myCategories = [];
        res.map((item) => {
          myCategories.push({ value: item.id, label: item.key });
        });
        setCategories(myCategories);
        setCheckedEmailPrefrences(new Array(myCategories.length).fill(false));
      })
      .catch((error) => {
        console.log(error);
      });

    ctx.showProgress(false);
  };

  useEffect(() => {
    myFunc();
  }, []);

  const findCitiesByState = async (value) => {
    ctx.showProgress(true);

    const citiesURL = `${STATE_CITIES_LIBRARY_URL}?state=${value}`;

    LibraryService(citiesURL)
      .then((res) => {
        let myCities = [];
        res.map((item) => {
          myCities.push({ value: item.id, label: item.key });
        });
        setCities(myCities);
      })
      .catch((error) => {
        console.log(error);
      });

    ctx.showProgress(false);
  };

  const createAccount = (values) => {
    ctx.showProgress(true);
    const accountInfo = params;
    const personalInfo = {};
    personalInfo.companyName = values.companyName;
    personalInfo.addressLineOne = values.addressLineOne;
    personalInfo.addressLineTwo = values.addressLineTwo;
    personalInfo.postalCode = values.postalCode;
    personalInfo.phoneNumber = values.phoneNumber;
    personalInfo.alternatePhoneNumber = values.alternatePhoneNumber;
    personalInfo.phoneType = values.phoneType;
    personalInfo.alternatePhoneType = values.alternatePhoneType;
    personalInfo.marketingConsent = values.marketingConsent;
    personalInfo.emailPrefrences = emailPrefrences;
    personalInfo.country = values.country;
    personalInfo.city = values.city;
    personalInfo.state = values.state;
    personalInfo.userAgreement = "t";

    accountInfo.personalInfo = personalInfo;

    console.log(accountInfo);
    SignupServerRequest(accountInfo)
      .then((data) => {
        console.log(data);
        setTimeout(() => {
          ctx.showProgress(false);
          navigate("/user-notified");
        }, 2000);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }
        const json = JSON.parse(e.message);
        console.log(json);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  const onChangeMarketingConsent = (event) => {
    console.log(event.value);
    setMarketingConsent(event.value);
  };

  const handleEmailPrefrencesOnChange = (position) => {
    let prefValues = {};
    prefValues = checkedEmailPrefrences
      .flatMap((bool, index) => (bool ? index : []))
      .map((i) => categories[i].value);

    const updatedCheckedPrefrences = checkedEmailPrefrences.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedEmailPrefrences(updatedCheckedPrefrences);

    prefValues = updatedCheckedPrefrences
      .flatMap((bool, index) => (bool ? index : []))
      .map((i) => categories[i].value);

    setEmailPrefrences(prefValues);
  };

  return (
    <Layout>
      <div className="row mr-0 ml-0">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="row">
                <div className="d-inline col-7">
                  <h2 className="pg-5 font-weight-bold purple-500">
                    Create Account
                  </h2>
                </div>
                <div className="d-inline col-5 text-right">
                  <h2
                    className=""
                    style={{ color: "#AFB0B5", fontSize: "24px" }}
                  >
                    Step: <b className="color-2">2/2</b>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <BHotrizontalProgress progress={33} />
              <p className="pg-1 mt-4 mr-10">
                Registration is quick and easy! enter your information once and
                gain access to all auction services marketplace. If you are
                already reister you can use the login credentials to bid on any
                item.
              </p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="d-inline col-6">
                  <h4 className="ca-subheading">2. Personal Information</h4>
                </div>
                <div className="d-inline col-6">
                  <h5 className="pg-1 font-weight-bold text-right">
                    <span className="color-red">* </span>Mandantory Fields
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={schema}
                onSubmit={(values) => {
                  console.log(values);
                  createAccount(values);
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  touched,
                  validateForm,
                  setFieldValue,
                }) => (
                  <form id="passportInfoForm">
                    {/* first row */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Select
                            name="country"
                            className="form-control"
                            label="Country"
                            mandatory={true}
                            defaultTouch
                            serverError={serverErrors && serverErrors.country}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setFieldValue("country", e.target.value);
                              setSelectedCountry(e?.target?.value);
                              setSelectedState(undefined);
                            }}
                          >
                            <option value="" disabled>
                              Select Country
                            </option>

                            {countries &&
                              countries.length > 0 &&
                              countries.map((country) => {
                                return (
                                  <option key={country.key} value={country.key}>
                                    {country.name}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Select
                            name="state"
                            className="form-control"
                            label="State"
                            mandatory={true}
                            defaultTouch
                            serverError={serverErrors && serverErrors.state}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setFieldValue("state", e.target.value);
                              setSelectedState(e?.target?.value);
                            }}
                          >
                            <option value="" disabled>
                              Select State
                            </option>

                            {selectedCountry &&
                              states &&
                              states.length > 0 &&
                              states
                                .filter(
                                  (item) => item.country == selectedCountry
                                )
                                .map((state) => {
                                  return (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  );
                                })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    {/* first row end */}

                    {/* 2 row */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Select
                            name="city"
                            className="form-control"
                            label="City"
                            mandatory={true}
                            defaultTouch
                            serverError={serverErrors && serverErrors.city}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setFieldValue("city", e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select City
                            </option>

                            {selectedState &&
                              cities &&
                              cities.length > 0 &&
                              cities
                                .filter((item) => item.state == selectedState)
                                .map((city) => {
                                  return (
                                    <option key={city.id} value={city.id}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="postalCode"
                            required="true"
                            component={ZInputComponent}
                            placeholder="Enter zip code"
                            label="Zip Code"
                            serverError={
                              serverErrors && serverErrors.postalCode
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* 2 row end */}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="addressLineOne"
                            required="true"
                            component={ZInputComponent}
                            placeholder="Enter address line 1"
                            label="Address Line 1"
                            serverError={
                              serverErrors && serverErrors.addressLineOne
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="addressLineTwo"
                            component={ZInputComponent}
                            placeholder="Enter address line 2"
                            label="Address Line 2"
                            serverError={
                              serverErrors && serverErrors.addressLineTwo
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* 4 row */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          {/* <label className="form-label">
                            What is phone type?
                            <span className="color-red"> *</span>
                          </label>
                          <SearchSelect
                            placeholder="Select phone type"
                            name="phoneType"
                            options={PhoneTypes}
                            onChange={(e) => {
                              setFieldValue("phoneType", e.value);
                            }}
                          /> */}

                          <Select
                            name="phoneType"
                            className="form-control"
                            label="What is phone type?"
                            mandatory={true}
                            defaultTouch
                            serverError={serverErrors && serverErrors.city}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setFieldValue("phoneType", e.target.value);
                            }}
                          >
                            <option value="" disabled>
                            Select phone type
                            </option>

                            {PhoneTypes.map((ptype) => {
                                  return (
                                    <option key={ptype.value} value={ptype.value}>
                                      {ptype.label}
                                    </option>
                                  );
                                })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="phoneNumber"
                            required="true"
                            component={ZInputComponent}
                            placeholder="Enter phone number"
                            label="Primary Phone Number"
                            validate={validateUSPhoneNumber}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 4 row end */}

                    {/* 5 row */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <label className="form-label">
                            What is alternate phone type?
                          </label>
                          <SearchSelect
                            placeholder="Select alternate phone type"
                            name="alternatePhoneType"
                            options={PhoneTypes}
                            onChange={(e) => {
                              setFieldValue("alternatePhoneType", e.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            type="text"
                            name="alternatePhoneNumber"
                            component={ZInputComponent}
                            placeholder="Enter alternate phone number"
                            label="Alternate Phone Number"
                            validate={validateUSPhoneNumber}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 5 row end */}

                    {/* 6 row */}
                    <div className="row p-3">
                      <div className="col-md-12 mb-5">
                        <label className="form-label font-weight-bold">
                          Please give your consent for electronic marketing
                          communications
                          <span className="color-red"> *</span>
                        </label>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="marketingConsent"
                            value="t"
                            id="marketingConsent1"
                            required="true"
                            onChange={(e) => {
                              setFieldValue("marketingConsent", e.target.value);
                              setShowList("t");
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="marketingConsent1"
                          >
                            Yes, I would like to receive electronic marketing
                            communications
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="marketingConsent"
                            value="f"
                            id="marketingConsent2"
                            required="true"
                            onChange={(e) => {
                              setFieldValue("marketingConsent", e.target.value);
                              setShowList("f");
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="marketingConsent2"
                          >
                            No, please do not send any electronic marketing
                            communications
                          </label>
                        </div>

                        {(touched.marketingConsent ||
                          errors.marketingConsent) && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.marketingConsent}
                          </div>
                        )}

                        {serverErrors && serverErrors.marketingConsent && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {serverErrors.marketingConsent}
                          </div>
                        )}
                      </div>

                      {showList == "t" && (
                        <div className="col-md-12">
                          <label className="form-label font-weight-bold">
                            Please select your email preferences, you may refine
                            these categories by assets in your preferences at
                            any time.
                          </label>
                          <div className="row js-form-message mb-4">
                            {categories.map(({ value, label }, index) => {
                              return (
                                <div
                                  className="col-md-6"
                                  key={index}
                                  style={{ display: "block" }}
                                >
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`ep-checkbox-${index}`}
                                      name="emailPrefrences"
                                      value={value}
                                      checked={checkedEmailPrefrences[index]}
                                      onChange={() =>
                                        handleEmailPrefrencesOnChange(index)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`ep-checkbox-${index}`}
                                    >
                                      {label}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* 6 row end */}

                    <div className="row mb-8">
                      <div className="col-md-12">
                        <p className="pl-3 font-weight-bold">
                          Please see our{" "}
                          <Link to="/privacy-policy" target="_blank" className="text-decoration-underline">
                            Privacy Policy for more information.
                          </Link>
                        </p>
                      </div>
                      <div className="col-md-12">
                        <Card style={{ width: "100%" }}>
                          <Card.Body className=" p-5">
                            <p className="pg-2">
                              By clicking the "I Agree to the User Agreement"
                              button, you are bound to the{" "}
                              <Link to="/terms" target="_blank" >user agreement.</Link>
                              If you disagree with the terms and conditions of
                              this User Agreement, you may not complete your
                              account registration, gain access, or otherwise
                              use the Sites or the Services.
                            </p>
                            <div className="row">
                              <div className="d-inline p-2 col-md-6">
                                <Link
                                  to="/login"
                                  className="btn-block btn btn-outline-primary px-5 text-transform-upper"
                                >
                                  I Disagree
                                </Link>
                              </div>
                              <div className="d-inline p-2 col-md-6">
                                <Link
                                  className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                                  type="submit"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    let res = await validateForm();
                                    console.log(res);
                                    if (res && Object.values(res)?.length > 0) {
                                      window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }
                                    handleSubmit(e);
                                  }}
                                >
                                  I Agree to the User Agreement
                                </Link>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Layout>
  );
};

export default PersonalInformation;
