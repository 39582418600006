import React, { useEffect, useState } from "react";
import BHotrizontalProgress from "../../components/UI/HorizontalProgress";
import { useNavigate, Link } from "react-router-dom";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import Layout from "../../layout/Layout";
import * as Yup from "yup";
import ZInputComponent from "../../components/UI/ZInputComponent";
import { Field, Form, Formik } from "formik";
import SearchSelect from "../../components/form/SearchSelect";
import { useAuthContext } from "../../redux/auth-context";
import { PhoneTypes } from "../../components/common/PhoneTypes";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
} from "../../services/Utilities";
import {
  UpdatePhoneNumbersRequest,
  fetchSellerAuctionConfig,
  saveSellerBankInfo,
  saveSellerTerms,
} from "../../services/UserService";
import Alert from "../../components/UI/Alert";
import Label from "../../components/UI/Label";
import DualErrorMessage from "../../components/UI/DualErrorMessage";
import ReactQuill from "react-quill";

const AddSellerTerms = () => {
  const ctx = useAuthContext();
  let navigate = useNavigate();

  const initialValue = {
    terms: "",
    termsPlain: "",
  };

  const [serverErrors, setServerErrors] = useState();
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);
  const [sellerTerms, setSellerTerms] = useState();
  const [initValues, setInitValues] = useState(initialValue);

  useEffect(() => {
    ctx.showProgress(true);
    fetchSellerAuctionConfig()
      .then((resp) => {
        setSellerTerms(resp?.sellerTerms);

        setInitValues((state) => {
          const tempState = { ...state };
          tempState.terms = resp?.sellerTerms;
          return tempState;
        });

        ctx.showProgress(false);
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;
        navigate("/error");
      });
  }, []);

  const schema = Yup.object().shape({
    termsPlain: Yup.string()
      .required("Seller terms and conditions is required")
      .max(20000, "Maximum 20000 characters allowed"),
  });

  const updateInfo = (values) => {
    let payload = {
      terms: values.terms,
    };

    ctx.showProgress(true);

    saveSellerTerms(payload)
      .then((data) => {
        console.log("info saved", data);
        ctx.showProgress(false);
        setUpdatedSuccessfully(true);
        navigate("/seller-terms-info", {
          state: { addStatus: true },
        });
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        console.log(json);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          }
        }

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  return (
    <Layout>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <AccountMenu accountInfoActive={true} subMenu={"sellerTerms"} />
          </div>
          <div className="offset-lg-1 col-lg-7 col-md-7">
            <div className="row">
              <div className="col-md-12">
                <h2 className="h3 font-weight-bold purple-500">
                  Seller Terms and Conditions
                </h2>
              </div>
              <div className="col-md-12 mb-4">
                <BHotrizontalProgress progress={33} />
              </div>

              <div className="col-md-12">
                <p className="pg-2 instructions-desc">
                  The seller terms and conditions field will be filled
                  automatically with this information when you list a new item.
                  You may update this information when creating a new item.
                </p>
              </div>

              <div className="col-md-12">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={initValues}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    console.log(values);
                    updateInfo(values);
                  }}
                >
                  {({
                    handleSubmit,
                    isSubmitting,
                    errors,
                    validateForm,
                    setFieldValue,
                  }) => (
                    <form id="passportInfoForm">
                      {updatedSuccessfully && (
                        <Alert
                          type="success"
                          children="Seller terms and conditions updated successfully."
                        />
                      )}
                      {serverErrors && (
                        <Alert
                          type="error"
                          children="Please update your Seller terms and conditions first."
                        />
                      )}

                      <div className="js-form-message mb-4">
                        <Label hightlighted required>
                          Terms and Conditions
                        </Label>

                        <ReactQuill
                          theme="snow"
                          value={sellerTerms}
                          onChange={(m) => {
                            setSellerTerms(m);

                            const ele = document.createElement("div");
                            ele.innerHTML = m;

                            setFieldValue("termsPlain", ele.textContent);
                            setFieldValue("terms", m);
                          }}
                          placeholder="Enter Terms and Conditions"
                          rows
                        />

                        <DualErrorMessage
                          fieldName="termsPlain"
                          serverError={serverErrors && serverErrors.terms}
                        />
                      </div>

                      <div className="row">
                        <div className="d-inline p-3 col-md-6">
                          <button
                            className="btn-block btn btn-outline-primary px-5 text-transform-upper"
                            type="button"
                            onClick={async (e) => {
                              e.preventDefault();
                              navigate("/seller-terms-info");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="d-inline p-3 col-md-6">
                          <Link
                            className="btn-block btn btn-primary-dark-w px-5 text-transform-upper"
                            type="submit"
                            onClick={async (e) => {
                              e.preventDefault();
                              let res = await validateForm();
                              console.log(res);
                              if (res && Object.values(res)?.length > 0) {
                              }
                              handleSubmit(e);
                            }}
                          >
                            Save
                          </Link>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  );
};

export default AddSellerTerms;
