import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import { Select } from "../../components/Select";
import ErrorMessage from "../../components/UI/ErrorMessage";
import ZInputComponent from "../../components/UI/ZInputComponent";
import {
  newMessageErrorMsg,
  NEW_MESSAGE_RECIPIENT_URL,
  NEW_MESSAGE_URL,
} from "../../Constants";
import Layout from "../../layout/Layout";
import { useAuthContext } from "../../redux/auth-context";
import { fetchGetData, postUserMessage } from "../../services/MessageService";
import {
  deflateErrors,
  isJSON,
  isStopFlowConditionMet,
  scrollTo,
} from "../../services/Utilities";

const NewMessage = () => {
  const navigate = useNavigate();
  const ctx = useAuthContext();
  const location = useLocation();

  const [message, setMessage] = useState("");
  const [recipients, setRecipients] = useState([]);

  const [pageErrors, setPageErrors] = useState();
  const [serverErrors, setServerErrors] = useState();

  const fetchMessageRecipient = async () => {
    ctx.showProgress(true);
    try {
      const rs = await fetchGetData(NEW_MESSAGE_RECIPIENT_URL);
      console.log("recipient", rs);
      setRecipients(rs);
      ctx.showProgress(false);
    } catch (e) {
      ctx.showProgress(false);
      if (isStopFlowConditionMet(e.message)) return;
      navigate("/error");
    }
  };

  const sendMessage = (values) => {
    ctx.showProgress(true);
    postUserMessage(NEW_MESSAGE_URL, values)
      .then((respose) => {
        ctx.showProgress(false);
        navigate("/outbox");
      })
      .catch((e) => {
        ctx.showProgress(false);
        if (!isJSON(e.message)) {
          navigate("/error");
          return;
        }

        if (isStopFlowConditionMet(e.message)) return;

        const json = JSON.parse(e.message);
        if (json.status === "BAD_REQUEST") {
          if (json.data.status === "VALIDATION_FAILED") {
            setServerErrors(deflateErrors(json.data.errors));
          } else {
            if (json.data.status === "NOT_FOUND") {
              navigate("/error");
            }
          }
        }
      });
  };

  useEffect(() => {
    fetchMessageRecipient();
  }, []);

  const initialValue = {
    receiver: "",
    subject: "",
    content: "",
  };

  const [formValue, setFormValue] = useState(initialValue);

  useEffect(() => {
    console.log("location obj", location);

    if (location?.state) {
      setFormValue((state) => {
        const tempState = { ...state };

        tempState.receiver = location.state.to;
        tempState.subject = location.state.subject;

        return tempState;
      });
    }
  }, [location]);

  const schema = Yup.object().shape({
    receiver: Yup.string().required("Recipient is required"),
    subject: Yup.string()
      .required("Subject is required")
      .max(128, "Subject cannot be more than 128 characters"),
    content: Yup.string()
      .required("Message content is required")
      .max(2048, "Message is too big"),
  });

  return (
    <>
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <AccountMenu messagesActive={true} subMenu="new-message" />
            </div>

            <div className="offset-lg-1 col-lg-8 col-md-8">
              <div className="row" id="pageError">
                <div className="col-12">
                  {pageErrors?.message && (
                    <ErrorMessage>{pageErrors.message}</ErrorMessage>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col mt-5 mb-5">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={formValue}
                    validationSchema={schema}
                    onSubmit={(values) => {
                      sendMessage(values);
                    }}
                  >
                    {({
                      handleSubmit,
                      errors,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form id="passportInfoForm">
                        <div className="js-form-message mb-4">
                          <Select
                            name="receiver"
                            className="form-control"
                            mandatory={true}
                            disabled
                            defaultTouch
                            serverError={serverErrors && serverErrors.receiver}
                            onChange={(e) => {
                              setFieldValue("receiver", e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Recipient
                            </option>

                            {recipients &&
                              recipients.length > 0 &&
                              recipients.map((r) => {
                                return (
                                  <option key={r.id} value={r.id}>
                                    {r.name}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>

                        <div className="js-form-message mb-4">
                          <Field
                            className="form-control"
                            name="subject"
                            component={ZInputComponent}
                            placeholder="Enter subject here"
                            serverError={serverErrors && serverErrors.subject}
                          />
                        </div>

                        <div className="js-form-message mb-4">
                          <ReactQuill
                            theme="snow"
                            value={message}
                            onChange={(m) => {
                              console.log(m);
                              setMessage(m);
                              setFieldValue("content", m);
                            }}
                            placeholder="Enter your message here"
                            rows
                          />

                          {errors && errors["content"] && (
                            <ErrorMessage>{errors["content"]}</ErrorMessage>
                          )}
                        </div>

                        <div className="js-form-message mb-4">
                          <button
                            className="btn btn-primary-dark-w px-5"
                            type="submit"
                            onClick={async (e) => {
                              e.preventDefault();
                              let res = await validateForm();
                              if (res && Object.values(res)?.length > 0) {
                                scrollTo("pageError");
                                setPageErrors({ message: newMessageErrorMsg });
                              }
                              handleSubmit(e);
                            }}
                          >
                            Send Message
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NewMessage;
