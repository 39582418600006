import { useRef } from "react";
import gteIcon from "../../assets/images/gte.svg";

const AccountMenuItem = ({
  title,
  active,
  onToggle,
  single = false,
  className,
  ...props
}) => {
  //const { question, answer } = faq;

  const contentEl = useRef();

  return (
    <li className={`accordion_item ${active ? "active" : ""} ${className}`}>
      <button className="button" onClick={onToggle}>
        {title}
        {!single && (
          <span className="control">
            {active ? (
              <img src={gteIcon} style={{ transform: `rotate(-90deg)` }} />
            ) : (
              <img src={gteIcon} />
            )}{" "}
          </span>
        )}
        {single && (
          <span className="control">
            <img src={gteIcon} />
          </span>
        )}
      </button>
      {!single && (
        <div
          ref={contentEl}
          className="innerpanel_wrapper"
          style={
            active
              ? { height: contentEl?.current?.scrollHeight }
              : { height: "0px" }
          }
        >
          <div className="innerpanel">{props.children}</div>
        </div>
      )}
    </li>
  );
};

export default AccountMenuItem;
